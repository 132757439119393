import clsx from 'clsx'
import React from 'react'
import { Tooltip } from '@mui/material'

interface HeaderActionButtonProps {
  label?: string
  icon?: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  tooltip?: string
}
const HeaderActionButton = ({
  label,
  icon,
  tooltip,
  onClick,
  disabled,
}: HeaderActionButtonProps) => {
  return (
    <Tooltip title={tooltip}>
      <div
        onClick={disabled ? undefined : onClick}
        className={clsx(
          'p-2.5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 focus:z-10 focus:ring-4 focus:ring-gray-200 ',
          disabled
            ? 'opacity-50 cursor-not-allowed'
            : 'hover:bg-gray-100 hover:text-blue-700 cursor-pointer'
        )}
      >
        <div className="flex flex-row items-center">
          {icon}
          {label}
        </div>
      </div>
    </Tooltip>
  )
}

export default HeaderActionButton

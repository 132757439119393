import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'lucide-react'
import { Tooltip } from '@mui/material'
import BaseHeader from './BaseHeader'
import HeaderActionButton from '../../components/HeaderActionButton'
import { BaseObject } from './types'

interface SubHeaderProps {
  object: BaseObject
  type: 'analysis' | 'data_set' | 'agent' | 'alert' | 'data_union'
  subtitle: string
  rightContent?: React.ReactNode
  image?: React.ReactNode
}

const SubHeader: React.FC<SubHeaderProps> = ({ object, type, subtitle, rightContent, image }) => {
  const subRightContent = (
    <>
      {rightContent}

      <Tooltip title="Back">
        <Link to={`/${type}/${object.uuid}`}>
          <HeaderActionButton icon={<ArrowLeft className="size-4" />} />
        </Link>
      </Tooltip>
    </>
  )

  return (
    <BaseHeader
      title={object.name}
      description={object.description}
      subtitle={subtitle}
      rightContent={subRightContent}
      type={type}
      uuid={object.uuid}
      image={image}
    />
  )
}

export default SubHeader

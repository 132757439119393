import { BotIcon, DatabaseIcon } from 'lucide-react'
import {
  PresentationChartBarIcon,
  BellAlertIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline'

export type ObjectType = 'agent' | 'alert' | 'analysis' | 'data_set' | 'data_union'

export const objectTypeToIcon = {
  agent: BotIcon,
  alert: BellAlertIcon,
  analysis: PresentationChartBarIcon,
  data_set: TableCellsIcon,
  data_union: DatabaseIcon,
}

export interface BaseObject {
  uuid: string
  name: string
  description: string
  image?: string
}

import { gql } from '@apollo/client'
import { ANALYSIS_FIELDS, ANALYSIS_RUN_FIELDS, ANALYSIS_TEMPLATE_FIELDS } from './fragments'

export const GET_ANALYSES_QUERY = gql`
  query analyses($agentUuid: ID) {
    analyses(agentUuid: $agentUuid) {
      ...AnalysisFields
    }
  }
  ${ANALYSIS_FIELDS}
`

export const GET_ANALYSIS_QUERY = gql`
  query analysis($uuid: ID!) {
    analysis(uuid: $uuid) {
      ...AnalysisFields
    }
  }

  ${ANALYSIS_FIELDS}
`

export const CREATE_ANALYSIS_MUTATION = gql`
  mutation createAnalysis(
    $name: String!
    $description: String!
    $instructions: String!
    $outputFormat: [ContentBlockFormatInput!]!
    $agentUuid: String!
    $webhookUrl: String
    $cronSchedule: String
    $knowledge: [KnowledgeInput!]
    $fileAttachments: [String!]
    $notes: [NoteInput!]
  ) {
    createAnalysis(
      name: $name
      description: $description
      instructions: $instructions
      outputFormat: $outputFormat
      agentUuid: $agentUuid
      webhookUrl: $webhookUrl
      cronSchedule: $cronSchedule
      knowledge: $knowledge
      fileAttachments: $fileAttachments
      notes: $notes
    ) {
      analysis {
        uuid
      }
      success
      errors
    }
  }
`
export const UPDATE_ANALYSIS_MUTATION = gql`
  mutation updateAnalysis(
    $uuid: String!
    $name: String!
    $description: String!
    $instructions: String!
    $outputFormat: [ContentBlockFormatInput!]!
    $agentUuid: String!
    $webhookUrl: String
    $cronSchedule: String
    $knowledge: [KnowledgeInput!]
    $fileAttachments: [String!]
    $notes: [NoteInput!]
  ) {
    updateAnalysis(
      uuid: $uuid
      name: $name
      description: $description
      instructions: $instructions
      outputFormat: $outputFormat
      agentUuid: $agentUuid
      webhookUrl: $webhookUrl
      cronSchedule: $cronSchedule
      knowledge: $knowledge
      fileAttachments: $fileAttachments
      notes: $notes
    ) {
      success
      errors
    }
  }
`

export const DELETE_ANALYSIS_MUTATION = gql`
  mutation deleteAnalysis($uuid: String!) {
    deleteAnalysis(uuid: $uuid) {
      success
      errors
    }
  }
`
export const MUTATION_SUBMIT_RUN_ANALYSIS = gql`
  mutation submitRunAnalysis($uuid: String!) {
    submitRunAnalysis(uuid: $uuid) {
      success
      errors
    }
  }
`

export const GET_LATEST_ANALYSIS_RUN_QUERY = gql`
  query lastAnalysisRun($uuid: ID!) {
    lastAnalysisRun(uuid: $uuid) {
      ...AnalysisRunFields
    }
  }
  ${ANALYSIS_RUN_FIELDS}
`

export const GET_HISTORIC_ANALYSIS_RUNS_QUERY = gql`
  query historicAnalysisRuns($uuid: ID!) {
    historicAnalysisRuns(uuid: $uuid) {
      ...AnalysisRunFields
    }
  }
  ${ANALYSIS_RUN_FIELDS}
`

export const GET_ONE_ANALYSIS = gql`
  query oneAnalysisRun($uuid: ID!) {
    oneAnalysisRun(uuid: $uuid) {
      ...AnalysisRunFields
    }
  }
  ${ANALYSIS_RUN_FIELDS}
`

export const GET_ANALYSIS_TEMPLATES_QUERY = gql`
  query analysisTemplates($agentUuid: ID!) {
    analysisTemplates(agentUuid: $agentUuid) {
      ...AnalysisTemplateFields
    }
  }
  ${ANALYSIS_TEMPLATE_FIELDS}
`

export const GET_AGENT_ANALYSES_QUERY = gql`
  query agentAnalyses($agentUuid: ID!) {
    agentAnalyses(agentUuid: $agentUuid) {
      ...AnalysisFields
    }
  }
  ${ANALYSIS_FIELDS}
`

export const CREATE_ANALYSIS_FROM_TEMPLATE = gql`
  mutation createAnalysisFromTemplate($agentUuid: String!, $templateId: String!) {
    createAnalysisFromTemplate(agentUuid: $agentUuid, templateId: $templateId) {
      success
      errors
    }
  }
`

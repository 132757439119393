/**
 * Types for the plan system that handles various steps (Analysis, Alert, DataSet, Agent, etc.)
 * These types define the structure of plans that can be created, viewed, and edited.
 */

export enum BackendEnum {
  Snowflake = 'snowflake',
  BigQuery = 'bigquery',
  Looker = 'looker',
}

export enum StepTypeEnum {
  Alert = 'alert',
  Analysis = 'analysis',
  Agent = 'agent',
  DataSet = 'data_set',
  EmailUpdate = 'email_update',
  DataUnion = 'data_union',
}

export interface UuidReference {
  type: 'plan' | 'backend'
  value: string
}

export interface Attachment {
  prompt: string
  file_type: 'excel' | 'csv' | 'image' | 'text'
}

export interface Task {
  name: string
  description: string
  task_prompt: string
  uuid: string
  type: string
}

export interface BaseStepType {
  type: StepTypeEnum
  name?: string
  description?: string
  uuid: UuidReference
}

export interface AlertStepType extends BaseStepType {
  type: StepTypeEnum.Alert
  name: string
  description: string
  agent_uuid: UuidReference
  tasks: Task[]
  cron_schedule?: string
}

export interface AnalysisStepType extends BaseStepType {
  type: StepTypeEnum.Analysis
  name: string
  description: string
  instructions: string
  output_format: Array<{
    type: string
    prompt: string
  }>
  tasks?: Task[]
  agent_uuid: UuidReference
}

export interface AgentStepType extends BaseStepType {
  type: StepTypeEnum.Agent
  first_name: string
  job_title?: string
  short_description: string
  long_description: string
  system_instruction: string
  data_sets?: DataSetRef[]
  data_unions?: DataSetRef[]
}

export interface EmailUpdateStepType extends BaseStepType {
  type: StepTypeEnum.EmailUpdate
  name: string
  description: string
  agent_uuid: UuidReference
  tasks?: Task[]
  synthesis?: string
  attachments?: Attachment[]
}

export interface DataSetStepType extends BaseStepType {
  type: StepTypeEnum.DataSet
  name: string
  description: string
  backend: BackendEnum
  definition: TableDefinition
}

export interface DataUnionStepType extends BaseStepType {
  type: StepTypeEnum.DataUnion
  name: string
  description: string
  definition: {
    data_set_uuid: UuidReference
    joins: Array<{
      data_set_uuid: UuidReference
      type: 'inner' | 'left_outer' | 'right_outer' | 'full_outer' | 'cross'
      relationship: 'one_to_one' | 'one_to_many' | 'many_to_one' | 'many_to_many'
      sql_on: string
    }>
  }
}

export type StepType =
  | AlertStepType
  | AnalysisStepType
  | AgentStepType
  | EmailUpdateStepType
  | DataSetStepType
  | DataUnionStepType

export interface DataSetRef {
  uuid: UuidReference
}

export interface TableDefinition {
  type: BackendEnum.Snowflake | BackendEnum.BigQuery
  table_definition: {
    type: 'table' | 'view'
    schema?: string
    table_name: string
    sql?: string
  }
}

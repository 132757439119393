import React from 'react'
import { ChevronRight } from 'lucide-react'
import { ObjectType, objectTypeToIcon } from './types'
import { Link } from 'react-router-dom'

interface BaseHeaderProps {
  title: string
  description: string
  rightContent?: React.ReactNode
  subtitle?: string
  type: ObjectType
  uuid: string
  image?: React.ReactNode
}

const BaseHeader: React.FC<BaseHeaderProps> = ({
  title,
  description,
  rightContent,
  subtitle,
  type,
  uuid,
  image,
}) => {
  const Icon = objectTypeToIcon[type]

  return (
    <div
      className="flex flex-row space-x-2 items-center rounded-lg px-4 py-2 -mx-4 mb-6 shadow-sm ring-1 ring-inset ring-blue-300"
      style={{
        backgroundImage:
          'linear-gradient(90deg, rgba(115, 49, 255, 0.1) 0%, rgba(0, 163, 255, 0.1) 16.4%, rgba(78, 81, 255, 0.1) 51.6%, rgba(57, 219, 255, 0.1) 79%, rgba(184, 79, 241, 0.1) 91.5%, rgba(115, 49, 255, 0.1) 100%)',
      }}
    >
      <div className="font-mono text-sm items-start text-blue-600" aria-hidden="true">
        {type === 'agent' && image ? image : <Icon className="size-6" />}
      </div>
      <div className="h-12 w-px bg-blue-600/20"></div>
      <div className="flex flex-col flex-grow">
        <div className="flex flex-row space-x-2 items-center text-lg font-medium tracking-tight">
          <div>
            <Link className="hover:underline" to={`/${type}/${uuid}`}>
              {title}
            </Link>
          </div>
          {subtitle && (
            <>
              <ChevronRight className="size-4" />
              <div className="text-sm font-normal text-gray-500">{subtitle}</div>
            </>
          )}
        </div>
        <div className="text-sm font-normal max-w-2xl text-gray-500 line-clamp-1 text-ellipsis">
          {description}
        </div>
      </div>

      {rightContent && <div className="flex flex-row space-x-2 items-center">{rightContent}</div>}
    </div>
  )
}

export default BaseHeader

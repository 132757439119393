import { useEffect, useRef, useCallback, useState } from 'react'
import { createConsumer } from '@rails/actioncable'
import { v4 as uuidv4 } from 'uuid'
import {
  ConversationItem,
  ConversationMessage,
  ConversationSideEffect,
  ConversationSideEffectItem,
  ConversationStepItem,
  ConversationSteps,
  ConversationTextItem,
  EventItem,
} from '../slices/chatSlice'
import { ActorEnum, FunctionToolCall, MessageStatusEnum } from '../../graphql'

const TIME_LIMIT = 1200 // Time limit in seconds (20 minutes)

interface UseAudioConversationProps {
  channel: string
  isRecording: boolean
  opts: any
}

export interface OutputConversationItem {
  role: string
  createdAt: string
  content: string[]
}

const useAudioConversation = ({
  channel,
  isRecording,
  opts = {},
}: UseAudioConversationProps & { opts: any }) => {
  const streamId = uuidv4()
  const subscriptionRef = useRef<any>(null)
  const cableRef = useRef<any>(null)
  const timerRef = useRef<NodeJS.Timeout | null>(null) // Ref to store the timer
  const countdownRef = useRef<NodeJS.Timeout | null>(null)
  const delayTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [timeLeft, setTimeLeft] = useState(TIME_LIMIT)
  const [conversation, setConversation] = useState<ConversationItem[]>([])
  const [isSessionReady, setIsSessionReady] = useState(false)
  const [pendingQueue, setPendingQueue] = useState<ConversationItem[]>([])
  const [isResponding, setIsResponding] = useState(false)

  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
  const host = window.location.hostname
  const port = window.location.port ? `:${window.location.port}` : ''
  const cableURL = `${protocol}//${host}${port}/cable`

  const startConversation = useCallback(() => {
    // Prevent multiple connections
    if (cableRef.current) {
      return
    }

    // Create the subscription
    cableRef.current = createConsumer(cableURL)
    subscriptionRef.current = cableRef.current.subscriptions.create(
      { channel, stream_id: streamId, opts },
      {
        received(data: any) {
          const messageType = data.type || data.message_type
          console.log(messageType, data)
          if (messageType === 'error') {
            console.error('Error from server:', data.data)
          } else if (messageType === 'shutdown') {
            stopConversation()
          } else {
            handleResponse(messageType, data)
          }
        },
      }
    )

    // Reset `timeLeft` whenever a new conversation starts
    setTimeLeft(TIME_LIMIT)

    // Start a countdown interval to update `timeLeft` every second
    countdownRef.current = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft <= 1) {
          stopConversation()
          return 0
        }
        return prevTimeLeft - 1
      })
    }, 1000)

    // Set a timer to stop the conversation automatically after the limit
    timerRef.current = setTimeout(() => {
      stopConversation()
    }, TIME_LIMIT * 1000)
  }, [streamId, cableURL])

  const stopConversation = useCallback(() => {
    // Unsubscribe if there is an active subscription
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe()
      subscriptionRef.current = null
    }

    // Close the cable connection
    if (cableRef.current) {
      cableRef.current = null
    }

    // Clear all timers
    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }
    if (countdownRef.current) {
      clearInterval(countdownRef.current)
      countdownRef.current = null
    }
    if (delayTimeoutRef.current) {
      clearTimeout(delayTimeoutRef.current)
      delayTimeoutRef.current = null
    }

    // Reset time left when conversation is stopped
    setTimeLeft(0)

    setIsSessionReady(false)
  }, [])

  const sendAudioData = (audioData: Float32Array) => {
    const base64Data = base64EncodeAudio(audioData)
    subscriptionRef.current?.perform('send_audio_chunk', {
      stream_id: streamId,
      audio_chunk: base64Data,
    })
  }

  const floatTo16BitPCM = (float32Array: Float32Array) => {
    const buffer = new ArrayBuffer(float32Array.length * 2)
    const view = new DataView(buffer)
    for (let i = 0; i < float32Array.length; i++) {
      const s = Math.max(-1, Math.min(1, float32Array[i]))
      view.setInt16(i * 2, s < 0 ? s * 0x8000 : s * 0x7fff, true)
    }
    return buffer
  }

  const base64EncodeAudio = (float32Array: Float32Array) => {
    const arrayBuffer = floatTo16BitPCM(float32Array)
    const bytes = new Uint8Array(arrayBuffer)
    return window.btoa(String.fromCharCode(...bytes))
  }

  const updateItemEvents = (itemId: string, event: EventItem) => {
    setPendingQueue((prev) =>
      prev.map((item) =>
        item.itemId === itemId ? { ...item, events: [...item.events, event] } : item
      )
    )
  }

  const convertStatus = (status: string | MessageStatusEnum) => {
    if (typeof status === 'string') {
      switch (status) {
        case 'completed':
          return MessageStatusEnum.Completed
        case 'in_progress':
          return MessageStatusEnum.InProgress
        case 'cancelled':
          return MessageStatusEnum.Cancelled
        default:
          return MessageStatusEnum.InProgress
      }
    }
    return status
  }

  const updateItemStatus = (itemId: string, status: MessageStatusEnum) => {
    setPendingQueue((prev) =>
      prev.map((item) =>
        item.itemId === itemId
          ? {
              ...item,
              status,
            }
          : item
      )
    )
  }

  const updateFunctionCallArguments = (itemId: string, functionArguments: any) => {
    setPendingQueue((prev) =>
      prev.map((item) => {
        if (item.itemId === itemId) {
          if (item.type == 'step') {
            const oneItem = {
              ...item,
            } as ConversationStepItem

            if (oneItem.type === 'step' && oneItem.content.steps[0].type === 'function_call') {
              let parsedArguments = {}
              try {
                parsedArguments = JSON.parse(functionArguments || '{}')
              } catch (error) {
                console.error('Failed to parse function arguments:', error)
              }

              ;(oneItem.content.steps[0] as FunctionToolCall).function.arguments = parsedArguments
            }
            return oneItem
          } else if (item.type == 'side_effect') {
            const oneItem = {
              ...item,
            } as ConversationSideEffectItem
            oneItem.content.data = JSON.parse(functionArguments || '{}')
            return oneItem
          }
        }
        return item
      })
    )
  }

  const updateMessageContent = (itemId: string, content: string) => {
    setPendingQueue((prev) =>
      prev.map((item) => {
        if (item.itemId === itemId) {
          const oneItem = {
            ...item,
          } as ConversationTextItem
          oneItem.content.message[0] = {
            type: 'text',
            value: content,
          }
          return oneItem
        }
        return item
      })
    )
  }

  const updateFunctionCallOutput = (itemId: string, output: any) => {
    console.log('updateFunctionCallOutput', itemId, output)
    setPendingQueue((prev) =>
      prev.map((item) => {
        if (item.itemId === itemId) {
          const oneItem = {
            ...item,
          } as ConversationStepItem
          console.log('oneItem', oneItem)
          if (oneItem.type === 'step' && oneItem.content.steps[0].type === 'function_call') {
            ;(oneItem.content.steps[0] as FunctionToolCall).function.output = output
          }
          return oneItem
        }
        return item
      })
    )
  }

  const addContentToConversationMessage = (itemId: string, content: any) => {
    setConversation((prev) =>
      prev.map((item) => {
        if (item.itemId === itemId) {
          ;(item.content as ConversationMessage).message.push(content)
          return item
        }
        return item
      })
    )
  }

  const handleResponse = useCallback(
    (responseType: string, data: any) => {
      const createdAt = data.created_at ? new Date(data.created_at) : new Date()
      console.log('responseType', responseType)
      console.log('data', data)

      // ignore any events from existing items that are geting replayed
      if (responseType == 'conversation.item.created') {
        if (data.item.id.startsWith('existing_item_')) {
          return
        }
      }

      // 1. Update or create items in pendingQueue
      if (
        responseType === 'conversation.item.created' &&
        data.item.type === 'function_call_output'
      ) {
        updateFunctionCallOutput(data.previous_item_id, data.item.output)
        updateItemStatus(data.previous_item_id, MessageStatusEnum.Completed)
      } else if (responseType === 'conversation.item.created') {
        let role = data.item.role
        let status = convertStatus(data.item.status)
        if (
          data.item.role === 'user' &&
          data.item.content.length === 1 &&
          data.item.content[0].type === 'input_audio' &&
          data.item.content[0].transcript === null
        ) {
          // the model immediately can take in the raw audio, but we don't want to be
          // complete until we see the transcript
          status = MessageStatusEnum.InProgress
        }

        let newItem: ConversationItem = {
          itemId: data.item.id,
          previousItemId: data.previous_item_id,
          previousItemUuid: null,
          uuid: uuidv4(),
          events: [],
          status: status,
          role: role == 'user' ? ActorEnum.User : ActorEnum.System,
          createdAt: createdAt.toISOString(),
          createdAtMillis: createdAt.getTime(),
          type: null,
          content: null,
        }

        if (data.item.type === 'function_call') {
          const functionName = data.item.name

          if (functionName.startsWith('side_effect')) {
            newItem = {
              ...newItem,
              type: 'side_effect',
              content: {
                type: 'side_effect',
                sideEffect: functionName,
                data: '',
                output: '',
              } as ConversationSideEffect,
            } as ConversationItem
          } else {
            newItem = {
              ...newItem,
              type: 'step',
              content: {
                type: 'steps',
                steps: [
                  {
                    __typename: 'FunctionToolCall',
                    description: data.item.description,
                    function: {
                      __typename: 'ToolFunction',
                      arguments: data.item.arguments,
                      name: data.item.name,
                      output: '',
                    },
                    name: data.item.label,
                    type: 'function_call',
                  },
                ],
              } as ConversationSteps,
            } as ConversationItem
          }
          role = 'assistant'
        } else {
          // regular message
          newItem = {
            ...newItem,
            type: 'text',
            content: {
              type: 'message',
              message: [{ type: 'text', value: '' }],
            } as ConversationMessage,
          } as ConversationItem
        }

        setPendingQueue((prev) => [...prev, newItem])
      } else if (responseType == 'session.created') {
        // do nothing - we want to wait for the session.updated event because
        // we make changes to defaults
      } else if (responseType == 'session.updated') {
        // do nothing - we want to wait for session.ready event
      } else if (responseType == 'session.ready') {
        setIsSessionReady(true)
      } else if (responseType == 'response.output_item.done') {
        if (data.item.type === 'function_call') {
          // do nothing
        } else if (data.item.type === 'message') {
          addContentToConversationMessage(data.item_id, data.item.content)
        }
      } else if (responseType === 'response.done') {
        data.response.output.forEach((output: any) => {
          if (output.type === 'function_call') {
            updateFunctionCallArguments(output.id, output.arguments)
            // don't update the status here, we want to wait for the output to be done
          } else if (output.type === 'message') {
            updateItemStatus(output.id, convertStatus(output.status))
          }
        })
        setIsResponding(false)
      } else if (responseType == 'response.created') {
        setIsResponding(true)
      } else if (responseType === 'response.text.done') {
        updateItemEvents(data.item_id, {
          type: responseType,
          eventId: data.event_id,
          outputIndex: data.output_index,
          contentIndex: data.content_index,
          content: data.text,
          createdAt: createdAt.toISOString(),
        })
        updateMessageContent(data.item_id, data.text)
      } else if (responseType == 'response.function_call_arguments.delta') {
        updateItemEvents(data.item_id, {
          type: responseType,
          eventId: data.event_id,
          outputIndex: data.output_index,
          content: data.delta,
          createdAt: createdAt.toISOString(),
        })
      } else if (responseType === 'response.function_call_arguments.done') {
        updateItemEvents(data.item_id, {
          type: responseType,
          eventId: data.event_id,
          outputIndex: data.output_index,
          content: data.arguments,
          createdAt: createdAt.toISOString(),
        })
        updateFunctionCallArguments(data.item_id, data.arguments)
      } else if (responseType === 'conversation.item.input_audio_transcription.completed') {
        updateItemEvents(data.item_id, {
          type: responseType,
          eventId: data.event_id,
          outputIndex: data.output_index,
          contentIndex: data.content_index,
          content: data.transcript,
          createdAt: createdAt.toISOString(),
        })
        updateMessageContent(data.item_id, data.transcript)
        updateItemStatus(data.item_id, MessageStatusEnum.Completed)
      } else if (responseType === 'response.output_item.done') {
        updateItemStatus(data.item.id, convertStatus(data.item.status))
      } else if (
        responseType === 'response.text.delta' ||
        responseType === 'response.audio_transcript.delta'
      ) {
        updateItemEvents(data.item_id, {
          type: responseType,
          eventId: data.event_id,
          outputIndex: data.output_index,
          contentIndex: data.content_index,
          content: data.delta,
          createdAt: createdAt.toISOString(),
        })
      } else {
        console.log('unknown response type', responseType)
        console.log(data)
      }

      // 2. Re-sort the pendingQueue in one setState
      setPendingQueue((prevQueue) => {
        // Make a copy
        const newQueue = [...prevQueue]

        // Sort logic (you had this in your code already):
        const sortedConversation: ConversationItem[] = []
        const itemsMap = new Map<string, ConversationItem>()
        newQueue.forEach((item) => itemsMap.set(item.itemId, item))

        newQueue.forEach((item) => {
          if (!item.previousItemId) {
            sortedConversation.push(item)
          } else {
            const previousIndex = sortedConversation.findIndex(
              (convItem) => convItem.itemId === item.previousItemId
            )
            if (previousIndex !== -1) {
              sortedConversation.splice(previousIndex + 1, 0, item)
            } else {
              sortedConversation.push(item)
            }
          }
        })

        // Ensure each item has a uuid
        sortedConversation.forEach((item) => {
          if (!item.uuid) {
            item.uuid = uuidv4()
          }
        })

        return sortedConversation
      })

      // 3. Pop items from the queue that are ready (events.length > 0)
      //    and move them into `conversation` in a single operation
      setPendingQueue((prevQueue) => {
        const newQueue = [...prevQueue]
        const readyItems: ConversationItem[] = []

        // You can refine this logic. Right now, we pop items from the front
        // until we find an item that has no events
        while (newQueue.length > 0 && newQueue[0].status === MessageStatusEnum.Completed) {
          const nextItem = newQueue.shift()
          if (!nextItem) {
            break
          }
          readyItems.push(nextItem)
        }

        // Only call setConversation once, with all items we popped.
        if (readyItems.length > 0) {
          setConversation((prev) => [...prev, ...readyItems])
        }

        return newQueue
      })
    },
    [pendingQueue]
  )

  useEffect(() => {
    console.log('pendingQueue', pendingQueue)
    console.log('conversation', conversation)
  }, [pendingQueue, conversation])

  // Start/stop conversation based on isRecording
  useEffect(() => {
    if (isRecording) {
      startConversation()
    } else {
      stopConversation()
    }
  }, [isRecording, startConversation, stopConversation])

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      stopConversation()
    }
  }, [stopConversation])

  return {
    sendAudioData,
    timeLeft,
    timeLimit: TIME_LIMIT,
    conversation,
    isSessionReady,
    pendingQueue,
    isResponding,
  }
}

export default useAudioConversation

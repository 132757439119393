import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'
import InviteModal from './InviteModal'
import { RootState } from '../../../store'
import { RESEND_EMAIL_INVITE_MUTATION, USERS_QUERY } from '../../../graphql/queries/user'
import LinearProgress from '@mui/material/LinearProgress'
import { snakeToHumanReadable } from '../../../../../components/common/utils/humanReadable'
import PageHeader from '../../../components/PageHeader'
import HeaderActionButton from '../../../components/HeaderActionButton'
import { format } from 'date-fns'
import {
  OrganizationUsersQuery,
  ResendEmailInviteMutation,
  ResendEmailInviteMutationVariables,
} from '../../../../../components/graphql'
import Spin from '../../../../../components/common/ui/Spin'

const MyTeam = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [resendingInvites, setResendingInvites] = useState<Set<string>>(new Set())
  const { user, organization } = useSelector((state: RootState) => state.app)
  const page = 1
  const perPage = 100
  const { loading, data, refetch } = useQuery(USERS_QUERY, {
    variables: {
      organizationId: organization.id,
      page,
      perPage,
    },
  })

  const [resendEmailInvite] = useMutation<
    ResendEmailInviteMutation,
    ResendEmailInviteMutationVariables
  >(RESEND_EMAIL_INVITE_MUTATION)

  useEffect(() => {
    refetch()
  }, [open])

  const handleAddUser = () => {
    setOpen(true)
  }

  const resendInvite = async (id: string) => {
    setResendingInvites(new Set(Array.from(resendingInvites).concat([id])))
    const output = await resendEmailInvite({
      variables: {
        id,
      },
    })
    setResendingInvites(
      new Set(Array.from(resendingInvites).filter((resendingId) => resendingId !== id))
    )
    if (output.data?.resendEmailInvite?.success) {
      window.toastr.success('Invite sent!')
    } else {
      window.toastr.error('There was an error with the invite')
    }
  }

  const people = (data && data.items) || []
  return (
    <React.Fragment>
      {open && <InviteModal open={open} setOpen={setOpen} />}
      <PageHeader title="My Team" breadCrumbs={[{ title: 'My Team' }]}>
        {user?.isOrganizationAdmin && (
          <HeaderActionButton onClick={handleAddUser} label="Add users" />
        )}
      </PageHeader>

      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {loading ? (
                <LinearProgress />
              ) : (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Joined on
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Roles
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <span className="sr-only">Invite</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {people.map((person: OrganizationUsersQuery['items'][number]) => (
                      <tr key={person.email}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {person.isInvitedPendingRegistration
                            ? ''
                            : person.firstName + ' ' + person.lastName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.isInvitedPendingRegistration
                            ? ''
                            : format(new Date(person.createdAt), 'MMMM d, yyyy')}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.roles
                            .map((oneRole) => snakeToHumanReadable(oneRole.name))
                            .join(', ')}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          {person.isInvitedPendingRegistration && (
                            <div className="flex flex-row space-x-2 justify-end">
                              <div className="italic text-sm font-light">invite sent</div>
                              <button
                                type="button"
                                className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                onClick={() => resendInvite(person.id)}
                                disabled={resendingInvites.has(person.id)}
                              >
                                {resendingInvites.has(person.id) ? (
                                  <Spin className="h-4 w-4" />
                                ) : (
                                  'resend'
                                )}
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MyTeam

import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import appReducer from './slices/appSlice'
import chatReducer from './slices/chatSlice'
import layoutSettingsReducer from './slices/layoutSettingsSlice'

const persistConfig = {
  key: 'layoutSettings',
  storage,
}

const persistedReducer = persistReducer(persistConfig, layoutSettingsReducer)

const store = configureStore({
  reducer: {
    app: appReducer,
    chat: chatReducer,
    layoutSettings: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store

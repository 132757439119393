import { AgentQuery } from 'app/javascript/components/graphql'
import React from 'react'
import { GET_AGENT_QUERY } from '../../graphql/queries/agent'
import { AgentQueryVariables } from 'app/javascript/components/graphql'
import { useQuery } from '@apollo/client'

export const companyAgentExamplePrompts = [
  'What can you help me with?',
  'Give me a list of suggested next steps',
  'What does my team look like?',
  'Summarize suggestions from the last week',
  'Anything weird requiring my attention?',
]

interface SamplePromptsProps {
  agentUuid: string
  handleSubmit: (prompt: string) => void
}

const SamplePrompts = ({ handleSubmit, agentUuid }: SamplePromptsProps) => {
  const { data, loading } = useQuery<AgentQuery, AgentQueryVariables>(GET_AGENT_QUERY, {
    skip: !agentUuid,
    variables: {
      uuid: agentUuid,
    },
  })
  const agent = data?.agent
  if (loading || !agent) {
    return <></>
  }

  const examplePrompts = agent.isCompany ? companyAgentExamplePrompts : agent?.prompts
  if (!examplePrompts) {
    return <></>
  }
  return (
    <div className="flex flex-col space-y-1 mt-5 justify-center items-center text-sm mx-4 w-full max-w-2xl">
      {examplePrompts.map((prompt, index) => (
        <div
          key={index}
          className="hover:underline cursor-pointer border bg-[#f0f4f9] hover:bg-[#DDE3EA] first:rounded-t-xl last:rounded-b-xl w-full flex items-center justify-center py-2 px-4 text-gray-500"
          onClick={() => handleSubmit(prompt)}
        >
          <div className="line-clamp-1 text-ellipsis text-center text-xs">{prompt}</div>
        </div>
      ))}
    </div>
  )
}

export default SamplePrompts

import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { GET_ANALYSIS_QUERY } from '../../../graphql/queries/analysis'
import { GET_DATA_SET_QUERY } from '../../../graphql/queries/data_set'
import { GET_WORKFLOW_QUERY } from '../../../graphql/queries/workflow'
import { GET_AGENT_QUERY } from '../../../graphql/queries/agent'
import { GET_DATA_UNION_QUERY } from '../../../graphql/queries/data_union'
import {
  AnalysisQueryVariables,
  DataSetQuery,
  DataSetQueryVariables,
  WorkflowQueryVariables,
  WorkflowQuery,
  AnalysisQuery,
  CompanyAgentQuery,
  CompanyAgentQueryVariables,
  CreateMessageThreadMutationVariables,
  CreateMessageThreadMutation,
  AddMessageToThreadMutation,
  AddMessageToThreadMutationVariables,
  ActorEnum,
  CreateArtifactMutation,
  CreateArtifactMutationVariables,
  AddStepToThreadMutation,
  AddStepToThreadMutationVariables,
  AgentQuery,
  AgentQueryVariables,
  DataUnionQuery,
  DataUnionQueryVariables,
} from '../../../../../components/graphql'
import LinearProgress from '@mui/material/LinearProgress'
import RealtimeConversation from '../Create/RealtimeConversation'
import { GET_COMPANY_AGENT } from '../../../graphql/queries/agent'
import {
  ADD_MESSAGE_TO_THREAD,
  CREATE_MESSAGE_THREAD,
  CREATE_ARTIFACT,
  ADD_STEP_TO_THREAD,
} from '../../../graphql/queries/message_thread'
import { v4 as uuidv4 } from 'uuid'
import { StepType } from '../../../utils/planTypes'
import {
  convertToAnalysisPlan,
  convertToAlertPlan,
  convertToDataSetPlan,
  convertToAgentPlan,
  convertToDataUnionPlan,
} from '../../../utils/planConversion'

interface EditParams {
  objectType: string
  uuid: string
}

const VALID_OBJECT_TYPES = ['analysis', 'data_set', 'alert', 'agent', 'data_union'] as const
type ValidObjectType = (typeof VALID_OBJECT_TYPES)[number]

const isValidUUID = (uuid: string): boolean => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
  return uuidRegex.test(uuid)
}

const isValidObjectType = (type: string): type is ValidObjectType => {
  return VALID_OBJECT_TYPES.includes(type as ValidObjectType)
}

const Loading = () => (
  <div className="absolute inset-0 flex flex-row h-full">
    <div className="flex flex-col w-full">
      <LinearProgress />
    </div>
  </div>
)

interface UseObjectDataResult {
  data: any
  loading: boolean
  error: any
}

const useObjectData = (objectType: ValidObjectType, uuid: string): UseObjectDataResult => {
  const {
    loading: dataSetLoading,
    error: dataSetError,
    data: dataSetData,
  } = useQuery<DataSetQuery, DataSetQueryVariables>(GET_DATA_SET_QUERY, {
    variables: { uuid },
    skip: objectType !== 'data_set',
  })

  const {
    loading: analysisLoading,
    error: analysisError,
    data: analysisData,
  } = useQuery<AnalysisQuery, AnalysisQueryVariables>(GET_ANALYSIS_QUERY, {
    variables: { uuid },
    skip: objectType !== 'analysis',
  })

  const {
    loading: workflowLoading,
    error: workflowError,
    data: workflowData,
  } = useQuery<WorkflowQuery, WorkflowQueryVariables>(GET_WORKFLOW_QUERY, {
    variables: { uuid },
    skip: objectType !== 'alert',
  })

  const {
    loading: agentLoading,
    error: agentError,
    data: agentData,
  } = useQuery<AgentQuery, AgentQueryVariables>(GET_AGENT_QUERY, {
    variables: { uuid },
    skip: objectType !== 'agent',
  })

  const {
    loading: dataUnionLoading,
    error: dataUnionError,
    data: dataUnionData,
  } = useQuery<DataUnionQuery, DataUnionQueryVariables>(GET_DATA_UNION_QUERY, {
    variables: { uuid },
    skip: objectType !== 'data_union',
  })

  const data = React.useMemo(() => {
    if (objectType === 'data_set' && dataSetData?.dataSet) {
      return dataSetData.dataSet
    }
    if (objectType === 'analysis' && analysisData?.analysis) {
      return analysisData.analysis
    }
    if (objectType === 'alert' && workflowData?.workflow) {
      return workflowData.workflow
    }
    if (objectType === 'agent' && agentData?.agent) {
      return agentData.agent
    }
    if (objectType === 'data_union' && dataUnionData?.dataUnion) {
      return dataUnionData.dataUnion
    }
    return null
  }, [objectType, dataSetData, analysisData, workflowData, agentData, dataUnionData])

  const loading =
    objectType === 'data_set'
      ? dataSetLoading
      : objectType === 'analysis'
      ? analysisLoading
      : objectType === 'alert'
      ? workflowLoading
      : objectType === 'agent'
      ? agentLoading
      : objectType === 'data_union'
      ? dataUnionLoading
      : false

  const error =
    objectType === 'data_set'
      ? dataSetError
      : objectType === 'analysis'
      ? analysisError
      : objectType === 'alert'
      ? workflowError
      : objectType === 'agent'
      ? agentError
      : objectType === 'data_union'
      ? dataUnionError
      : null

  return { data, loading, error }
}

interface UseThreadCreatorResult {
  threadUuid: string | null
  loading: boolean
  error: Error | null
}

const useThreadCreator = (objectData: any): UseThreadCreatorResult => {
  const [threadUuid, setThreadUuid] = React.useState<string | null>(null)
  const [error, setError] = React.useState<Error | null>(null)

  const { data: agentData, loading: agentLoading } = useQuery<
    CompanyAgentQuery,
    CompanyAgentQueryVariables
  >(GET_COMPANY_AGENT)

  const [createMessageThread] = useMutation<
    CreateMessageThreadMutation,
    CreateMessageThreadMutationVariables
  >(CREATE_MESSAGE_THREAD)

  React.useEffect(() => {
    if (agentLoading || !agentData?.companyAgent || !objectData || threadUuid) {
      return
    }

    const agent = agentData.companyAgent
    createMessageThread({ variables: { agentUuid: agent.uuid } })
      .then(({ data }) => {
        if (data?.createMessageThread?.uuid) {
          setThreadUuid(data.createMessageThread.uuid)
        }
      })
      .catch((error) => {
        console.error('Error creating message thread:', error)
        window.toastr.error('Error creating conversation thread')
        setError(error)
      })
  }, [agentData, objectData, agentLoading, createMessageThread, threadUuid])

  return { threadUuid, loading: agentLoading, error }
}

interface UseInitialMessageResult {
  isMessageSent: boolean
  loading: boolean
  error: Error | null
}

const useInitialMessage = (
  threadUuid: string | null,
  objectData: any,
  objectType: ValidObjectType
): UseInitialMessageResult => {
  const [isMessageSent, setIsMessageSent] = React.useState(false)
  const [error, setError] = React.useState<Error | null>(null)
  const [loading, setLoading] = React.useState(false)

  const [addMessageToThread] = useMutation<
    AddMessageToThreadMutation,
    AddMessageToThreadMutationVariables
  >(ADD_MESSAGE_TO_THREAD)

  React.useEffect(() => {
    if (!threadUuid || !objectData || isMessageSent || loading) {
      return
    }

    setLoading(true)
    const now = new Date()
    const name = objectType === 'agent' ? objectData.firstName : objectData.name
    const message = {
      uuid: uuidv4(),
      actor: ActorEnum.System,
      message: [
        {
          type: 'text',
          value: `Hi - I'm ready to help you edit the ${objectType.replace(
            '_',
            ' '
          )} "${name}". What would you like to change?`,
        },
      ],
      createdAt: now.toISOString(),
      createdAtMilli: now.getTime(),
      updatedAt: now.toISOString(),
      updatedAtMilli: now.getTime(),
    }

    addMessageToThread({
      variables: {
        threadUuid,
        message,
      },
    })
      .then(() => {
        setIsMessageSent(true)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error adding initial message:', error)
        window.toastr.error('Error setting up conversation')
        setError(error)
        setLoading(false)
      })
  }, [threadUuid, objectData, objectType, isMessageSent, loading, addMessageToThread])

  return { isMessageSent, loading, error }
}

interface UseArtifactCreatorResult {
  isArtifactCreated: boolean
  loading: boolean
  error: Error | null
}

const useArtifactCreator = (
  threadUuid: string | null,
  objectType: ValidObjectType,
  objectData: any
): UseArtifactCreatorResult => {
  const [isArtifactCreated, setIsArtifactCreated] = React.useState(false)
  const [error, setError] = React.useState<Error | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [hasAttempted, setHasAttempted] = React.useState(false)

  const [createArtifact] = useMutation<CreateArtifactMutation, CreateArtifactMutationVariables>(
    CREATE_ARTIFACT
  )

  const [addStepToThread] = useMutation<AddStepToThreadMutation, AddStepToThreadMutationVariables>(
    ADD_STEP_TO_THREAD
  )

  const createArtifactContent = (type: ValidObjectType, data: any): StepType[] => {
    console.log(data)
    switch (type) {
      case 'analysis':
        return [convertToAnalysisPlan(data)]
      case 'alert':
        return [convertToAlertPlan(data)]
      case 'data_set':
        return [convertToDataSetPlan(data)]
      case 'agent':
        return [convertToAgentPlan(data)]
      case 'data_union':
        return [convertToDataUnionPlan(data)]
      default:
        throw new Error(`Unsupported object type: ${type}`)
    }
  }

  React.useEffect(() => {
    // Don't retry if we've already attempted and got an error
    if (hasAttempted) {
      return
    }

    if (!threadUuid) {
      return
    }

    if (isArtifactCreated || loading) {
      return
    }

    if (!objectData) {
      return
    }

    setLoading(true)
    setHasAttempted(true)
    const content = createArtifactContent(objectType, objectData)
    const objectLabel = objectType.replace('_', ' ')
    const displayName = objectType === 'agent' ? objectData.firstName : objectData.name
    createArtifact({
      variables: {
        messageThreadUuid: threadUuid,
        name: 'Updating ' + objectLabel + ' "' + displayName + '"',
        description: 'We are updating the ' + objectLabel + ' "' + displayName + '"',
        content,
      },
    })
      .then(({ data }) => {
        // add a simulation function call to the thread
        return addStepToThread({
          variables: {
            threadUuid,
            step: {
              uuid: uuidv4(),
              actor: ActorEnum.System,
              step: [
                {
                  type: 'function',
                  functionToolCall: {
                    name: 'Get Deliverable Plans',
                    description:
                      "Get the list of deliverable plans for the current chat. This is the work product that we're creating during this conversation.",
                    type: 'function',
                    function: {
                      name: 'get_deliverable_plans',
                      arguments: null,
                      output: JSON.stringify({
                        uuid: data.createArtifact.uuid,
                        content: content,
                      }),
                    },
                  },
                },
              ],
              createdAt: new Date().toISOString(),
              createdAtMilli: new Date().getTime(),
              updatedAt: new Date().toISOString(),
              updatedAtMilli: new Date().getTime(),
            },
          },
        })
      })
      .then(() => {
        setIsArtifactCreated(true)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error creating artifact:', error)
        window.toastr.error('Error creating plan')
        setError(error)
        setLoading(false)
      })
  }, [threadUuid, objectData, objectType, isArtifactCreated, loading, createArtifact, hasAttempted])

  return { isArtifactCreated, loading, error }
}

const Edit = () => {
  const { objectType, uuid } = useParams<EditParams>()

  if (!isValidObjectType(objectType) || !isValidUUID(uuid)) {
    return <Redirect to="/home" />
  }

  const {
    data: objectData,
    loading: objectLoading,
    error: objectError,
  } = useObjectData(objectType, uuid)
  const { threadUuid, loading: threadLoading, error: threadError } = useThreadCreator(objectData)
  const {
    isMessageSent,
    loading: messageLoading,
    error: messageError,
  } = useInitialMessage(threadUuid, objectData, objectType)
  const {
    isArtifactCreated,
    loading: artifactLoading,
    error: artifactError,
  } = useArtifactCreator(threadUuid, objectType, objectData)

  if (objectLoading || threadLoading || messageLoading || artifactLoading) {
    return <Loading />
  }

  if (objectError) {
    window.toastr.error('There was an error fetching the object')
    return <Redirect to="/home" />
  }

  if (messageError || threadError || artifactError) {
    window.toastr.error('There was an error setting up the conversation')
    return <Redirect to="/home" />
  }

  if (!objectData || !threadUuid || !isMessageSent || !isArtifactCreated) {
    return <Loading />
  }

  return <RealtimeConversation threadUuid={threadUuid} emptyWorkspace={<LinearProgress />} />
}

export default Edit

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { clsx } from 'clsx'
import { RootState } from '../store'
import { useSelector } from 'react-redux'
import AgentImage from './AgentImage'
import { AgentFieldsFragment } from '../../graphql'

type AgentCardProps = {
  agent: AgentFieldsFragment
}
const AgentCard = ({ agent }: AgentCardProps) => {
  const { user } = useSelector((state: RootState) => state.app)
  const url = '/agent/' + agent.uuid

  const isExpertAgent = agent.type === 'ExpertAgent'

  return (
    <React.Fragment>
      <div
        className={clsx(
          'relative flex flex-1 flex-col space-y-4 px-6 py-4 h-full max-w-sm border border-gray-100 rounded-lg hover:shadow',
          isExpertAgent ? 'bg-gradient-to-r from-blue-50 to-white' : 'bg-white' // Apply gradient for ExpertAgent
        )}
      >
        {user.isOrganizationAdmin && (
          <Menu as="div" className="absolute top-5 right-5 ml-auto">
            <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Open options</span>
              <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <MenuItem>
                  {({ active }) => (
                    <Link
                      to={`/agent/edit/${agent.uuid}`}
                      className={clsx(
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                      )}
                    >
                      Edit<span className="sr-only">, {agent.label}</span>
                    </Link>
                  )}
                </MenuItem>
              </MenuItems>
            </Transition>
          </Menu>
        )}
        <div className="flex flex-row space-x-5">
          <Link to={url} className="">
            <AgentImage agent={agent} className="mx-auto h-24 w-24 flex-shrink-0 rounded-xl" />
          </Link>
          <div className="flex flex-col">
            <h3 className="mt-6 text-xl font-medium text-gray-900">{agent.firstName}</h3>

            <div className="items-center ">
              <span className="rounded-lg bg-flowmo-blue-100 px-2 py-1 text-xs font-medium text-flowmo-blue-700 ring-1 ring-inset ring-green-600/20">
                {agent.jobTitle}
              </span>
            </div>
          </div>
        </div>

        <div className="flex-grow text-xs text-gray-800 line-clamp-4">{agent.description}</div>

        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="flex w-0 flex-1 justify-start">
            <Link
              to={url}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-flowmo-blue-400 rounded-lg hover:bg-flowmo-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              Start work
              <svg
                className="w-3.5 h-3.5 ml-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AgentCard

import React from 'react'
import TextContent from '../../../components/Chat/TextContent'
import { MessageStatusEnum, StepUnion } from '../../../../graphql'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { Maximize2, Search, SquareCode, SquareFunction, Wrench } from 'lucide-react'
import { Chip } from '@mui/joy'
import clsx from 'clsx'

interface StepChipProps {
  status: MessageStatusEnum
  step: StepUnion
}

const StepChip = ({ status, step }: StepChipProps) => {
  const [open, setOpen] = React.useState(false)

  const inProgress = status === MessageStatusEnum.InProgress

  const iconClass = 'size-4 text-gray-600'
  let initial = <Wrench className={iconClass} />
  if (step.__typename === 'FunctionToolCall') {
    initial = <SquareFunction className={iconClass} />
  } else if (step.__typename === 'CodeInterpreterToolCall') {
    initial = <SquareCode className={iconClass} />
  } else if (step.__typename === 'FileSearchToolCall') {
    initial = <Search className={iconClass} />
  }

  return (
    <div className="mt-1">
      <Chip
        variant="outlined"
        color={inProgress ? 'primary' : 'neutral'}
        onClick={() => setOpen(true)}
        startDecorator={initial}
        endDecorator={<Maximize2 className="size-3 text-gray-400" />}
        className={clsx(
          'flex flex-row space-x-2 text-xs cursor-pointer',
          inProgress && 'animate-pulse'
        )}
      >
        <span className="text-xs text-gray-500">{step.name}</span>
      </Chip>

      <Dialog className="relative z-10" open={open} onClose={setOpen}>
        <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 mx-[20%] sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="flex flex-col">
                  <div className="text-lg">{step.name}</div>
                  <TextContent content={{ type: 'text', value: step.description }} />
                  {step.__typename === 'FunctionToolCall' && (
                    <>
                      <div className="">
                        <div className="text-sm font-semibold">Arguments</div>
                        <div className="text-xs text-gray-500">
                          {JSON.stringify(step.function.arguments)}
                        </div>
                      </div>
                      <TextContent
                        content={{
                          type: 'text',
                          value: step.function.output,
                        }}
                      />
                    </>
                  )}
                  {step.__typename === 'CodeInterpreterToolCall' && (
                    <>
                      <TextContent
                        content={{
                          type: 'text',
                          value: '```python \n ' + step.codeInterpreter.input + ' \n ```',
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={() => setOpen(false)}
                  data-autofocus
                >
                  Close
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default StepChip

import React, { useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { GET_WORKFLOW_QUERY, MUTATION_SUBMIT_RUN_WORKFLOW } from '../../../graphql/queries/workflow'
import { WorkflowQuery, WorkflowQueryVariables } from '../../../../graphql'
import Spin from '../../../../common/ui/Spin'
import { Link, useHistory } from 'react-router-dom'
import { ClockIcon, PlusCircleIcon, UserIcon } from '@heroicons/react/24/outline'
import CreateTask from '../../../components/CreateTask'
import TaskCard from './TaskCard'
import { AlertStatus } from '../../../components/AlertCard'
import { format } from 'date-fns'
import { PlayCircleIcon } from '@heroicons/react/20/solid'
import { MessageSquareIcon, Pencil, WandSparkles } from 'lucide-react'
import MainHeader, { Action } from '../../../components/ObjectHeader/MainHeader'

const OneWorkflow = ({ uuid }: { uuid: string }) => {
  const [isCreatingTask, setIsCreatingTask] = React.useState(false)
  const [isRunning, setIsRunning] = React.useState(false)
  const [submitRunWorkflow] = useMutation(MUTATION_SUBMIT_RUN_WORKFLOW)
  const refetchInterval = React.useRef<NodeJS.Timeout | null>(null)

  const history = useHistory()
  document.title = 'Workflow | FlowMo'

  const {
    data: workflowData,
    loading: workflowLoading,
    refetch: refetchWorkflow,
  } = useQuery<WorkflowQuery, WorkflowQueryVariables>(GET_WORKFLOW_QUERY, {
    variables: { uuid: uuid },
    fetchPolicy: 'network-only',
  })

  // check if the workflow is running on initial load
  useEffect(() => {
    if (workflowData?.workflow) {
      if (workflowData.workflow.status === 'running' && !isRunning) {
        setIsRunning(true)
      }
      if (workflowData.workflow.status != 'running' && isRunning) {
        refetchWorkflow().then(() => {
          setIsRunning(false)
        })
      }
    }
  }, [workflowData])

  React.useEffect(() => {
    if (isRunning) {
      refetchInterval.current = setInterval(() => {
        refetchWorkflow()
      }, 2000)
    } else {
      if (refetchInterval.current) {
        clearInterval(refetchInterval.current)
        refetchInterval.current = null
      }
    }

    // Clear the interval when the component is unmounted
    return () => {
      if (refetchInterval.current) {
        clearInterval(refetchInterval.current)
      }
    }
  }, [isRunning, refetchWorkflow])

  if (workflowLoading) {
    return <Spin />
  }

  const workflow = workflowData?.workflow

  if (!workflow) {
    history.push('/alerts')
    return <></>
  }

  const onTaskCreate = () => {
    refetchWorkflow()
    setIsCreatingTask(false)
  }

  const onTaskCancel = () => {
    setIsCreatingTask(false)
  }

  const onTaskDelete = () => {
    refetchWorkflow()
  }

  const handleRunWorkflow = async () => {
    setIsRunning(true)
    submitRunWorkflow({
      variables: { uuid: workflow.uuid },
    })
  }

  const actions: Action[] = [
    {
      name: 'Edit with AI',
      icon: WandSparkles,
      href: (uuid: string) => `/edit/alert/${uuid}`,
    },
    {
      name: 'Edit',
      icon: Pencil,
      href: (uuid: string) => `/alert/edit/${uuid}`,
    },
  ]

  return (
    <>
      <MainHeader object={workflow} type="alert" actions={actions}>
        <button onClick={handleRunWorkflow} disabled={isRunning} className="">
          {isRunning ? <Spin /> : <PlayCircleIcon className="w-8 h-8 hover:text-flowmo-blue-600" />}
        </button>
      </MainHeader>

      <div className="flex items-center justify-between flex-row space-x-4">
        <div className="mt-4">
          <AlertStatus workflow={workflow} />
        </div>
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {workflow.name}
          </h2>
          <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="mt-2 flex flex-row space-x-2 justify-center items-center text-sm text-gray-500">
              <MessageSquareIcon className="size-4" />
              <Link
                className="text-flowmo-blue-900 hover:text-flowmo-blue-600 hover:underline"
                to={'/agent/' + workflow.agent.uuid}
              >
                {workflow.agent.label}
              </Link>
            </div>

            <div className="mt-2 flex flex-row space-x-2 justify-center items-center text-sm text-gray-500">
              <UserIcon className="size-4" />
              <div>
                by {workflow.createdBy.firstName} {workflow.createdBy.lastName[0]}
              </div>
            </div>
            <div className="mt-2 flex flex-row space-x-2 justify-center items-center text-sm text-gray-500">
              <ClockIcon className="size-4" />
              <div>on {format(new Date(workflow.createdAt), 'MMMM d, yyyy')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-10 flex flex-col space-y-2">
        {workflow.tasks.map((oneTask) => (
          <TaskCard task={oneTask} key={oneTask.uuid} onDelete={onTaskDelete} />
        ))}
      </div>

      <div className="">
        {isCreatingTask ? (
          <div className="">
            <CreateTask
              taskableType="Workflow"
              taskableUuid={workflow.uuid}
              onSuccess={onTaskCreate}
              onCancel={onTaskCancel}
            />
          </div>
        ) : (
          <div className="">
            <button
              onClick={() => setIsCreatingTask(true)}
              className="flex flex-row space-x-2 items-center hover:underline text-flowmo-blue-900 hover:text-flowmo-blue-600"
            >
              <PlusCircleIcon className="h-6 w-6" /> <span>Add Task</span>
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default OneWorkflow

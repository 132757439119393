import React from 'react'
import PageHeader from '../../components/PageHeader'
import { GET_ORGANIZATION_AGENTS_QUERY } from '../../graphql/queries/agent'
import { useQuery } from '@apollo/client'
import {
  OrganizationAgentsQuery,
  OrganizationAgentsQueryVariables,
} from '../../../../components/graphql'
import AgentCard from '../../components/AgentCard'
import HeaderActionButton from '../../components/HeaderActionButton'
import { Link } from 'react-router-dom'
import EmptyState from '../../../../components/Walkthrough/ui/EmptyState'
import ExpertTemplates from '../../components/ExpertTemplates'
import LinearProgress from '@mui/material/LinearProgress'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Plus } from 'lucide-react'

const Agents = () => {
  const { user } = useSelector((state: RootState) => state.app)
  const { loading, data } = useQuery<OrganizationAgentsQuery, OrganizationAgentsQueryVariables>(
    GET_ORGANIZATION_AGENTS_QUERY,
    {
      fetchPolicy: 'network-only', // always fetches from the network, skips cache
    }
  )
  const organizationAgents = data?.organizationAgents
  return (
    <React.Fragment>
      <PageHeader title="Agents">
        {user?.isOrganizationAdmin && (
          <Link to="/agent/create">
            <HeaderActionButton icon={<Plus className="size-4" />} tooltip="Create new" />
          </Link>
        )}
      </PageHeader>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {organizationAgents?.length === 0 && (
            <EmptyState
              title="No agents yet"
              description="Create your first agent and share it with your organization"
            />
          )}

          <div className="flex flex-wrap">
            <ul
              role="list"
              className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 "
            >
              {organizationAgents?.map((agent) => (
                <li key={agent.uuid} className="col-span-1 divide-y">
                  <AgentCard agent={agent} />
                </li>
              ))}
            </ul>
          </div>
        </>
      )}

      <div className="my-20">
        <h2 className="text-xl font-bold">Expert Agents</h2>
        <div className="mt-2 text-gray-500 text-base">
          Add an expert agent to your organization. These agents are pre-configured to work with
          your data, and are managed by FlowMo.
        </div>
        <ExpertTemplates />
      </div>
    </React.Fragment>
  )
}

export default Agents

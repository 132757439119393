import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { Link, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import LinearProgress from '@mui/material/LinearProgress'
import { BotIcon, PlusCircle } from 'lucide-react'

import { GET_MESSAGE_THREADS } from '../../graphql/queries/message_thread'
import {
  GetMessageThreadsQuery,
  GetMessageThreadsQueryVariables,
} from 'app/javascript/components/graphql'
import {
  getDateCategory,
  getRelativeTimeString,
} from '../../../../components/common/utils/dateDisplay'

const INITIAL_LIMIT = 30
const LOAD_MORE_LIMIT = 20

const EmptyThreadList = () => {
  return (
    <div className="text-sm text-gray-500 text-center pt-48">
      Select an agent to start a conversation
    </div>
  )
}

interface ThreadListProps {
  agentUuid: string | null
  threadUuid: string | null
  onThreadClick: (threadUuid: string) => void
  onNewThreadClick: () => void
}

const ThreadList = ({
  agentUuid,
  threadUuid,
  onThreadClick,
  onNewThreadClick,
}: ThreadListProps) => {
  const history = useHistory()
  const [selectedThreadUuid, setSelectedThreadUuid] = useState<string | null>(null)

  /**
   * We'll store threads in local state if we want to merge new data easily;
   * but you can also rely on Apollo's `fetchMore.updateQuery`.
   */
  const [threads, setThreads] = useState<GetMessageThreadsQuery['getMessageThreads']>([])

  const { loading, fetchMore, refetch } = useQuery<
    GetMessageThreadsQuery,
    GetMessageThreadsQueryVariables
  >(GET_MESSAGE_THREADS, {
    skip: !agentUuid,
    variables: {
      agentUuid: agentUuid ?? '',
      limit: INITIAL_LIMIT,
      offset: 0,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // On the first load, set the threads in state
      setThreads(data.getMessageThreads)
    },
  })

  useEffect(() => {
    setSelectedThreadUuid(threadUuid)
  }, [threadUuid])

  useEffect(() => {
    // Refetch if thread_uuid changes in the URL or if agentUuid changes
    if (agentUuid && history.location.search.includes('thread_uuid')) {
      refetch({
        agentUuid,
        // you could also force offset=0 if you want to reload from scratch
      })
    }
  }, [agentUuid, history.location.search, refetch])

  /**
   * Intersection Observer setup
   */
  const observerRef = useRef<HTMLDivElement | null>(null)

  const loadMore = useCallback(async () => {
    // We only load more if we're not already in a loading state
    if (!loading && threads.length > 0) {
      const nextOffset = threads.length

      const { data: fetchMoreData } = await fetchMore({
        variables: {
          agentUuid: agentUuid ?? '',
          limit: LOAD_MORE_LIMIT,
          offset: nextOffset,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prevResult
          }
          return {
            getMessageThreads: [
              ...prevResult.getMessageThreads,
              ...fetchMoreResult.getMessageThreads,
            ],
          }
        },
      })
      // Optionally update local state if you want immediate reflection
      if (fetchMoreData?.getMessageThreads?.length) {
        setThreads((oldThreads) => [...oldThreads, ...fetchMoreData.getMessageThreads])
      }
    }
  }, [agentUuid, fetchMore, loading, threads])

  /**
   * Setup intersection observer to trigger `loadMore` when reaching the bottom
   */
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries
        if (entry.isIntersecting) {
          // If the bottom sentinel is in view, load more threads
          loadMore()
        }
      },
      {
        root: null, // defaults to viewport
        rootMargin: '0px',
        threshold: 0.1,
      }
    )

    if (observerRef.current) {
      observer.observe(observerRef.current)
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current)
      }
    }
  }, [loadMore])

  const handleThreadClick = (threadUuid: string) => {
    setSelectedThreadUuid(threadUuid)
    onThreadClick(threadUuid)
  }

  let lastCategory: string | null = null

  return (
    <div className="flex flex-col w-48 h-full border-r border-gray-200 bg-gray-100 overflow-y-auto">
      {!agentUuid && <EmptyThreadList />}

      {loading && threads.length === 0 && (
        <div className="flex-grow">
          <LinearProgress />
        </div>
      )}

      {agentUuid && (
        <div className="text-sm font-semibold px-4 py-2 flex flex-row items-center justify-between">
          <div>Conversations</div>
          <Link
            to={`/agent/${agentUuid}`}
            className="flex flex-row items-center justify-center hover:bg-gray-200 hover:text-gray-800 rounded-full cursor-pointer text-gray-600"
          >
            <BotIcon className="h-4 w-4" />
          </Link>
          <div
            className="flex flex-row items-center justify-center hover:bg-gray-200 hover:text-gray-800 rounded-full cursor-pointer text-gray-600"
            onClick={onNewThreadClick}
          >
            <PlusCircle className="h-4 w-4" />
          </div>
        </div>
      )}

      <div className="flex flex-col text-xs px-4">
        {threads.map((thread) => {
          const currentCategory = getDateCategory(thread.createdAt)
          const showDivider = lastCategory !== currentCategory
          lastCategory = currentCategory

          return (
            <React.Fragment key={thread.uuid}>
              {showDivider && <div className="divider my-2">{currentCategory}</div>}
              <div
                className={clsx(
                  'flex flex-row items-center justify-between p-2 rounded-xl cursor-pointer hover:bg-gray-200',
                  thread.uuid === selectedThreadUuid && 'bg-gray-200'
                )}
                onClick={() => handleThreadClick(thread.uuid)}
              >
                <div className="text-gray-600 flex flex-row space-x-2 justify-start">
                  <span className="text-xs line-clamp-1">
                    Chat ({getRelativeTimeString(thread.createdAt)})
                  </span>
                </div>
              </div>
            </React.Fragment>
          )
        })}
      </div>

      {/* If we have no threads and we're not loading, show a message */}
      {!loading && agentUuid && threads.length === 0 && (
        <div className="text-sm text-gray-500 text-center pt-48">Start a new conversation</div>
      )}

      {/* This sentinel div is used by IntersectionObserver to detect "scroll to bottom" */}
      <div ref={observerRef} style={{ height: '20px' }} />
    </div>
  )
}

export default ThreadList

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import {
  ActorEnum,
  AgentFieldsFragment,
  Artifact,
  CompanyAgentFieldsFragment,
  Content,
  Knowledge,
  MessageContentUnion,
  MessageHistoryFieldsFragment,
  MessageStatusEnum,
  Note,
  StepHistoryFieldsFragment,
  StepUnion,
} from '../../graphql'
import {
  FileAttachment as FileAttachmentType,
  SideEffectFieldsFragment,
} from '../../../components/graphql'

export interface OneChatProps {
  isQuerying: boolean
  query: string
  fileAttachments: FileAttachmentType[]
  content: Content[]
  chatId: string
  threadUuid: string
  messages: MessageHistoryFieldsFragment[]
  steps: StepHistoryFieldsFragment[]
  sideEffects: SideEffectFieldsFragment[]
  agent: AgentFieldsFragment | CompanyAgentFieldsFragment
  agentUuid: string
  chatStatusDescription: string
  conversation: ConversationItem[]
  artifacts: Artifact[]
  artifactsLoading: boolean
}

export interface ChatState {
  [key: string]: OneChatProps
}

export interface ConversationMessage {
  type: 'message'
  message: MessageContentUnion[]
  notes?: Note[]
  fileAttachments?: FileAttachmentType[]
  knowledge?: Knowledge[]
}

export interface ConversationSteps {
  type: 'steps'
  steps: StepUnion[]
}

export interface ConversationSideEffect {
  type: 'side_effect'
  sideEffect: string
  data: any
  output: string
}

export interface BaseConversationItem {
  uuid: string
  previousItemUuid: string | null
  role: ActorEnum
  createdAt: string
  createdAtMillis: number
  status: MessageStatusEnum | null
  events?: EventItem[]
  itemId?: string
  previousItemId?: string
}

export interface ConversationTextItem extends BaseConversationItem {
  type: 'text'
  content: ConversationMessage
}

export interface ConversationSideEffectItem extends BaseConversationItem {
  type: 'side_effect'
  content: ConversationSideEffect
}

export interface ConversationStepItem extends BaseConversationItem {
  type: 'step'
  content: ConversationSteps
}

export type ConversationItem =
  | ConversationTextItem
  | ConversationSideEffectItem
  | ConversationStepItem

export interface EventItem {
  type: string
  eventId: string
  outputIndex: number
  contentIndex?: number
  content?: string
  createdAt: string
}

export interface ConversationItemUpdate {
  uuid: string
  status: MessageStatusEnum | null
  content: ConversationMessage | ConversationSideEffect | ConversationSteps
}

export interface ConversationMessageDelta {
  uuid: string
  delta: MessageContentUnion[]
}

const initialChatProps: OneChatProps = {
  chatId: '',
  isQuerying: false,
  query: '',
  fileAttachments: [],
  content: [],
  messages: [],
  steps: [],
  sideEffects: [],
  threadUuid: '',
  agent: null,
  agentUuid: '',
  conversation: [],
  chatStatusDescription: '',
  artifacts: [],
  artifactsLoading: false,
}

const initialState: ChatState = {}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    createChat: {
      prepare: () => {
        const chatId = uuidv4()
        return { payload: { chatId } }
      },
      reducer: (state, action: PayloadAction<{ chatId: string }>) => {
        const { chatId } = action.payload
        state[chatId] = { ...initialChatProps, chatId }
      },
    },
    removeChat: (state, action: PayloadAction<{ chatId: string }>) => {
      const { chatId } = action.payload
      delete state[chatId]
    },
    setIsQuerying: (state, action: PayloadAction<{ chatId: string; isQuerying: boolean }>) => {
      const { chatId, isQuerying } = action.payload
      if (state[chatId]) {
        state[chatId].isQuerying = isQuerying
      }
    },
    setQuery: (
      state,
      action: PayloadAction<{
        chatId: string
        query: string
        fileAttachments: FileAttachmentType[]
        content: Content[]
      }>
    ) => {
      const { chatId, query, fileAttachments, content } = action.payload
      if (state[chatId]) {
        state[chatId].query = query
        state[chatId].fileAttachments = fileAttachments
        state[chatId].content = content
      }
    },
    clearConversation: (state, action: PayloadAction<{ chatId: string }>) => {
      const { chatId } = action.payload
      if (state[chatId]) {
        state[chatId].conversation = []
      }
    },
    addConversationItem: (
      state,
      action: PayloadAction<{ chatId: string; item: ConversationItem }>
    ) => {
      const { chatId, item } = action.payload
      if (state[chatId]) {
        state[chatId].conversation.push(item)

        // resort conversation by createdAtMillis or by previousItemUuid ascending
        state[chatId].conversation.sort((a, b) => {
          if (a.previousItemUuid && b.previousItemUuid) {
            return b.createdAtMillis - a.createdAtMillis
          }
          return a.previousItemUuid ? -1 : 1
        })
      }
    },
    updateConversationItem: (
      state,
      action: PayloadAction<{ chatId: string; item: ConversationItemUpdate }>
    ) => {
      const { chatId, item } = action.payload
      if (state[chatId]) {
        state[chatId].conversation = state[chatId].conversation.map((i: ConversationItem) => {
          if (i.uuid === item.uuid) {
            const updatedItem = { ...i } as ConversationItem
            if (item.status !== null) {
              updatedItem.status = item.status
            }
            updatedItem.content = {
              ...updatedItem.content,
              ...item.content,
            } as ConversationItem['content']
            return updatedItem
          }
          return i
        })
      }
    },
    appendConversationMessageDelta: (
      state,
      action: PayloadAction<{ chatId: string; item: ConversationMessageDelta }>
    ) => {
      const { chatId, item } = action.payload
      if (!state[chatId]) {
        return
      }

      state[chatId].conversation = state[chatId].conversation.map((i: ConversationItem) => {
        if (i.uuid !== item.uuid || i.type !== 'text' || i.content.type !== 'message') {
          return i
        }

        const textItem = i as ConversationTextItem
        let currentContent = [...textItem.content.message]

        if (currentContent.length === 0) {
          currentContent = item.delta
        } else {
          item.delta.forEach((oneDelta: MessageContentUnion & { index: number }) => {
            if (oneDelta.index > -1 && currentContent[oneDelta.index]) {
              currentContent[oneDelta.index].value += oneDelta.value
            }
          })
        }

        return {
          ...textItem,
          content: {
            ...textItem.content,
            message: currentContent,
          },
        } as ConversationTextItem
      })
    },
    setThreadUuid: (state, action: PayloadAction<{ chatId: string; threadUuid: string }>) => {
      const { chatId, threadUuid } = action.payload
      if (state[chatId]) {
        state[chatId].threadUuid = threadUuid
      }
    },
    setAgent: (
      state,
      action: PayloadAction<{
        chatId: string
        agent: AgentFieldsFragment | CompanyAgentFieldsFragment
      }>
    ) => {
      const { chatId, agent } = action.payload
      if (state[chatId]) {
        state[chatId].agent = agent
      }
    },
    setAgentUuid: (state, action: PayloadAction<{ chatId: string; agentUuid: string }>) => {
      const { chatId, agentUuid } = action.payload
      if (state[chatId]) {
        state[chatId].agentUuid = agentUuid
      }
    },
    resetChat: (state, action: PayloadAction<{ chatId: string }>) => {
      const { chatId } = action.payload
      if (state[chatId]) {
        state[chatId] = { ...initialChatProps, chatId }
      }
    },
    updateMessage: (
      state,
      action: PayloadAction<{ chatId: string; message: MessageHistoryFieldsFragment }>
    ) => {
      const { chatId, message } = action.payload
      if (state[chatId]) {
        state[chatId].messages = state[chatId].messages.map((m) =>
          m.uuid === message.uuid ? message : m
        )
      }
    },
    updateStep: (
      state,
      action: PayloadAction<{ chatId: string; step: StepHistoryFieldsFragment }>
    ) => {
      const { chatId, step } = action.payload
      if (state[chatId]) {
        state[chatId].steps = state[chatId].steps.map((s) => (s.id === step.id ? step : s))
      }
    },
    setChatStatusDescription: (
      state,
      action: PayloadAction<{ chatId: string; status: string }>
    ) => {
      const { chatId, status } = action.payload
      if (state[chatId]) {
        state[chatId].chatStatusDescription = status
      }
    },
    addArtifact: (state, action: PayloadAction<{ chatId: string; artifact: Artifact }>) => {
      const { chatId, artifact } = action.payload
      if (state[chatId]) {
        state[chatId].artifacts.push(artifact)
      }
    },
    updateArtifact: (state, action: PayloadAction<{ chatId: string; artifact: Artifact }>) => {
      const { chatId, artifact } = action.payload
      if (state[chatId]) {
        state[chatId].artifacts = state[chatId].artifacts.map((a) =>
          a.uuid === artifact.uuid ? artifact : a
        )
      }
    },
    setArtifacts: (state, action: PayloadAction<{ chatId: string; artifacts: Artifact[] }>) => {
      const { chatId, artifacts } = action.payload
      if (state[chatId]) {
        state[chatId].artifacts = artifacts
      }
    },
    setArtifactsLoading: (state, action: PayloadAction<{ chatId: string; loading: boolean }>) => {
      const { chatId, loading } = action.payload
      if (state[chatId]) {
        state[chatId].artifactsLoading = loading
      }
    },
  },
})

export const { actions: chatActions } = chatSlice

export default chatSlice.reducer

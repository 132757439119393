import React from 'react'
import PageHeader from '../../components/PageHeader'
import DynamicForm, { FieldType } from '../../components/DynamicForm'
import { useMutation } from '@apollo/client'
import { CREATE_SKILL_SET } from '../../../../components/App/graphql/queries/skill_set'
import {
  CreateSkillSetMutation,
  CreateSkillSetMutationVariables,
} from 'app/javascript/components/graphql'
import { useHistory } from 'react-router-dom'

const CreateSkillSet = () => {
  const history = useHistory()
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const [createSkillSet] = useMutation<CreateSkillSetMutation, CreateSkillSetMutationVariables>(
    CREATE_SKILL_SET
  )
  const formData = [
    {
      name: 'name',
      label: 'Name',
      type: FieldType.Text,
      required: true,
      defaultValue: '',
    },
    {
      label: 'Description',
      name: 'description',
      type: FieldType.Textarea,
      required: true,
      defaultValue: '',
    },
  ]

  const handleSubmit = async (values: any) => {
    const response = await createSkillSet({
      variables: { name: values.name, description: values.description },
    })

    if (response.data.data.uuid) {
      window.toastr.success('Skill set created successfully')
      history.push('/skill_set/' + response.data.data.uuid)
      return
    } else {
      window.toastr.error('Error creating skill set')
      return
    }
  }

  return (
    <>
      <PageHeader
        title="Create Skill Set"
        breadCrumbs={[{ title: 'My Library', to: '/my_library' }, { title: 'Create Skill Set' }]}
      />
      <div className="max-w-2xl">
        <DynamicForm ref={formRef} formData={formData} handleSubmit={handleSubmit} />

        <button
          type="submit"
          onClick={() => formRef.current?.submitForm()}
          className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-flowmo-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-flowmo-blue-800"
        >
          Create
        </button>
      </div>
    </>
  )
}

export default CreateSkillSet

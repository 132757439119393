import React from 'react'
import {
  ADD_DATASET_TO_AGENT_MUTATION,
  GET_DATA_SET_QUERY,
  REFRESH_DATA_SET_MUTATION,
  REMOVE_DATASET_FROM_AGENT_MUTATION,
} from '../../../graphql/queries/data_set'
import { useMutation, useQuery } from '@apollo/client'
import { Link, useHistory } from 'react-router-dom'
import {
  AddDataSetToAgentMutation,
  AddDataSetToAgentMutationVariables,
  DataSetQuery,
  DataSetQueryVariables,
  OrganizationAgentsQuery,
  OrganizationAgentsQueryVariables,
  RefreshDataSetMutation,
  RefreshDataSetMutationVariables,
  RemoveDataSetFromAgentMutation,
  RemoveDataSetFromAgentMutationVariables,
} from '../../../../../components/graphql'
import Spin from '../../../../../components/common/ui/Spin'
import HeaderActionButton from '../../../components/HeaderActionButton'
import { GET_ORGANIZATION_AGENTS_QUERY } from '../../../graphql/queries/agent'
import { Switch } from '@headlessui/react'
import clsx from 'clsx'
import DataSetSample from './DataSetSample'
import TextContent from '../../../components/Chat/TextContent'
import DataSetFields from './DataSetFields'
import { WandSparkles, LinkIcon, RefreshCw, Pencil } from 'lucide-react'
import LinearProgress from '@mui/material/LinearProgress'
import MainHeader, { Action } from '../../../components/ObjectHeader/MainHeader'
import { VerticalTabs, TabItem } from '../../../../../components/common/ui/VerticalTabs'

interface OneDataSetProps {
  uuid: string
}

const OneDataSet = ({ uuid }: OneDataSetProps) => {
  const history = useHistory()
  const [loadingSwitches, setLoadingSwitches] = React.useState<string[]>([]) // array of agent UUIDs that are currently loading
  const [loadingRefresh, setLoadingRefresh] = React.useState<boolean>(false)
  const { data, loading, refetch } = useQuery<DataSetQuery, DataSetQueryVariables>(
    GET_DATA_SET_QUERY,
    {
      variables: { uuid },
      fetchPolicy: 'network-only',
    }
  )

  const [addDataSetToAgent] = useMutation<
    AddDataSetToAgentMutation,
    AddDataSetToAgentMutationVariables
  >(ADD_DATASET_TO_AGENT_MUTATION)
  const [removeDataSetFromAgent] = useMutation<
    RemoveDataSetFromAgentMutation,
    RemoveDataSetFromAgentMutationVariables
  >(REMOVE_DATASET_FROM_AGENT_MUTATION)

  const [refreshDataSet] = useMutation<RefreshDataSetMutation, RefreshDataSetMutationVariables>(
    REFRESH_DATA_SET_MUTATION
  )

  const {
    data: agentsData,
    loading: agentsLoading,
    refetch: refetchAgents,
  } = useQuery<OrganizationAgentsQuery, OrganizationAgentsQueryVariables>(
    GET_ORGANIZATION_AGENTS_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  )
  const agents = agentsData?.organizationAgents || []

  if (loading) {
    return <LinearProgress />
  }

  if (!loading && !data?.dataSet) {
    window.toastr.error('Data Set not found')
    history.push('/data_sets')
    return null
  }
  const dataSet = data?.dataSet

  const handleCheckboxChange = async (agentUuid: string, isChecked: boolean) => {
    setLoadingSwitches((prev) => [...prev, agentUuid])

    try {
      if (isChecked) {
        const response = await addDataSetToAgent({
          variables: {
            agentUuid,
            dataSetUuid: uuid,
          },
        })
        if (response.data.addDataSetToAgent.errors.length > 0) {
          window.toastr.error('Error adding walkthrough to agent', 'Error')
        } else {
          window.toastr.success('Successfully added walkthrough to agent', 'Added')
        }
      } else {
        const response = await removeDataSetFromAgent({
          variables: {
            agentUuid,
            dataSetUuid: uuid,
          },
        })
        if (response.data.removeDataSetFromAgent.errors.length > 0) {
          window.toastr.error('Error removing walkthrough to agent', 'Error')
        } else {
          window.toastr.success('Successfully removed the walkthrough to agent', 'Removed')
        }
      }
    } catch (err) {
      window.toastr.error('An unexpected error occurred', 'Error')
    } finally {
      setLoadingSwitches((prev) => prev.filter((id) => id !== agentUuid))
      refetchAgents()
    }
  }

  const handleRefreshDataSet = async () => {
    setLoadingRefresh(true)
    const response = await refreshDataSet({
      variables: {
        uuid,
      },
    })
    await refetch()
    if (response.data.refreshDataSet.errors.length > 0) {
      window.toastr.error('Error refreshing data set', 'Error')
    } else {
      window.toastr.success('Successfully refreshed data set', 'Refreshed')
    }
    setLoadingRefresh(false)
  }

  let actions: Action[] = []
  if (dataSet.isEditable) {
    actions = [
      {
        name: 'Edit with AI',
        icon: WandSparkles,
        href: (uuid: string) => `/edit/data_set/${uuid}`,
      },
      {
        name: 'Edit',
        icon: Pencil,
        href: (uuid: string) => `/data_set/edit/${uuid}`,
      },
    ]
  }

  const DetailsContent = () => (
    <dl className="divide-y divide-gray-100">
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">Name</dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          {dataSet.name}
        </dd>
      </div>
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">Description</dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <TextContent content={{ type: 'text', value: dataSet.description }} />
        </dd>
      </div>
      {dataSet.definition['looker'] && (
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">Explore</dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {dataSet.definition['looker']['exploreUrl'] && (
              <a
                href={dataSet.definition['looker']['exploreUrl']}
                className="hover:underline break-words line-clamp-4"
                target="_blank"
                rel="noreferrer"
              >
                {dataSet.definition['looker']['exploreUrl']}
                <LinkIcon className="h-4 w-4 inline-block ml-1" />
              </a>
            )}
          </dd>
        </div>
      )}

      {dataSet.definition['snowflake'] && (
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">Snowflake Table</dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {dataSet.definition['snowflake']['schema']}.{dataSet.definition['snowflake']['table']}
          </dd>
        </div>
      )}
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">Build Status</dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          {dataSet.status}
        </dd>
      </div>
    </dl>
  )

  const FieldsContent = () => <DataSetFields dataSet={dataSet} />

  const SampleContent = () => <DataSetSample dataSet={dataSet} />

  const AgentsContent = () => (
    <div className="flex flex-col space-y-2">
      {agentsLoading && <Spin />}

      {agents.map((agent) => {
        const enabled = agent.dataSets?.some((wt) => wt.uuid === uuid)
        const isLoading = loadingSwitches.includes(agent.uuid)

        // only show the agent if the data set is editable
        if (!dataSet.isEditable && !enabled) {
          return null
        }

        return (
          <div key={agent.uuid} className="w-full p-5 border rounded">
            <div className="flex flex-row">
              <div className="flex flex-col flex-grow">
                <div className="text-lg font-semibold">
                  <Link to={`/agent/${agent.uuid}`}>{agent.label}</Link>
                </div>
                <div className="text-md text-gray-800">{agent.description}</div>
              </div>
              <div className="justify-center">
                {dataSet.isEditable && (
                  <Switch
                    checked={enabled}
                    onChange={(checked) => handleCheckboxChange(agent.uuid, checked)}
                    className={clsx(
                      enabled ? 'bg-flowmo-blue-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-flowmo-blue-600 focus:ring-offset-2'
                    )}
                    disabled={isLoading}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={clsx(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )

  const tabs: TabItem[] = [
    {
      key: 'details',
      label: 'Details',
      content: <DetailsContent />,
    },
    {
      key: 'fields',
      label: 'Fields',
      content: <FieldsContent />,
    },
    {
      key: 'sample',
      label: 'Sample',
      content: <SampleContent />,
    },
    {
      key: 'agents',
      label: 'Agents',
      content: <AgentsContent />,
    },
  ]

  return (
    <>
      <MainHeader object={dataSet} type="data_set" actions={actions}>
        <HeaderActionButton
          icon={loadingRefresh ? <Spin className="size-4" /> : <RefreshCw className="size-4" />}
          onClick={() => handleRefreshDataSet()}
          disabled={loadingRefresh}
        />
      </MainHeader>

      <VerticalTabs tabs={tabs} className="mt-4" />
    </>
  )
}

export default OneDataSet

import React from 'react'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { Ellipsis } from 'lucide-react'
import { Link } from 'react-router-dom'

interface Action {
  name: string
  icon: React.ComponentType<{ className?: string }>
  href: (uuid: string) => string
}

interface HeaderFlyoutMenuProps {
  actions: Action[]
  uuid: string
}

const HeaderFlyoutMenu: React.FC<HeaderFlyoutMenuProps> = ({ actions, uuid }) => {
  return (
    <Popover className="relative">
      <PopoverButton className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100">
        <Ellipsis className="w-5 h-5 text-gray-500" />
      </PopoverButton>
      <PopoverPanel className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {actions.map((action) => {
          const Icon = action.icon
          return (
            <Link
              key={action.name}
              to={action.href(uuid)}
              className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              <Icon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
              {action.name}
            </Link>
          )
        })}
      </PopoverPanel>
    </Popover>
  )
}

export default HeaderFlyoutMenu

import React, { useState, useRef, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import { Send } from '@mui/icons-material'
import { File, Paperclip, Video } from 'lucide-react'
import { Menu, MenuItem, MenuItems, MenuButton } from '@headlessui/react'
import { FileUploadModal } from '../DynamicForm/FileAttachmentField'
import { Content, FileAttachment as FileAttachmentType } from 'app/javascript/components/graphql'
import { ContentSelectorModal } from '../DynamicForm/KnowledgeField'
import { FileAttachment, VideoAttachment } from './MessageAttachment'

interface PromptInputProps {
  placeholder?: string
  backgroundColor?: string
  handleSubmit: (
    message: string,
    fileAttachments?: FileAttachmentType[],
    content?: Content[]
  ) => void
  isQuerying: boolean
  allowFileUpload?: boolean
}

const PromptInput = ({
  handleSubmit,
  isQuerying,
  placeholder = 'Enter a prompt here',
  backgroundColor = 'bg-[rgb(240,244,249)]',
  allowFileUpload = false,
}: PromptInputProps) => {
  const [inputText, setInputText] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [showUploadFile, setShowUploadFile] = useState(false)
  const [showAddVideo, setShowAddVideo] = useState(false)
  const [selectedFileAttachments, setSelectedFileAttachments] = useState<FileAttachmentType[]>([])
  const [selectedContent, setSelectedContent] = useState<Content[]>([])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value)
  }

  const onSubmit = useCallback(() => {
    handleSubmit(inputText, selectedFileAttachments, selectedContent)
    setInputText('')
    setSelectedFileAttachments([])
    setSelectedContent([])
    inputRef.current?.focus()
  }, [handleSubmit, inputText, selectedContent, selectedFileAttachments])

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && e.keyCode !== 229) {
      onSubmit()
    }
  }

  const handleAddFileAttachment = (fileAttachment: FileAttachmentType) => {
    setSelectedFileAttachments([...selectedFileAttachments, fileAttachment])
  }

  const handleRemoveFileAttachment = (fileAttachment: FileAttachmentType) => {
    setSelectedFileAttachments(
      selectedFileAttachments.filter((fa) => fa.uuid !== fileAttachment.uuid)
    )
  }

  const handleUpdateSelectedContent = (contentList: Content[]) => {
    setSelectedContent(contentList)
  }

  const handleRemoveSelectedContent = (contentToRemove: Content) => {
    setSelectedContent(selectedContent.filter((c) => c.uuid !== contentToRemove.uuid))
  }

  // Track previous value of isQuerying to detect transition from true -> false
  const wasQuerying = useRef(isQuerying)
  useEffect(() => {
    if (wasQuerying.current && !isQuerying) {
      // Just finished querying, so focus the input
      inputRef.current?.focus()
    }
    wasQuerying.current = isQuerying
  }, [isQuerying])

  return (
    <>
      <div className="flex flex-wrap gap-2">
        {selectedFileAttachments.map((fa) => (
          <FileAttachment
            fileAttachment={fa}
            handleRemoveFileAttachment={() => handleRemoveFileAttachment(fa)}
            key={fa.uuid}
          />
        ))}

        {selectedContent.map((c) => (
          <VideoAttachment
            content={c}
            handleRemoveContent={() => handleRemoveSelectedContent(c)}
            key={c.uuid}
          />
        ))}
      </div>
      <div
        className={clsx(
          'relative flex items-center rounded-full p-2 shadow',
          backgroundColor,
          'focus-within:ring-1 focus-within:ring-blue-300 focus-within:ring-opacity-50'
        )}
      >
        {allowFileUpload && (
          <div className="relative z-40">
            <Menu as="div" className="relative">
              <MenuButton
                className={clsx(
                  'inline-flex items-center gap-2 rounded-full p-2 text-sm font-semibold text-gray-800 focus:outline-none hover:bg-gray-100',
                  'hover:bg-white',
                  backgroundColor,
                  isQuerying ? 'cursor-not-allowed' : 'cursor-pointer'
                )}
              >
                <Paperclip className="w-6 h-6 text-gray-400" />
              </MenuButton>
              <MenuItems className="absolute left-0 mt-2 w-52 origin-top-left -top-2 bg-white rounded-md shadow-lg ring-1 text-sm ring-black ring-opacity-5 focus:outline-none">
                <MenuItem>
                  <button
                    className={`group flex w-full items-center hover:bg-gray-100 gap-2 rounded-lg py-1.5 px-3`}
                    onClick={() => setShowUploadFile(true)}
                  >
                    <File className="w-4 h-4 text-gray-500" />
                    Upload File
                  </button>
                </MenuItem>
                <MenuItem>
                  <button
                    className={`group flex w-full items-center hover:bg-gray-100 gap-2 rounded-lg py-1.5 px-3`}
                    onClick={() => setShowAddVideo(true)}
                  >
                    <Video className="w-4 h-4 text-gray-500" />
                    Add Video
                  </button>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
        )}

        <input
          ref={inputRef}
          type="text"
          value={inputText}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          disabled={isQuerying}
          placeholder={placeholder}
          className={`flex-grow bg-transparent border-0 ring-0 focus:ring-0 placeholder-gray-400 outline-none pl-4 ${
            isQuerying ? 'cursor-not-allowed text-gray-500' : 'cursor-text text-gray-800'
          }`}
        />
        <div className="flex items-center space-x-2">
          <button
            onClick={onSubmit}
            disabled={isQuerying || !inputText.trim()}
            className={clsx(
              'p-2 text-white border-0 rounded-full transition-all duration-300 ease-in-out flex flex-row items-center justify-center',
              inputText.trim() ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-400',
              isQuerying ? 'animate-spin' : ''
            )}
          >
            {isQuerying ? (
              <div className="w-4 h-4 border-t-2 border-white rounded-full animate-spin"></div>
            ) : (
              <Send fontSize="inherit" />
            )}
          </button>
        </div>
      </div>
      {showUploadFile && (
        <FileUploadModal
          isOpen={showUploadFile}
          onClose={() => setShowUploadFile(false)}
          onUploadSuccess={(fileAttachment: FileAttachmentType) => {
            setShowUploadFile(false)
            handleAddFileAttachment(fileAttachment)
          }}
        />
      )}

      <ContentSelectorModal
        isOpen={showAddVideo}
        onClose={() => setShowAddVideo(false)}
        defaultSelectedContent={selectedContent}
        onUpdateSelectedContent={handleUpdateSelectedContent}
      />
    </>
  )
}

export default PromptInput

import React, { useEffect, useState, useRef } from 'react'
import AudioRecorder from './AudioRecorder'
import useAudioConversation, { OutputConversationItem } from '../../hooks/useAudioConversation'
import TextContent from '../Chat/TextContent'
import { ActorEnum } from '../../../graphql'
import ThreeDots from '../../../common/ui/ThreeDots'
import { ConversationMessage } from '../../slices/chatSlice'

const AudioConversation = ({
  onConversationItems,
}: {
  onConversationItems: (items: OutputConversationItem[]) => void
}) => {
  const [isRecording, setIsRecording] = useState(false) // Track recording state
  const conversationEndRef = useRef<HTMLDivElement | null>(null)

  const { sendAudioData, timeLeft, timeLimit, conversation, pendingQueue, isSessionReady } =
    useAudioConversation({
      channel: 'AudioTranscriptionChannel',
      isRecording,
      opts: {},
    })

  useEffect(() => {
    const outputConversation = conversation.map((item) => ({
      role: item.role,
      createdAt: item.createdAt,
      content: item.events.map((event) => event.content),
    }))

    onConversationItems(outputConversation)
  }, [conversation])

  useEffect(() => {
    if (conversationEndRef.current) {
      conversationEndRef.current.scrollTop = conversationEndRef.current.scrollHeight
    }
  }, [conversation])

  console.log(conversation)
  return (
    <div className="flex flex-col gap-2 bg-gray-700 rounded-lg p-2">
      <div className="">
        <AudioRecorder
          onAudioData={sendAudioData}
          isRecording={isRecording}
          setIsRecording={setIsRecording}
          timeLeft={timeLeft}
          timeLimit={timeLimit}
          isSessionReady={isSessionReady}
        />
      </div>
      <div
        className="flex flex-col gap-2 overflow-y-auto h-80  border rounded-lg shadow-inner p-2 bg-white"
        ref={conversationEndRef}
      >
        {conversation.length == 0 && pendingQueue.length == 0 && (
          <div className="text-center text-gray-400 mt-4">
            Start a conversation by starting the recording and speaking
          </div>
        )}
        {conversation.map((oneConversation, index) => (
          <div
            key={index}
            className={`px-4 py-2 text-sm rounded-lg max-w-xs ${
              oneConversation.role === ActorEnum.User
                ? 'bg-[rgb(237,243,253)] text-black self-end'
                : 'bg-[rgb(247,250,255)] text-black self-start'
            }`}
          >
            <TextContent
              content={{
                type: 'text',
                value: (oneConversation.content as ConversationMessage).message
                  .map((message) => message.value)
                  .join(''),
              }}
            />
          </div>
        ))}

        {pendingQueue.map((item, index) => (
          <>
            <div
              key={index}
              className={`px-4 py-2 text-sm rounded-lg max-w-xs text-gray-500 ${
                item.role === ActorEnum.User
                  ? 'bg-[rgb(237,243,253)] self-end'
                  : 'bg-[rgb(247,250,255)] self-start'
              }`}
            >
              <ThreeDots />
            </div>
          </>
        ))}
      </div>
    </div>
  )
}

export default AudioConversation

import { useQuery } from '@apollo/client'
import {
  AgentQuery,
  AgentQueryVariables,
  ArtifactsQuery,
  ArtifactsQueryVariables,
  DataSetsQuery,
  DataSetsQueryVariables,
  DataUnionsQuery,
  DataUnionsQueryVariables,
} from 'app/javascript/components/graphql'
import { GET_DATA_SETS_QUERY } from '../../graphql/queries/data_set'
import { GET_AGENT_QUERY } from '../../graphql/queries/agent'
import { GET_DATA_UNIONS_QUERY } from '../../graphql/queries/data_union'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Accordion from '@mui/joy/Accordion'
import AccordionDetails from '@mui/joy/AccordionDetails'
import AccordionSummary from '@mui/joy/AccordionSummary'
import {
  Bell,
  ChevronRight,
  ChevronLeft,
  ChevronDown,
  Maximize2,
  Minimize2,
  Bot,
  ChartArea,
  Table,
  UserCircleIcon,
  ListTodoIcon,
  Clock10Icon,
  DatabaseIcon,
  FolderOpenIcon,
  TableIcon,
  CodeIcon,
  WrenchIcon,
  TextIcon,
  RectangleHorizontal,
  Mail,
  PaperclipIcon,
  Merge,
} from 'lucide-react'
import clsx from 'clsx'
import Tooltip from '@mui/joy/Tooltip'
import AccordionGroup from '@mui/joy/AccordionGroup'
import { parseCronExpression, daysOfWeek, hours } from '../../utils/cronUtils'
import {
  DataSetRef,
  Task,
  EmailUpdateStepType,
  DataSetStepType,
  StepTypeEnum,
  StepType,
  AnalysisStepType,
  AlertStepType,
  AgentStepType,
  DataUnionStepType,
} from '../../utils/planTypes'
import StepAction from './RealtimeCreate/StepAction'
import { GET_ARTIFACTS } from '../../graphql/queries/message_thread'

// Types
type Backend = 'snowflake' | 'bigquery' | 'looker'

interface UuidReference {
  type: 'plan' | 'backend'
  value: string
}

interface StepProps {
  step: StepType
  expanded: boolean
  onAccordionChange: (expanded: boolean) => void
  allSteps: StepType[]
  handleArtifactChange: ({ makeConsistent }: { makeConsistent: boolean }) => void
  artifact: GraphQLArtifact
}

export interface GraphQLArtifact {
  uuid: string
  name: string
  description: string
  artifactType: string
  createdAt: string
  updatedAt: string
  content: StepType[]
}

interface CreatePlanProps {
  artifact: GraphQLArtifact
  handleArtifactChange: ({ makeConsistent }: { makeConsistent: boolean }) => void
}

interface CreateListProps {
  artifacts: GraphQLArtifact[]
  handleArtifactChange: ({ makeConsistent }: { makeConsistent: boolean }) => void
}

// Type guards
function isStep(step: unknown): step is StepType {
  if (!step || typeof step !== 'object') {
    return false
  }
  const stepObj = step as { type?: string }
  return ['alert', 'analysis', 'agent', 'email_update', 'data_set', 'data_union'].includes(
    stepObj.type || ''
  )
}

export function isDataSet(step: StepType): step is DataSetStepType {
  return step.type === 'data_set'
}

export function isDataUnionStep(step: StepType): step is DataUnionStepType {
  return step.type === 'data_union'
}

export function isAlertStep(step: StepType): step is AlertStepType {
  return step.type === 'alert'
}

export function isAnalysisStep(step: StepType): step is AnalysisStepType {
  return step.type === 'analysis'
}

export function isAgentStep(step: StepType): step is AgentStepType {
  return step.type === 'agent'
}

export function isEmailUpdateStep(step: StepType): step is EmailUpdateStepType {
  return step.type === 'email_update'
}

// Constants
const BACKEND_LABELS: Record<Backend, string> = {
  snowflake: 'Snowflake',
  bigquery: 'BigQuery',
  looker: 'Looker',
}

const STEP_ICONS: Record<StepTypeEnum, { icon: React.ReactNode; tooltip: string }> = {
  alert: { icon: <Bell size={16} className="text-gray-500" />, tooltip: 'Alert' },
  analysis: { icon: <ChartArea size={16} className="text-gray-500" />, tooltip: 'Analysis' },
  agent: { icon: <Bot size={16} className="text-gray-500" />, tooltip: 'Agent' },
  data_set: { icon: <Table size={16} className="text-gray-500" />, tooltip: 'Dataset' },
  email_update: { icon: <Mail size={16} className="text-gray-500" />, tooltip: 'Email Update' },
  data_union: { icon: <TableIcon size={16} className="text-gray-500" />, tooltip: 'Data Union' },
}

// Shared styles
const contentStyles = 'whitespace-pre-wrap break-words max-w-full overflow-x-auto'

// Helper function to get the display ID for a step
const getStepId = (uuid: UuidReference): string => {
  return `${uuid.type}-${uuid.value}`
}

// Custom hook for getting agent name
const useAgent = (step: StepType, allSteps: StepType[]) => {
  const findAgentUuid = (step: StepType) => {
    if (isAlertStep(step) || isAnalysisStep(step) || isEmailUpdateStep(step)) {
      // Handle both structures: direct agent_uuid and nested agent object
      const agentRef: UuidReference = (step as any).agent_uuid
      return agentRef?.value
    }
    if (isAgentStep(step)) {
      return step.uuid.value
    }
    return null
  }

  const agentUuid = findAgentUuid(step)
  const { data, loading } = useQuery<AgentQuery, AgentQueryVariables>(GET_AGENT_QUERY, {
    skip: !agentUuid,
    variables: {
      uuid: agentUuid || '',
    },
  })

  const agentName = React.useMemo(() => {
    if (loading) {
      return 'Loading...'
    }
    if (data?.agent?.label) {
      return data.agent.label
    }
    if (isAlertStep(step) || isAnalysisStep(step) || isEmailUpdateStep(step)) {
      if (step.agent_uuid.type === 'plan') {
        const agentStep = allSteps.find(
          (currentStep) =>
            isAgentStep(currentStep) && currentStep.uuid.value === step.agent_uuid.value
        ) as AgentStepType | undefined
        return agentStep?.first_name || 'Unknown Agent'
      }
    }
    if (isAgentStep(step)) {
      return step.first_name || 'Unknown Agent'
    }
    return 'Unknown Agent'
  }, [data, loading, step, allSteps])

  return { agentName, loading }
}

// Custom hook for getting data set names
const useDataSets = (dataSets: DataSetRef[] | undefined, allSteps: StepType[]) => {
  // Find UUIDs that need to be fetched from the backend
  const uuidsToFetch = React.useMemo(() => {
    if (!dataSets) {
      return []
    }
    return dataSets
      .filter((ds: DataSetRef) => ds.uuid.type === 'backend')
      .map((ds) => ds.uuid.value)
  }, [dataSets])

  // Create a single query for all UUIDs
  const { data: dataSetsData } = useQuery<DataSetsQuery, DataSetsQueryVariables>(
    GET_DATA_SETS_QUERY,
    {
      variables: { uuids: uuidsToFetch },
      skip: !uuidsToFetch.length,
    }
  )

  return React.useMemo(() => {
    if (!dataSets) {
      return []
    }

    return dataSets.map((ds: DataSetRef) => {
      // If it's a new data set, look in all steps
      if (ds.uuid.type === 'plan') {
        const dataSetStep = allSteps.find(
          (currentStep) => isDataSet(currentStep) && currentStep.uuid.value === ds.uuid.value
        )
        return dataSetStep?.name || ds.uuid.value
      }

      // Otherwise, look in fetched data
      const matchingDataSet = dataSetsData?.dataSets?.find(
        (dataSet: { uuid: string; name: string }) => dataSet.uuid === ds.uuid.value
      )
      if (matchingDataSet) {
        return matchingDataSet.name
      }

      return ds.uuid.value
    })
  }, [dataSets, allSteps, dataSetsData])
}

// Custom hook for getting data union names
const useDataUnions = (dataUnions: DataSetRef[] | undefined, allSteps: StepType[]) => {
  // Find UUIDs that need to be fetched from the backend
  const uuidsToFetch = React.useMemo(() => {
    if (!dataUnions) {
      return []
    }
    return dataUnions
      .filter((du: DataSetRef) => du.uuid.type === 'backend')
      .map((du) => du.uuid.value)
  }, [dataUnions])

  // Create a single query for all UUIDs
  const { data: dataUnionsData } = useQuery<DataUnionsQuery, DataUnionsQueryVariables>(
    GET_DATA_UNIONS_QUERY,
    {
      variables: { uuids: uuidsToFetch },
      skip: !uuidsToFetch.length,
    }
  )

  return React.useMemo(() => {
    if (!dataUnions) {
      return []
    }

    return dataUnions.map((du: DataSetRef) => {
      // If it's a new data union, look in all steps
      if (du.uuid.type === 'plan') {
        const dataUnionStep = allSteps.find(
          (currentStep) => isDataUnionStep(currentStep) && currentStep.uuid.value === du.uuid.value
        )
        return dataUnionStep?.name || du.uuid.value
      }

      // Otherwise, look in fetched data
      const matchingDataUnion = dataUnionsData?.dataUnions?.find(
        (dataUnion: { uuid: string; name: string }) => dataUnion.uuid === du.uuid.value
      )
      if (matchingDataUnion) {
        return matchingDataUnion.name
      }

      return du.uuid.value
    })
  }, [dataUnions, allSteps, dataUnionsData])
}

// Shared Components
const InfoRow = ({
  icon,
  content,
  className = '',
}: {
  icon: React.ReactNode
  content: React.ReactNode
  className?: string
}) => (
  <div className={`flex w-full flex-none gap-x-4 border-t py-2 border-gray-900/5 ${className}`}>
    <dt className="flex-none">{icon}</dt>
    <dd className="text-sm/6 font-medium text-gray-900">{content}</dd>
  </div>
)

const StepHeader = ({ type, name }: { type: StepTypeEnum; name: string }) => {
  const { icon, tooltip } = STEP_ICONS[type] || STEP_ICONS.alert

  return (
    <div className="flex items-center gap-2">
      <Tooltip title={tooltip} placement="top">
        <div className="p-1">{icon}</div>
      </Tooltip>
      <span className="font-medium">{name}</span>
    </div>
  )
}

const StepAccordion = ({
  step,
  expanded,
  onAccordionChange,
  type,
  children,
  handleArtifactChange,
  artifact,
}: {
  step: StepType
  expanded: boolean
  onAccordionChange: (expanded: boolean) => void
  type: StepTypeEnum
  children: React.ReactNode
  handleArtifactChange: ({ makeConsistent }: { makeConsistent: boolean }) => void
  artifact: GraphQLArtifact
}) => (
  <Accordion expanded={expanded} onChange={(e, expanded) => onAccordionChange(expanded)}>
    <AccordionSummary indicator={<ChevronDown />}>
      <StepHeader
        type={type}
        name={
          (() => {
            if (isDataSet(step)) {
              return step.name
            }
            if (isAgentStep(step)) {
              return step.first_name
            }
            return step.name
          })() || ''
        }
      />
    </AccordionSummary>
    <AccordionDetails className="transition-all duration-300 ease-in-out">
      <div className="p-4 border rounded-lg bg-white shadow-sm">
        {children}
        <StepAction handleArtifactChange={handleArtifactChange} step={step} artifact={artifact} />
      </div>
    </AccordionDetails>
  </Accordion>
)

// Step Components
export const AlertStep = ({
  step: originalStep,
  expanded,
  onAccordionChange,
  allSteps,
  handleArtifactChange,
  artifact,
}: StepProps) => {
  if (!isAlertStep(originalStep)) {
    return null
  }
  const step = originalStep
  const { agentName: alertAgentName } = useAgent(step, allSteps)

  const scheduleText = React.useMemo(() => {
    if (!step.cron_schedule) {
      return 'Manually Launched'
    }
    const parsed = parseCronExpression(step.cron_schedule)
    if (Object.keys(parsed).length === 0) {
      return step.cron_schedule // If we can't parse it, just show the raw string
    }
    const timeOfDay = hours[parsed.hour].label
    if (parsed.timeFrame === 'daily') {
      return `Daily at ${timeOfDay}`
    } else if (parsed.timeFrame === 'weekly') {
      const day = daysOfWeek[parsed.dayOfWeek].name
      return `Every ${day} at ${timeOfDay}`
    } else if (parsed.timeFrame === 'monthly') {
      const day = parsed.dayOfMonth
      return `Monthly on day ${day} at ${timeOfDay}`
    } else if (parsed.timeFrame === 'quarterly') {
      const day = parsed.dayOfQuarter
      return `Quarterly on day ${day} at ${timeOfDay}`
    }
    return step.cron_schedule
  }, [step.cron_schedule])

  return (
    <StepAccordion
      step={step}
      expanded={expanded}
      onAccordionChange={onAccordionChange}
      type={StepTypeEnum.Alert}
      handleArtifactChange={handleArtifactChange}
      artifact={artifact}
    >
      <div className={`text-sm text-gray-600 ${contentStyles}`}>{step.description}</div>
      <InfoRow
        icon={<UserCircleIcon className="h-6 w-5 text-gray-400" />}
        content={alertAgentName}
        className="mt-6"
      />
      <InfoRow icon={<Clock10Icon className="h-6 w-5 text-gray-400" />} content={scheduleText} />
      <InfoRow icon={<ListTodoIcon className="h-6 w-5 text-gray-400" />} content="Tasks" />

      {step.tasks && step.tasks.length > 0 && (
        <div className="mt-4 ml-4 text-xs">
          <div className="space-y-2">
            {step.tasks.map((task: Task, index: number) => (
              <div key={index} className="flex flex-col space-y-1 pl-4 border-l-2 border-blue-200">
                <div className="font-bold">{task.name}</div>
                <div className={`font-semibold text-gray-500 ${contentStyles}`}>
                  {task.description}
                </div>
                <div className="text-xs text-gray-500">{task.task_prompt}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </StepAccordion>
  )
}

export const AnalysisStep = ({
  step: originalStep,
  expanded,
  onAccordionChange,
  allSteps,
  handleArtifactChange,
  artifact,
}: StepProps) => {
  if (!isAnalysisStep(originalStep)) {
    return null
  }
  const step = originalStep
  const { agentName } = useAgent(step, allSteps)

  return (
    <StepAccordion
      step={step}
      expanded={expanded}
      onAccordionChange={onAccordionChange}
      type={StepTypeEnum.Analysis}
      handleArtifactChange={handleArtifactChange}
      artifact={artifact}
    >
      <InfoRow icon={<UserCircleIcon className="h-6 w-5 text-gray-400" />} content={agentName} />
      <InfoRow
        icon={<RectangleHorizontal className="h-6 w-5 text-gray-400" />}
        content={<div className={`text-sm text-gray-600 ${contentStyles}`}>{step.description}</div>}
      />
      {step.instructions && (
        <InfoRow
          icon={<TextIcon className="h-6 w-5 text-gray-400" />}
          content={
            <div>
              <div className="font-medium">Instructions</div>
              <div className={`text-sm text-gray-500 ${contentStyles}`}>{step.instructions}</div>
            </div>
          }
        />
      )}
      {step.output_format && step.output_format.length > 0 && (
        <InfoRow
          icon={<CodeIcon className="h-6 w-5 text-gray-400" />}
          content={
            <div>
              <div className="font-medium">Output Format</div>
              <div className="mt-2 space-y-4">
                {step.output_format.map((block, index) => (
                  <div
                    key={index}
                    className="flex flex-col space-y-1 pl-4 border-l-2 border-blue-200"
                  >
                    <div className="font-bold text-sm">{block.type}</div>
                    <div className={`text-sm text-gray-500 ${contentStyles}`}>{block.prompt}</div>
                  </div>
                ))}
              </div>
            </div>
          }
        />
      )}
      {step.tasks && step.tasks.length > 0 && (
        <InfoRow
          icon={<ListTodoIcon className="h-6 w-5 text-gray-400" />}
          content={
            <div>
              <div className="font-medium">Tasks</div>
              <div className="mt-2 space-y-4">
                {step.tasks.map((task, index) => (
                  <div
                    key={index}
                    className="flex flex-col space-y-1 pl-4 border-l-2 border-blue-200"
                  >
                    <div className="font-bold text-sm">{task.name}</div>
                    <div className={`text-sm text-gray-500 ${contentStyles}`}>
                      {task.description}
                    </div>
                    <div className="text-xs text-gray-500">{task.task_prompt}</div>
                  </div>
                ))}
              </div>
            </div>
          }
        />
      )}
    </StepAccordion>
  )
}

export const AgentStep = ({
  step: originalStep,
  expanded,
  onAccordionChange,
  allSteps,
  handleArtifactChange,
  artifact,
}: StepProps) => {
  if (!isAgentStep(originalStep)) {
    return null
  }
  const step = originalStep
  const dataSetNames = useDataSets(step.data_sets, allSteps)
  const dataUnionNames = useDataUnions(step.data_unions, allSteps)

  return (
    <StepAccordion
      step={step}
      expanded={expanded}
      onAccordionChange={onAccordionChange}
      type={StepTypeEnum.Agent}
      handleArtifactChange={handleArtifactChange}
      artifact={artifact}
    >
      <InfoRow
        icon={<UserCircleIcon className="h-6 w-5 text-gray-400" />}
        content={
          <div className="font-medium">
            {step.first_name} ({step.job_title})
          </div>
        }
      />
      <InfoRow
        icon={<TextIcon className="h-6 w-5 text-gray-400" />}
        content={
          <div>
            <div className="font-medium">Description</div>
            <div className="flex flex-col space-y-2 text-sm text-gray-500 ">
              <div>
                <div className="font-medium">Short</div>
                <div>{step.short_description}</div>
              </div>
              <div>
                <div className="font-medium">Long</div>
                <div>{step.long_description}</div>
              </div>
            </div>
          </div>
        }
      />
      {step.system_instruction && (
        <InfoRow
          icon={<WrenchIcon className="h-6 w-5 mt-1 text-gray-400" />}
          content={
            <div>
              <div className="font-medium">Instructions</div>
              <div className="text-sm text-gray-500">{step.system_instruction}</div>
            </div>
          }
        />
      )}
      {dataSetNames.length > 0 && (
        <InfoRow
          icon={<TableIcon className="h-6 w-5 text-gray-400" />}
          content={
            <div className="flex flex-col space-y-2">
              <div className="font-medium">Data Sets:</div>
              <div className="space-y-2">
                {dataSetNames.map((name: string, index: number) => (
                  <div key={index} className="text-sm">
                    {name}
                  </div>
                ))}
              </div>
            </div>
          }
        />
      )}
      {dataUnionNames.length > 0 && (
        <InfoRow
          icon={<DatabaseIcon className="h-6 w-5 text-gray-400" />}
          content={
            <div className="flex flex-col space-y-2">
              <div className="font-medium">Data Unions:</div>
              <div className="space-y-2">
                {dataUnionNames.map((name: string, index: number) => (
                  <div key={index} className="text-sm">
                    {name}
                  </div>
                ))}
              </div>
            </div>
          }
        />
      )}
    </StepAccordion>
  )
}

const DataSetTableSchema = ({ schema, table_name }: { schema?: string; table_name: string }) => (
  <>
    {schema && (
      <InfoRow icon={<FolderOpenIcon className="h-6 w-5 text-gray-400" />} content={schema} />
    )}
    <InfoRow icon={<TableIcon className="h-6 w-5 text-gray-400" />} content={table_name} />
  </>
)

const DataSetViewSchema = ({ table_name, sql }: { table_name: string; sql: string }) => (
  <>
    <InfoRow icon={<TableIcon className="h-6 w-5 text-gray-400" />} content={table_name} />

    <InfoRow
      icon={<CodeIcon className="h-6 w-5 text-gray-400" />}
      content={<pre className={`mt-1 text-xs ${contentStyles}`}>{sql}</pre>}
    />
  </>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DataSetStep = ({
  step,
  expanded,
  onAccordionChange,
  handleArtifactChange,
  artifact,
}: StepProps) => {
  if (!isDataSet(step)) {
    return null
  }

  return (
    <StepAccordion
      step={step}
      expanded={expanded}
      onAccordionChange={onAccordionChange}
      type={StepTypeEnum.DataSet}
      handleArtifactChange={handleArtifactChange}
      artifact={artifact}
    >
      <div className={`text-sm text-gray-600 ${contentStyles}`}>{step.description}</div>
      <InfoRow
        icon={<DatabaseIcon className="h-6 w-5 text-gray-400" />}
        content={step.backend ? BACKEND_LABELS[step.backend] : 'Unknown'}
        className="mt-6"
      />

      {step.definition && (
        <>
          {step.definition.type === 'snowflake' && (
            <>
              {step.definition.table_definition.type === 'table' ? (
                <DataSetTableSchema
                  schema={step.definition.table_definition.schema}
                  table_name={step.definition.table_definition.table_name}
                />
              ) : (
                <DataSetViewSchema
                  table_name={step.definition.table_definition.table_name}
                  sql={step.definition.table_definition.sql || ''}
                />
              )}
            </>
          )}
          {step.definition.type === 'bigquery' && (
            <>
              {step.definition.table_definition.type === 'table' ? (
                <DataSetTableSchema
                  schema={step.definition.table_definition.schema}
                  table_name={step.definition.table_definition.table_name}
                />
              ) : (
                <DataSetViewSchema
                  table_name={step.definition.table_definition.table_name}
                  sql={step.definition.table_definition.sql || ''}
                />
              )}
            </>
          )}
        </>
      )}
    </StepAccordion>
  )
}

export const DataUnionStep = ({
  step,
  expanded,
  onAccordionChange,
  handleArtifactChange,
  artifact,
}: StepProps) => {
  const dataUnion = step as DataUnionStepType

  const baseDataSetName = useDataSets(
    [{ uuid: dataUnion.definition.data_set_uuid }],
    artifact.content
  )[0]

  const joinedDataSetNames = useDataSets(
    dataUnion.definition.joins.map((join) => ({ uuid: join.data_set_uuid })),
    artifact.content
  )

  return (
    <StepAccordion
      step={step}
      expanded={expanded}
      onAccordionChange={onAccordionChange}
      type={StepTypeEnum.DataUnion}
      handleArtifactChange={handleArtifactChange}
      artifact={artifact}
    >
      <div className="space-y-4">
        <InfoRow
          icon={<RectangleHorizontal className="h-6 w-5 text-gray-400" />}
          content={
            <div className={`text-sm text-gray-600 ${contentStyles}`}>{dataUnion.description}</div>
          }
        />
        <div className="divide-y divide-gray-100">
          <InfoRow
            icon={<DatabaseIcon size={16} className="text-gray-500" />}
            content={<span className="text-sm">Base Dataset: {baseDataSetName}</span>}
          />
          <div className="pt-4">
            <div className="flex items-center gap-2">
              <Merge size={16} className="text-gray-500" />
              <div className="text-sm font-medium">Joins:</div>
            </div>
            {dataUnion.definition.joins.map((join, index) => (
              <div key={index} className="ml-4 space-y-1 mt-2">
                <InfoRow
                  icon={<TableIcon size={16} className="text-gray-500" />}
                  content={
                    <div className="text-sm">
                      <div>Dataset: {joinedDataSetNames[index]}</div>
                      <div>Type: {join.type.replace(/_/g, ' ')}</div>
                      <div>Relationship: {join.relationship.replace(/_/g, ' ')}</div>
                      <div>Join Condition: {join.sql_on}</div>
                    </div>
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </StepAccordion>
  )
}

export const CreatePlan = ({ artifact, handleArtifactChange }: CreatePlanProps) => {
  const steps = artifact.content as StepType[]
  const [isExpanded, setIsExpanded] = useState(true)
  const [expandedSteps, setExpandedSteps] = useState<{ [key: string]: boolean }>({})

  // Initialize expanded state for all steps
  useEffect(() => {
    const initialState = steps.reduce((acc: { [key: string]: boolean }, step: StepType) => {
      acc[getStepId(step.uuid)] = true
      return acc
    }, {})
    setExpandedSteps(initialState)
  }, [steps])

  const toggleExpansion = () => {
    const newExpandedState = !isExpanded
    setIsExpanded(newExpandedState)

    const newExpandedSteps = Object.keys(expandedSteps).reduce(
      (acc: { [key: string]: boolean }, key) => {
        acc[key] = newExpandedState
        return acc
      },
      {}
    )
    setExpandedSteps(newExpandedSteps)
  }

  const handleStepChange = (uuid: UuidReference, expanded: boolean) => {
    const stepId = getStepId(uuid)
    setExpandedSteps((prev) => ({
      ...prev,
      [stepId]: expanded,
    }))
    // Update isExpanded based on whether all steps are expanded or not
    setIsExpanded(Object.values({ ...expandedSteps, [stepId]: expanded }).every((value) => value))
  }

  return (
    <div>
      <div className="flex flex-col">
        <div className="flex justify-between items-center">
          <div className="text-lg font-bold line-clamp-1">{artifact.name}</div>
          <div
            className="flex flex-row border rounded-md p-1 cursor-pointer hover:bg-gray-100"
            onClick={toggleExpansion}
          >
            {isExpanded ? <Minimize2 size={12} /> : <Maximize2 size={12} />}
          </div>
        </div>
        <div className="text-sm text-gray-500">{artifact.description}</div>
      </div>
      <div className="mt-8">
        <AccordionGroup transition="0.2s" disableDivider>
          {artifact.content.map((step, index) => {
            if (!isStep(step)) {
              return null
            }
            const expanded = expandedSteps[getStepId(step.uuid)] ?? true
            return (
              <div key={index} className="flex-grow">
                {step.type === 'alert' && (
                  <AlertStep
                    step={step}
                    expanded={expanded}
                    onAccordionChange={(expanded) => handleStepChange(step.uuid, expanded)}
                    allSteps={artifact.content}
                    handleArtifactChange={handleArtifactChange}
                    artifact={artifact}
                  />
                )}
                {step.type === 'analysis' && (
                  <AnalysisStep
                    step={step}
                    expanded={expanded}
                    onAccordionChange={(expanded) => handleStepChange(step.uuid, expanded)}
                    allSteps={artifact.content}
                    handleArtifactChange={handleArtifactChange}
                    artifact={artifact}
                  />
                )}
                {step.type === 'agent' && (
                  <AgentStep
                    step={step}
                    expanded={expanded}
                    onAccordionChange={(expanded) => handleStepChange(step.uuid, expanded)}
                    allSteps={artifact.content}
                    handleArtifactChange={handleArtifactChange}
                    artifact={artifact}
                  />
                )}
                {step.type === 'data_set' && (
                  <DataSetStep
                    step={step}
                    expanded={expanded}
                    onAccordionChange={(expanded) => handleStepChange(step.uuid, expanded)}
                    allSteps={artifact.content}
                    handleArtifactChange={handleArtifactChange}
                    artifact={artifact}
                  />
                )}
                {step.type === 'email_update' && (
                  <EmailUpdateStep
                    step={step}
                    expanded={expanded}
                    onAccordionChange={(expanded) => handleStepChange(step.uuid, expanded)}
                    allSteps={artifact.content}
                    handleArtifactChange={handleArtifactChange}
                    artifact={artifact}
                  />
                )}
                {step.type === 'data_union' && (
                  <DataUnionStep
                    step={step}
                    expanded={expanded}
                    onAccordionChange={(expanded) => handleStepChange(step.uuid, expanded)}
                    allSteps={artifact.content}
                    handleArtifactChange={handleArtifactChange}
                    artifact={artifact}
                  />
                )}
              </div>
            )
          })}
        </AccordionGroup>
      </div>
    </div>
  )
}

export const CreateList: React.FC<CreateListProps> = ({ artifacts, handleArtifactChange }) => {
  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    if (artifacts.length > 0) {
      setActiveStep(artifacts.length - 1)
    }
  }, [artifacts])

  if (!artifacts || artifacts.length === 0) {
    return <></>
  }

  const handleNext = () => {
    if (activeStep < artifacts.length - 1) {
      setActiveStep(activeStep + 1)
    }
  }

  const handlePrevious = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
    }
  }

  return (
    <div className="relative flex flex-col">
      <div className="absolute inset-0 w-full flex flex-row h-10 items-center px-1 justify-end border-b border-gray-200 bg-blue-50 space-x-4">
        <div className="text-xs text-gray-500">
          {activeStep + 1} of {artifacts.length} plans
        </div>
        <div className="flex flex-row gap-2">
          <ChevronLeft
            className={clsx('text-gray-500', {
              'opacity-50': activeStep === 0,
              'cursor-pointer': activeStep > 0,
            })}
            onClick={handlePrevious}
          />
          <ChevronRight
            className={clsx('text-gray-500', {
              'opacity-50': activeStep === artifacts.length - 1,
              'cursor-pointer': activeStep < artifacts.length - 1,
            })}
            onClick={handleNext}
          />
        </div>
      </div>
      <div className="flex-grow mt-10 px-5 py-2 overflow-y-auto">
        <CreatePlan artifact={artifacts[activeStep]} handleArtifactChange={handleArtifactChange} />
      </div>
    </div>
  )
}

export const DummyCreateList: React.FC = () => {
  const history = useHistory()
  const searchParams = new URLSearchParams(history.location.search)
  const threadUuid = searchParams.get('thread_uuid')

  const { data } = useQuery<ArtifactsQuery, ArtifactsQueryVariables>(GET_ARTIFACTS, {
    variables: {
      messageThreadUuid: threadUuid,
    },
  })

  const artifacts = data?.artifacts || []

  return (
    <div className="absolute inset-0 flex flex-row h-full">
      <div className="flex flex-col h-full w-[50%] bg-gray-200 shadow-inner overflow-y-auto">
        Dummy chat area
      </div>
      <div className="w-[50%] overflow-y-auto">
        <CreateList
          artifacts={artifacts}
          handleArtifactChange={({ makeConsistent }) => {
            console.log('handleArtifactChange', makeConsistent)
          }}
        />
      </div>
    </div>
  )
}

export const EmailUpdateStep = ({
  step: originalStep,
  expanded,
  onAccordionChange,
  allSteps,
  handleArtifactChange,
  artifact,
}: StepProps) => {
  if (!isEmailUpdateStep(originalStep)) {
    return null
  }
  const step = originalStep
  const { agentName } = useAgent(step, allSteps)

  return (
    <StepAccordion
      step={step}
      expanded={expanded}
      onAccordionChange={onAccordionChange}
      type={StepTypeEnum.EmailUpdate}
      handleArtifactChange={handleArtifactChange}
      artifact={artifact}
    >
      <InfoRow
        icon={<UserCircleIcon className="h-6 w-5 text-gray-400" />}
        content={agentName}
        className="mt-6"
      />
      <InfoRow
        icon={<RectangleHorizontal className="h-6 w-5 text-gray-400" />}
        content={<div className={`text-sm text-gray-600 ${contentStyles}`}>{step.description}</div>}
      />
      {step.synthesis && (
        <InfoRow
          icon={<TextIcon className="h-6 w-5 text-gray-400" />}
          content={
            <div>
              <div className="font-medium">Email Content</div>
              <div className={`text-sm text-gray-500 ${contentStyles}`}>{step.synthesis}</div>
            </div>
          }
        />
      )}
      {step.tasks && step.tasks.length > 0 && (
        <InfoRow
          icon={<ListTodoIcon className="h-6 w-5 text-gray-400" />}
          content={
            <div>
              <div className="font-medium">Tasks</div>
              <div className="mt-2 space-y-4">
                {step.tasks.map((task, index) => (
                  <div
                    key={index}
                    className="flex flex-col space-y-1 pl-4 border-l-2 border-blue-200"
                  >
                    <div className="font-bold text-sm">{task.name}</div>
                    <div className={`text-sm text-gray-500 ${contentStyles}`}>
                      {task.description}
                    </div>
                    <div className="text-xs text-gray-500">{task.task_prompt}</div>
                  </div>
                ))}
              </div>
            </div>
          }
        />
      )}
      {step.attachments && step.attachments.length > 0 && (
        <InfoRow
          icon={<PaperclipIcon className="h-6 w-5 text-gray-400" />}
          content={
            <div>
              <div className="font-medium">Attachments</div>
              <div className="mt-2 space-y-4">
                {step.attachments.map((attachment, index) => (
                  <div
                    key={index}
                    className="flex flex-col space-y-1 pl-4 border-l-2 border-blue-200"
                  >
                    <div className="font-bold text-sm">{attachment.file_type}</div>
                    <div className={`text-sm text-gray-500 ${contentStyles}`}>
                      {attachment.prompt}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
        />
      )}
    </StepAccordion>
  )
}

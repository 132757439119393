import React, { useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_TASK_RUNS_QUERY,
  GET_TASK_QUERY,
  SUBMIT_RUN_TASK_MUTATION,
} from '../../../graphql/queries/task'
import {
  SubmitRunTaskMutation,
  SubmitRunTaskMutationVariables,
  TaskQuery,
  TaskQueryVariables,
  TaskRunsQuery,
  TaskRunsQueryVariables,
} from '../../../../graphql'
import Spin from '../../../../common/ui/Spin'
import { Link } from 'react-router-dom'
import HeaderActionButton from '../../../components/HeaderActionButton'
import { CheckCircleIcon, PlayCircleIcon } from '@heroicons/react/24/solid'
import { TaskStatus, TaskType } from '../OneAlert/TaskCard'
import EmptyContent from '../EditAgent/EmptyContent'
import TaskRun from './TaskRun'
import { QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import TaskVisualization from './TaskVisualization'
import { LinearProgress } from '@mui/material'
import SubHeader from '../../../components/ObjectHeader/SubHeader'
import { BriefcaseIcon, CalendarIcon, Pencil } from 'lucide-react'
import { getRelativeTimeString } from '../../../../common/utils/dateDisplay'

const OneTask = ({ uuid }: { uuid: string }) => {
  document.title = 'Task | FlowMo'
  const [offset, setOffset] = React.useState(0)
  const [taskRuns, setTaskRuns] = React.useState([])
  const [isRunning, setIsRunning] = React.useState(false)
  const [hasMore, setHasMore] = React.useState(true)
  const taskRunsContainerRef = React.useRef<HTMLDivElement>(null)

  const { data: taskData, loading: taskLoading } = useQuery<TaskQuery, TaskQueryVariables>(
    GET_TASK_QUERY,
    {
      variables: { uuid },
      fetchPolicy: 'network-only',
    }
  )
  const task = taskData?.task

  const { data: taskRunsData, loading: taskRunsLoading } = useQuery<
    TaskRunsQuery,
    TaskRunsQueryVariables
  >(GET_TASK_RUNS_QUERY, {
    variables: { taskUuid: uuid, limit: 10, offset },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.taskRuns.length === 0) {
        setHasMore(false)
        setTaskRuns((prevRuns) => prevRuns)
        return
      }
      // Only append runs that we don't already have
      setTaskRuns((prevRuns) => {
        const newRuns = data.taskRuns.filter(
          (newRun) => !prevRuns.some((existingRun) => existingRun.uuid === newRun.uuid)
        )
        return [...prevRuns, ...newRuns]
      })
    },
  })

  // Separate query for polling the latest run when running
  const { refetch: refetchLatestRun } = useQuery<TaskRunsQuery, TaskRunsQueryVariables>(
    GET_TASK_RUNS_QUERY,
    {
      variables: { taskUuid: uuid, limit: 1, offset: 0 },
      fetchPolicy: 'network-only',
      skip: true, // Don't execute the query automatically
    }
  )

  const [submitRunTask] = useMutation<SubmitRunTaskMutation, SubmitRunTaskMutationVariables>(
    SUBMIT_RUN_TASK_MUTATION
  )

  const refetchTaskRunsInterval = React.useRef<NodeJS.Timeout | null>(null)

  // Handle initial task runs data
  useEffect(() => {
    if (!taskRunsData) {
      return
    }

    if (taskRunsData.taskRuns.length === 0) {
      setHasMore(false)
      return
    }

    setTaskRuns((prevRuns) => {
      const newRuns = taskRunsData.taskRuns.filter(
        (newRun) => !prevRuns.some((existingRun) => existingRun.uuid === newRun.uuid)
      )
      return [...prevRuns, ...newRuns]
    })
  }, [taskRunsData])

  // Handle polling for latest run
  useEffect(() => {
    let isMounted = true

    const pollLatestRun = async () => {
      if (!isRunning) {
        return
      }

      try {
        const { data } = await refetchLatestRun()
        if (!isMounted || !data?.taskRuns.length) {
          return
        }

        const latestRun = data.taskRuns[0]
        setTaskRuns((prevRuns) => {
          const runsWithoutLatest = prevRuns.filter((run) => run.uuid !== latestRun.uuid)
          return [latestRun, ...runsWithoutLatest]
        })
      } catch (error) {
        console.error('Error polling latest run:', error)
      }
    }

    if (isRunning) {
      refetchTaskRunsInterval.current = setInterval(pollLatestRun, 2000)
    }

    return () => {
      isMounted = false
      if (refetchTaskRunsInterval.current) {
        clearInterval(refetchTaskRunsInterval.current)
        refetchTaskRunsInterval.current = null
      }
    }
  }, [isRunning, refetchLatestRun])

  // we might come to the page while the task is running
  useEffect(() => {
    if (!isRunning && task?.status === 'running') {
      setIsRunning(true)
    }
  }, [task])

  useEffect(() => {
    if (isRunning && taskRunsData?.taskRuns.length > 0) {
      const lastRunStatus = taskRunsData.taskRuns[0].status
      if (lastRunStatus !== 'running' && lastRunStatus !== 'pending') {
        setIsRunning(false)
      }
    }
  }, [taskRunsData])

  // Replace scroll handler with intersection observer
  useEffect(() => {
    const container = taskRunsContainerRef.current
    if (!container || !hasMore || taskRunsLoading) {
      return undefined
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setOffset((prevOffset) => prevOffset + 10)
        }
      },
      { threshold: 0.5 }
    )

    observer.observe(container)
    return () => observer.disconnect()
  }, [hasMore, taskRunsLoading])

  const handleRun = async () => {
    if (isRunning) {
      window.toastr.error('Task is already running')
      return
    }
    setIsRunning(true)
    const response = await submitRunTask({ variables: { uuid } })
    if (response.data.submitRunTask.errors.length > 0) {
      window.toastr.error('Error submitting task to run')
      setIsRunning(false)
    } else {
      window.toastr.success('Task submitted successfully')
    }
  }

  const taskableType = task?.taskableType?.toLowerCase() == 'workflow' ? 'alert' : 'analysis'

  if (taskLoading) {
    return <LinearProgress />
  }

  return (
    <>
      <SubHeader
        object={{
          name: task.taskable.name,
          description: task.taskable.description,
          uuid: task.taskable.uuid,
        }}
        type={taskableType}
        subtitle={task.name}
        rightContent={
          <>
            <Link to={'/task/edit/' + uuid}>
              <HeaderActionButton tooltip="Edit" icon={<Pencil className="h-4 w-4" />} />
            </Link>
          </>
        }
      />

      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="flex flex-row items-center space-x-2 text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            <TaskStatus status={task.status} />
            <div>{task.name}</div>
          </h2>
          <div className="mt-1 ml-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <BriefcaseIcon aria-hidden="true" className="mr-1.5 size-5 shrink-0 text-gray-400" />
              {task.taskableType == 'Workflow' ? 'Alert' : task.taskableType}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <CalendarIcon aria-hidden="true" className="mr-1.5 size-5 shrink-0 text-gray-400" />
              {getRelativeTimeString(task.createdAt)}
            </div>
          </div>
        </div>
        <div className="mt-5 flex lg:ml-4 lg:mt-0">
          <span className="sm:ml-3">
            <button
              onClick={handleRun}
              disabled={isRunning}
              className="inline-flex items-center rounded-md bg-flowmo-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-flowbo-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-flowbo-blue-600"
            >
              {isRunning ? (
                <>
                  <Spin aria-hidden="true" className="-ml-0.5 mr-1.5 size-5" />
                  Running
                </>
              ) : (
                <>
                  <PlayCircleIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5" />
                  Run
                </>
              )}
            </button>
          </span>
        </div>
      </div>

      <div className="my-10">
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-6 sm:px-6">
            <h3 className="text-base font-semibold leading-7 text-gray-900">Details</h3>
          </div>
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Type</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <TaskType taskType={task.taskType} />
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Description</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {task.description}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Task</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <TaskVisualization task={task.task} />
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="">
        <>
          {taskRuns.length === 0 ? (
            <EmptyContent title="The task hasn't been run." />
          ) : (
            <ul role="list" className="space-y-6">
              {taskRuns.map((run, runIndex) => (
                <li key={run.uuid} className="relative flex gap-x-4">
                  <div
                    className={clsx(
                      runIndex === taskRuns.length - 1 ? 'h-6' : '-bottom-6',
                      'absolute left-0 top-0 flex w-6 justify-center -bottom-6'
                    )}
                  >
                    <div className="w-px bg-gray-200"></div>
                  </div>
                  {run.status == 'succeeded' && (
                    <CheckCircleIcon className="text-green-500 relative mt-3 h-6 w-6 flex-none rounded-full" />
                  )}
                  {run.status == 'failed' && (
                    <XCircleIcon className=" text-red-500 relative mt-3 h-6 w-6 flex-none rounded-full" />
                  )}
                  {run.status == 'pending' && (
                    <QuestionMarkCircleIcon className="text-yellow-500 relative mt-3 h-6 w-6 flex-none rounded-full" />
                  )}
                  <TaskRun run={run} />
                </li>
              ))}
            </ul>
          )}
        </>
        {taskRunsLoading && <LinearProgress />}
        <div ref={taskRunsContainerRef} />
      </div>
    </>
  )
}

export default OneTask

import React, { useEffect, useRef, useState } from 'react'
import PromptInput from '../../../components/Chat/PromptInput'
import AudioRecorder from '../../../components/Audio/AudioRecorder'
import useAudioConversation from '../../../hooks/useAudioConversation'
import { useMutation, useQuery } from '@apollo/client'
import { GET_COMPANY_AGENT } from '../../../graphql/queries/agent'
import { useChat } from '../../../hooks/useChat'
import MessageThread from '../../../components/SidebarChat/MessageThread'
import {
  ADD_MESSAGE_TO_THREAD,
  ADD_SIDE_EFFECT_TO_THREAD,
  ADD_STEP_TO_THREAD,
} from '../../../graphql/queries/message_thread'
import { Keyboard, Mic } from 'lucide-react'
import MessageBase from '../../../components/Chat/MessageBase'
import ThreeDots from '../../../../common/ui/ThreeDots'
import LinearProgress from '@mui/joy/LinearProgress'
import { ConversationSteps } from '../../../slices/chatSlice'
import StepChip from '../OneAgentChat/StepChip'
import {
  AddSideEffectToThreadMutation,
  AddStepToThreadMutation,
  AddStepToThreadMutationVariables,
  AddMessageToThreadMutation,
  AddMessageToThreadMutationVariables,
  AddSideEffectToThreadMutationVariables,
  MessageStatusEnum,
  FunctionToolCall,
  ToolFunction,
  StepInput,
} from '../../../../graphql'
import { CreateList } from '../CreateList'

interface RealtimeConversationProps {
  emptyWorkspace: React.ReactNode
  threadUuid?: string
}
const RealtimeConversation = ({ emptyWorkspace, threadUuid }: RealtimeConversationProps) => {
  const divRef = useRef<HTMLDivElement>(null)
  const { data: agentData, loading } = useQuery(GET_COMPANY_AGENT)
  const agent = agentData?.companyAgent
  const creatingThreadRef = useRef(false)
  const [isRecording, setIsRecording] = useState(false) // Track recording state
  const [mode, setMode] = useState<'text' | 'voice'>('text')

  const {
    chatState,
    handleSubmit,
    getOrCreateThreadUuid,
    addConversationItem,
    handleArtifactChange,
  } = useChat({
    agent,
    initialThreadUuid: threadUuid,
  })

  const {
    sendAudioData,
    timeLeft,
    timeLimit,
    conversation,
    isSessionReady,
    pendingQueue,
    isResponding,
  } = useAudioConversation({
    channel: 'ChiefOfStaffChannel',
    isRecording: chatState?.threadUuid ? isRecording : false,
    opts: {
      thread_uuid: chatState?.threadUuid,
    },
  })

  const [addMessageToThread] = useMutation<
    AddMessageToThreadMutation,
    AddMessageToThreadMutationVariables
  >(ADD_MESSAGE_TO_THREAD)

  const [addStepToThread] = useMutation<AddStepToThreadMutation, AddStepToThreadMutationVariables>(
    ADD_STEP_TO_THREAD
  )
  const [addSideEffectToThread] = useMutation<
    AddSideEffectToThreadMutation,
    AddSideEffectToThreadMutationVariables
  >(ADD_SIDE_EFFECT_TO_THREAD)

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [divRef, pendingQueue, conversation, chatState])

  // create the the thread if we have a conversation started
  useEffect(() => {
    const createThread = async () => {
      if (!chatState.threadUuid && !creatingThreadRef.current) {
        try {
          creatingThreadRef.current = true
          await getOrCreateThreadUuid()
        } finally {
          creatingThreadRef.current = false
        }
      }
    }

    if (isRecording && !chatState?.threadUuid) {
      createThread()
    }
  }, [isRecording, chatState?.threadUuid])

  useEffect(() => {
    if (conversation.length === 0 || !chatState?.threadUuid) {
      return
    }

    // update all the message
    conversation.forEach((oneConversationItem) => {
      // does the message exist in the chat already
      const existingItem = chatState.conversation.find((m) => m.uuid === oneConversationItem.uuid)
      if (existingItem) {
        return
      }

      addConversationItem(oneConversationItem)

      if (oneConversationItem.type === 'step') {
        const oneStepList = oneConversationItem.content.steps.map((step) => {
          // remove the __typename field
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { __typename, ...oneStep } = step

          if (oneStep.type === 'function_call') {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { __typename, ...oneFunction } = (oneStep as FunctionToolCall)
              .function as ToolFunction
            ;(oneStep as FunctionToolCall).function = oneFunction
            oneStep.type = 'function'
            return {
              type: 'function',
              functionToolCall: oneStep,
            }
          } else if (oneStep.type === 'code_interpreter_call') {
            return {
              type: oneStep.type,
              codeInterpreterToolCall: oneStep,
            }
          } else if (oneStep.type === 'file_search_call') {
            return {
              type: oneStep.type,
              fileSearchToolCall: oneStep,
            }
          }

          throw new Error('Invalid step type')
        })

        addStepToThread({
          variables: {
            threadUuid: chatState.threadUuid,
            step: {
              id: null,
              uuid: oneConversationItem.uuid,
              actor: oneConversationItem.role,
              step: oneStepList as StepInput[],
              createdAt: oneConversationItem.createdAt,
              createdAtMilli: oneConversationItem.createdAtMillis,
              updatedAt: oneConversationItem.createdAt,
              updatedAtMilli: oneConversationItem.createdAtMillis,
            },
          },
        })
      } else if (oneConversationItem.type === 'side_effect') {
        addSideEffectToThread({
          variables: {
            threadUuid: chatState.threadUuid,
            sideEffect: {
              id: null,
              actor: oneConversationItem.role,
              uuid: oneConversationItem.uuid,
              sideEffect: oneConversationItem.content.sideEffect,
              data: oneConversationItem.content.data,
              output: oneConversationItem.content.output,
              createdAt: oneConversationItem.createdAt,
              createdAtMilli: oneConversationItem.createdAtMillis,
              updatedAt: oneConversationItem.createdAt,
              updatedAtMilli: oneConversationItem.createdAtMillis,
            },
          },
        })
      } else if (oneConversationItem.type === 'text') {
        addMessageToThread({
          variables: {
            threadUuid: chatState.threadUuid,
            message: {
              id: null,
              uuid: oneConversationItem.uuid,
              actor: oneConversationItem.role,
              message: oneConversationItem.content.message.map((m) => ({
                type: m.type,
                value: m.value,
              })),
              createdAt: oneConversationItem.createdAt,
              createdAtMilli: oneConversationItem.createdAtMillis,
              updatedAt: oneConversationItem.createdAt,
              updatedAtMilli: oneConversationItem.createdAtMillis,
            },
          },
        })
      }
    })
  }, [conversation, chatState?.threadUuid])

  if (!chatState || !agent || loading) {
    return <LinearProgress />
  }

  const { isQuerying } = chatState

  const handleToggle = () => {
    setMode(mode === 'text' ? 'voice' : 'text')
    if (mode === 'text' && isRecording) {
      setIsRecording(false)
    }
  }

  return (
    <div className="absolute inset-0 flex flex-row h-full">
      <div className="flex flex-col h-full w-[50%] bg-gray-200 shadow-inner overflow-y-auto">
        <div className="flex-grow p-4">
          <MessageThread chatState={chatState} showName={false} />
          {pendingQueue.map((item) => {
            if (item.type === 'side_effect') {
              return (
                <div key={item.uuid} className="w-60">
                  <LinearProgress />
                </div>
              )
            } else if (item.type === 'step') {
              const conversationSteps = item.content as ConversationSteps
              return conversationSteps.steps.map((step) => {
                return (
                  <div key={item.uuid}>
                    <StepChip step={step} status={MessageStatusEnum.InProgress} />
                  </div>
                )
              })
            } else {
              return (
                <div key={item.uuid}>
                  <MessageBase
                    actor={item.role}
                    createdAt={item.createdAt}
                    showVoting={false}
                    showName={false}
                  >
                    <div className="flex flex-row space-x-2 text-gray-300">
                      <ThreeDots />
                    </div>
                  </MessageBase>
                </div>
              )
            }
          })}
          {isResponding && (
            <div className="flex flex-row space-x-2 text-gray-300">
              <ThreeDots /> Thinking...
            </div>
          )}
          <div ref={divRef}></div>
        </div>
        <div className="flex flex-col items-center justify-center w-full mb-8">
          <div className="flex flex-row w-full items-center justify-center gap-2 max-w-xl">
            <div className="flex-shrink-0">
              <button
                className="bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-full flex items-center text-lg"
                onClick={handleToggle}
                aria-label={mode === 'text' ? 'Switch to voice input' : 'Switch to text input'}
              >
                {mode === 'text' ? <Mic className="size-6" /> : <Keyboard className="size-6" />}
              </button>
            </div>

            <div className="flex-grow mr-2">
              {mode === 'voice' ? (
                <AudioRecorder
                  onAudioData={sendAudioData}
                  isRecording={isRecording}
                  setIsRecording={setIsRecording}
                  timeLeft={timeLeft}
                  timeLimit={timeLimit}
                  isSessionReady={isSessionReady}
                />
              ) : (
                <PromptInput handleSubmit={handleSubmit} isQuerying={isQuerying} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full w-[50%] overflow-y-auto">
        {chatState?.artifacts && chatState?.artifacts.length > 0 ? (
          <CreateList
            artifacts={chatState?.artifacts}
            handleArtifactChange={handleArtifactChange}
          />
        ) : (
          emptyWorkspace
        )}
      </div>
    </div>
  )
}

export default RealtimeConversation

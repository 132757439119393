import React, { useEffect, useState } from 'react'
import { GET_COMPANY_AGENT, GET_ORGANIZATION_AGENTS_QUERY } from '../../graphql/queries/agent'
import { useQuery } from '@apollo/client'
import {
  CompanyAgentQuery,
  CompanyAgentQueryVariables,
  OrganizationAgentsQuery,
  OrganizationAgentsQueryVariables,
} from 'app/javascript/components/graphql'
import LinearProgress from '@mui/material/LinearProgress'
import clsx from 'clsx'
import FlowmoIcon from '../../../../../assets/images/FlowMo_Icon.png'

const AgentCard: React.FC<{
  firstName: string
  jobTitle: string
  selected: boolean
  imageUrl?: string
  onClick: () => void
}> = ({ firstName, jobTitle, selected, onClick, imageUrl }) => {
  return (
    <div
      className={clsx(
        'flex flex-row items-center gap-2',
        'text-xs rounded-full bg-gray-100 px-2 py-1 transition-all duration-150 ease-in-out',
        selected
          ? 'bg-gray-300 rounded-r-none'
          : 'hover:bg-gray-200 hover:shadow-sm cursor-pointer mr-2'
      )}
      onClick={onClick}
    >
      {imageUrl ? (
        <img src={imageUrl} alt={firstName} className="size-10 rounded-full mr-2" />
      ) : (
        <div className="flex items-center justify-center size-10 rounded-full mr-2 bg-white">
          <img src={FlowmoIcon} alt={firstName} className="size-6" />
        </div>
      )}
      <div className="flex flex-col">
        <div className="text-sm font-semibold line-clamp-1 text-gray-700">{firstName}</div>
        <div className="text-sm line-clamp-1 text-gray-500">{jobTitle}</div>
      </div>
    </div>
  )
}

const AgentSidebar: React.FC<{ onAgentClick: (agentUuid: string) => void }> = ({
  onAgentClick,
}) => {
  const [selectedAgentUuid, setSelectedAgentUuid] = useState<string | null>(null)

  // get organization agents
  const { data: organizationAgentData, loading: organizationAgentLoading } = useQuery<
    OrganizationAgentsQuery,
    OrganizationAgentsQueryVariables
  >(GET_ORGANIZATION_AGENTS_QUERY)

  // get company agent
  const { data: companyAgentData, loading: companyAgentLoading } = useQuery<
    CompanyAgentQuery,
    CompanyAgentQueryVariables
  >(GET_COMPANY_AGENT)

  // set the selected agent if you see it in the url
  useEffect(() => {
    const url = new URL(window.location.href)
    const agentUuid = url.searchParams.get('agent_uuid')
    if (agentUuid) {
      setSelectedAgentUuid(agentUuid)
    }
  }, [])

  if (organizationAgentLoading || companyAgentLoading) {
    return (
      <div className="w-48 flex flex-col h-full gap-6 ml-2 border-r border-gray-200">
        <LinearProgress />
      </div>
    )
  }

  const organizationAgents = organizationAgentData?.organizationAgents

  const customAgents = organizationAgents?.filter((agent) => agent.isCustom)
  const expertAgents = organizationAgents?.filter((agent) => agent.isExpert)
  const companyAgent = companyAgentData?.companyAgent

  const handleAgentClick = (agentUuid: string) => {
    setSelectedAgentUuid(agentUuid)
    onAgentClick(agentUuid)
  }

  return (
    <div
      className={clsx(
        'w-48 flex flex-col h-full gap-6 ml-2 border-r border-gray-200 overflow-y-auto'
      )}
    >
      <div className="mt-10">
        <AgentCard
          imageUrl={''}
          firstName={'FlowMo'}
          jobTitle={'Chief of Staff'}
          selected={selectedAgentUuid === companyAgent.uuid}
          onClick={() => handleAgentClick(companyAgent.uuid)}
        />
      </div>
      <div className="flex flex-col gap-2 divide-y divide-gray-200">
        <div className="text-sm font-semibold">Expert Agents</div>
        <div className="flex flex-col pt-2 gap-2">
          {expertAgents?.map((agent) => (
            <AgentCard
              key={agent.uuid}
              imageUrl={agent.profileImageUrl}
              firstName={agent.firstName}
              jobTitle={agent.jobTitle}
              selected={selectedAgentUuid === agent.uuid}
              onClick={() => handleAgentClick(agent.uuid)}
            />
          ))}
          {expertAgents.length === 0 && (
            <div className="mt-5 text-xs text-gray-500">No expert agents</div>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-2 divide-y divide-gray-200">
        <div className="text-sm font-semibold pt-2">Custom Agents</div>
        <div className="flex flex-col pt-2 gap-2">
          {customAgents?.map((agent) => (
            <AgentCard
              key={agent.uuid}
              imageUrl={agent.profileImageUrl}
              firstName={agent.firstName}
              jobTitle={agent.jobTitle}
              selected={selectedAgentUuid === agent.uuid}
              onClick={() => handleAgentClick(agent.uuid)}
            />
          ))}
          {customAgents.length === 0 && (
            <div className="mt-5 text-xs text-gray-500">No custom agents</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AgentSidebar

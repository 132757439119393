import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Link, useHistory } from 'react-router-dom'
import {
  DataUnionQuery,
  DataUnionQueryVariables,
  OrganizationAgentsQuery,
  OrganizationAgentsQueryVariables,
  AddDataUnionToAgentMutation,
  AddDataUnionToAgentMutationVariables,
  RemoveDataUnionFromAgentMutation,
  RemoveDataUnionFromAgentMutationVariables,
} from '../../../../../components/graphql'
import { GET_DATA_UNION_QUERY } from '../../../graphql/queries/data_union'
import { GET_ORGANIZATION_AGENTS_QUERY } from '../../../graphql/queries/agent'
import {
  ADD_DATA_UNION_TO_AGENT_MUTATION,
  REMOVE_DATA_UNION_FROM_AGENT_MUTATION,
} from '../../../graphql/queries/data_union'
import { Switch } from '@headlessui/react'
import clsx from 'clsx'
import TextContent from '../../../components/Chat/TextContent'
import { WandSparkles, Pencil, ArrowRight } from 'lucide-react'
import LinearProgress from '@mui/material/LinearProgress'
import MainHeader from '../../../components/ObjectHeader/MainHeader'
import type { Action } from '../../../components/ObjectHeader/MainHeader'
import { VerticalTabs, TabItem } from '../../../../../components/common/ui/VerticalTabs'

interface OneDataUnionProps {
  uuid: string
}

const OneDataUnion = ({ uuid }: OneDataUnionProps) => {
  const history = useHistory()
  const [loadingSwitches, setLoadingSwitches] = React.useState<string[]>([])

  const { data, loading } = useQuery<DataUnionQuery, DataUnionQueryVariables>(
    GET_DATA_UNION_QUERY,
    {
      variables: { uuid },
      fetchPolicy: 'network-only',
    }
  )

  const [addDataUnionToAgent] = useMutation<
    AddDataUnionToAgentMutation,
    AddDataUnionToAgentMutationVariables
  >(ADD_DATA_UNION_TO_AGENT_MUTATION)
  const [removeDataUnionFromAgent] = useMutation<
    RemoveDataUnionFromAgentMutation,
    RemoveDataUnionFromAgentMutationVariables
  >(REMOVE_DATA_UNION_FROM_AGENT_MUTATION)

  const {
    data: agentsData,
    loading: agentsLoading,
    refetch: refetchAgents,
  } = useQuery<OrganizationAgentsQuery, OrganizationAgentsQueryVariables>(
    GET_ORGANIZATION_AGENTS_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  )
  const agents = agentsData?.organizationAgents || []

  if (loading) {
    return <LinearProgress />
  }

  if (!loading && !data?.dataUnion) {
    window.toastr.error('Data Union not found')
    history.push('/data_unions')
    return null
  }

  const dataUnion = data?.dataUnion

  const handleCheckboxChange = async (agentUuid: string, isChecked: boolean) => {
    setLoadingSwitches((prev) => [...prev, agentUuid])

    try {
      if (isChecked) {
        const response = await addDataUnionToAgent({
          variables: {
            agentUuid,
            dataUnionUuid: uuid,
          },
        })
        if (response.data?.addDataUnionToAgent.errors?.length > 0) {
          window.toastr.error('Error adding data union to agent', 'Error')
        } else {
          window.toastr.success('Successfully added data union to agent', 'Added')
        }
      } else {
        const response = await removeDataUnionFromAgent({
          variables: {
            agentUuid,
            dataUnionUuid: uuid,
          },
        })
        if (response.data?.removeDataUnionFromAgent.errors?.length > 0) {
          window.toastr.error('Error removing data union from agent', 'Error')
        } else {
          window.toastr.success('Successfully removed data union from agent', 'Removed')
        }
      }
    } catch (err) {
      window.toastr.error('An unexpected error occurred', 'Error')
    } finally {
      setLoadingSwitches((prev) => prev.filter((id) => id !== agentUuid))
      refetchAgents()
    }
  }

  let actions: Action[] = []
  if (dataUnion.isEditable) {
    actions = [
      {
        name: 'Edit with AI',
        icon: WandSparkles,
        href: (uuid: string) => `/edit/data_union/${uuid}`,
      },
      {
        name: 'Edit',
        icon: Pencil,
        href: (uuid: string) => `/data_union/edit/${uuid}`,
      },
    ]
  }

  const DetailsContent = () => {
    const baseDataSet = dataUnion.dataSets.find((ds) => ds.uuid === dataUnion.definition.dataSet)
    const joinedDataSets = dataUnion.definition.joins.map((join) => ({
      ...join,
      dataSet: dataUnion.dataSets.find((ds) => ds.uuid === join.dataSet),
    }))

    interface JoinedDataSet {
      type: string
      relationship: string
      sqlOn: string
      dataSet?: {
        uuid: string
        name: string
      }
    }

    return (
      <div className="space-y-8">
        <dl className="divide-y divide-gray-100 max-w-3xl">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Name</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {dataUnion.name}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Description</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <TextContent content={{ type: 'text', value: dataUnion.description }} />
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Structure</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <div className="space-y-6">
                <div className="p-4 border rounded-lg bg-white">
                  <h3 className="text-lg font-semibold mb-2">Base Data Set</h3>
                  {baseDataSet ? (
                    <Link
                      to={`/data_set/${baseDataSet.uuid}`}
                      className="text-flowmo-blue-600 hover:underline"
                    >
                      {baseDataSet.name}
                    </Link>
                  ) : (
                    <span className="text-gray-500">No base dataset selected</span>
                  )}
                </div>

                {joinedDataSets.map((join: JoinedDataSet, index: number) => (
                  <div key={index} className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <ArrowRight className="h-6 w-6 text-gray-400" />
                    </div>
                    <div className="flex-grow p-4 border rounded-lg bg-white">
                      <div className="flex justify-between items-start">
                        <div>
                          <Link
                            to={`/data_set/${join.dataSet?.uuid}`}
                            className="text-flowmo-blue-600 hover:underline block"
                          >
                            {join.dataSet?.name}
                          </Link>
                          <div className="text-sm text-gray-500 mt-1">
                            <span className="font-medium">Join Type:</span>{' '}
                            {join.type.toLowerCase().replace('_', ' ')}
                          </div>
                          <div className="text-sm text-gray-500">
                            <span className="font-medium">Relationship:</span>{' '}
                            {join.relationship.toLowerCase().replace('_', ' ')}
                          </div>
                        </div>
                        <div className="text-sm text-gray-500 bg-gray-50 p-2 rounded">
                          <span className="font-medium">ON:</span> {join.sqlOn}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </dd>
          </div>
        </dl>
      </div>
    )
  }

  const AgentsContent = () => (
    <div className="flex flex-col space-y-2">
      {agentsLoading && <LinearProgress />}

      {agents.map((agent) => {
        // @ts-ignore - Agent type needs to be updated to include dataUnions
        const enabled = agent.dataUnions?.some((du: { uuid: string }) => du.uuid === uuid)
        const isLoading = loadingSwitches.includes(agent.uuid)

        if (!dataUnion.isEditable && !enabled) {
          return null
        }

        return (
          <div key={agent.uuid} className="w-full p-5 border rounded">
            <div className="flex flex-row">
              <div className="flex flex-col flex-grow">
                <div className="text-lg font-semibold">
                  <Link to={`/agent/${agent.uuid}`}>{agent.label}</Link>
                </div>
                <div className="text-md text-gray-800">{agent.description}</div>
              </div>
              <div className="justify-center">
                {dataUnion.isEditable && (
                  <Switch
                    checked={enabled}
                    onChange={(checked) => handleCheckboxChange(agent.uuid, checked)}
                    className={clsx(
                      enabled ? 'bg-flowmo-blue-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-flowmo-blue-600 focus:ring-offset-2'
                    )}
                    disabled={isLoading}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={clsx(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )

  const tabs: TabItem[] = [
    {
      key: 'details',
      label: 'Details',
      content: <DetailsContent />,
    },
    {
      key: 'agents',
      label: 'Agents',
      content: <AgentsContent />,
    },
  ]

  return (
    <div className="min-h-screen bg-white">
      <MainHeader
        object={{
          name: dataUnion.name,
          description: dataUnion.description,
          uuid: dataUnion.uuid,
        }}
        type="data_union"
        actions={actions}
      />
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        <VerticalTabs tabs={tabs} />
      </div>
    </div>
  )
}

export default OneDataUnion

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  BigInt: { input: any; output: any }
  Date: { input: any; output: any }
  ISO8601DateTime: { input: any; output: any }
  JSON: { input: any; output: any }
  Upload: { input: any; output: any }
}

export enum ActorEnum {
  /** System actor */
  System = 'SYSTEM',
  /** User actor */
  User = 'USER',
  /** Workflow actor */
  Workflow = 'WORKFLOW',
}

export type Agent = {
  __typename?: 'Agent'
  backend: Scalars['String']['output']
  buildStatus?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy?: Maybe<User>
  dataSets?: Maybe<Array<DataSet>>
  dataUnions?: Maybe<Array<DataUnion>>
  description?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isCompany: Scalars['Boolean']['output']
  isCustom: Scalars['Boolean']['output']
  isExpert: Scalars['Boolean']['output']
  isPublic?: Maybe<Scalars['Boolean']['output']>
  jobTitle?: Maybe<Scalars['String']['output']>
  label: Scalars['String']['output']
  plugins?: Maybe<Array<Plugin>>
  primer?: Maybe<Scalars['String']['output']>
  profileImageUrl?: Maybe<Scalars['String']['output']>
  prompts?: Maybe<Array<Scalars['String']['output']>>
  shortDescription?: Maybe<Scalars['String']['output']>
  skillSets?: Maybe<Array<SkillSet>>
  type: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  uuid: Scalars['String']['output']
  walkthroughs?: Maybe<Array<Walkthrough>>
}

export type AgentCreate = {
  __typename?: 'AgentCreate'
  agent?: Maybe<Agent>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type AgentFile = {
  __typename?: 'AgentFile'
  filePath?: Maybe<Scalars['String']['output']>
  fileUuid?: Maybe<Scalars['String']['output']>
  value: Scalars['String']['output']
}

export type AgentKnowledge = {
  __typename?: 'AgentKnowledge'
  content: Scalars['String']['output']
  documentType: Scalars['String']['output']
  source: Scalars['String']['output']
  sourceName: Scalars['String']['output']
  sourceType: Scalars['String']['output']
  sourceUuid: Scalars['String']['output']
  uuid: Scalars['String']['output']
}

export type Analysis = {
  __typename?: 'Analysis'
  agent: Agent
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: User
  cronSchedule?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  fileAttachments?: Maybe<Array<FileAttachment>>
  id: Scalars['ID']['output']
  instructions: Scalars['String']['output']
  knowledge?: Maybe<Array<Knowledge>>
  name: Scalars['String']['output']
  notes?: Maybe<Array<Note>>
  outputFormat: Array<ContentBlockFormat>
  status: Scalars['String']['output']
  tasks: Array<Task>
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['ID']['output']
  webhookUrl?: Maybe<Scalars['String']['output']>
}

export type AnalysisCreateResponse = {
  __typename?: 'AnalysisCreateResponse'
  analysis?: Maybe<Analysis>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success?: Maybe<Scalars['Boolean']['output']>
}

export type AnalysisRun = {
  __typename?: 'AnalysisRun'
  analysis?: Maybe<Analysis>
  createdAt: Scalars['ISO8601DateTime']['output']
  finishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  instructions?: Maybe<Scalars['String']['output']>
  messageThreadUuid?: Maybe<Scalars['String']['output']>
  output?: Maybe<Array<ContentBlockUnion>>
  outputFormat: Array<ContentBlockFormat>
  responseMessage?: Maybe<Scalars['String']['output']>
  startedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  tasks: Array<AnalysisTaskResult>
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['String']['output']
  webhookResponse?: Maybe<Scalars['JSON']['output']>
  webhookUrl?: Maybe<Scalars['String']['output']>
}

export type AnalysisTaskResult = {
  __typename?: 'AnalysisTaskResult'
  createdAt: Scalars['ISO8601DateTime']['output']
  description: Scalars['String']['output']
  name: Scalars['String']['output']
  response?: Maybe<Array<MessageContentUnion>>
  task?: Maybe<Scalars['String']['output']>
}

export type AnalysisTemplate = {
  __typename?: 'AnalysisTemplate'
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  createdBy?: Maybe<User>
  description: Scalars['String']['output']
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  instructions: Scalars['String']['output']
  isOwner?: Maybe<Scalars['Boolean']['output']>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  outputFormat: Array<ContentBlockFormat>
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  steps: Array<Scalars['JSON']['output']>
  tasks?: Maybe<Array<TaskTemplate>>
  updatedAt: Scalars['Date']['output']
  uuid: Scalars['String']['output']
}

export type Artifact = {
  __typename?: 'Artifact'
  artifactType: Scalars['String']['output']
  content: Scalars['JSON']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  description: Scalars['String']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['ID']['output']
}

export type Audio = {
  __typename?: 'Audio'
  audioUrl?: Maybe<Scalars['String']['output']>
  data?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  uuid: Scalars['String']['output']
}

export type ChartBlock = {
  __typename?: 'ChartBlock'
  chartType?: Maybe<Scalars['String']['output']>
  data?: Maybe<Scalars['JSON']['output']>
  options?: Maybe<Scalars['JSON']['output']>
  title?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
  xAxis?: Maybe<Scalars['JSON']['output']>
  yAxis?: Maybe<Scalars['JSON']['output']>
}

export type CodeInterpret = {
  __typename?: 'CodeInterpret'
  input?: Maybe<Scalars['String']['output']>
  outputs?: Maybe<Scalars['JSON']['output']>
}

export type CodeInterpretInput = {
  input?: InputMaybe<Scalars['String']['input']>
  outputs?: InputMaybe<Scalars['JSON']['input']>
}

export type CodeInterpreterToolCall = {
  __typename?: 'CodeInterpreterToolCall'
  codeInterpreter: CodeInterpret
  description: Scalars['String']['output']
  name: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type CodeInterpreterToolCallInput = {
  codeInterpreter: CodeInterpretInput
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type CompanyAgent = {
  __typename?: 'CompanyAgent'
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  isCompany?: Maybe<Scalars['Boolean']['output']>
  isCustom?: Maybe<Scalars['Boolean']['output']>
  isExpert?: Maybe<Scalars['Boolean']['output']>
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  uuid: Scalars['String']['output']
}

export type Content = SkillSet | Walkthrough

/** Content block format can specify Text, Markdown, Chart, or Table with a prompt */
export type ContentBlockFormat = {
  __typename?: 'ContentBlockFormat'
  prompt: Scalars['String']['output']
  type: Scalars['String']['output']
}

/** Attributes for creating a content block format */
export type ContentBlockFormatInput = {
  /** Prompt for generating this content block */
  prompt: Scalars['String']['input']
  /** Type of content block (text, markdown, chart, table) */
  type: Scalars['String']['input']
}

/** Content blocks can be Text, Markdown, Chart, or Table */
export type ContentBlockUnion = ChartBlock | MarkdownBlock | TableBlock | TextBlock

/** All available sources */
export enum ContentType {
  Complete = 'Complete',
  Incomplete = 'Incomplete',
  SkillSet = 'SkillSet',
  Walkthrough = 'Walkthrough',
}

export type ConversationUnion = MessageHistory | SideEffect | StepHistory

export type CreditUsage = {
  __typename?: 'CreditUsage'
  quantity: Scalars['Int']['output']
  usageDate: Scalars['ISO8601DateTime']['output']
}

export type DataCredential = {
  __typename?: 'DataCredential'
  credentialType: Scalars['String']['output']
  credentials: Scalars['JSON']['output']
  errors?: Maybe<Array<Scalars['String']['output']>>
  uuid: Scalars['String']['output']
}

export type DataSet = {
  __typename?: 'DataSet'
  agents?: Maybe<Array<Agent>>
  backendType?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy?: Maybe<User>
  dataSample?: Maybe<Scalars['String']['output']>
  definition: Scalars['JSON']['output']
  description: Scalars['String']['output']
  functionDefinition?: Maybe<FunctionDefinition>
  id: Scalars['Int']['output']
  isEditable?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  organization?: Maybe<Organization>
  status?: Maybe<Scalars['String']['output']>
  templateId?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['String']['output']
}

export type DataSetCreateResponse = {
  __typename?: 'DataSetCreateResponse'
  dataSet?: Maybe<DataSet>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success?: Maybe<Scalars['Boolean']['output']>
}

export type DataUnion = {
  __typename?: 'DataUnion'
  agents: Array<Agent>
  backendType: Scalars['String']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: User
  dataSets: Array<DataSet>
  definition: DataUnionDefinition
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  isEditable: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  organization: Organization
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['String']['output']
}

export type DataUnionCreateResponse = {
  __typename?: 'DataUnionCreateResponse'
  dataUnion?: Maybe<DataUnion>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type DataUnionDefinition = {
  __typename?: 'DataUnionDefinition'
  /** UUID of the base dataset */
  dataSet: Scalars['String']['output']
  /** Array of join configurations */
  joins: Array<DataUnionJoin>
}

export type DataUnionJoin = {
  __typename?: 'DataUnionJoin'
  /** UUID of the dataset being joined */
  dataSet: Scalars['String']['output']
  /** Type of relationship between the joined tables */
  relationship: JoinRelationshipEnum
  /** SQL join condition */
  sqlOn: Scalars['String']['output']
  /** Type of join */
  type: JoinTypeEnum
}

/** All available destination types */
export enum DestinationType {
  Organization = 'organization',
  RemoveFromSkillSet = 'remove_from_skill_set',
  SkillSet = 'skill_set',
  User = 'user',
}

export type EvaluationMessage = {
  __typename?: 'EvaluationMessage'
  reason?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['Boolean']['output']>
}

/** All available event subject types */
export enum EventSubjectType {
  Skillset = 'skillset',
  Walkthrough = 'walkthrough',
}

export type ExpertAgent = {
  __typename?: 'ExpertAgent'
  backend: Scalars['String']['output']
  buildStatus?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: User
  dataSets?: Maybe<Array<DataSet>>
  description: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  isPublic?: Maybe<Scalars['Boolean']['output']>
  jobTitle: Scalars['String']['output']
  label: Scalars['String']['output']
  plugins?: Maybe<Array<Plugin>>
  primer: Scalars['String']['output']
  profileImageUrl?: Maybe<Scalars['String']['output']>
  prompts?: Maybe<Array<Scalars['String']['output']>>
  shortDescription: Scalars['String']['output']
  skillSets?: Maybe<Array<SkillSet>>
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  uuid: Scalars['String']['output']
  walkthroughs?: Maybe<Array<Walkthrough>>
}

export type ExpertAgentTemplate = {
  __typename?: 'ExpertAgentTemplate'
  id: Scalars['String']['output']
  instructions?: Maybe<Scalars['String']['output']>
  jobTitle: Scalars['String']['output']
  longDescription?: Maybe<Scalars['String']['output']>
  shortDescription?: Maybe<Scalars['String']['output']>
  uuid: Scalars['String']['output']
}

export type FeedItem = Agent | AnalysisRun | Suggestion | TaskRun | Workflow

/** All available types */
export enum FeedItemTypeEnum {
  /** Agent */
  Agent = 'AGENT',
  /** Analysis Run */
  AnalysisRun = 'ANALYSIS_RUN',
  /** Suggestion */
  Suggestion = 'SUGGESTION',
  /** Task Run */
  TaskRun = 'TASK_RUN',
  /** Workflow */
  Workflow = 'WORKFLOW',
}

export enum FeedbackEnum {
  /** Thumbs down feedback */
  ThumbsDown = 'THUMBS_DOWN',
  /** Thumbs up feedback */
  ThumbsUp = 'THUMBS_UP',
}

export type FileAttachment = {
  __typename?: 'FileAttachment'
  contentLength: Scalars['Int']['output']
  downloadUrl?: Maybe<Scalars['String']['output']>
  fileExtension: Scalars['String']['output']
  fileName: Scalars['String']['output']
  s3Uri?: Maybe<Scalars['String']['output']>
  uuid: Scalars['ID']['output']
}

export type FileSearchToolCall = {
  __typename?: 'FileSearchToolCall'
  description: Scalars['String']['output']
  name: Scalars['String']['output']
  raw: Scalars['JSON']['output']
  type: Scalars['String']['output']
}

export type FileSearchToolCallInput = {
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  raw: Scalars['JSON']['input']
  type: Scalars['String']['input']
}

export type FunctionDefinition = {
  __typename?: 'FunctionDefinition'
  fields?: Maybe<Array<FunctionDefinitionField>>
  filters?: Maybe<Array<FunctionDefinitionFilter>>
}

export type FunctionDefinitionField = {
  __typename?: 'FunctionDefinitionField'
  category: Scalars['String']['output']
  name: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type FunctionDefinitionFilter = {
  __typename?: 'FunctionDefinitionFilter'
  name: Scalars['String']['output']
}

export type FunctionToolCall = {
  __typename?: 'FunctionToolCall'
  description: Scalars['String']['output']
  function: ToolFunction
  name: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type FunctionToolCallInput = {
  description: Scalars['String']['input']
  function: ToolFunctionInput
  name: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type Gif = {
  __typename?: 'Gif'
  data?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
}

export type ImageContent = {
  __typename?: 'ImageContent'
  type: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type ImageData = {
  __typename?: 'ImageData'
  assetId: Scalars['String']['output']
  bytes: Scalars['String']['output']
  format: Scalars['String']['output']
  height: Scalars['Int']['output']
  publicId: Scalars['String']['output']
  url: Scalars['String']['output']
  version: Scalars['String']['output']
  width: Scalars['Int']['output']
}

export enum JoinRelationshipEnum {
  /** Many-to-many relationship */
  ManyToMany = 'MANY_TO_MANY',
  /** Many-to-one relationship */
  ManyToOne = 'MANY_TO_ONE',
  /** One-to-many relationship */
  OneToMany = 'ONE_TO_MANY',
  /** One-to-one relationship */
  OneToOne = 'ONE_TO_ONE',
}

export enum JoinTypeEnum {
  /** Cross join */
  Cross = 'CROSS',
  /** Full outer join */
  FullOuter = 'FULL_OUTER',
  /** Inner join */
  Inner = 'INNER',
  /** Left outer join */
  LeftOuter = 'LEFT_OUTER',
  /** Right outer join */
  RightOuter = 'RIGHT_OUTER',
}

export type Knowledge = {
  __typename?: 'Knowledge'
  type: Scalars['String']['output']
  uuid: Scalars['ID']['output']
}

export type KnowledgeInput = {
  type: Scalars['String']['input']
  uuid: Scalars['ID']['input']
}

export type LearningActivity = {
  __typename?: 'LearningActivity'
  action: LearningActivityActionType
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  objectId: Scalars['ID']['output']
  objectType: LearningActivityObjectType
  subjectId: Scalars['ID']['output']
  subjectType?: Maybe<LearningActivitySubjectType>
  updatedAt: Scalars['Date']['output']
}

/** All available types */
export enum LearningActivityActionType {
  Completed = 'completed',
  Started = 'started',
  Viewed = 'viewed',
  ViewedShared = 'viewed_shared',
}

/** All available types */
export enum LearningActivityObjectType {
  SkillSet = 'skill_set',
  Walkthrough = 'walkthrough',
}

/** All available types */
export enum LearningActivitySubjectType {
  User = 'user',
}

export type MarkdownBlock = {
  __typename?: 'MarkdownBlock'
  content?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
}

/** Content can be of type Text, Image, or File */
export type MessageContentUnion = ImageContent | TextContent

export type MessageFeedback = {
  __typename?: 'MessageFeedback'
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type MessageHistory = {
  __typename?: 'MessageHistory'
  actor: ActorEnum
  createdAt: Scalars['ISO8601DateTime']['output']
  /** Milliseconds since epoch */
  createdAtMilli: Scalars['BigInt']['output']
  feedback?: Maybe<FeedbackEnum>
  fileAttachments?: Maybe<Array<FileAttachment>>
  id: Scalars['Int']['output']
  knowledge?: Maybe<Array<Knowledge>>
  message: Array<MessageContentUnion>
  notes?: Maybe<Array<Note>>
  status?: Maybe<MessageStatusEnum>
  updatedAt: Scalars['ISO8601DateTime']['output']
  /** Milliseconds since epoch */
  updatedAtMilli: Scalars['BigInt']['output']
  uuid: Scalars['String']['output']
}

export type MessageHistoryInput = {
  actor: ActorEnum
  createdAt: Scalars['ISO8601DateTime']['input']
  createdAtMilli?: InputMaybe<Scalars['BigInt']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  message?: InputMaybe<Array<MessageInput>>
  updatedAt: Scalars['ISO8601DateTime']['input']
  updatedAtMilli?: InputMaybe<Scalars['BigInt']['input']>
  uuid: Scalars['String']['input']
}

export type MessageInput = {
  type: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type MessageResponse = {
  __typename?: 'MessageResponse'
  errors: Array<Scalars['String']['output']>
  threadUuid?: Maybe<Scalars['String']['output']>
}

export enum MessageStatusEnum {
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Completed */
  Completed = 'COMPLETED',
  /** In progress */
  InProgress = 'IN_PROGRESS',
}

export type MessageThread = {
  __typename?: 'MessageThread'
  agent: Agent
  createdAt: Scalars['ISO8601DateTime']['output']
  messages: Array<MessageHistory>
  sideEffects: Array<SideEffect>
  steps: Array<StepHistory>
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['String']['output']
}

export type MetaData = {
  __typename?: 'MetaData'
  count: Scalars['Int']['output']
}

export type Mutation = {
  __typename?: 'Mutation'
  addDataSetToAgent: ResponseStatus
  addDataUnionToAgent: ResponseStatus
  addMessageToThread: ResponseStatus
  addPlugin: ResponseStatus
  addSideEffectToThread: ResponseStatus
  addSkillSetToAgent: ResponseStatus
  addStepToThread: ResponseStatus
  addToWaitlist: WaitlistResponse
  addWalkthroughToAgent: ResponseStatus
  createAgent: AgentCreate
  createAnalysis: AnalysisCreateResponse
  createAnalysisFromTemplate: AnalysisCreateResponse
  createArtifact: Artifact
  createDataCredentials: ResponseStatus
  createDataSet: DataSetCreateResponse
  createDataUnion: DataUnionCreateResponse
  createEvent: RrwebEvents
  createExpertAgent: ResponseStatus
  createFileAttachment: FileAttachment
  createLearningActivity: LearningActivity
  createMessageThread: MessageThread
  createSkillSet: SkillSet
  createTask: ResponseStatus
  createUser: User
  createUserActivity: UserActivity
  createWalkthrough: Walkthrough
  createWalkthroughV2: TemporaryWalkthrough
  createWorkflow: WorkflowCreateResponse
  createWorkflowFromTemplate: WorkflowCreateResponse
  deleteAgent: ResponseStatus
  deleteAnalysis: ResponseStatus
  deleteArtifact: ResponseStatus
  deleteDataCredentials: ResponseStatus
  deleteDataSet: ResponseStatus
  deleteDataUnion: ResponseStatus
  deleteFileAttachment: ResponseStatus
  deleteSkillSet: SkillSet
  deleteTask: ResponseStatus
  deleteUser: User
  deleteWalkthrough: Walkthrough
  deleteWorkflow: ResponseStatus
  discardWalkthrough: Walkthrough
  generateProfileImage: ResponseStatus
  messageFeedback: MessageFeedback
  moveSkillSet: SkillSet
  moveWalkthrough: Walkthrough
  publishWalkthrough: Walkthrough
  rebuildAgent: ResponseStatus
  refreshDataSet: ResponseStatus
  removeDataSetFromAgent: ResponseStatus
  removeDataUnionFromAgent: ResponseStatus
  removePlugin: ResponseStatus
  removeSkillSetFromAgent: ResponseStatus
  removeWalkthroughFromAgent: ResponseStatus
  resendEmailInvite: ResponseStatus
  sendAgentMessage: MessageResponse
  sendInvites: ResponseStatus
  shareEmbedWalkthrough: Walkthrough
  shareSkillSet: SkillSet
  shareWalkthrough: Walkthrough
  stopImpersonating: ResponseStatus
  submitRunAnalysis: ResponseStatus
  submitRunTask: ResponseStatus
  submitRunWorkflow: ResponseStatus
  taskRunMarkPassed: ResponseStatus
  toggleSuggestionStatus: ToggleSuggestionStatus
  updateAgent: ResponseStatus
  updateAgentPrompts: ResponseStatus
  updateAnalysis: ResponseStatus
  updateArtifact: Artifact
  updateArtifactUuid: Artifact
  updateCurrentUser: ResponseStatus
  updateDataCredentials: ResponseStatus
  updateDataSet: ResponseStatus
  updateDataUnion: DataUnionCreateResponse
  updateOrganization: ResponseStatus
  updatePlugin: ResponseStatus
  updateSkillSet: SkillSet
  updateTask: ResponseStatus
  updateUser: User
  updateWalkthrough: Walkthrough
  updateWorkflow: ResponseStatus
  uploadAudio: Audio
  uploadGif: Gif
  uploadGifV2: Response
  uploadRrwebChunk: RrwebEvents
  uploadRrwebChunkV2: Response
  uploadTemporaryWalkthroughAudio: Response
  uploadUserScreenshot: Screenshot
}

export type MutationAddDataSetToAgentArgs = {
  agentUuid: Scalars['String']['input']
  dataSetUuid: Scalars['String']['input']
}

export type MutationAddDataUnionToAgentArgs = {
  agentUuid: Scalars['String']['input']
  dataUnionUuid: Scalars['String']['input']
}

export type MutationAddMessageToThreadArgs = {
  message: MessageHistoryInput
  threadUuid: Scalars['String']['input']
}

export type MutationAddPluginArgs = {
  agentUuid: Scalars['String']['input']
  data: Scalars['JSON']['input']
  pluginTemplateId: Scalars['String']['input']
}

export type MutationAddSideEffectToThreadArgs = {
  sideEffect: SideEffectInput
  threadUuid: Scalars['String']['input']
}

export type MutationAddSkillSetToAgentArgs = {
  agentUuid: Scalars['String']['input']
  skillSetUuid: Scalars['String']['input']
}

export type MutationAddStepToThreadArgs = {
  step: StepHistoryInput
  threadUuid: Scalars['String']['input']
}

export type MutationAddToWaitlistArgs = {
  email: Scalars['String']['input']
}

export type MutationAddWalkthroughToAgentArgs = {
  agentUuid: Scalars['String']['input']
  walkthroughUuid: Scalars['String']['input']
}

export type MutationCreateAgentArgs = {
  description: Scalars['String']['input']
  firstName: Scalars['String']['input']
  jobTitle: Scalars['String']['input']
  primer: Scalars['String']['input']
  shortDescription: Scalars['String']['input']
}

export type MutationCreateAnalysisArgs = {
  agentUuid: Scalars['String']['input']
  cronSchedule?: InputMaybe<Scalars['String']['input']>
  description: Scalars['String']['input']
  fileAttachments?: InputMaybe<Array<Scalars['String']['input']>>
  instructions: Scalars['String']['input']
  knowledge?: InputMaybe<Array<KnowledgeInput>>
  name: Scalars['String']['input']
  notes?: InputMaybe<Array<NoteInput>>
  outputFormat?: InputMaybe<Array<ContentBlockFormatInput>>
  webhookUrl?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateAnalysisFromTemplateArgs = {
  agentUuid: Scalars['String']['input']
  templateId: Scalars['String']['input']
}

export type MutationCreateArtifactArgs = {
  content: Scalars['JSON']['input']
  description: Scalars['String']['input']
  messageThreadUuid: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type MutationCreateDataCredentialsArgs = {
  credentialType: Scalars['String']['input']
  credentials: Scalars['JSON']['input']
}

export type MutationCreateDataSetArgs = {
  definition: Scalars['JSON']['input']
  description: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type MutationCreateDataUnionArgs = {
  definition: Scalars['JSON']['input']
  description: Scalars['String']['input']
  isEditable: Scalars['Boolean']['input']
  name: Scalars['String']['input']
}

export type MutationCreateEventArgs = {
  metadata?: InputMaybe<Scalars['JSON']['input']>
  source?: InputMaybe<Scalars['String']['input']>
  subjectId: Scalars['ID']['input']
  subjectType: EventSubjectType
  timestamp: Scalars['Float']['input']
  type: Scalars['String']['input']
}

export type MutationCreateExpertAgentArgs = {
  uuid: Scalars['String']['input']
}

export type MutationCreateFileAttachmentArgs = {
  fileAttachment: Scalars['Upload']['input']
}

export type MutationCreateLearningActivityArgs = {
  action: LearningActivityActionType
  objectId: Scalars['ID']['input']
  objectType: LearningActivityObjectType
  subjectId?: InputMaybe<Scalars['ID']['input']>
  subjectType?: InputMaybe<LearningActivitySubjectType>
}

export type MutationCreateMessageThreadArgs = {
  agentUuid: Scalars['String']['input']
}

export type MutationCreateSkillSetArgs = {
  description: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type MutationCreateTaskArgs = {
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  outputWebhookUrl: Scalars['String']['input']
  runSchedule: Scalars['String']['input']
  task: Scalars['String']['input']
  taskType?: InputMaybe<Scalars['String']['input']>
  taskableType: Scalars['String']['input']
  taskableUuid: Scalars['String']['input']
}

export type MutationCreateUserArgs = {
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  organization: Scalars['ID']['input']
  password?: InputMaybe<Scalars['String']['input']>
  roles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type MutationCreateUserActivityArgs = {
  action: Scalars['String']['input']
  metadata?: InputMaybe<Scalars['JSON']['input']>
  objectId?: InputMaybe<Scalars['ID']['input']>
  objectType?: InputMaybe<UserActivityObjectType>
  url: Scalars['String']['input']
  userId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationCreateWalkthroughArgs = {
  description?: InputMaybe<Scalars['String']['input']>
  meta?: InputMaybe<Scalars['JSON']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  steps?: InputMaybe<Array<Scalars['JSON']['input']>>
}

export type MutationCreateWalkthroughV2Args = {
  description?: InputMaybe<Scalars['String']['input']>
  meta?: InputMaybe<Scalars['JSON']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  steps?: InputMaybe<Array<Scalars['JSON']['input']>>
}

export type MutationCreateWorkflowArgs = {
  agentUuid: Scalars['String']['input']
  cronSchedule?: InputMaybe<Scalars['String']['input']>
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  webhookUrl?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateWorkflowFromTemplateArgs = {
  agentUuid: Scalars['String']['input']
  templateUuid: Scalars['String']['input']
}

export type MutationDeleteAgentArgs = {
  uuid: Scalars['String']['input']
}

export type MutationDeleteAnalysisArgs = {
  uuid: Scalars['String']['input']
}

export type MutationDeleteArtifactArgs = {
  uuid: Scalars['String']['input']
}

export type MutationDeleteDataCredentialsArgs = {
  uuid: Scalars['String']['input']
}

export type MutationDeleteDataSetArgs = {
  uuid: Scalars['String']['input']
}

export type MutationDeleteDataUnionArgs = {
  uuid: Scalars['String']['input']
}

export type MutationDeleteFileAttachmentArgs = {
  uuid: Scalars['ID']['input']
}

export type MutationDeleteSkillSetArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteTaskArgs = {
  uuid: Scalars['String']['input']
}

export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteWalkthroughArgs = {
  id: Scalars['ID']['input']
  organizationId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationDeleteWorkflowArgs = {
  uuid: Scalars['String']['input']
}

export type MutationDiscardWalkthroughArgs = {
  uuid: Scalars['ID']['input']
}

export type MutationGenerateProfileImageArgs = {
  uuid: Scalars['String']['input']
}

export type MutationMessageFeedbackArgs = {
  agentUuid: Scalars['String']['input']
  feedback: Scalars['String']['input']
  messageUuid: Scalars['String']['input']
}

export type MutationMoveSkillSetArgs = {
  destinationId?: InputMaybe<Scalars['ID']['input']>
  destinationType: DestinationType
  id: Scalars['ID']['input']
}

export type MutationMoveWalkthroughArgs = {
  destinationId?: InputMaybe<Scalars['ID']['input']>
  destinationType: DestinationType
  id: Scalars['ID']['input']
}

export type MutationPublishWalkthroughArgs = {
  uuid: Scalars['ID']['input']
}

export type MutationRebuildAgentArgs = {
  uuid: Scalars['String']['input']
}

export type MutationRefreshDataSetArgs = {
  uuid: Scalars['String']['input']
}

export type MutationRemoveDataSetFromAgentArgs = {
  agentUuid: Scalars['String']['input']
  dataSetUuid: Scalars['String']['input']
}

export type MutationRemoveDataUnionFromAgentArgs = {
  agentUuid: Scalars['String']['input']
  dataUnionUuid: Scalars['String']['input']
}

export type MutationRemovePluginArgs = {
  id: Scalars['Int']['input']
}

export type MutationRemoveSkillSetFromAgentArgs = {
  agentUuid: Scalars['String']['input']
  skillSetUuid: Scalars['String']['input']
}

export type MutationRemoveWalkthroughFromAgentArgs = {
  agentUuid: Scalars['String']['input']
  walkthroughUuid: Scalars['String']['input']
}

export type MutationResendEmailInviteArgs = {
  id: Scalars['String']['input']
}

export type MutationSendAgentMessageArgs = {
  agentUuid: Scalars['String']['input']
  chatId?: InputMaybe<Scalars['String']['input']>
  enableStreaming?: InputMaybe<Scalars['Boolean']['input']>
  fileAttachments?: InputMaybe<Array<Scalars['String']['input']>>
  knowledge?: InputMaybe<Array<KnowledgeInput>>
  message: Scalars['String']['input']
  notes?: InputMaybe<Array<NoteInput>>
  threadUuid?: InputMaybe<Scalars['String']['input']>
}

export type MutationSendInvitesArgs = {
  emails: Array<Scalars['String']['input']>
}

export type MutationShareEmbedWalkthroughArgs = {
  enabled: Scalars['Boolean']['input']
  id: Scalars['ID']['input']
}

export type MutationShareSkillSetArgs = {
  id: Scalars['ID']['input']
  shareType: ShareTypeEnum
}

export type MutationShareWalkthroughArgs = {
  id: Scalars['ID']['input']
  shareType: ShareTypeEnum
}

export type MutationSubmitRunAnalysisArgs = {
  uuid: Scalars['String']['input']
}

export type MutationSubmitRunTaskArgs = {
  uuid: Scalars['String']['input']
}

export type MutationSubmitRunWorkflowArgs = {
  uuid: Scalars['String']['input']
}

export type MutationTaskRunMarkPassedArgs = {
  uuid: Scalars['String']['input']
}

export type MutationToggleSuggestionStatusArgs = {
  uuid: Scalars['String']['input']
}

export type MutationUpdateAgentArgs = {
  description: Scalars['String']['input']
  firstName: Scalars['String']['input']
  jobTitle: Scalars['String']['input']
  primer: Scalars['String']['input']
  shortDescription: Scalars['String']['input']
  uuid: Scalars['String']['input']
}

export type MutationUpdateAgentPromptsArgs = {
  prompts: Array<Scalars['String']['input']>
  uuid: Scalars['String']['input']
}

export type MutationUpdateAnalysisArgs = {
  agentUuid: Scalars['String']['input']
  cronSchedule?: InputMaybe<Scalars['String']['input']>
  description: Scalars['String']['input']
  fileAttachments?: InputMaybe<Array<Scalars['String']['input']>>
  instructions: Scalars['String']['input']
  knowledge?: InputMaybe<Array<KnowledgeInput>>
  name: Scalars['String']['input']
  notes?: InputMaybe<Array<NoteInput>>
  outputFormat: Array<ContentBlockFormatInput>
  uuid: Scalars['String']['input']
  webhookUrl?: InputMaybe<Scalars['String']['input']>
}

export type MutationUpdateArtifactArgs = {
  content: Scalars['JSON']['input']
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  uuid: Scalars['String']['input']
}

export type MutationUpdateArtifactUuidArgs = {
  artifactUuid: Scalars['String']['input']
  backendUuid: Scalars['String']['input']
  planUuid: Scalars['String']['input']
}

export type MutationUpdateCurrentUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  privacyPolicyAccepted?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateDataCredentialsArgs = {
  credentials: Scalars['JSON']['input']
  uuid: Scalars['String']['input']
}

export type MutationUpdateDataSetArgs = {
  definition: Scalars['JSON']['input']
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  uuid: Scalars['String']['input']
}

export type MutationUpdateDataUnionArgs = {
  definition: Scalars['JSON']['input']
  description: Scalars['String']['input']
  isEditable: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  uuid: Scalars['String']['input']
}

export type MutationUpdateOrganizationArgs = {
  id: Scalars['Int']['input']
  name: Scalars['String']['input']
}

export type MutationUpdatePluginArgs = {
  config: Scalars['JSON']['input']
  id: Scalars['Int']['input']
}

export type MutationUpdateSkillSetArgs = {
  description: Scalars['String']['input']
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type MutationUpdateTaskArgs = {
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  outputWebhookUrl: Scalars['String']['input']
  runSchedule: Scalars['String']['input']
  task: Scalars['String']['input']
  taskType?: InputMaybe<Scalars['String']['input']>
  uuid: Scalars['String']['input']
}

export type MutationUpdateUserArgs = {
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  lastName?: InputMaybe<Scalars['String']['input']>
  organization?: InputMaybe<Scalars['ID']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  roles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type MutationUpdateWalkthroughArgs = {
  audioEnabled?: InputMaybe<Scalars['Boolean']['input']>
  description: Scalars['String']['input']
  id: Scalars['ID']['input']
  labs?: InputMaybe<Scalars['Boolean']['input']>
  liveLearning?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  steps?: InputMaybe<Array<Scalars['JSON']['input']>>
  synthesis?: InputMaybe<Scalars['String']['input']>
}

export type MutationUpdateWorkflowArgs = {
  agentUuid: Scalars['String']['input']
  cronSchedule?: InputMaybe<Scalars['String']['input']>
  description: Scalars['String']['input']
  name: Scalars['String']['input']
  uuid: Scalars['String']['input']
  webhookUrl?: InputMaybe<Scalars['String']['input']>
}

export type MutationUploadAudioArgs = {
  file: Scalars['Upload']['input']
  walkthroughId: Scalars['ID']['input']
}

export type MutationUploadGifArgs = {
  urls: Array<Scalars['String']['input']>
  walkthroughId: Scalars['ID']['input']
}

export type MutationUploadGifV2Args = {
  urls: Array<Scalars['String']['input']>
  walkthroughUuid: Scalars['ID']['input']
}

export type MutationUploadRrwebChunkArgs = {
  file: Scalars['Upload']['input']
  index: Scalars['Int']['input']
  walkthroughId: Scalars['ID']['input']
}

export type MutationUploadRrwebChunkV2Args = {
  file: Scalars['Upload']['input']
  index: Scalars['Int']['input']
  walkthroughUuid: Scalars['ID']['input']
}

export type MutationUploadTemporaryWalkthroughAudioArgs = {
  file: Scalars['Upload']['input']
  walkthroughUuid: Scalars['ID']['input']
}

export type MutationUploadUserScreenshotArgs = {
  publicId: Scalars['String']['input']
  screenshot: Scalars['Upload']['input']
}

export type Note = {
  __typename?: 'Note'
  note?: Maybe<Scalars['String']['output']>
  uuid: Scalars['String']['output']
}

export type NoteInput = {
  note: Scalars['String']['input']
  uuid: Scalars['String']['input']
}

export type Organization = {
  __typename?: 'Organization'
  createdAt: Scalars['Date']['output']
  emailNamespace?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isPaid: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  restrictByGroupOrRole: Scalars['Boolean']['output']
  updatedAt: Scalars['Date']['output']
  userCount?: Maybe<Scalars['Int']['output']>
  users: Array<User>
}

export type Plugin = {
  __typename?: 'Plugin'
  config: Scalars['JSON']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['Int']['output']
  isEditable: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  pluginTemplate?: Maybe<PluginTemplate>
  pluginType: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type PluginTemplate = {
  __typename?: 'PluginTemplate'
  dataTemplate: Scalars['JSON']['output']
  description: Scalars['String']['output']
  id: Scalars['String']['output']
  isPublic: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  onePerAgent: Scalars['Boolean']['output']
  type: Scalars['String']['output']
  useCases: Array<Scalars['String']['output']>
}

/** Get the conversation */
export type Query = {
  __typename?: 'Query'
  CurrentUser?: Maybe<User>
  SkillSet: SkillSet
  User: User
  Walkthrough: Walkthrough
  /** Find an agent by UUID */
  agent?: Maybe<Agent>
  agentAnalyses?: Maybe<Array<Analysis>>
  /** Find all agents that use this data set */
  agentDataSets?: Maybe<Array<DataSet>>
  /** Find all data unions that are used by an agent */
  agentDataUnions?: Maybe<Array<DataUnion>>
  /** Find all knowledge for an agent */
  agentKnowledge?: Maybe<Array<AgentKnowledge>>
  /** Find a knowledge item for an agent */
  agentKnowledgeItem?: Maybe<AgentKnowledge>
  /** Find all suggestions for an agent */
  agentSuggestions?: Maybe<Array<Suggestion>>
  agentWorkflows?: Maybe<Array<Workflow>>
  allRoles: Array<Role>
  analyses?: Maybe<Array<Analysis>>
  analysis?: Maybe<Analysis>
  analysisTasks?: Maybe<Array<Task>>
  analysisTemplates?: Maybe<Array<AnalysisTemplate>>
  /** Find an artifact by UUID */
  artifact?: Maybe<Artifact>
  /** Find all artifacts for a message thread */
  artifacts?: Maybe<Array<Artifact>>
  assignedToMe: Array<SkillSet>
  assignedToMeMeta: MetaData
  /** Find the company agent */
  companyAgent?: Maybe<CompanyAgent>
  completed: Array<Content>
  completedMeta: MetaData
  creditUsages?: Maybe<Array<CreditUsage>>
  /** Looker credentials for the current organization */
  dataCredentials?: Maybe<Array<DataCredential>>
  /** Find a dataset by UUID */
  dataSet?: Maybe<DataSet>
  /** Get multiple data sets by uuids */
  dataSets?: Maybe<Array<DataSet>>
  /** Find a data union by UUID */
  dataUnion?: Maybe<DataUnion>
  /** Get multiple data unions by uuids */
  dataUnions?: Maybe<Array<DataUnion>>
  /** Find all demo agents */
  demoAgents?: Maybe<Array<Agent>>
  /** Find all expert agent templates */
  expertAgentTemplates?: Maybe<Array<ExpertAgentTemplate>>
  /** Find all expert agents */
  expertAgents?: Maybe<Array<ExpertAgent>>
  feedItems?: Maybe<Array<FeedItem>>
  fileAttachment?: Maybe<FileAttachment>
  /** Get the conversation in a thread by UUID */
  getConversation?: Maybe<Array<ConversationUnion>>
  getCreatedContent: Array<Content>
  getCreatedContentMeta: MetaData
  /** Get the message in a thread by UUID */
  getMessageHistories?: Maybe<Array<MessageHistory>>
  /** Get the message thread */
  getMessageThread?: Maybe<MessageThread>
  /** Get the message threads */
  getMessageThreads?: Maybe<Array<MessageThread>>
  getOrganizationContent: Array<Content>
  getOrganizationContentMeta: MetaData
  /** Get the side effects in a thread by UUID */
  getSideEffectHistories?: Maybe<Array<SideEffect>>
  /** Get the steps in a thread by UUID */
  getStepHistories?: Maybe<Array<StepHistory>>
  historicAnalysisRuns?: Maybe<Array<AnalysisRun>>
  lastAnalysisRun?: Maybe<AnalysisRun>
  oneAnalysisRun?: Maybe<AnalysisRun>
  /** Find all agents for an organization */
  organizationAgents?: Maybe<Array<Agent>>
  /** Find all data sets for an organization */
  organizationDataSets?: Maybe<Array<DataSet>>
  /** Find all data unions for an organization */
  organizationDataUnions?: Maybe<Array<DataUnion>>
  organizationSuggestions?: Maybe<Array<Suggestion>>
  organizationUsers: Array<User>
  organizationUsersMeta: MetaData
  /** Get all the plugin templates */
  pluginTemplates?: Maybe<Array<PluginTemplate>>
  /** Get all the plugins for an agent */
  plugins?: Maybe<Array<Plugin>>
  recentlyViewed: Array<Content>
  recentlyViewedMeta: MetaData
  sharedWithMe: Array<Content>
  sharedWithMeMeta: MetaData
  /** Get the summary of the conversation */
  summary?: Maybe<Scalars['String']['output']>
  task?: Maybe<Task>
  taskRuns?: Maybe<Array<TaskRun>>
  tasks?: Maybe<Array<Task>>
  trueUser?: Maybe<User>
  walkthroughByUuid: Walkthrough
  workflow?: Maybe<Workflow>
  workflowTasks?: Maybe<Array<Task>>
  workflowTemplates?: Maybe<Array<WorkflowTemplate>>
  workflows?: Maybe<Array<Workflow>>
}

/** Get the conversation */
export type QuerySkillSetArgs = {
  uuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryUserArgs = {
  id: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryWalkthroughArgs = {
  id: Scalars['ID']['input']
  organizationId?: InputMaybe<Scalars['ID']['input']>
}

/** Get the conversation */
export type QueryAgentArgs = {
  uuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryAgentAnalysesArgs = {
  agentUuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryAgentDataSetsArgs = {
  uuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryAgentDataUnionsArgs = {
  uuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryAgentKnowledgeArgs = {
  uuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryAgentKnowledgeItemArgs = {
  agentUuid: Scalars['String']['input']
  itemUuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryAgentSuggestionsArgs = {
  agentUuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryAgentWorkflowsArgs = {
  agentUuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryAnalysesArgs = {
  agentUuid?: InputMaybe<Scalars['ID']['input']>
}

/** Get the conversation */
export type QueryAnalysisArgs = {
  uuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryAnalysisTasksArgs = {
  analysisUuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryAnalysisTemplatesArgs = {
  agentUuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryArtifactArgs = {
  uuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryArtifactsArgs = {
  makeConsistent?: InputMaybe<Scalars['Boolean']['input']>
  messageThreadUuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryAssignedToMeArgs = {
  contentType?: InputMaybe<ContentType>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
}

/** Get the conversation */
export type QueryAssignedToMeMetaArgs = {
  contentType?: InputMaybe<ContentType>
}

/** Get the conversation */
export type QueryCompletedArgs = {
  contentType?: InputMaybe<ContentType>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
}

/** Get the conversation */
export type QueryCompletedMetaArgs = {
  contentType?: InputMaybe<ContentType>
}

/** Get the conversation */
export type QueryCreditUsagesArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

/** Get the conversation */
export type QueryDataSetArgs = {
  uuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryDataSetsArgs = {
  uuids: Array<Scalars['String']['input']>
}

/** Get the conversation */
export type QueryDataUnionArgs = {
  uuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryDataUnionsArgs = {
  uuids: Array<Scalars['String']['input']>
}

/** Get the conversation */
export type QueryFeedItemsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  types?: InputMaybe<Array<FeedItemTypeEnum>>
}

/** Get the conversation */
export type QueryFileAttachmentArgs = {
  uuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryGetConversationArgs = {
  uuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryGetCreatedContentArgs = {
  contentType?: InputMaybe<Array<ContentType>>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
}

/** Get the conversation */
export type QueryGetCreatedContentMetaArgs = {
  contentType?: InputMaybe<Array<ContentType>>
}

/** Get the conversation */
export type QueryGetMessageHistoriesArgs = {
  uuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryGetMessageThreadArgs = {
  uuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryGetMessageThreadsArgs = {
  agentUuid: Scalars['String']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

/** Get the conversation */
export type QueryGetOrganizationContentArgs = {
  contentType?: InputMaybe<Array<ContentType>>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
}

/** Get the conversation */
export type QueryGetOrganizationContentMetaArgs = {
  contentType?: InputMaybe<Array<ContentType>>
}

/** Get the conversation */
export type QueryGetSideEffectHistoriesArgs = {
  uuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryGetStepHistoriesArgs = {
  uuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryHistoricAnalysisRunsArgs = {
  uuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryLastAnalysisRunArgs = {
  uuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryOneAnalysisRunArgs = {
  uuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryOrganizationUsersArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
}

/** Get the conversation */
export type QueryOrganizationUsersMetaArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>
}

/** Get the conversation */
export type QueryPluginsArgs = {
  agentUuid: Scalars['String']['input']
}

/** Get the conversation */
export type QueryRecentlyViewedArgs = {
  contentType?: InputMaybe<ContentType>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
}

/** Get the conversation */
export type QueryRecentlyViewedMetaArgs = {
  contentType?: InputMaybe<ContentType>
}

/** Get the conversation */
export type QuerySharedWithMeArgs = {
  contentType?: InputMaybe<ContentType>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
}

/** Get the conversation */
export type QuerySharedWithMeMetaArgs = {
  contentType?: InputMaybe<ContentType>
}

/** Get the conversation */
export type QuerySummaryArgs = {
  conversation: Scalars['String']['input']
  summarizationPrompt?: InputMaybe<Scalars['String']['input']>
}

/** Get the conversation */
export type QueryTaskArgs = {
  uuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryTaskRunsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  taskUuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryTasksArgs = {
  taskableType: Scalars['String']['input']
  taskableUuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryWalkthroughByUuidArgs = {
  uuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryWorkflowArgs = {
  uuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryWorkflowTasksArgs = {
  workflowUuid: Scalars['ID']['input']
}

/** Get the conversation */
export type QueryWorkflowTemplatesArgs = {
  agentUuid: Scalars['ID']['input']
}

export type Response = {
  __typename?: 'Response'
  data?: Maybe<Scalars['String']['output']>
}

export type ResponseStatus = {
  __typename?: 'ResponseStatus'
  errors?: Maybe<Array<Scalars['String']['output']>>
  success?: Maybe<Scalars['Boolean']['output']>
}

export type Role = {
  __typename?: 'Role'
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
}

export type RrwebEvents = {
  __typename?: 'RrwebEvents'
  data?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
}

export type Screenshot = {
  __typename?: 'Screenshot'
  imageData: ImageData
}

/** All available share types */
export enum ShareTypeEnum {
  Organization = 'organization',
  Private = 'private',
  Public = 'public',
}

export type SideEffect = {
  __typename?: 'SideEffect'
  createdAt: Scalars['ISO8601DateTime']['output']
  /** Milliseconds since epoch */
  createdAtMilli: Scalars['BigInt']['output']
  data: Scalars['JSON']['output']
  output?: Maybe<Scalars['String']['output']>
  sideEffect: Scalars['String']['output']
  status?: Maybe<MessageStatusEnum>
  updatedAt: Scalars['ISO8601DateTime']['output']
  /** Milliseconds since epoch */
  updatedAtMilli: Scalars['BigInt']['output']
  uuid: Scalars['String']['output']
}

export type SideEffectInput = {
  actor: ActorEnum
  createdAt: Scalars['ISO8601DateTime']['input']
  createdAtMilli?: InputMaybe<Scalars['BigInt']['input']>
  data?: InputMaybe<Scalars['JSON']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  output?: InputMaybe<Scalars['String']['input']>
  sideEffect: Scalars['String']['input']
  updatedAt: Scalars['ISO8601DateTime']['input']
  updatedAtMilli?: InputMaybe<Scalars['BigInt']['input']>
  uuid: Scalars['String']['input']
}

export type SkillSet = {
  __typename?: 'SkillSet'
  canDelete?: Maybe<Scalars['Boolean']['output']>
  canEdit?: Maybe<Scalars['Boolean']['output']>
  canEditSharing?: Maybe<Scalars['Boolean']['output']>
  canMove?: Maybe<Scalars['Boolean']['output']>
  completed?: Maybe<Scalars['Boolean']['output']>
  createdAt: Scalars['Date']['output']
  createdBy?: Maybe<User>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isInMyLibrary?: Maybe<Scalars['Boolean']['output']>
  isInMyOrganizationLibrary?: Maybe<Scalars['Boolean']['output']>
  isOwner?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  sharedAccess: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
  uuid: Scalars['String']['output']
  walkthroughs?: Maybe<Array<Walkthrough>>
}

export type StepHistory = {
  __typename?: 'StepHistory'
  actor: ActorEnum
  createdAt: Scalars['ISO8601DateTime']['output']
  /** Milliseconds since epoch */
  createdAtMilli: Scalars['BigInt']['output']
  id: Scalars['Int']['output']
  status?: Maybe<MessageStatusEnum>
  step: Array<StepUnion>
  updatedAt: Scalars['ISO8601DateTime']['output']
  /** Milliseconds since epoch */
  updatedAtMilli: Scalars['BigInt']['output']
  uuid: Scalars['String']['output']
}

export type StepHistoryInput = {
  actor: ActorEnum
  createdAt: Scalars['ISO8601DateTime']['input']
  createdAtMilli?: InputMaybe<Scalars['BigInt']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
  step: Array<StepInput>
  updatedAt: Scalars['ISO8601DateTime']['input']
  updatedAtMilli?: InputMaybe<Scalars['BigInt']['input']>
  uuid: Scalars['String']['input']
}

export type StepInput = {
  codeInterpreterToolCall?: InputMaybe<CodeInterpreterToolCallInput>
  fileSearchToolCall?: InputMaybe<FileSearchToolCallInput>
  functionToolCall?: InputMaybe<FunctionToolCallInput>
  type: Scalars['String']['input']
}

/** Possible types for the step field in StepHistory */
export type StepUnion = CodeInterpreterToolCall | FileSearchToolCall | FunctionToolCall

export type Suggestion = {
  __typename?: 'Suggestion'
  agent: Agent
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  body: Scalars['String']['output']
  closedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy?: Maybe<User>
  description?: Maybe<Scalars['String']['output']>
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isOwner?: Maybe<Scalars['Boolean']['output']>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  organization: Organization
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  status: SuggestionStatusEnum
  steps: Array<Scalars['JSON']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['ID']['output']
}

export enum SuggestionStatusEnum {
  /** Suggestion is closed */
  Closed = 'closed',
  /** Suggestion is open */
  Open = 'open',
}

export type TableBlock = {
  __typename?: 'TableBlock'
  headers: Array<Scalars['String']['output']>
  rows: Array<Array<Scalars['JSON']['output']>>
  type: Scalars['String']['output']
}

export type Task = {
  __typename?: 'Task'
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: User
  description: Scalars['String']['output']
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isOwner?: Maybe<Scalars['Boolean']['output']>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  outputWebhookUrl?: Maybe<Scalars['String']['output']>
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  runSchedule?: Maybe<Scalars['String']['output']>
  status: Scalars['String']['output']
  steps: Array<Scalars['JSON']['output']>
  task: Scalars['String']['output']
  taskRuns?: Maybe<Array<TaskRun>>
  taskType?: Maybe<Scalars['String']['output']>
  taskable: TaskableUnion
  taskableType: Scalars['String']['output']
  taskableUuid: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['ID']['output']
}

export type TaskRun = {
  __typename?: 'TaskRun'
  agent?: Maybe<Agent>
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy?: Maybe<User>
  description?: Maybe<Scalars['String']['output']>
  evaluationMessage?: Maybe<EvaluationMessage>
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isOwner?: Maybe<Scalars['Boolean']['output']>
  messageHistory?: Maybe<Array<MessageHistory>>
  messageThread?: Maybe<MessageThread>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  requestMessage?: Maybe<Scalars['String']['output']>
  responseMessage?: Maybe<Scalars['String']['output']>
  responseStatus?: Maybe<Scalars['String']['output']>
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  runtimeSeconds?: Maybe<Scalars['Int']['output']>
  sideEffectHistory?: Maybe<Array<SideEffect>>
  startedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  state: Scalars['String']['output']
  status: Scalars['String']['output']
  stepHistory?: Maybe<Array<StepHistory>>
  steps: Array<Scalars['JSON']['output']>
  task: Task
  threadUuid?: Maybe<Scalars['ID']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['ID']['output']
  webhookCompletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  webhookResponseStatus?: Maybe<Scalars['String']['output']>
  webhookStatus?: Maybe<Scalars['String']['output']>
}

export type TaskTemplate = {
  __typename?: 'TaskTemplate'
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  createdBy?: Maybe<User>
  description: Scalars['String']['output']
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isOwner?: Maybe<Scalars['Boolean']['output']>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  runSchedule?: Maybe<Scalars['String']['output']>
  steps: Array<Scalars['JSON']['output']>
  task: Scalars['String']['output']
  taskType: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
  uuid: Scalars['String']['output']
}

/** Objects that can be taskable, either a Workflow or an Analysis */
export type TaskableUnion = Analysis | Workflow

export type TemporaryWalkthrough = {
  __typename?: 'TemporaryWalkthrough'
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  createdBy?: Maybe<User>
  description?: Maybe<Scalars['String']['output']>
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isOwner?: Maybe<Scalars['Boolean']['output']>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  steps: Array<Scalars['JSON']['output']>
  updatedAt: Scalars['Date']['output']
  uuid: Scalars['String']['output']
}

export type TextBlock = {
  __typename?: 'TextBlock'
  content?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
}

export type TextContent = {
  __typename?: 'TextContent'
  files?: Maybe<Array<AgentFile>>
  type: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type ToggleSuggestionStatus = {
  __typename?: 'ToggleSuggestionStatus'
  errors?: Maybe<Array<Scalars['String']['output']>>
  status?: Maybe<Scalars['String']['output']>
  success?: Maybe<Scalars['Boolean']['output']>
}

export type ToolFunction = {
  __typename?: 'ToolFunction'
  arguments?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  output?: Maybe<Scalars['String']['output']>
}

export type ToolFunctionInput = {
  arguments?: InputMaybe<Scalars['JSON']['input']>
  name: Scalars['String']['input']
  output?: InputMaybe<Scalars['String']['input']>
}

export type User = {
  __typename?: 'User'
  createdAt: Scalars['Date']['output']
  currentSignInAt?: Maybe<Scalars['Date']['output']>
  email: Scalars['String']['output']
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isInvitedPendingRegistration: Scalars['Boolean']['output']
  lastName?: Maybe<Scalars['String']['output']>
  lastSignInAt?: Maybe<Scalars['Date']['output']>
  longName: Scalars['String']['output']
  organization: Organization
  privacyPolicyAccepted: Scalars['Boolean']['output']
  roles: Array<Role>
  shortName: Scalars['String']['output']
  updatedAt: Scalars['Date']['output']
}

export type UserActivity = {
  __typename?: 'UserActivity'
  action: Scalars['String']['output']
  createdAt: Scalars['Date']['output']
  id: Scalars['ID']['output']
  metadata?: Maybe<Scalars['JSON']['output']>
  object?: Maybe<UserActivityObject>
  objectId?: Maybe<Scalars['ID']['output']>
  objectType?: Maybe<UserActivityObjectType>
  updatedAt: Scalars['Date']['output']
  url: Scalars['String']['output']
  userId: Scalars['ID']['output']
}

export type UserActivityObject = SkillSet | Walkthrough

/** All available user activity object types */
export enum UserActivityObjectType {
  Skillset = 'skillset',
  Walkthrough = 'walkthrough',
}

export type WaitlistResponse = {
  __typename?: 'WaitlistResponse'
  errors: Array<Scalars['String']['output']>
  placeInLine: Scalars['Int']['output']
  success: Scalars['Boolean']['output']
}

export type Walkthrough = {
  __typename?: 'Walkthrough'
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  canDelete?: Maybe<Scalars['Boolean']['output']>
  canEdit?: Maybe<Scalars['Boolean']['output']>
  canEditSharing?: Maybe<Scalars['Boolean']['output']>
  canMove?: Maybe<Scalars['Boolean']['output']>
  completed?: Maybe<Scalars['Boolean']['output']>
  createdAt: Scalars['Date']['output']
  createdBy?: Maybe<User>
  description?: Maybe<Scalars['String']['output']>
  embedEnabled: Scalars['Boolean']['output']
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  interactionCount: Scalars['Int']['output']
  isInMyLibrary?: Maybe<Scalars['Boolean']['output']>
  isInMyOrganizationLibrary?: Maybe<Scalars['Boolean']['output']>
  isOwner?: Maybe<Scalars['Boolean']['output']>
  isReadonly: Scalars['Boolean']['output']
  labs?: Maybe<Scalars['Boolean']['output']>
  lastSynthesisAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  liveLearning?: Maybe<Scalars['Boolean']['output']>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  sharedAccess: Scalars['String']['output']
  skillSet?: Maybe<SkillSet>
  steps: Array<Scalars['JSON']['output']>
  summary?: Maybe<Scalars['String']['output']>
  synthesis?: Maybe<Scalars['String']['output']>
  synthesisStatus?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['Date']['output']
  uuid: Scalars['String']['output']
  viewCount: Scalars['Int']['output']
}

export type Workflow = {
  __typename?: 'Workflow'
  agent: Agent
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  createdBy: User
  cronSchedule?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isOwner?: Maybe<Scalars['Boolean']['output']>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  steps: Array<Scalars['JSON']['output']>
  tasks: Array<Task>
  updatedAt: Scalars['ISO8601DateTime']['output']
  uuid: Scalars['ID']['output']
  webhookUrl?: Maybe<Scalars['String']['output']>
}

export type WorkflowCreateResponse = {
  __typename?: 'WorkflowCreateResponse'
  errors?: Maybe<Array<Scalars['String']['output']>>
  success?: Maybe<Scalars['Boolean']['output']>
  workflow?: Maybe<Workflow>
}

export type WorkflowTemplate = {
  __typename?: 'WorkflowTemplate'
  audioEnabled: Scalars['Boolean']['output']
  audioUrl?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['Date']['output']
  createdBy?: Maybe<User>
  description: Scalars['String']['output']
  hasAudio: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isOwner?: Maybe<Scalars['Boolean']['output']>
  meta?: Maybe<Scalars['JSON']['output']>
  name: Scalars['String']['output']
  rrwebEventsUrl?: Maybe<Scalars['String']['output']>
  steps: Array<Scalars['JSON']['output']>
  tasks: Array<TaskTemplate>
  updatedAt: Scalars['Date']['output']
  uuid: Scalars['ID']['output']
}

export type OrganizationAgentsQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationAgentsQuery = {
  __typename?: 'Query'
  organizationAgents?: Array<{
    __typename?: 'Agent'
    firstName?: string | null
    label: string
    jobTitle?: string | null
    profileImageUrl?: string | null
    shortDescription?: string | null
    description?: string | null
    primer?: string | null
    backend: string
    uuid: string
    type: string
    prompts?: Array<string> | null
    id: string
    isPublic?: boolean | null
    isExpert: boolean
    isCustom: boolean
    isCompany: boolean
    createdAt: any
    createdBy?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
    plugins?: Array<{
      __typename?: 'Plugin'
      id: number
      name: string
      pluginType: string
      config: any
      isEditable: boolean
      pluginTemplate?: {
        __typename?: 'PluginTemplate'
        id: string
        name: string
        type: string
        isPublic: boolean
        description: string
        useCases: Array<string>
        dataTemplate: any
        onePerAgent: boolean
      } | null
    }> | null
    walkthroughs?: Array<{
      __typename?: 'Walkthrough'
      id: string
      uuid: string
      name: string
      description?: string | null
      steps: Array<any>
      meta?: any | null
      audioEnabled: boolean
      hasAudio: boolean
      canEdit?: boolean | null
      canMove?: boolean | null
      isOwner?: boolean | null
      isInMyLibrary?: boolean | null
      isInMyOrganizationLibrary?: boolean | null
      canEditSharing?: boolean | null
      canDelete?: boolean | null
      sharedAccess: string
      completed?: boolean | null
      createdAt: any
      viewCount: number
      interactionCount: number
      rrwebEventsUrl?: string | null
      audioUrl?: string | null
      liveLearning?: boolean | null
      labs?: boolean | null
      embedEnabled: boolean
      createdBy?: {
        __typename?: 'User'
        firstName?: string | null
        lastName?: string | null
        id: string
      } | null
      skillSet?: {
        __typename?: 'SkillSet'
        id: string
        name: string
        uuid: string
        sharedAccess: string
      } | null
    }> | null
    dataSets?: Array<{
      __typename?: 'DataSet'
      id: number
      uuid: string
      name: string
      dataSample?: string | null
      backendType?: string | null
      templateId?: string | null
      isEditable?: boolean | null
      status?: string | null
      description: string
      definition: any
      createdAt: any
      updatedAt: any
      createdBy?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
      functionDefinition?: {
        __typename?: 'FunctionDefinition'
        fields?: Array<{
          __typename?: 'FunctionDefinitionField'
          name: string
          type: string
          category: string
        }> | null
      } | null
    }> | null
    dataUnions?: Array<{
      __typename?: 'DataUnion'
      id: string
      uuid: string
      name: string
      description: string
      isEditable: boolean
      createdAt: any
      updatedAt: any
      definition: {
        __typename?: 'DataUnionDefinition'
        dataSet: string
        joins: Array<{
          __typename?: 'DataUnionJoin'
          type: JoinTypeEnum
          relationship: JoinRelationshipEnum
          sqlOn: string
          dataSet: string
        }>
      }
      organization: { __typename?: 'Organization'; id: string; name: string }
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
      dataSets: Array<{ __typename?: 'DataSet'; id: number; uuid: string; name: string }>
    }> | null
    skillSets?: Array<{ __typename?: 'SkillSet'; id: string; uuid: string; name: string }> | null
  }> | null
}

export type SendAgentMessageMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  threadUuid?: InputMaybe<Scalars['String']['input']>
  message: Scalars['String']['input']
  chatId: Scalars['String']['input']
  enableStreaming: Scalars['Boolean']['input']
  fileAttachments?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  knowledge?: InputMaybe<Array<KnowledgeInput> | KnowledgeInput>
}>

export type SendAgentMessageMutation = {
  __typename?: 'Mutation'
  sendAgentMessage: {
    __typename?: 'MessageResponse'
    errors: Array<string>
    threadUuid?: string | null
  }
}

export type MessageFeedbackMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  messageUuid: Scalars['String']['input']
  feedback: Scalars['String']['input']
}>

export type MessageFeedbackMutation = {
  __typename?: 'Mutation'
  messageFeedback: { __typename?: 'MessageFeedback'; errors: Array<string>; success: boolean }
}

export type CreateAgentMutationVariables = Exact<{
  firstName: Scalars['String']['input']
  jobTitle: Scalars['String']['input']
  shortDescription: Scalars['String']['input']
  description: Scalars['String']['input']
  primer: Scalars['String']['input']
}>

export type CreateAgentMutation = {
  __typename?: 'Mutation'
  createAgent: {
    __typename?: 'AgentCreate'
    success: boolean
    errors: Array<string>
    agent?: {
      __typename?: 'Agent'
      firstName?: string | null
      label: string
      jobTitle?: string | null
      profileImageUrl?: string | null
      shortDescription?: string | null
      description?: string | null
      primer?: string | null
      uuid: string
      backend: string
    } | null
  }
}

export type AddWalkthroughToAgentMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  walkthroughUuid: Scalars['String']['input']
}>

export type AddWalkthroughToAgentMutation = {
  __typename?: 'Mutation'
  addWalkthroughToAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type RemoveWalkthroughFromAgentMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  walkthroughUuid: Scalars['String']['input']
}>

export type RemoveWalkthroughFromAgentMutation = {
  __typename?: 'Mutation'
  removeWalkthroughFromAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type AddSkillSetToAgentMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  skillSetUuid: Scalars['String']['input']
}>

export type AddSkillSetToAgentMutation = {
  __typename?: 'Mutation'
  addSkillSetToAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type RemoveSkillSetFromAgentMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  skillSetUuid: Scalars['String']['input']
}>

export type RemoveSkillSetFromAgentMutation = {
  __typename?: 'Mutation'
  removeSkillSetFromAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UpdateAgentMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  firstName: Scalars['String']['input']
  jobTitle: Scalars['String']['input']
  shortDescription: Scalars['String']['input']
  description: Scalars['String']['input']
  primer: Scalars['String']['input']
}>

export type UpdateAgentMutation = {
  __typename?: 'Mutation'
  updateAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type AgentQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type AgentQuery = {
  __typename?: 'Query'
  agent?: {
    __typename?: 'Agent'
    firstName?: string | null
    label: string
    jobTitle?: string | null
    profileImageUrl?: string | null
    shortDescription?: string | null
    description?: string | null
    primer?: string | null
    backend: string
    type: string
    uuid: string
    prompts?: Array<string> | null
    id: string
    isPublic?: boolean | null
    createdAt: any
    buildStatus?: string | null
    isExpert: boolean
    isCustom: boolean
    isCompany: boolean
    createdBy?: {
      __typename?: 'User'
      firstName?: string | null
      lastName?: string | null
      id: string
    } | null
    plugins?: Array<{
      __typename?: 'Plugin'
      id: number
      name: string
      pluginType: string
      config: any
      isEditable: boolean
      pluginTemplate?: {
        __typename?: 'PluginTemplate'
        id: string
        name: string
        type: string
        isPublic: boolean
        description: string
        useCases: Array<string>
        dataTemplate: any
        onePerAgent: boolean
      } | null
    }> | null
    walkthroughs?: Array<{
      __typename?: 'Walkthrough'
      id: string
      uuid: string
      name: string
      description?: string | null
      steps: Array<any>
      meta?: any | null
      audioEnabled: boolean
      hasAudio: boolean
      canEdit?: boolean | null
      canMove?: boolean | null
      isOwner?: boolean | null
      isInMyLibrary?: boolean | null
      isInMyOrganizationLibrary?: boolean | null
      canEditSharing?: boolean | null
      canDelete?: boolean | null
      sharedAccess: string
      completed?: boolean | null
      createdAt: any
      viewCount: number
      interactionCount: number
      rrwebEventsUrl?: string | null
      audioUrl?: string | null
      liveLearning?: boolean | null
      labs?: boolean | null
      embedEnabled: boolean
      createdBy?: {
        __typename?: 'User'
        firstName?: string | null
        lastName?: string | null
        id: string
      } | null
      skillSet?: {
        __typename?: 'SkillSet'
        id: string
        name: string
        uuid: string
        sharedAccess: string
      } | null
    }> | null
    dataSets?: Array<{
      __typename?: 'DataSet'
      id: number
      uuid: string
      name: string
      dataSample?: string | null
      backendType?: string | null
      templateId?: string | null
      isEditable?: boolean | null
      status?: string | null
      description: string
      definition: any
      createdAt: any
      updatedAt: any
      createdBy?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
      functionDefinition?: {
        __typename?: 'FunctionDefinition'
        fields?: Array<{
          __typename?: 'FunctionDefinitionField'
          name: string
          type: string
          category: string
        }> | null
      } | null
    }> | null
    dataUnions?: Array<{
      __typename?: 'DataUnion'
      id: string
      uuid: string
      name: string
      description: string
      isEditable: boolean
      createdAt: any
      updatedAt: any
      definition: {
        __typename?: 'DataUnionDefinition'
        dataSet: string
        joins: Array<{
          __typename?: 'DataUnionJoin'
          type: JoinTypeEnum
          relationship: JoinRelationshipEnum
          sqlOn: string
          dataSet: string
        }>
      }
      organization: { __typename?: 'Organization'; id: string; name: string }
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
      dataSets: Array<{ __typename?: 'DataSet'; id: number; uuid: string; name: string }>
    }> | null
    skillSets?: Array<{
      __typename?: 'SkillSet'
      id: string
      uuid: string
      name: string
      description?: string | null
    }> | null
  } | null
}

export type DeleteAgentMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DeleteAgentMutation = {
  __typename?: 'Mutation'
  deleteAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type PluginTemplatesQueryVariables = Exact<{ [key: string]: never }>

export type PluginTemplatesQuery = {
  __typename?: 'Query'
  pluginTemplates?: Array<{
    __typename?: 'PluginTemplate'
    id: string
    name: string
    type: string
    description: string
    useCases: Array<string>
    dataTemplate: any
    onePerAgent: boolean
  }> | null
}

export type AddPluginMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  pluginTemplateId: Scalars['String']['input']
  data: Scalars['JSON']['input']
}>

export type AddPluginMutation = {
  __typename?: 'Mutation'
  addPlugin: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type PluginsQueryVariables = Exact<{
  agentUuid: Scalars['String']['input']
}>

export type PluginsQuery = {
  __typename?: 'Query'
  plugins?: Array<{
    __typename?: 'Plugin'
    id: number
    name: string
    pluginType: string
    config: any
    isEditable: boolean
    pluginTemplate?: {
      __typename?: 'PluginTemplate'
      id: string
      name: string
      type: string
      isPublic: boolean
      description: string
      useCases: Array<string>
      dataTemplate: any
      onePerAgent: boolean
    } | null
  }> | null
}

export type RemovePluginMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type RemovePluginMutation = {
  __typename?: 'Mutation'
  removePlugin: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UpdatePluginMutationVariables = Exact<{
  id: Scalars['Int']['input']
  config: Scalars['JSON']['input']
}>

export type UpdatePluginMutation = {
  __typename?: 'Mutation'
  updatePlugin: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UpdateAgentPromptsMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  prompts: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type UpdateAgentPromptsMutation = {
  __typename?: 'Mutation'
  updateAgentPrompts: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type GenerateProfileImageMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type GenerateProfileImageMutation = {
  __typename?: 'Mutation'
  generateProfileImage: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type RebuildAgentMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type RebuildAgentMutation = {
  __typename?: 'Mutation'
  rebuildAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type AgentKnowledgeQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type AgentKnowledgeQuery = {
  __typename?: 'Query'
  agentKnowledge?: Array<{
    __typename?: 'AgentKnowledge'
    documentType: string
    uuid: string
    sourceType: string
    sourceName: string
    sourceUuid: string
  }> | null
}

export type AgentKnowledgeItemQueryVariables = Exact<{
  agentUuid: Scalars['String']['input']
  itemUuid: Scalars['String']['input']
}>

export type AgentKnowledgeItemQuery = {
  __typename?: 'Query'
  agentKnowledgeItem?: {
    __typename?: 'AgentKnowledge'
    documentType: string
    uuid: string
    sourceType: string
    sourceName: string
    sourceUuid: string
    content: string
  } | null
}

export type CompanyAgentQueryVariables = Exact<{ [key: string]: never }>

export type CompanyAgentQuery = {
  __typename?: 'Query'
  companyAgent?: {
    __typename?: 'CompanyAgent'
    id: string
    uuid: string
    isCompany?: boolean | null
    isExpert?: boolean | null
    isCustom?: boolean | null
    createdAt: any
    updatedAt?: any | null
  } | null
}

export type ExpertAgentTemplatesQueryVariables = Exact<{ [key: string]: never }>

export type ExpertAgentTemplatesQuery = {
  __typename?: 'Query'
  expertAgentTemplates?: Array<{
    __typename?: 'ExpertAgentTemplate'
    id: string
    uuid: string
    jobTitle: string
    shortDescription?: string | null
    longDescription?: string | null
    instructions?: string | null
  }> | null
}

export type CreateExpertAgentMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type CreateExpertAgentMutation = {
  __typename?: 'Mutation'
  createExpertAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type AnalysesQueryVariables = Exact<{
  agentUuid?: InputMaybe<Scalars['ID']['input']>
}>

export type AnalysesQuery = {
  __typename?: 'Query'
  analyses?: Array<{
    __typename?: 'Analysis'
    id: string
    uuid: string
    name: string
    description: string
    instructions: string
    status: string
    webhookUrl?: string | null
    cronSchedule?: string | null
    createdAt: any
    outputFormat: Array<{ __typename?: 'ContentBlockFormat'; type: string; prompt: string }>
    knowledge?: Array<{ __typename?: 'Knowledge'; uuid: string; type: string }> | null
    fileAttachments?: Array<{
      __typename?: 'FileAttachment'
      uuid: string
      fileName: string
      fileExtension: string
      contentLength: number
      downloadUrl?: string | null
    }> | null
    notes?: Array<{ __typename?: 'Note'; uuid: string; note?: string | null }> | null
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    tasks: Array<{
      __typename?: 'Task'
      id: string
      uuid: string
      name: string
      description: string
      task: string
      taskType?: string | null
      status: string
      taskableType: string
      taskableUuid: string
      outputWebhookUrl?: string | null
      runSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName?: string | null
      label: string
      jobTitle?: string | null
      primer?: string | null
      backend: string
      type: string
      profileImageUrl?: string | null
      shortDescription?: string | null
      description?: string | null
      isCompany: boolean
      isExpert: boolean
      isCustom: boolean
      createdAt: any
      createdBy?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
    }
  }> | null
}

export type AnalysisQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type AnalysisQuery = {
  __typename?: 'Query'
  analysis?: {
    __typename?: 'Analysis'
    id: string
    uuid: string
    name: string
    description: string
    instructions: string
    status: string
    webhookUrl?: string | null
    cronSchedule?: string | null
    createdAt: any
    outputFormat: Array<{ __typename?: 'ContentBlockFormat'; type: string; prompt: string }>
    knowledge?: Array<{ __typename?: 'Knowledge'; uuid: string; type: string }> | null
    fileAttachments?: Array<{
      __typename?: 'FileAttachment'
      uuid: string
      fileName: string
      fileExtension: string
      contentLength: number
      downloadUrl?: string | null
    }> | null
    notes?: Array<{ __typename?: 'Note'; uuid: string; note?: string | null }> | null
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    tasks: Array<{
      __typename?: 'Task'
      id: string
      uuid: string
      name: string
      description: string
      task: string
      taskType?: string | null
      status: string
      taskableType: string
      taskableUuid: string
      outputWebhookUrl?: string | null
      runSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName?: string | null
      label: string
      jobTitle?: string | null
      primer?: string | null
      backend: string
      type: string
      profileImageUrl?: string | null
      shortDescription?: string | null
      description?: string | null
      isCompany: boolean
      isExpert: boolean
      isCustom: boolean
      createdAt: any
      createdBy?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
    }
  } | null
}

export type CreateAnalysisMutationVariables = Exact<{
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  instructions: Scalars['String']['input']
  outputFormat: Array<ContentBlockFormatInput> | ContentBlockFormatInput
  agentUuid: Scalars['String']['input']
  webhookUrl?: InputMaybe<Scalars['String']['input']>
  cronSchedule?: InputMaybe<Scalars['String']['input']>
  knowledge?: InputMaybe<Array<KnowledgeInput> | KnowledgeInput>
  fileAttachments?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  notes?: InputMaybe<Array<NoteInput> | NoteInput>
}>

export type CreateAnalysisMutation = {
  __typename?: 'Mutation'
  createAnalysis: {
    __typename?: 'AnalysisCreateResponse'
    success?: boolean | null
    errors?: Array<string> | null
    analysis?: { __typename?: 'Analysis'; uuid: string } | null
  }
}

export type UpdateAnalysisMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  instructions: Scalars['String']['input']
  outputFormat: Array<ContentBlockFormatInput> | ContentBlockFormatInput
  agentUuid: Scalars['String']['input']
  webhookUrl?: InputMaybe<Scalars['String']['input']>
  cronSchedule?: InputMaybe<Scalars['String']['input']>
  knowledge?: InputMaybe<Array<KnowledgeInput> | KnowledgeInput>
  fileAttachments?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>
  notes?: InputMaybe<Array<NoteInput> | NoteInput>
}>

export type UpdateAnalysisMutation = {
  __typename?: 'Mutation'
  updateAnalysis: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type DeleteAnalysisMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DeleteAnalysisMutation = {
  __typename?: 'Mutation'
  deleteAnalysis: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type SubmitRunAnalysisMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type SubmitRunAnalysisMutation = {
  __typename?: 'Mutation'
  submitRunAnalysis: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type LastAnalysisRunQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type LastAnalysisRunQuery = {
  __typename?: 'Query'
  lastAnalysisRun?: {
    __typename?: 'AnalysisRun'
    uuid: string
    instructions?: string | null
    responseMessage?: string | null
    messageThreadUuid?: string | null
    webhookUrl?: string | null
    webhookResponse?: any | null
    startedAt?: any | null
    finishedAt?: any | null
    createdAt: any
    updatedAt: any
    outputFormat: Array<{ __typename?: 'ContentBlockFormat'; type: string; prompt: string }>
    output?: Array<
      | {
          __typename?: 'ChartBlock'
          type: string
          chartType?: string | null
          data?: any | null
          options?: any | null
          xAxis?: any | null
          yAxis?: any | null
          title?: string | null
        }
      | { __typename?: 'MarkdownBlock'; type: string; content?: string | null }
      | { __typename?: 'TableBlock'; type: string; headers: Array<string>; rows: Array<Array<any>> }
      | { __typename?: 'TextBlock'; type: string; content?: string | null }
    > | null
    tasks: Array<{
      __typename?: 'AnalysisTaskResult'
      name: string
      description: string
      task?: string | null
      createdAt: any
      response?: Array<
        | { __typename?: 'ImageContent'; type: string; value: string }
        | {
            __typename?: 'TextContent'
            type: string
            value: string
            files?: Array<{
              __typename?: 'AgentFile'
              value: string
              filePath?: string | null
              fileUuid?: string | null
            }> | null
          }
      > | null
    }>
    analysis?: {
      __typename?: 'Analysis'
      id: string
      uuid: string
      name: string
      description: string
      instructions: string
      status: string
      webhookUrl?: string | null
      cronSchedule?: string | null
      createdAt: any
      outputFormat: Array<{ __typename?: 'ContentBlockFormat'; type: string; prompt: string }>
      knowledge?: Array<{ __typename?: 'Knowledge'; uuid: string; type: string }> | null
      fileAttachments?: Array<{
        __typename?: 'FileAttachment'
        uuid: string
        fileName: string
        fileExtension: string
        contentLength: number
        downloadUrl?: string | null
      }> | null
      notes?: Array<{ __typename?: 'Note'; uuid: string; note?: string | null }> | null
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
      tasks: Array<{
        __typename?: 'Task'
        id: string
        uuid: string
        name: string
        description: string
        task: string
        taskType?: string | null
        status: string
        taskableType: string
        taskableUuid: string
        outputWebhookUrl?: string | null
        runSchedule?: string | null
        createdAt: any
        createdBy: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
        }
      }>
      agent: {
        __typename?: 'Agent'
        id: string
        uuid: string
        firstName?: string | null
        label: string
        jobTitle?: string | null
        primer?: string | null
        backend: string
        type: string
        profileImageUrl?: string | null
        shortDescription?: string | null
        description?: string | null
        isCompany: boolean
        isExpert: boolean
        isCustom: boolean
        createdAt: any
        createdBy?: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
        } | null
      }
    } | null
  } | null
}

export type HistoricAnalysisRunsQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type HistoricAnalysisRunsQuery = {
  __typename?: 'Query'
  historicAnalysisRuns?: Array<{
    __typename?: 'AnalysisRun'
    uuid: string
    instructions?: string | null
    responseMessage?: string | null
    messageThreadUuid?: string | null
    webhookUrl?: string | null
    webhookResponse?: any | null
    startedAt?: any | null
    finishedAt?: any | null
    createdAt: any
    updatedAt: any
    outputFormat: Array<{ __typename?: 'ContentBlockFormat'; type: string; prompt: string }>
    output?: Array<
      | {
          __typename?: 'ChartBlock'
          type: string
          chartType?: string | null
          data?: any | null
          options?: any | null
          xAxis?: any | null
          yAxis?: any | null
          title?: string | null
        }
      | { __typename?: 'MarkdownBlock'; type: string; content?: string | null }
      | { __typename?: 'TableBlock'; type: string; headers: Array<string>; rows: Array<Array<any>> }
      | { __typename?: 'TextBlock'; type: string; content?: string | null }
    > | null
    tasks: Array<{
      __typename?: 'AnalysisTaskResult'
      name: string
      description: string
      task?: string | null
      createdAt: any
      response?: Array<
        | { __typename?: 'ImageContent'; type: string; value: string }
        | {
            __typename?: 'TextContent'
            type: string
            value: string
            files?: Array<{
              __typename?: 'AgentFile'
              value: string
              filePath?: string | null
              fileUuid?: string | null
            }> | null
          }
      > | null
    }>
    analysis?: {
      __typename?: 'Analysis'
      id: string
      uuid: string
      name: string
      description: string
      instructions: string
      status: string
      webhookUrl?: string | null
      cronSchedule?: string | null
      createdAt: any
      outputFormat: Array<{ __typename?: 'ContentBlockFormat'; type: string; prompt: string }>
      knowledge?: Array<{ __typename?: 'Knowledge'; uuid: string; type: string }> | null
      fileAttachments?: Array<{
        __typename?: 'FileAttachment'
        uuid: string
        fileName: string
        fileExtension: string
        contentLength: number
        downloadUrl?: string | null
      }> | null
      notes?: Array<{ __typename?: 'Note'; uuid: string; note?: string | null }> | null
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
      tasks: Array<{
        __typename?: 'Task'
        id: string
        uuid: string
        name: string
        description: string
        task: string
        taskType?: string | null
        status: string
        taskableType: string
        taskableUuid: string
        outputWebhookUrl?: string | null
        runSchedule?: string | null
        createdAt: any
        createdBy: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
        }
      }>
      agent: {
        __typename?: 'Agent'
        id: string
        uuid: string
        firstName?: string | null
        label: string
        jobTitle?: string | null
        primer?: string | null
        backend: string
        type: string
        profileImageUrl?: string | null
        shortDescription?: string | null
        description?: string | null
        isCompany: boolean
        isExpert: boolean
        isCustom: boolean
        createdAt: any
        createdBy?: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
        } | null
      }
    } | null
  }> | null
}

export type OneAnalysisRunQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type OneAnalysisRunQuery = {
  __typename?: 'Query'
  oneAnalysisRun?: {
    __typename?: 'AnalysisRun'
    uuid: string
    instructions?: string | null
    responseMessage?: string | null
    messageThreadUuid?: string | null
    webhookUrl?: string | null
    webhookResponse?: any | null
    startedAt?: any | null
    finishedAt?: any | null
    createdAt: any
    updatedAt: any
    outputFormat: Array<{ __typename?: 'ContentBlockFormat'; type: string; prompt: string }>
    output?: Array<
      | {
          __typename?: 'ChartBlock'
          type: string
          chartType?: string | null
          data?: any | null
          options?: any | null
          xAxis?: any | null
          yAxis?: any | null
          title?: string | null
        }
      | { __typename?: 'MarkdownBlock'; type: string; content?: string | null }
      | { __typename?: 'TableBlock'; type: string; headers: Array<string>; rows: Array<Array<any>> }
      | { __typename?: 'TextBlock'; type: string; content?: string | null }
    > | null
    tasks: Array<{
      __typename?: 'AnalysisTaskResult'
      name: string
      description: string
      task?: string | null
      createdAt: any
      response?: Array<
        | { __typename?: 'ImageContent'; type: string; value: string }
        | {
            __typename?: 'TextContent'
            type: string
            value: string
            files?: Array<{
              __typename?: 'AgentFile'
              value: string
              filePath?: string | null
              fileUuid?: string | null
            }> | null
          }
      > | null
    }>
    analysis?: {
      __typename?: 'Analysis'
      id: string
      uuid: string
      name: string
      description: string
      instructions: string
      status: string
      webhookUrl?: string | null
      cronSchedule?: string | null
      createdAt: any
      outputFormat: Array<{ __typename?: 'ContentBlockFormat'; type: string; prompt: string }>
      knowledge?: Array<{ __typename?: 'Knowledge'; uuid: string; type: string }> | null
      fileAttachments?: Array<{
        __typename?: 'FileAttachment'
        uuid: string
        fileName: string
        fileExtension: string
        contentLength: number
        downloadUrl?: string | null
      }> | null
      notes?: Array<{ __typename?: 'Note'; uuid: string; note?: string | null }> | null
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
      tasks: Array<{
        __typename?: 'Task'
        id: string
        uuid: string
        name: string
        description: string
        task: string
        taskType?: string | null
        status: string
        taskableType: string
        taskableUuid: string
        outputWebhookUrl?: string | null
        runSchedule?: string | null
        createdAt: any
        createdBy: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
        }
      }>
      agent: {
        __typename?: 'Agent'
        id: string
        uuid: string
        firstName?: string | null
        label: string
        jobTitle?: string | null
        primer?: string | null
        backend: string
        type: string
        profileImageUrl?: string | null
        shortDescription?: string | null
        description?: string | null
        isCompany: boolean
        isExpert: boolean
        isCustom: boolean
        createdAt: any
        createdBy?: {
          __typename?: 'User'
          id: string
          firstName?: string | null
          lastName?: string | null
        } | null
      }
    } | null
  } | null
}

export type AnalysisTemplatesQueryVariables = Exact<{
  agentUuid: Scalars['ID']['input']
}>

export type AnalysisTemplatesQuery = {
  __typename?: 'Query'
  analysisTemplates?: Array<{
    __typename?: 'AnalysisTemplate'
    id: string
    name: string
    description: string
    instructions: string
    outputFormat: Array<{ __typename?: 'ContentBlockFormat'; type: string; prompt: string }>
    tasks?: Array<{
      __typename?: 'TaskTemplate'
      name: string
      description: string
      task: string
    }> | null
  }> | null
}

export type AgentAnalysesQueryVariables = Exact<{
  agentUuid: Scalars['ID']['input']
}>

export type AgentAnalysesQuery = {
  __typename?: 'Query'
  agentAnalyses?: Array<{
    __typename?: 'Analysis'
    id: string
    uuid: string
    name: string
    description: string
    instructions: string
    status: string
    webhookUrl?: string | null
    cronSchedule?: string | null
    createdAt: any
    outputFormat: Array<{ __typename?: 'ContentBlockFormat'; type: string; prompt: string }>
    knowledge?: Array<{ __typename?: 'Knowledge'; uuid: string; type: string }> | null
    fileAttachments?: Array<{
      __typename?: 'FileAttachment'
      uuid: string
      fileName: string
      fileExtension: string
      contentLength: number
      downloadUrl?: string | null
    }> | null
    notes?: Array<{ __typename?: 'Note'; uuid: string; note?: string | null }> | null
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    tasks: Array<{
      __typename?: 'Task'
      id: string
      uuid: string
      name: string
      description: string
      task: string
      taskType?: string | null
      status: string
      taskableType: string
      taskableUuid: string
      outputWebhookUrl?: string | null
      runSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName?: string | null
      label: string
      jobTitle?: string | null
      primer?: string | null
      backend: string
      type: string
      profileImageUrl?: string | null
      shortDescription?: string | null
      description?: string | null
      isCompany: boolean
      isExpert: boolean
      isCustom: boolean
      createdAt: any
      createdBy?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
    }
  }> | null
}

export type CreateAnalysisFromTemplateMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  templateId: Scalars['String']['input']
}>

export type CreateAnalysisFromTemplateMutation = {
  __typename?: 'Mutation'
  createAnalysisFromTemplate: {
    __typename?: 'AnalysisCreateResponse'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type ConversationSummaryQueryVariables = Exact<{
  conversation: Scalars['String']['input']
  summarizationPrompt?: InputMaybe<Scalars['String']['input']>
}>

export type ConversationSummaryQuery = { __typename?: 'Query'; summary?: string | null }

export type UpdateDataCredentialsMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  credentials: Scalars['JSON']['input']
}>

export type UpdateDataCredentialsMutation = {
  __typename?: 'Mutation'
  updateDataCredentials: {
    __typename?: 'ResponseStatus'
    errors?: Array<string> | null
    success?: boolean | null
  }
}

export type DataCredentialsQueryVariables = Exact<{ [key: string]: never }>

export type DataCredentialsQuery = {
  __typename?: 'Query'
  dataCredentials?: Array<{
    __typename?: 'DataCredential'
    credentials: any
    credentialType: string
    uuid: string
  }> | null
}

export type CreateDataCredentialsMutationVariables = Exact<{
  credentials: Scalars['JSON']['input']
  credentialType: Scalars['String']['input']
}>

export type CreateDataCredentialsMutation = {
  __typename?: 'Mutation'
  createDataCredentials: {
    __typename?: 'ResponseStatus'
    errors?: Array<string> | null
    success?: boolean | null
  }
}

export type DeleteDataCredentialsMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DeleteDataCredentialsMutation = {
  __typename?: 'Mutation'
  deleteDataCredentials: {
    __typename?: 'ResponseStatus'
    errors?: Array<string> | null
    success?: boolean | null
  }
}

export type CreateDataSetMutationVariables = Exact<{
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  definition: Scalars['JSON']['input']
}>

export type CreateDataSetMutation = {
  __typename?: 'Mutation'
  createDataSet: {
    __typename?: 'DataSetCreateResponse'
    success?: boolean | null
    errors?: Array<string> | null
    dataSet?: {
      __typename?: 'DataSet'
      id: number
      uuid: string
      name: string
      dataSample?: string | null
      backendType?: string | null
      templateId?: string | null
      isEditable?: boolean | null
      status?: string | null
      description: string
      definition: any
      createdAt: any
      updatedAt: any
      createdBy?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
      functionDefinition?: {
        __typename?: 'FunctionDefinition'
        fields?: Array<{
          __typename?: 'FunctionDefinitionField'
          name: string
          type: string
          category: string
        }> | null
      } | null
    } | null
  }
}

export type DeleteDataSetMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DeleteDataSetMutation = {
  __typename?: 'Mutation'
  deleteDataSet: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UpdateDataSetMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  definition: Scalars['JSON']['input']
}>

export type UpdateDataSetMutation = {
  __typename?: 'Mutation'
  updateDataSet: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type DataSetQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DataSetQuery = {
  __typename?: 'Query'
  dataSet?: {
    __typename?: 'DataSet'
    id: number
    uuid: string
    name: string
    dataSample?: string | null
    backendType?: string | null
    templateId?: string | null
    isEditable?: boolean | null
    status?: string | null
    description: string
    definition: any
    createdAt: any
    updatedAt: any
    createdBy?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
    functionDefinition?: {
      __typename?: 'FunctionDefinition'
      fields?: Array<{
        __typename?: 'FunctionDefinitionField'
        name: string
        type: string
        category: string
      }> | null
    } | null
  } | null
}

export type DataSetsQueryVariables = Exact<{
  uuids: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type DataSetsQuery = {
  __typename?: 'Query'
  dataSets?: Array<{
    __typename?: 'DataSet'
    id: number
    uuid: string
    name: string
    dataSample?: string | null
    backendType?: string | null
    templateId?: string | null
    isEditable?: boolean | null
    status?: string | null
    description: string
    definition: any
    createdAt: any
    updatedAt: any
    createdBy?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
    functionDefinition?: {
      __typename?: 'FunctionDefinition'
      fields?: Array<{
        __typename?: 'FunctionDefinitionField'
        name: string
        type: string
        category: string
      }> | null
    } | null
  }> | null
}

export type AgentDataSetsQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type AgentDataSetsQuery = {
  __typename?: 'Query'
  agentDataSets?: Array<{
    __typename?: 'DataSet'
    id: number
    uuid: string
    name: string
    dataSample?: string | null
    backendType?: string | null
    templateId?: string | null
    isEditable?: boolean | null
    status?: string | null
    description: string
    definition: any
    createdAt: any
    updatedAt: any
    createdBy?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
    functionDefinition?: {
      __typename?: 'FunctionDefinition'
      fields?: Array<{
        __typename?: 'FunctionDefinitionField'
        name: string
        type: string
        category: string
      }> | null
    } | null
  }> | null
}

export type OrganizationDataSetsQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationDataSetsQuery = {
  __typename?: 'Query'
  organizationDataSets?: Array<{
    __typename?: 'DataSet'
    id: number
    uuid: string
    name: string
    dataSample?: string | null
    backendType?: string | null
    templateId?: string | null
    isEditable?: boolean | null
    status?: string | null
    description: string
    definition: any
    createdAt: any
    updatedAt: any
    createdBy?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
    functionDefinition?: {
      __typename?: 'FunctionDefinition'
      fields?: Array<{
        __typename?: 'FunctionDefinitionField'
        name: string
        type: string
        category: string
      }> | null
    } | null
  }> | null
}

export type AddDataSetToAgentMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  dataSetUuid: Scalars['String']['input']
}>

export type AddDataSetToAgentMutation = {
  __typename?: 'Mutation'
  addDataSetToAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type RemoveDataSetFromAgentMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  dataSetUuid: Scalars['String']['input']
}>

export type RemoveDataSetFromAgentMutation = {
  __typename?: 'Mutation'
  removeDataSetFromAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type RefreshDataSetMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type RefreshDataSetMutation = {
  __typename?: 'Mutation'
  refreshDataSet: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type DataSetFieldsQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DataSetFieldsQuery = {
  __typename?: 'Query'
  dataSet?: {
    __typename?: 'DataSet'
    uuid: string
    name: string
    functionDefinition?: {
      __typename?: 'FunctionDefinition'
      fields?: Array<{
        __typename?: 'FunctionDefinitionField'
        name: string
        type: string
        category: string
      }> | null
    } | null
  } | null
}

export type CreateDataUnionMutationVariables = Exact<{
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  definition: Scalars['JSON']['input']
  isEditable: Scalars['Boolean']['input']
}>

export type CreateDataUnionMutation = {
  __typename?: 'Mutation'
  createDataUnion: {
    __typename?: 'DataUnionCreateResponse'
    success: boolean
    errors: Array<string>
    dataUnion?: {
      __typename?: 'DataUnion'
      id: string
      uuid: string
      name: string
      description: string
      isEditable: boolean
      createdAt: any
      updatedAt: any
      definition: {
        __typename?: 'DataUnionDefinition'
        dataSet: string
        joins: Array<{
          __typename?: 'DataUnionJoin'
          type: JoinTypeEnum
          relationship: JoinRelationshipEnum
          sqlOn: string
          dataSet: string
        }>
      }
      organization: { __typename?: 'Organization'; id: string; name: string }
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
      dataSets: Array<{ __typename?: 'DataSet'; id: number; uuid: string; name: string }>
    } | null
  }
}

export type DeleteDataUnionMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DeleteDataUnionMutation = {
  __typename?: 'Mutation'
  deleteDataUnion: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UpdateDataUnionMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  definition: Scalars['JSON']['input']
  isEditable: Scalars['Boolean']['input']
}>

export type UpdateDataUnionMutation = {
  __typename?: 'Mutation'
  updateDataUnion: {
    __typename?: 'DataUnionCreateResponse'
    success: boolean
    errors: Array<string>
  }
}

export type DataUnionQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DataUnionQuery = {
  __typename?: 'Query'
  dataUnion?: {
    __typename?: 'DataUnion'
    id: string
    uuid: string
    name: string
    description: string
    isEditable: boolean
    createdAt: any
    updatedAt: any
    definition: {
      __typename?: 'DataUnionDefinition'
      dataSet: string
      joins: Array<{
        __typename?: 'DataUnionJoin'
        type: JoinTypeEnum
        relationship: JoinRelationshipEnum
        sqlOn: string
        dataSet: string
      }>
    }
    organization: { __typename?: 'Organization'; id: string; name: string }
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    dataSets: Array<{ __typename?: 'DataSet'; id: number; uuid: string; name: string }>
  } | null
}

export type DataUnionsQueryVariables = Exact<{
  uuids: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type DataUnionsQuery = {
  __typename?: 'Query'
  dataUnions?: Array<{
    __typename?: 'DataUnion'
    id: string
    uuid: string
    name: string
    description: string
    isEditable: boolean
    createdAt: any
    updatedAt: any
    definition: {
      __typename?: 'DataUnionDefinition'
      dataSet: string
      joins: Array<{
        __typename?: 'DataUnionJoin'
        type: JoinTypeEnum
        relationship: JoinRelationshipEnum
        sqlOn: string
        dataSet: string
      }>
    }
    organization: { __typename?: 'Organization'; id: string; name: string }
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    dataSets: Array<{ __typename?: 'DataSet'; id: number; uuid: string; name: string }>
  }> | null
}

export type AgentDataUnionsQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type AgentDataUnionsQuery = {
  __typename?: 'Query'
  agentDataUnions?: Array<{
    __typename?: 'DataUnion'
    id: string
    uuid: string
    name: string
    description: string
    isEditable: boolean
    createdAt: any
    updatedAt: any
    definition: {
      __typename?: 'DataUnionDefinition'
      dataSet: string
      joins: Array<{
        __typename?: 'DataUnionJoin'
        type: JoinTypeEnum
        relationship: JoinRelationshipEnum
        sqlOn: string
        dataSet: string
      }>
    }
    organization: { __typename?: 'Organization'; id: string; name: string }
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    dataSets: Array<{ __typename?: 'DataSet'; id: number; uuid: string; name: string }>
  }> | null
}

export type OrganizationDataUnionsQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationDataUnionsQuery = {
  __typename?: 'Query'
  organizationDataUnions?: Array<{
    __typename?: 'DataUnion'
    id: string
    uuid: string
    name: string
    description: string
    isEditable: boolean
    createdAt: any
    updatedAt: any
    definition: {
      __typename?: 'DataUnionDefinition'
      dataSet: string
      joins: Array<{
        __typename?: 'DataUnionJoin'
        type: JoinTypeEnum
        relationship: JoinRelationshipEnum
        sqlOn: string
        dataSet: string
      }>
    }
    organization: { __typename?: 'Organization'; id: string; name: string }
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    dataSets: Array<{ __typename?: 'DataSet'; id: number; uuid: string; name: string }>
  }> | null
}

export type AddDataUnionToAgentMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  dataUnionUuid: Scalars['String']['input']
}>

export type AddDataUnionToAgentMutation = {
  __typename?: 'Mutation'
  addDataUnionToAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type RemoveDataUnionFromAgentMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
  dataUnionUuid: Scalars['String']['input']
}>

export type RemoveDataUnionFromAgentMutation = {
  __typename?: 'Mutation'
  removeDataUnionFromAgent: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type FileAttachmentFragmentFragment = {
  __typename?: 'FileAttachment'
  uuid: string
  fileName: string
  fileExtension: string
  contentLength: number
  downloadUrl?: string | null
}

export type GetFileAttachmentQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type GetFileAttachmentQuery = {
  __typename?: 'Query'
  fileAttachment?: {
    __typename?: 'FileAttachment'
    uuid: string
    fileName: string
    fileExtension: string
    contentLength: number
    downloadUrl?: string | null
  } | null
}

export type CreateFileAttachmentMutationVariables = Exact<{
  fileAttachment: Scalars['Upload']['input']
}>

export type CreateFileAttachmentMutation = {
  __typename?: 'Mutation'
  createFileAttachment: {
    __typename?: 'FileAttachment'
    uuid: string
    fileName: string
    fileExtension: string
    contentLength: number
    downloadUrl?: string | null
  }
}

export type DeleteFileAttachmentMutationVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type DeleteFileAttachmentMutation = {
  __typename?: 'Mutation'
  deleteFileAttachment: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UserFieldsFragment = {
  __typename?: 'User'
  id: string
  firstName?: string | null
  lastName?: string | null
}

export type AgentFieldsFragment = {
  __typename?: 'Agent'
  id: string
  uuid: string
  firstName?: string | null
  label: string
  jobTitle?: string | null
  primer?: string | null
  backend: string
  type: string
  profileImageUrl?: string | null
  shortDescription?: string | null
  description?: string | null
  isCompany: boolean
  isExpert: boolean
  isCustom: boolean
  createdAt: any
  createdBy?: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
  } | null
}

export type CompanyAgentFieldsFragment = {
  __typename?: 'CompanyAgent'
  id: string
  uuid: string
  isCompany?: boolean | null
  isExpert?: boolean | null
  isCustom?: boolean | null
  createdAt: any
  updatedAt?: any | null
}

export type TaskFieldsFragment = {
  __typename?: 'Task'
  id: string
  uuid: string
  name: string
  description: string
  task: string
  taskType?: string | null
  status: string
  taskableType: string
  taskableUuid: string
  outputWebhookUrl?: string | null
  runSchedule?: string | null
  createdAt: any
  createdBy: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
  }
}

export type SideEffectFieldsFragment = {
  __typename?: 'SideEffect'
  uuid: string
  sideEffect: string
  data: any
  output?: string | null
  createdAt: any
  createdAtMilli: any
  status?: MessageStatusEnum | null
}

export type ContentBlockFormatFieldsFragment = {
  __typename?: 'ContentBlockFormat'
  type: string
  prompt: string
}

type ContentBlockFields_ChartBlock_Fragment = {
  __typename?: 'ChartBlock'
  type: string
  chartType?: string | null
  data?: any | null
  options?: any | null
}

type ContentBlockFields_MarkdownBlock_Fragment = {
  __typename?: 'MarkdownBlock'
  type: string
  content?: string | null
}

type ContentBlockFields_TableBlock_Fragment = {
  __typename?: 'TableBlock'
  type: string
  headers: Array<string>
  rows: Array<Array<any>>
}

type ContentBlockFields_TextBlock_Fragment = {
  __typename?: 'TextBlock'
  type: string
  content?: string | null
}

export type ContentBlockFieldsFragment =
  | ContentBlockFields_ChartBlock_Fragment
  | ContentBlockFields_MarkdownBlock_Fragment
  | ContentBlockFields_TableBlock_Fragment
  | ContentBlockFields_TextBlock_Fragment

export type AnalysisFieldsFragment = {
  __typename?: 'Analysis'
  id: string
  uuid: string
  name: string
  description: string
  instructions: string
  status: string
  webhookUrl?: string | null
  cronSchedule?: string | null
  createdAt: any
  outputFormat: Array<{ __typename?: 'ContentBlockFormat'; type: string; prompt: string }>
  knowledge?: Array<{ __typename?: 'Knowledge'; uuid: string; type: string }> | null
  fileAttachments?: Array<{
    __typename?: 'FileAttachment'
    uuid: string
    fileName: string
    fileExtension: string
    contentLength: number
    downloadUrl?: string | null
  }> | null
  notes?: Array<{ __typename?: 'Note'; uuid: string; note?: string | null }> | null
  createdBy: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
  }
  tasks: Array<{
    __typename?: 'Task'
    id: string
    uuid: string
    name: string
    description: string
    task: string
    taskType?: string | null
    status: string
    taskableType: string
    taskableUuid: string
    outputWebhookUrl?: string | null
    runSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
  }>
  agent: {
    __typename?: 'Agent'
    id: string
    uuid: string
    firstName?: string | null
    label: string
    jobTitle?: string | null
    primer?: string | null
    backend: string
    type: string
    profileImageUrl?: string | null
    shortDescription?: string | null
    description?: string | null
    isCompany: boolean
    isExpert: boolean
    isCustom: boolean
    createdAt: any
    createdBy?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
  }
}

export type AnalysisRunFieldsFragment = {
  __typename?: 'AnalysisRun'
  uuid: string
  instructions?: string | null
  responseMessage?: string | null
  messageThreadUuid?: string | null
  webhookUrl?: string | null
  webhookResponse?: any | null
  startedAt?: any | null
  finishedAt?: any | null
  createdAt: any
  updatedAt: any
  outputFormat: Array<{ __typename?: 'ContentBlockFormat'; type: string; prompt: string }>
  output?: Array<
    | {
        __typename?: 'ChartBlock'
        type: string
        chartType?: string | null
        data?: any | null
        options?: any | null
        xAxis?: any | null
        yAxis?: any | null
        title?: string | null
      }
    | { __typename?: 'MarkdownBlock'; type: string; content?: string | null }
    | { __typename?: 'TableBlock'; type: string; headers: Array<string>; rows: Array<Array<any>> }
    | { __typename?: 'TextBlock'; type: string; content?: string | null }
  > | null
  tasks: Array<{
    __typename?: 'AnalysisTaskResult'
    name: string
    description: string
    task?: string | null
    createdAt: any
    response?: Array<
      | { __typename?: 'ImageContent'; type: string; value: string }
      | {
          __typename?: 'TextContent'
          type: string
          value: string
          files?: Array<{
            __typename?: 'AgentFile'
            value: string
            filePath?: string | null
            fileUuid?: string | null
          }> | null
        }
    > | null
  }>
  analysis?: {
    __typename?: 'Analysis'
    id: string
    uuid: string
    name: string
    description: string
    instructions: string
    status: string
    webhookUrl?: string | null
    cronSchedule?: string | null
    createdAt: any
    outputFormat: Array<{ __typename?: 'ContentBlockFormat'; type: string; prompt: string }>
    knowledge?: Array<{ __typename?: 'Knowledge'; uuid: string; type: string }> | null
    fileAttachments?: Array<{
      __typename?: 'FileAttachment'
      uuid: string
      fileName: string
      fileExtension: string
      contentLength: number
      downloadUrl?: string | null
    }> | null
    notes?: Array<{ __typename?: 'Note'; uuid: string; note?: string | null }> | null
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    tasks: Array<{
      __typename?: 'Task'
      id: string
      uuid: string
      name: string
      description: string
      task: string
      taskType?: string | null
      status: string
      taskableType: string
      taskableUuid: string
      outputWebhookUrl?: string | null
      runSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName?: string | null
      label: string
      jobTitle?: string | null
      primer?: string | null
      backend: string
      type: string
      profileImageUrl?: string | null
      shortDescription?: string | null
      description?: string | null
      isCompany: boolean
      isExpert: boolean
      isCustom: boolean
      createdAt: any
      createdBy?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
    }
  } | null
}

export type WorkflowFieldsFragment = {
  __typename?: 'Workflow'
  id: string
  uuid: string
  name: string
  status?: string | null
  description: string
  webhookUrl?: string | null
  cronSchedule?: string | null
  createdAt: any
  createdBy: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
  }
  tasks: Array<{
    __typename?: 'Task'
    id: string
    uuid: string
    name: string
    description: string
    task: string
    taskType?: string | null
    status: string
    taskableType: string
    taskableUuid: string
    outputWebhookUrl?: string | null
    runSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
  }>
  agent: {
    __typename?: 'Agent'
    id: string
    uuid: string
    firstName?: string | null
    label: string
    jobTitle?: string | null
    primer?: string | null
    backend: string
    type: string
    profileImageUrl?: string | null
    shortDescription?: string | null
    description?: string | null
    isCompany: boolean
    isExpert: boolean
    isCustom: boolean
    createdAt: any
    createdBy?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
  }
}

export type MessageHistoryFieldsFragment = {
  __typename?: 'MessageHistory'
  id: number
  actor: ActorEnum
  feedback?: FeedbackEnum | null
  createdAt: any
  createdAtMilli: any
  updatedAt: any
  uuid: string
  status?: MessageStatusEnum | null
  message: Array<
    | { __typename?: 'ImageContent'; type: string; value: string }
    | {
        __typename?: 'TextContent'
        type: string
        value: string
        files?: Array<{
          __typename?: 'AgentFile'
          value: string
          filePath?: string | null
          fileUuid?: string | null
        }> | null
      }
  >
  notes?: Array<{ __typename?: 'Note'; uuid: string; note?: string | null }> | null
  fileAttachments?: Array<{
    __typename?: 'FileAttachment'
    uuid: string
    fileName: string
    fileExtension: string
    contentLength: number
    downloadUrl?: string | null
  }> | null
  knowledge?: Array<{ __typename?: 'Knowledge'; uuid: string; type: string }> | null
}

export type DataSetFieldsFragment = {
  __typename?: 'DataSet'
  id: number
  uuid: string
  name: string
  dataSample?: string | null
  backendType?: string | null
  templateId?: string | null
  isEditable?: boolean | null
  status?: string | null
  description: string
  definition: any
  createdAt: any
  updatedAt: any
  createdBy?: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
  } | null
  functionDefinition?: {
    __typename?: 'FunctionDefinition'
    fields?: Array<{
      __typename?: 'FunctionDefinitionField'
      name: string
      type: string
      category: string
    }> | null
  } | null
}

export type DataUnionFieldsFragment = {
  __typename?: 'DataUnion'
  id: string
  uuid: string
  name: string
  description: string
  isEditable: boolean
  createdAt: any
  updatedAt: any
  definition: {
    __typename?: 'DataUnionDefinition'
    dataSet: string
    joins: Array<{
      __typename?: 'DataUnionJoin'
      type: JoinTypeEnum
      relationship: JoinRelationshipEnum
      sqlOn: string
      dataSet: string
    }>
  }
  organization: { __typename?: 'Organization'; id: string; name: string }
  createdBy: {
    __typename?: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
  }
  dataSets: Array<{ __typename?: 'DataSet'; id: number; uuid: string; name: string }>
}

export type StepHistoryFieldsFragment = {
  __typename?: 'StepHistory'
  id: number
  actor: ActorEnum
  createdAt: any
  createdAtMilli: any
  updatedAt: any
  uuid: string
  status?: MessageStatusEnum | null
  step: Array<
    | {
        __typename?: 'CodeInterpreterToolCall'
        name: string
        description: string
        type: string
        codeInterpreter: {
          __typename?: 'CodeInterpret'
          input?: string | null
          outputs?: any | null
        }
      }
    | { __typename?: 'FileSearchToolCall'; name: string; description: string; type: string }
    | {
        __typename?: 'FunctionToolCall'
        name: string
        description: string
        type: string
        function: {
          __typename?: 'ToolFunction'
          name: string
          arguments?: any | null
          output?: string | null
        }
      }
  >
}

export type TaskRunFieldsFragment = {
  __typename?: 'TaskRun'
  uuid: string
  status: string
  responseMessage?: string | null
  threadUuid?: string | null
  responseStatus?: string | null
  startedAt?: any | null
  completedAt?: any | null
  createdAt: any
  updatedAt: any
  task: {
    __typename?: 'Task'
    id: string
    uuid: string
    name: string
    description: string
    task: string
    taskType?: string | null
    status: string
    taskableType: string
    taskableUuid: string
    outputWebhookUrl?: string | null
    runSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
  }
}

export type SuggestionFieldsFragment = {
  __typename?: 'Suggestion'
  uuid: string
  body: string
  status: SuggestionStatusEnum
  createdAt: any
  updatedAt: any
  closedAt?: any | null
}

export type PluginTemplateFieldsFragment = {
  __typename?: 'PluginTemplate'
  id: string
  name: string
  type: string
  isPublic: boolean
  description: string
  useCases: Array<string>
  dataTemplate: any
  onePerAgent: boolean
}

export type PluginFieldsFragment = {
  __typename?: 'Plugin'
  id: number
  name: string
  pluginType: string
  config: any
  isEditable: boolean
  pluginTemplate?: {
    __typename?: 'PluginTemplate'
    id: string
    name: string
    type: string
    isPublic: boolean
    description: string
    useCases: Array<string>
    dataTemplate: any
    onePerAgent: boolean
  } | null
}

export type AnalysisTemplateFieldsFragment = {
  __typename?: 'AnalysisTemplate'
  id: string
  name: string
  description: string
  instructions: string
  outputFormat: Array<{ __typename?: 'ContentBlockFormat'; type: string; prompt: string }>
  tasks?: Array<{
    __typename?: 'TaskTemplate'
    name: string
    description: string
    task: string
  }> | null
}

export type ArtifactFieldsFragment = {
  __typename?: 'Artifact'
  uuid: string
  artifactType: string
  name: string
  description: string
  content: any
  deletedAt?: any | null
  createdAt: any
  updatedAt: any
}

export type GetMessageHistoriesQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type GetMessageHistoriesQuery = {
  __typename?: 'Query'
  getMessageHistories?: Array<{
    __typename?: 'MessageHistory'
    id: number
    actor: ActorEnum
    feedback?: FeedbackEnum | null
    createdAt: any
    createdAtMilli: any
    updatedAt: any
    uuid: string
    status?: MessageStatusEnum | null
    message: Array<
      | { __typename?: 'ImageContent'; type: string; value: string }
      | {
          __typename?: 'TextContent'
          type: string
          value: string
          files?: Array<{
            __typename?: 'AgentFile'
            value: string
            filePath?: string | null
            fileUuid?: string | null
          }> | null
        }
    >
    notes?: Array<{ __typename?: 'Note'; uuid: string; note?: string | null }> | null
    fileAttachments?: Array<{
      __typename?: 'FileAttachment'
      uuid: string
      fileName: string
      fileExtension: string
      contentLength: number
      downloadUrl?: string | null
    }> | null
    knowledge?: Array<{ __typename?: 'Knowledge'; uuid: string; type: string }> | null
  }> | null
}

export type GetStepHistoriesQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type GetStepHistoriesQuery = {
  __typename?: 'Query'
  getStepHistories?: Array<{
    __typename?: 'StepHistory'
    id: number
    actor: ActorEnum
    createdAt: any
    createdAtMilli: any
    updatedAt: any
    uuid: string
    status?: MessageStatusEnum | null
    step: Array<
      | {
          __typename?: 'CodeInterpreterToolCall'
          name: string
          description: string
          type: string
          codeInterpreter: {
            __typename?: 'CodeInterpret'
            input?: string | null
            outputs?: any | null
          }
        }
      | { __typename?: 'FileSearchToolCall'; name: string; description: string; type: string }
      | {
          __typename?: 'FunctionToolCall'
          name: string
          description: string
          type: string
          function: {
            __typename?: 'ToolFunction'
            name: string
            arguments?: any | null
            output?: string | null
          }
        }
    >
  }> | null
}

export type GetSideEffectHistoriesQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type GetSideEffectHistoriesQuery = {
  __typename?: 'Query'
  getSideEffectHistories?: Array<{
    __typename?: 'SideEffect'
    uuid: string
    sideEffect: string
    data: any
    output?: string | null
    createdAt: any
    createdAtMilli: any
    status?: MessageStatusEnum | null
  }> | null
}

export type GetMessageThreadsQueryVariables = Exact<{
  agentUuid: Scalars['String']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type GetMessageThreadsQuery = {
  __typename?: 'Query'
  getMessageThreads?: Array<{
    __typename?: 'MessageThread'
    createdAt: any
    updatedAt: any
    uuid: string
  }> | null
}

export type GetMessageThreadQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type GetMessageThreadQuery = {
  __typename?: 'Query'
  getMessageThread?: {
    __typename?: 'MessageThread'
    messages: Array<{
      __typename?: 'MessageHistory'
      id: number
      actor: ActorEnum
      feedback?: FeedbackEnum | null
      createdAt: any
      createdAtMilli: any
      updatedAt: any
      uuid: string
      status?: MessageStatusEnum | null
      message: Array<
        | { __typename?: 'ImageContent'; type: string; value: string }
        | {
            __typename?: 'TextContent'
            type: string
            value: string
            files?: Array<{
              __typename?: 'AgentFile'
              value: string
              filePath?: string | null
              fileUuid?: string | null
            }> | null
          }
      >
      notes?: Array<{ __typename?: 'Note'; uuid: string; note?: string | null }> | null
      fileAttachments?: Array<{
        __typename?: 'FileAttachment'
        uuid: string
        fileName: string
        fileExtension: string
        contentLength: number
        downloadUrl?: string | null
      }> | null
      knowledge?: Array<{ __typename?: 'Knowledge'; uuid: string; type: string }> | null
    }>
    steps: Array<{
      __typename?: 'StepHistory'
      id: number
      actor: ActorEnum
      createdAt: any
      createdAtMilli: any
      updatedAt: any
      uuid: string
      status?: MessageStatusEnum | null
      step: Array<
        | {
            __typename?: 'CodeInterpreterToolCall'
            name: string
            description: string
            type: string
            codeInterpreter: {
              __typename?: 'CodeInterpret'
              input?: string | null
              outputs?: any | null
            }
          }
        | { __typename?: 'FileSearchToolCall'; name: string; description: string; type: string }
        | {
            __typename?: 'FunctionToolCall'
            name: string
            description: string
            type: string
            function: {
              __typename?: 'ToolFunction'
              name: string
              arguments?: any | null
              output?: string | null
            }
          }
      >
    }>
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName?: string | null
      label: string
      jobTitle?: string | null
      primer?: string | null
      backend: string
      type: string
      profileImageUrl?: string | null
      shortDescription?: string | null
      description?: string | null
      isCompany: boolean
      isExpert: boolean
      isCustom: boolean
      createdAt: any
      createdBy?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
    }
    sideEffects: Array<{
      __typename?: 'SideEffect'
      uuid: string
      sideEffect: string
      data: any
      output?: string | null
      createdAt: any
      createdAtMilli: any
      status?: MessageStatusEnum | null
    }>
  } | null
}

export type AddMessageToThreadMutationVariables = Exact<{
  threadUuid: Scalars['String']['input']
  message: MessageHistoryInput
}>

export type AddMessageToThreadMutation = {
  __typename?: 'Mutation'
  addMessageToThread: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type AddStepToThreadMutationVariables = Exact<{
  threadUuid: Scalars['String']['input']
  step: StepHistoryInput
}>

export type AddStepToThreadMutation = {
  __typename?: 'Mutation'
  addStepToThread: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type AddSideEffectToThreadMutationVariables = Exact<{
  threadUuid: Scalars['String']['input']
  sideEffect: SideEffectInput
}>

export type AddSideEffectToThreadMutation = {
  __typename?: 'Mutation'
  addSideEffectToThread: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type CreateMessageThreadMutationVariables = Exact<{
  agentUuid: Scalars['String']['input']
}>

export type CreateMessageThreadMutation = {
  __typename?: 'Mutation'
  createMessageThread: { __typename?: 'MessageThread'; uuid: string }
}

export type GetConversationQueryVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type GetConversationQuery = {
  __typename?: 'Query'
  getConversation?: Array<
    | {
        __typename?: 'MessageHistory'
        id: number
        actor: ActorEnum
        feedback?: FeedbackEnum | null
        createdAt: any
        createdAtMilli: any
        updatedAt: any
        uuid: string
        status?: MessageStatusEnum | null
        message: Array<
          | { __typename?: 'ImageContent'; type: string; value: string }
          | {
              __typename?: 'TextContent'
              type: string
              value: string
              files?: Array<{
                __typename?: 'AgentFile'
                value: string
                filePath?: string | null
                fileUuid?: string | null
              }> | null
            }
        >
        notes?: Array<{ __typename?: 'Note'; uuid: string; note?: string | null }> | null
        fileAttachments?: Array<{
          __typename?: 'FileAttachment'
          uuid: string
          fileName: string
          fileExtension: string
          contentLength: number
          downloadUrl?: string | null
        }> | null
        knowledge?: Array<{ __typename?: 'Knowledge'; uuid: string; type: string }> | null
      }
    | {
        __typename?: 'SideEffect'
        uuid: string
        sideEffect: string
        data: any
        output?: string | null
        createdAt: any
        createdAtMilli: any
        status?: MessageStatusEnum | null
      }
    | {
        __typename?: 'StepHistory'
        id: number
        actor: ActorEnum
        createdAt: any
        createdAtMilli: any
        updatedAt: any
        uuid: string
        status?: MessageStatusEnum | null
        step: Array<
          | {
              __typename?: 'CodeInterpreterToolCall'
              name: string
              description: string
              type: string
              codeInterpreter: {
                __typename?: 'CodeInterpret'
                input?: string | null
                outputs?: any | null
              }
            }
          | { __typename?: 'FileSearchToolCall'; name: string; description: string; type: string }
          | {
              __typename?: 'FunctionToolCall'
              name: string
              description: string
              type: string
              function: {
                __typename?: 'ToolFunction'
                name: string
                arguments?: any | null
                output?: string | null
              }
            }
        >
      }
  > | null
}

export type ArtifactsQueryVariables = Exact<{
  messageThreadUuid: Scalars['String']['input']
  makeConsistent?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ArtifactsQuery = {
  __typename?: 'Query'
  artifacts?: Array<{
    __typename?: 'Artifact'
    uuid: string
    artifactType: string
    name: string
    description: string
    content: any
    deletedAt?: any | null
    createdAt: any
    updatedAt: any
  }> | null
}

export type CreateArtifactMutationVariables = Exact<{
  messageThreadUuid: Scalars['String']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  content: Scalars['JSON']['input']
}>

export type CreateArtifactMutation = {
  __typename?: 'Mutation'
  createArtifact: {
    __typename?: 'Artifact'
    uuid: string
    artifactType: string
    name: string
    description: string
    content: any
    deletedAt?: any | null
    createdAt: any
    updatedAt: any
  }
}

export type UpdateArtifactMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  content: Scalars['JSON']['input']
}>

export type UpdateArtifactMutation = {
  __typename?: 'Mutation'
  updateArtifact: {
    __typename?: 'Artifact'
    uuid: string
    artifactType: string
    name: string
    description: string
    content: any
    deletedAt?: any | null
    createdAt: any
    updatedAt: any
  }
}

export type DeleteArtifactMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DeleteArtifactMutation = {
  __typename?: 'Mutation'
  deleteArtifact: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UpdateArtifactUuidMutationVariables = Exact<{
  artifactUuid: Scalars['String']['input']
  planUuid: Scalars['String']['input']
  backendUuid: Scalars['String']['input']
}>

export type UpdateArtifactUuidMutation = {
  __typename?: 'Mutation'
  updateArtifactUuid: {
    __typename?: 'Artifact'
    uuid: string
    artifactType: string
    name: string
    description: string
    content: any
    deletedAt?: any | null
    createdAt: any
    updatedAt: any
  }
}

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['Int']['input']
  name: Scalars['String']['input']
}>

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation'
  updateOrganization: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type CreditUsagesQueryVariables = Exact<{
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
}>

export type CreditUsagesQuery = {
  __typename?: 'Query'
  creditUsages?: Array<{ __typename?: 'CreditUsage'; usageDate: any; quantity: number }> | null
}

export type OrganizationSuggestionsQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationSuggestionsQuery = {
  __typename?: 'Query'
  organizationSuggestions?: Array<{
    __typename?: 'Suggestion'
    uuid: string
    body: string
    status: SuggestionStatusEnum
    createdAt: any
    updatedAt: any
    closedAt?: any | null
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName?: string | null
      label: string
      jobTitle?: string | null
      primer?: string | null
      backend: string
      type: string
      profileImageUrl?: string | null
      shortDescription?: string | null
      description?: string | null
      isCompany: boolean
      isExpert: boolean
      isCustom: boolean
      createdAt: any
      createdBy?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
    }
  }> | null
}

export type AgentSuggestionsQueryVariables = Exact<{
  agentUuid: Scalars['String']['input']
}>

export type AgentSuggestionsQuery = {
  __typename?: 'Query'
  agentSuggestions?: Array<{
    __typename?: 'Suggestion'
    uuid: string
    body: string
    status: SuggestionStatusEnum
    createdAt: any
    updatedAt: any
    closedAt?: any | null
  }> | null
}

export type ToggleSuggestionStatusMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type ToggleSuggestionStatusMutation = {
  __typename?: 'Mutation'
  toggleSuggestionStatus: {
    __typename?: 'ToggleSuggestionStatus'
    success?: boolean | null
    errors?: Array<string> | null
    status?: string | null
  }
}

export type TasksQueryVariables = Exact<{
  taskableUuid: Scalars['ID']['input']
  taskableType: Scalars['String']['input']
}>

export type TasksQuery = {
  __typename?: 'Query'
  tasks?: Array<{
    __typename?: 'Task'
    id: string
    uuid: string
    name: string
    description: string
    task: string
    taskType?: string | null
    status: string
    taskableType: string
    taskableUuid: string
    outputWebhookUrl?: string | null
    runSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
  }> | null
}

export type TaskQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type TaskQuery = {
  __typename?: 'Query'
  task?: {
    __typename?: 'Task'
    id: string
    uuid: string
    name: string
    description: string
    task: string
    taskType?: string | null
    status: string
    taskableType: string
    taskableUuid: string
    outputWebhookUrl?: string | null
    runSchedule?: string | null
    createdAt: any
    taskable:
      | { __typename: 'Analysis'; uuid: string; name: string; description: string }
      | { __typename: 'Workflow'; uuid: string; name: string; description: string }
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
  } | null
}

export type CreateTaskMutationVariables = Exact<{
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  task: Scalars['String']['input']
  outputWebhookUrl: Scalars['String']['input']
  runSchedule: Scalars['String']['input']
  taskableUuid: Scalars['String']['input']
  taskableType: Scalars['String']['input']
  taskType?: InputMaybe<Scalars['String']['input']>
}>

export type CreateTaskMutation = {
  __typename?: 'Mutation'
  createTask: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UpdateTaskMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  task: Scalars['String']['input']
  outputWebhookUrl: Scalars['String']['input']
  runSchedule: Scalars['String']['input']
  taskType?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateTaskMutation = {
  __typename?: 'Mutation'
  updateTask: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type DeleteTaskMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DeleteTaskMutation = {
  __typename?: 'Mutation'
  deleteTask: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type SubmitRunTaskMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type SubmitRunTaskMutation = {
  __typename?: 'Mutation'
  submitRunTask: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type TaskRunsQueryVariables = Exact<{
  taskUuid: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type TaskRunsQuery = {
  __typename?: 'Query'
  taskRuns?: Array<{
    __typename?: 'TaskRun'
    id: string
    uuid: string
    status: string
    state: string
    createdAt: any
    startedAt?: any | null
    completedAt?: any | null
    runtimeSeconds?: number | null
    threadUuid?: string | null
    webhookResponseStatus?: string | null
    webhookStatus?: string | null
    webhookCompletedAt?: any | null
    requestMessage?: string | null
    responseMessage?: string | null
    responseStatus?: string | null
    task: { __typename?: 'Task'; task: string }
    agent?: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName?: string | null
      label: string
      jobTitle?: string | null
      primer?: string | null
      backend: string
      type: string
      profileImageUrl?: string | null
      shortDescription?: string | null
      description?: string | null
      isCompany: boolean
      isExpert: boolean
      isCustom: boolean
      createdAt: any
      createdBy?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
    } | null
    evaluationMessage?: {
      __typename?: 'EvaluationMessage'
      reason?: string | null
      status?: boolean | null
    } | null
  }> | null
}

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  __typename?: 'Query'
  currentUser?: {
    __typename?: 'User'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    privacyPolicyAccepted: boolean
    shortName: string
    longName: string
    organization: {
      __typename?: 'Organization'
      id: string
      name: string
      restrictByGroupOrRole: boolean
      emailNamespace?: string | null
      isPaid: boolean
    }
    roles: Array<{ __typename?: 'Role'; id: string; name: string }>
  } | null
}

export type TrueUserQueryVariables = Exact<{ [key: string]: never }>

export type TrueUserQuery = {
  __typename?: 'Query'
  trueUser?: {
    __typename?: 'User'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    privacyPolicyAccepted: boolean
    shortName: string
    longName: string
    organization: {
      __typename?: 'Organization'
      id: string
      name: string
      restrictByGroupOrRole: boolean
      emailNamespace?: string | null
      isPaid: boolean
    }
    roles: Array<{ __typename?: 'Role'; id: string; name: string }>
  } | null
}

export type OrganizationUsersQueryVariables = Exact<{
  organizationId: Scalars['ID']['input']
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
}>

export type OrganizationUsersQuery = {
  __typename?: 'Query'
  items: Array<{
    __typename?: 'User'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    createdAt: any
    updatedAt: any
    isInvitedPendingRegistration: boolean
    roles: Array<{ __typename?: 'Role'; id: string; name: string }>
  }>
  total: { __typename?: 'MetaData'; count: number }
}

export type SendInvitesMutationVariables = Exact<{
  emails: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type SendInvitesMutation = {
  __typename?: 'Mutation'
  sendInvites: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type ResendEmailInviteMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type ResendEmailInviteMutation = {
  __typename?: 'Mutation'
  resendEmailInvite: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type UpdateCurrentUserMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  privacyPolicyAccepted?: InputMaybe<Scalars['Boolean']['input']>
  password?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateCurrentUserMutation = {
  __typename?: 'Mutation'
  updateCurrentUser: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type StopImpersonatingMutationVariables = Exact<{ [key: string]: never }>

export type StopImpersonatingMutation = {
  __typename?: 'Mutation'
  stopImpersonating: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type AddToWaitlistMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type AddToWaitlistMutation = {
  __typename?: 'Mutation'
  addToWaitlist: {
    __typename?: 'WaitlistResponse'
    errors: Array<string>
    success: boolean
    placeInLine: number
  }
}

export type WorkflowsQueryVariables = Exact<{ [key: string]: never }>

export type WorkflowsQuery = {
  __typename?: 'Query'
  workflows?: Array<{
    __typename?: 'Workflow'
    id: string
    uuid: string
    name: string
    status?: string | null
    description: string
    webhookUrl?: string | null
    cronSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    tasks: Array<{
      __typename?: 'Task'
      id: string
      uuid: string
      name: string
      description: string
      task: string
      taskType?: string | null
      status: string
      taskableType: string
      taskableUuid: string
      outputWebhookUrl?: string | null
      runSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName?: string | null
      label: string
      jobTitle?: string | null
      primer?: string | null
      backend: string
      type: string
      profileImageUrl?: string | null
      shortDescription?: string | null
      description?: string | null
      isCompany: boolean
      isExpert: boolean
      isCustom: boolean
      createdAt: any
      createdBy?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
    }
  }> | null
}

export type WorkflowQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type WorkflowQuery = {
  __typename?: 'Query'
  workflow?: {
    __typename?: 'Workflow'
    id: string
    uuid: string
    name: string
    status?: string | null
    description: string
    webhookUrl?: string | null
    cronSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    tasks: Array<{
      __typename?: 'Task'
      id: string
      uuid: string
      name: string
      description: string
      task: string
      taskType?: string | null
      status: string
      taskableType: string
      taskableUuid: string
      outputWebhookUrl?: string | null
      runSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName?: string | null
      label: string
      jobTitle?: string | null
      primer?: string | null
      backend: string
      type: string
      profileImageUrl?: string | null
      shortDescription?: string | null
      description?: string | null
      isCompany: boolean
      isExpert: boolean
      isCustom: boolean
      createdAt: any
      createdBy?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
    }
  } | null
}

export type DeleteWorkflowMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type DeleteWorkflowMutation = {
  __typename?: 'Mutation'
  deleteWorkflow: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type CreateWorkflowMutationVariables = Exact<{
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  agentUuid: Scalars['String']['input']
  webhookUrl?: InputMaybe<Scalars['String']['input']>
  cronSchedule?: InputMaybe<Scalars['String']['input']>
}>

export type CreateWorkflowMutation = {
  __typename?: 'Mutation'
  createWorkflow: {
    __typename?: 'WorkflowCreateResponse'
    success?: boolean | null
    errors?: Array<string> | null
    workflow?: { __typename?: 'Workflow'; uuid: string } | null
  }
}

export type UpdateWorkflowMutationVariables = Exact<{
  uuid: Scalars['String']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  agentUuid: Scalars['String']['input']
  webhookUrl?: InputMaybe<Scalars['String']['input']>
  cronSchedule?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateWorkflowMutation = {
  __typename?: 'Mutation'
  updateWorkflow: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type AgentWorkflowsQueryVariables = Exact<{
  agentUuid: Scalars['ID']['input']
}>

export type AgentWorkflowsQuery = {
  __typename?: 'Query'
  agentWorkflows?: Array<{
    __typename?: 'Workflow'
    id: string
    uuid: string
    name: string
    status?: string | null
    description: string
    webhookUrl?: string | null
    cronSchedule?: string | null
    createdAt: any
    createdBy: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    }
    tasks: Array<{
      __typename?: 'Task'
      id: string
      uuid: string
      name: string
      description: string
      task: string
      taskType?: string | null
      status: string
      taskableType: string
      taskableUuid: string
      outputWebhookUrl?: string | null
      runSchedule?: string | null
      createdAt: any
      createdBy: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      }
    }>
    agent: {
      __typename?: 'Agent'
      id: string
      uuid: string
      firstName?: string | null
      label: string
      jobTitle?: string | null
      primer?: string | null
      backend: string
      type: string
      profileImageUrl?: string | null
      shortDescription?: string | null
      description?: string | null
      isCompany: boolean
      isExpert: boolean
      isCustom: boolean
      createdAt: any
      createdBy?: {
        __typename?: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
      } | null
    }
  }> | null
}

export type WorkflowTemplatesQueryVariables = Exact<{
  agentUuid: Scalars['ID']['input']
}>

export type WorkflowTemplatesQuery = {
  __typename?: 'Query'
  workflowTemplates?: Array<{
    __typename?: 'WorkflowTemplate'
    uuid: string
    name: string
    description: string
    tasks: Array<{
      __typename?: 'TaskTemplate'
      name: string
      description: string
      task: string
      runSchedule?: string | null
      taskType: string
    }>
  }> | null
}

export type CreateWorkflowFromTemplateMutationVariables = Exact<{
  templateUuid: Scalars['String']['input']
  agentUuid: Scalars['String']['input']
}>

export type CreateWorkflowFromTemplateMutation = {
  __typename?: 'Mutation'
  createWorkflowFromTemplate: {
    __typename?: 'WorkflowCreateResponse'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type TaskRunMarkPassedMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type TaskRunMarkPassedMutation = {
  __typename?: 'Mutation'
  taskRunMarkPassed: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type SubmitRunWorkflowMutationVariables = Exact<{
  uuid: Scalars['String']['input']
}>

export type SubmitRunWorkflowMutation = {
  __typename?: 'Mutation'
  submitRunWorkflow: {
    __typename?: 'ResponseStatus'
    success?: boolean | null
    errors?: Array<string> | null
  }
}

export type WalkthroughFieldsFragment = {
  __typename?: 'Walkthrough'
  id: string
  uuid: string
  name: string
  description?: string | null
  steps: Array<any>
  meta?: any | null
  audioEnabled: boolean
  hasAudio: boolean
  canEdit?: boolean | null
  canMove?: boolean | null
  isOwner?: boolean | null
  isInMyLibrary?: boolean | null
  isInMyOrganizationLibrary?: boolean | null
  canEditSharing?: boolean | null
  canDelete?: boolean | null
  sharedAccess: string
  completed?: boolean | null
  createdAt: any
  embedEnabled: boolean
  updatedAt: any
  viewCount: number
  interactionCount: number
  createdBy?: {
    __typename?: 'User'
    firstName?: string | null
    lastName?: string | null
    id: string
  } | null
  skillSet?: {
    __typename?: 'SkillSet'
    id: string
    name: string
    uuid: string
    sharedAccess: string
  } | null
}

export type SkillSetFieldsFragment = {
  __typename?: 'SkillSet'
  id: string
  name: string
  uuid: string
  completed?: boolean | null
  canEdit?: boolean | null
  canMove?: boolean | null
  isInMyLibrary?: boolean | null
  isInMyOrganizationLibrary?: boolean | null
  sharedAccess: string
  canEditSharing?: boolean | null
  createdAt: any
  updatedAt: any
  walkthroughs?: Array<{
    __typename?: 'Walkthrough'
    id: string
    name: string
    uuid: string
    steps: Array<any>
    completed?: boolean | null
    meta?: any | null
  }> | null
}

export type GetCreatedContentQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
  contentType?: InputMaybe<Array<ContentType> | ContentType>
}>

export type GetCreatedContentQuery = {
  __typename?: 'Query'
  items: Array<
    | {
        __typename?: 'SkillSet'
        id: string
        name: string
        uuid: string
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        sharedAccess: string
        canEditSharing?: boolean | null
        createdAt: any
        updatedAt: any
        walkthroughs?: Array<{
          __typename?: 'Walkthrough'
          id: string
          name: string
          uuid: string
          steps: Array<any>
          completed?: boolean | null
          meta?: any | null
        }> | null
      }
    | {
        __typename?: 'Walkthrough'
        id: string
        uuid: string
        name: string
        description?: string | null
        steps: Array<any>
        meta?: any | null
        audioEnabled: boolean
        hasAudio: boolean
        canEdit?: boolean | null
        canMove?: boolean | null
        isOwner?: boolean | null
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        canEditSharing?: boolean | null
        canDelete?: boolean | null
        sharedAccess: string
        completed?: boolean | null
        createdAt: any
        embedEnabled: boolean
        updatedAt: any
        viewCount: number
        interactionCount: number
        createdBy?: {
          __typename?: 'User'
          firstName?: string | null
          lastName?: string | null
          id: string
        } | null
        skillSet?: {
          __typename?: 'SkillSet'
          id: string
          name: string
          uuid: string
          sharedAccess: string
        } | null
      }
  >
  total: { __typename?: 'MetaData'; count: number }
}

export type GetOrganizationContentQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
  contentType?: InputMaybe<Array<ContentType> | ContentType>
}>

export type GetOrganizationContentQuery = {
  __typename?: 'Query'
  items: Array<
    | {
        __typename?: 'SkillSet'
        id: string
        name: string
        uuid: string
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        sharedAccess: string
        canEditSharing?: boolean | null
        createdAt: any
        updatedAt: any
        walkthroughs?: Array<{
          __typename?: 'Walkthrough'
          id: string
          name: string
          uuid: string
          steps: Array<any>
          completed?: boolean | null
          meta?: any | null
        }> | null
      }
    | {
        __typename?: 'Walkthrough'
        id: string
        uuid: string
        name: string
        description?: string | null
        steps: Array<any>
        meta?: any | null
        audioEnabled: boolean
        hasAudio: boolean
        canEdit?: boolean | null
        canMove?: boolean | null
        isOwner?: boolean | null
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        canEditSharing?: boolean | null
        canDelete?: boolean | null
        sharedAccess: string
        completed?: boolean | null
        createdAt: any
        embedEnabled: boolean
        updatedAt: any
        viewCount: number
        interactionCount: number
        createdBy?: {
          __typename?: 'User'
          firstName?: string | null
          lastName?: string | null
          id: string
        } | null
        skillSet?: {
          __typename?: 'SkillSet'
          id: string
          name: string
          uuid: string
          sharedAccess: string
        } | null
      }
  >
  total: { __typename?: 'MetaData'; count: number }
}

export type CreateEventMutationVariables = Exact<{
  type: Scalars['String']['input']
  subjectType: EventSubjectType
  subjectId: Scalars['ID']['input']
  source?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<Scalars['JSON']['input']>
  timestamp: Scalars['Float']['input']
}>

export type CreateEventMutation = {
  __typename?: 'Mutation'
  event: { __typename?: 'RrwebEvents'; data?: string | null }
}

export type CreateLearningActivityMutationVariables = Exact<{
  subjectType?: InputMaybe<LearningActivitySubjectType>
  subjectId?: InputMaybe<Scalars['ID']['input']>
  objectType: LearningActivityObjectType
  action: LearningActivityActionType
  objectId: Scalars['ID']['input']
}>

export type CreateLearningActivityMutation = {
  __typename?: 'Mutation'
  item: {
    __typename?: 'LearningActivity'
    id: string
    objectId: string
    objectType: LearningActivityObjectType
    action: LearningActivityActionType
    subjectType?: LearningActivitySubjectType | null
    subjectId: string
  }
}

export type AllRolesQueryVariables = Exact<{ [key: string]: never }>

export type AllRolesQuery = {
  __typename?: 'Query'
  roles: Array<{ __typename?: 'Role'; id: string; name: string; createdAt: any; updatedAt: any }>
}

export type SkillSetQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type SkillSetQuery = {
  __typename?: 'Query'
  skillSet: {
    __typename?: 'SkillSet'
    id: string
    name: string
    description?: string | null
    uuid: string
    canEdit?: boolean | null
    canMove?: boolean | null
    sharedAccess: string
    canEditSharing?: boolean | null
    completed?: boolean | null
    isInMyLibrary?: boolean | null
    isInMyOrganizationLibrary?: boolean | null
    createdAt: any
    createdBy?: {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
    } | null
    walkthroughs?: Array<{
      __typename?: 'Walkthrough'
      id: string
      uuid: string
      name: string
      description?: string | null
      steps: Array<any>
      meta?: any | null
      canEdit?: boolean | null
      canMove?: boolean | null
      isOwner?: boolean | null
      isInMyLibrary?: boolean | null
      isInMyOrganizationLibrary?: boolean | null
      canEditSharing?: boolean | null
      canDelete?: boolean | null
      sharedAccess: string
      completed?: boolean | null
      createdAt: any
      createdBy?: {
        __typename?: 'User'
        firstName?: string | null
        lastName?: string | null
        id: string
      } | null
      skillSet?: {
        __typename: 'SkillSet'
        id: string
        name: string
        description?: string | null
        uuid: string
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        sharedAccess: string
        canEditSharing?: boolean | null
      } | null
    }> | null
  }
}

export type DeleteSkillSetMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteSkillSetMutation = {
  __typename?: 'Mutation'
  deleteSkillSet: { __typename?: 'SkillSet'; id: string }
}

export type UpdateSkillSetMutationVariables = Exact<{
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
}>

export type UpdateSkillSetMutation = {
  __typename?: 'Mutation'
  updateSkillSet: {
    __typename?: 'SkillSet'
    id: string
    name: string
    description?: string | null
    uuid: string
    createdBy?: { __typename?: 'User'; id: string; firstName?: string | null } | null
    walkthroughs?: Array<{
      __typename?: 'Walkthrough'
      id: string
      uuid: string
      name: string
    }> | null
  }
}

export type CreateSkillSetMutationVariables = Exact<{
  name: Scalars['String']['input']
  description: Scalars['String']['input']
}>

export type CreateSkillSetMutation = {
  __typename?: 'Mutation'
  data: {
    __typename?: 'SkillSet'
    id: string
    name: string
    description?: string | null
    uuid: string
    createdAt: any
    updatedAt: any
    createdBy?: { __typename?: 'User'; id: string } | null
  }
}

export type ShareSkillSetMutationVariables = Exact<{
  id: Scalars['ID']['input']
  shareType: ShareTypeEnum
}>

export type ShareSkillSetMutation = {
  __typename?: 'Mutation'
  shareSkillSet: {
    __typename?: 'SkillSet'
    id: string
    name: string
    sharedAccess: string
    isInMyLibrary?: boolean | null
    isInMyOrganizationLibrary?: boolean | null
    uuid: string
  }
}

export type MoveSkillSetMutationVariables = Exact<{
  id: Scalars['ID']['input']
  destinationType: DestinationType
  destinationId?: InputMaybe<Scalars['ID']['input']>
}>

export type MoveSkillSetMutation = {
  __typename?: 'Mutation'
  skillSet: {
    __typename?: 'SkillSet'
    id: string
    uuid: string
    name: string
    isInMyLibrary?: boolean | null
    isInMyOrganizationLibrary?: boolean | null
  }
}

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  organization: Scalars['ID']['input']
  roles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>
  password?: InputMaybe<Scalars['String']['input']>
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  data: {
    __typename?: 'User'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    shortName: string
    longName: string
  }
}

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  roles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>
  password?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  data: {
    __typename?: 'User'
    id: string
    email: string
    firstName?: string | null
    lastName?: string | null
    shortName: string
    longName: string
  }
}

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteUserMutation = {
  __typename?: 'Mutation'
  data: { __typename?: 'User'; id: string }
}

export type CreateUserActivityMutationVariables = Exact<{
  action: Scalars['String']['input']
  url: Scalars['String']['input']
  metadata?: InputMaybe<Scalars['JSON']['input']>
  objectType?: InputMaybe<UserActivityObjectType>
  objectId?: InputMaybe<Scalars['ID']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
}>

export type CreateUserActivityMutation = {
  __typename?: 'Mutation'
  createUserActivity: { __typename?: 'UserActivity'; id: string }
}

export type UploadAudioMutationVariables = Exact<{
  file: Scalars['Upload']['input']
  walkthroughId: Scalars['ID']['input']
}>

export type UploadAudioMutation = {
  __typename?: 'Mutation'
  uploadAudio: { __typename?: 'Audio'; id: string; uuid: string; audioUrl?: string | null }
}

export type WalkthroughByUuidQueryVariables = Exact<{
  uuid: Scalars['ID']['input']
}>

export type WalkthroughByUuidQuery = {
  __typename?: 'Query'
  walkthrough: {
    __typename?: 'Walkthrough'
    id: string
    uuid: string
    name: string
    description?: string | null
    meta?: any | null
    steps: Array<any>
    audioEnabled: boolean
    hasAudio: boolean
    isOwner?: boolean | null
    isInMyLibrary?: boolean | null
    isInMyOrganizationLibrary?: boolean | null
    canEdit?: boolean | null
    canMove?: boolean | null
    canEditSharing?: boolean | null
    createdAt: any
    canDelete?: boolean | null
    sharedAccess: string
    completed?: boolean | null
    rrwebEventsUrl?: string | null
    audioUrl?: string | null
    liveLearning?: boolean | null
    labs?: boolean | null
    embedEnabled: boolean
    viewCount: number
    interactionCount: number
    synthesis?: string | null
    synthesisStatus?: string | null
    summary?: string | null
    lastSynthesisAt?: any | null
    updatedAt: any
    createdBy?: {
      __typename?: 'User'
      firstName?: string | null
      lastName?: string | null
      id: string
    } | null
    skillSet?: {
      __typename?: 'SkillSet'
      id: string
      name: string
      uuid: string
      isInMyLibrary?: boolean | null
      isInMyOrganizationLibrary?: boolean | null
      sharedAccess: string
      canEditSharing?: boolean | null
    } | null
  }
}

export type DeleteWalkthroughMutationVariables = Exact<{
  id: Scalars['ID']['input']
  organizationId?: InputMaybe<Scalars['ID']['input']>
}>

export type DeleteWalkthroughMutation = {
  __typename?: 'Mutation'
  deleteWalkthrough: { __typename?: 'Walkthrough'; id: string }
}

export type MoveWalkthroughMutationVariables = Exact<{
  id: Scalars['ID']['input']
  destinationType: DestinationType
  destinationId?: InputMaybe<Scalars['ID']['input']>
}>

export type MoveWalkthroughMutation = {
  __typename?: 'Mutation'
  walkthrough: {
    __typename?: 'Walkthrough'
    id: string
    uuid: string
    name: string
    isInMyLibrary?: boolean | null
    isInMyOrganizationLibrary?: boolean | null
    skillSet?: { __typename?: 'SkillSet'; uuid: string } | null
  }
}

export type UpdateWalkthroughMutationVariables = Exact<{
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  description: Scalars['String']['input']
  steps: Array<Scalars['JSON']['input']> | Scalars['JSON']['input']
  liveLearning?: InputMaybe<Scalars['Boolean']['input']>
  labs?: InputMaybe<Scalars['Boolean']['input']>
  audioEnabled?: InputMaybe<Scalars['Boolean']['input']>
  synthesis?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateWalkthroughMutation = {
  __typename?: 'Mutation'
  updateWalkthrough: {
    __typename?: 'Walkthrough'
    id: string
    uuid: string
    description?: string | null
    name: string
    steps: Array<any>
    audioEnabled: boolean
    audioUrl?: string | null
    synthesis?: string | null
  }
}

export type ShareWalkthroughMutationVariables = Exact<{
  id: Scalars['ID']['input']
  shareType: ShareTypeEnum
}>

export type ShareWalkthroughMutation = {
  __typename?: 'Mutation'
  shareWalkthrough: {
    __typename?: 'Walkthrough'
    id: string
    name: string
    sharedAccess: string
    isInMyOrganizationLibrary?: boolean | null
    isInMyLibrary?: boolean | null
  }
}

export type ShareEmbedWalkthroughMutationVariables = Exact<{
  id: Scalars['ID']['input']
  enabled: Scalars['Boolean']['input']
}>

export type ShareEmbedWalkthroughMutation = {
  __typename?: 'Mutation'
  shareEmbedWalkthrough: { __typename?: 'Walkthrough'; id: string }
}

export type GetRecentlyViewedWalkthroughsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
  contentType?: InputMaybe<ContentType>
}>

export type GetRecentlyViewedWalkthroughsQuery = {
  __typename?: 'Query'
  items: Array<
    | {
        __typename: 'SkillSet'
        id: string
        name: string
        uuid: string
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        sharedAccess: string
        canEditSharing?: boolean | null
        walkthroughs?: Array<{
          __typename?: 'Walkthrough'
          id: string
          name: string
          steps: Array<any>
          uuid: string
          completed?: boolean | null
          meta?: any | null
        }> | null
      }
    | {
        __typename: 'Walkthrough'
        id: string
        description?: string | null
        name: string
        steps: Array<any>
        uuid: string
        sharedAccess: string
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        createdAt: any
        updatedAt: any
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        canEditSharing?: boolean | null
        meta?: any | null
        viewCount: number
        interactionCount: number
        skillSet?: {
          __typename: 'SkillSet'
          id: string
          name: string
          uuid: string
          sharedAccess: string
        } | null
      }
  >
  total: { __typename?: 'MetaData'; count: number }
}

export type GetWalkthroughsSharedWithMeQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<Scalars['String']['input']>
  sortDirection?: InputMaybe<Scalars['String']['input']>
  contentType?: InputMaybe<ContentType>
}>

export type GetWalkthroughsSharedWithMeQuery = {
  __typename?: 'Query'
  items: Array<
    | {
        __typename: 'SkillSet'
        id: string
        name: string
        uuid: string
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        sharedAccess: string
        canEditSharing?: boolean | null
        walkthroughs?: Array<{
          __typename?: 'Walkthrough'
          id: string
          name: string
          uuid: string
          steps: Array<any>
          meta?: any | null
          completed?: boolean | null
        }> | null
      }
    | {
        __typename: 'Walkthrough'
        id: string
        description?: string | null
        name: string
        steps: Array<any>
        uuid: string
        meta?: any | null
        sharedAccess: string
        isInMyLibrary?: boolean | null
        isInMyOrganizationLibrary?: boolean | null
        createdAt: any
        updatedAt: any
        completed?: boolean | null
        canEdit?: boolean | null
        canMove?: boolean | null
        canEditSharing?: boolean | null
        viewCount: number
        interactionCount: number
        createdBy?: {
          __typename?: 'User'
          firstName?: string | null
          lastName?: string | null
          id: string
        } | null
        skillSet?: {
          __typename: 'SkillSet'
          id: string
          name: string
          uuid: string
          sharedAccess: string
        } | null
      }
  >
  total: { __typename?: 'MetaData'; count: number }
}

import React from 'react'
import DataSetCard from './DataSetCard'
import AgentCard from './AgentCard'
import ChartResults from './ChartResults'
import LookerExplore from './LookerExplore'
import { ConversationSideEffect } from '../../slices/chatSlice'

const CardContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className="flex flex-wrap gap-2">{children}</div>
}

interface SideEffectProps {
  sideEffect: ConversationSideEffect
}

const SideEffect: React.FC<SideEffectProps> = ({ sideEffect }) => {
  const sideEffectType = sideEffect.sideEffect
  const sideEffectData = sideEffect.data
  if (!sideEffectType || !sideEffectData) {
    return <></>
  }

  if (sideEffectType === 'side_effect_show_data_sets' && sideEffectData['data_set_uuid_list']) {
    return (
      <CardContainer>
        {sideEffectData['data_set_uuid_list'].map((one_uuid: string) => (
          <DataSetCard key={one_uuid} uuid={one_uuid} />
        ))}
      </CardContainer>
    )
  } else if (sideEffectType === 'side_effect_show_agent') {
    const agent_uuid_list = sideEffectData['agent_uuid_list'] || []
    return (
      <CardContainer>
        {agent_uuid_list.map((one_uuid: string) => (
          <AgentCard key={one_uuid} uuid={one_uuid} />
        ))}
      </CardContainer>
    )
  } else if (sideEffectType === 'side_effect_chart_results') {
    return <ChartResults config={sideEffect.data} />
  } else if (sideEffectType.startsWith('side_effect_looker_data_set_')) {
    return <LookerExplore sideEffect={sideEffect} />
  }
  return <></>
}

export default SideEffect

import React, { ReactNode } from 'react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import clsx from 'clsx'

export type TabItem = {
  key: string
  label: string
  content: ReactNode
}

type VerticalTabsProps = {
  tabs: TabItem[]
  className?: string
  tabListWidth?: string
  onChange?: (tab: TabItem) => void
}

export const VerticalTabs = ({
  tabs,
  className = '',
  tabListWidth = 'w-48',
  onChange,
}: VerticalTabsProps) => {
  return (
    <div className={clsx('flex-1 flex', className)}>
      <TabGroup
        as="div"
        className="flex-1 flex h-full"
        onChange={(index) => onChange?.(tabs[index])}
      >
        <div className="flex min-h-full w-full">
          <div
            className={clsx(
              tabListWidth,
              'shrink-0 border-r border-gray-200 min-h-[calc(100vh-12rem)] pt-10'
            )}
          >
            <TabList className="flex flex-col space-y-2 sticky top-4">
              {tabs.map((tab) => (
                <Tab
                  key={tab.key}
                  className={({ selected }) =>
                    clsx(
                      'group relative rounded-l-xl px-6 py-3 text-left transition-all border-t border-b border-l focus:outline-none focus-visible:outline-none',
                      selected
                        ? 'bg-white border-flowmo-blue-200 text-flowmo-blue-900'
                        : 'border-gray-200 hover:bg-gray-50/80 hover:border-gray-300 hover:translate-x-1 text-gray-700 hover:text-flowmo-blue-900'
                    )
                  }
                >
                  {({ selected }) => (
                    <span
                      className={clsx(
                        'font-display text-sm',
                        selected
                          ? 'text-flowmo-blue-900'
                          : 'text-gray-900 group-hover:text-flowmo-blue-900'
                      )}
                    >
                      {tab.label}
                    </span>
                  )}
                </Tab>
              ))}
            </TabList>
          </div>

          <TabPanels className="flex-1 pt-10 bg-white shadow-inner rounded-r-lg">
            <div className="max-w-7xl mx-auto px-8 w-full">
              {tabs.map((tab) => (
                <TabPanel key={tab.key}>{tab.content}</TabPanel>
              ))}
            </div>
          </TabPanels>
        </div>
      </TabGroup>
    </div>
  )
}

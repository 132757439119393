import React, { useState } from 'react'
import {
  DELETE_DATA_UNION_MUTATION,
  GET_DATA_UNION_QUERY,
  UPDATE_DATA_UNION_MUTATION,
} from '../../graphql/queries/data_union'
import {
  DataUnionQuery,
  DataUnionQueryVariables,
  UpdateDataUnionMutation,
  UpdateDataUnionMutationVariables,
  DeleteDataUnionMutation,
  DeleteDataUnionMutationVariables,
} from '../../../../components/graphql'
import Spin from '../../../../components/common/ui/Spin'
import { useMutation, useQuery } from '@apollo/client'
import DynamicForm from '../../components/DynamicForm'
import { useHistory } from 'react-router-dom'
import HeaderActionButton from '../../components/HeaderActionButton'
import DeleteModal from '../../components/DeleteModal'
import SubHeader from '../../components/ObjectHeader/SubHeader'
import { Trash } from 'lucide-react'
import { dataUnionFormData } from './CreateDataUnion'

const EditDataUnion = ({ uuid }: { uuid: string }) => {
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const [isSaving, setIsSaving] = React.useState(false)
  const history = useHistory()
  const { data, loading } = useQuery<DataUnionQuery, DataUnionQueryVariables>(
    GET_DATA_UNION_QUERY,
    {
      variables: { uuid },
    }
  )
  const [open, setOpen] = useState(false)

  const [updateDataUnion] = useMutation<UpdateDataUnionMutation, UpdateDataUnionMutationVariables>(
    UPDATE_DATA_UNION_MUTATION
  )

  const [deleteDataUnion] = useMutation<DeleteDataUnionMutation, DeleteDataUnionMutationVariables>(
    DELETE_DATA_UNION_MUTATION
  )

  if (loading) {
    return <Spin />
  }

  const data_union = data?.dataUnion

  if (!data_union?.isEditable) {
    window.toastr.error('You are not authorized to edit this data union')
    history.push(`/data_union/${uuid}`)
    return null
  }

  if (!loading && !data_union) {
    window.toastr.error('Data Union not found')
    history.push('/data_unions')
    return null
  }

  const handleDelete = async () => {
    const response = await deleteDataUnion({
      variables: {
        uuid,
      },
    })
    if (response.data?.deleteDataUnion.errors.length > 0) {
      window.toastr.error('Error deleting data union')
    } else {
      window.toastr.success('Data Union deleted successfully')
      history.push('/data_model')
    }
    return
  }

  const handleSubmit = async (values: any) => {
    setIsSaving(true)
    const response = await updateDataUnion({
      variables: {
        uuid,
        name: values.name,
        description: values.description,
        definition: values.definition,
        isEditable: true,
      },
    })
    if (response.data?.updateDataUnion.errors.length > 0) {
      window.toastr.error('Error updating data union')
      history.push('/data_unions')
    } else {
      window.toastr.success('Data union updated successfully')
      history.push(`/data_union/${uuid}`)
    }
    setIsSaving(false)
    return
  }

  const initialData = {
    name: data_union?.name,
    description: data_union?.description,
    definition: JSON.stringify(data_union?.definition),
  }

  return (
    <>
      <SubHeader
        object={data_union}
        type="data_union"
        subtitle="Edit Data Union"
        rightContent={
          <HeaderActionButton
            onClick={() => setOpen(true)}
            icon={<Trash className="size-4" />}
            tooltip="Delete"
          />
        }
      />
      <DeleteModal
        open={open}
        setOpen={setOpen}
        onDelete={handleDelete}
        title="Delete Data Union"
        buttonLabel="Delete"
        description="Are you sure you want to delete the data union? All of your data will be permanently removed from our servers forever. This action cannot be undone."
      />
      <div className="max-w-2xl">
        <DynamicForm
          ref={formRef}
          formData={dataUnionFormData}
          initialData={initialData}
          handleSubmit={handleSubmit}
        />
        <div className="flex flex-row space-x-4">
          <button
            type="submit"
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-flowmo-blue-600 hover:bg-flowmo-blue-700"
            onClick={() => formRef.current?.submitForm()}
          >
            {isSaving ? <Spin /> : 'Save'}
          </button>
          <button
            type="button"
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-300 hover:bg-gray-500"
            onClick={() => history.push(`/data_union/${uuid}`)}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  )
}

export default EditDataUnion

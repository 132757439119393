import React, { useState } from 'react'
import {
  DialogBackdrop,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from '@headlessui/react'
import { Dialog, Transition } from '@headlessui/react'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { DELETE_CREDENTIAL_MUTATION, GET_CREDENTIALS } from '../../../graphql/queries/credential'
import { useMutation, useQuery } from '@apollo/client'
import {
  DataCredentialsQuery,
  DataCredentialsQueryVariables,
  DeleteDataCredentialsMutation,
  DeleteDataCredentialsMutationVariables,
} from 'app/javascript/components/graphql'
import _ from 'lodash'
import CredentialsForm, { FormItem } from './CredentalsForm'
import { FieldType } from '../../../components/DynamicForm'

const formData: Record<string, FormItem[]> = {
  looker: [
    {
      name: 'looker_url',
      label: 'Looker URL',
      type: FieldType.Url,
      required: true,
      defaultValue: '',
    },
    {
      name: 'looker_api_url',
      label: 'Looker API URL',
      type: FieldType.Url,
      required: true,
      defaultValue: '',
    },
    {
      name: 'username',
      label: 'Username',
      type: FieldType.Text,
      required: true,
      defaultValue: '',
    },
    {
      name: 'password',
      label: 'Password',
      type: FieldType.Password,
      required: true,
      defaultValue: '',
    },
    {
      name: 'client_id',
      label: 'Client ID',
      type: FieldType.Text,
      required: true,
      defaultValue: '',
    },
    {
      name: 'client_secret',
      label: 'Client Secret',
      type: FieldType.Password,
      required: true,
      defaultValue: '',
    },
  ],
  snowflake: [
    {
      name: 'host',
      label: 'Host',
      type: FieldType.Text,
      required: true,
      defaultValue: '',
    },
    {
      name: 'organization',
      label: 'Organization',
      type: FieldType.Text,
      required: true,
      defaultValue: '',
    },
    {
      name: 'account',
      label: 'Account',
      type: FieldType.Text,
      required: true,
      defaultValue: '',
    },
    {
      name: 'database',
      label: 'Database',
      type: FieldType.Text,
      required: true,
      defaultValue: '',
    },
    {
      name: 'username',
      label: 'Username',
      type: FieldType.Text,
      required: true,
      defaultValue: '',
    },
    {
      name: 'private_key',
      label: 'Private Key',
      type: FieldType.Textarea,
      required: true,
      defaultValue: '',
    },
    {
      name: 'warehouse',
      label: 'Warehouse',
      type: FieldType.Text,
      required: true,
      defaultValue: '',
    },
    {
      name: 'role',
      label: 'Role',
      type: FieldType.Text,
      required: true,
      defaultValue: '',
    },
  ],
  powerbi: [
    {
      name: 'workspace',
      label: 'Workspace',
      type: FieldType.Text,
      required: true,
      defaultValue: '',
    },
    {
      name: 'report',
      label: 'Report',
      type: FieldType.Text,
      required: true,
      defaultValue: '',
    },
    {
      name: 'username',
      label: 'Username',
      type: FieldType.Text,
      required: true,
      defaultValue: '',
    },
    {
      name: 'password',
      label: 'Password',
      type: FieldType.Password,
      required: true,
      defaultValue: '',
    },
  ],
  bigquery: [
    {
      name: 'project_id',
      label: 'Project ID',
      type: FieldType.Text,
      required: true,
      defaultValue: '',
    },
    {
      name: 'dataset_name',
      label: 'Dataset Name',
      type: FieldType.Text,
      required: true,
      defaultValue: '',
    },
    {
      name: 'service_account_file',
      label: 'Service Account File',
      type: FieldType.Textarea,
      required: true,
      defaultValue: '',
    },
  ],
}

interface BackendType {
  value: string
  label: string
}

const DataBackendForm = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const [createBackend, setCreateBackend] = useState<BackendType>(null)
  const [editBackend, setEditBackend] = useState<DataCredentialsQuery['dataCredentials'][0]>(null)
  const [deleteBackend, setDeleteBackend] =
    useState<DataCredentialsQuery['dataCredentials'][0]>(null)

  const { data, refetch: refetchDataCredentials } = useQuery<
    DataCredentialsQuery,
    DataCredentialsQueryVariables
  >(GET_CREDENTIALS, {
    fetchPolicy: 'network-only',
  })

  const [deleteCredentialsMutation] = useMutation<
    DeleteDataCredentialsMutation,
    DeleteDataCredentialsMutationVariables
  >(DELETE_CREDENTIAL_MUTATION)

  const dataCredentials = data?.dataCredentials || []

  const backendTypes: BackendType[] = [
    { value: 'looker', label: 'Looker' },
    { value: 'snowflake', label: 'Snowflake' },
    { value: 'bigquery', label: 'BigQuery' },
  ]

  const availableBackendTypes = backendTypes.filter(
    (backend) => !dataCredentials.find((credential) => credential.credentialType === backend.value)
  )

  const handleBackendSelect = (backend: any) => {
    setCreateBackend(backend)
    setIsCreateModalOpen(true)
  }

  const handleBackendDelete = async () => {
    const response = await deleteCredentialsMutation({
      variables: {
        uuid: deleteBackend.uuid,
      },
    })

    if (response.data.deleteDataCredentials.errors.length > 0) {
      window.toastr.error('Failed to delete backend')
      return
    }

    setDeleteBackend(null)
    window.toastr.success('Backend deleted successfully')
    refetchDataCredentials()
  }

  return (
    <div className="relative flex flex-col">
      <div className="flex items-center justify-end">
        {availableBackendTypes.length > 0 && (
          <Menu as="div" className="relative inline-block text-left">
            <MenuButton className="inline-flex space-x-2 items-center justify-center p-2 text-gray-400 hover:text-gray-500">
              <div className="">Add backend</div>
              <PlusCircleIcon className="w-5 h-5" />
            </MenuButton>
            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1">
                  {availableBackendTypes.map((backend) => (
                    <MenuItem key={backend.value}>
                      <button
                        className={`${'text-gray-700'} group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        onClick={() => handleBackendSelect(backend)}
                      >
                        {backend.label}
                      </button>
                    </MenuItem>
                  ))}
                </div>
              </MenuItems>
            </Transition>
          </Menu>
        )}
      </div>

      <div className="flex flex-row flex-wrap">
        {dataCredentials.map((credential) => (
          <div
            key={credential.uuid}
            className="w-72 bg-white border border-gray-200 rounded-lg shadow m-5"
          >
            <div className="flex flex-col items-center py-10">
              <h5 className="mb-1 text-xl font-medium text-gray-900">
                {_.capitalize(credential.credentialType)}
              </h5>
              <div className="flex mt-4 md:mt-6">
                <button
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                  onClick={() => {
                    setEditBackend(credential)
                    setIsEditModalOpen(true)
                  }}
                >
                  Edit
                </button>
                <button
                  className="py-2 px-4 ms-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 "
                  onClick={() => setDeleteBackend(credential)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}

        {dataCredentials.length === 0 && (
          <div className="flex items-center justify-center w-full h-24 text-gray-500">
            No data backends found
          </div>
        )}
      </div>

      {deleteBackend && (
        <Transition appear show={true} as={React.Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={() => setDeleteBackend(null)}
          >
            <div className="min-h-screen px-4 text-center">
              <TransitionChild
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <DialogBackdrop className="fixed inset-0 bg-black opacity-30" />
              </TransitionChild>

              <span className="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <TransitionChild
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900 mb-2">
                    Delete Backend
                  </DialogTitle>
                  <div className="mt-2">
                    <p>Are you sure you want to delete this backend?</p>
                    <div className="flex flex-row space-x-2 mt-4">
                      <button
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-blue-800"
                        onClick={handleBackendDelete}
                      >
                        Delete
                      </button>
                      <button
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-red-700"
                        onClick={() => setDeleteBackend(null)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition>
      )}

      {editBackend && (
        <Transition appear show={isEditModalOpen} as={React.Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={() => setIsEditModalOpen(false)}
          >
            <div className="min-h-screen px-4 text-center">
              <TransitionChild
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <DialogBackdrop className="fixed inset-0 bg-black opacity-30" />
              </TransitionChild>

              <span className="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <TransitionChild
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900 mb-2">
                    Edit {_.capitalize(editBackend.credentialType)} Backend
                  </DialogTitle>
                  <div className="mt-2">
                    <CredentialsForm
                      formData={formData[editBackend.credentialType]}
                      currentValues={editBackend.credentials}
                      handleError={() => {
                        setIsEditModalOpen(false)
                        window.toastr.error('Failed to update backend')
                      }}
                      handleSuccess={() => {
                        setIsEditModalOpen(false)
                        window.toastr.success('Backend updated successfully')
                        refetchDataCredentials()
                      }}
                      handleCancel={() => setIsEditModalOpen(false)}
                      uuid={editBackend.uuid}
                      credentialType={editBackend.credentialType}
                    />
                  </div>
                </div>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition>
      )}

      <Transition appear show={isCreateModalOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setIsCreateModalOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <TransitionChild
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <DialogBackdrop className="fixed inset-0 bg-black opacity-30" />
            </TransitionChild>

            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <TransitionChild
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900 mb-2">
                  Add {createBackend?.label} Backend
                </DialogTitle>
                <div className="mt-2">
                  <CredentialsForm
                    formData={formData[createBackend?.value]}
                    currentValues={[[]]}
                    handleError={() => {
                      setIsCreateModalOpen(false)
                      window.toastr.error('Failed to add backend')
                    }}
                    handleSuccess={() => {
                      setIsCreateModalOpen(false)
                      window.toastr.success('Backend added successfully')
                      refetchDataCredentials()
                    }}
                    handleCancel={() => setIsCreateModalOpen(false)}
                    credentialType={createBackend?.value}
                  />
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default DataBackendForm

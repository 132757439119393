import React, { useEffect, useRef, useState } from 'react'
import PromptInput from './Chat/PromptInput'
import { CompanyAgentFieldsFragment } from '../../graphql'
import { useHistory } from 'react-router-dom'
import { AgentFieldsFragment } from '../../graphql'
import { useChat } from '../hooks/useChat'
import MessageThread from './SidebarChat/MessageThread'
import LinearProgress from '@mui/material/LinearProgress'

interface AgentChatProps {
  agent: AgentFieldsFragment | CompanyAgentFieldsFragment
  initialThreadUuid?: string
  initialQuery?: string
  acceptFileUploads?: boolean
  backgroundColor?: string
  showName?: boolean
  useUrlParams?: boolean
}
const AgentChat = ({
  agent,
  initialThreadUuid = '',
  initialQuery = '',
  acceptFileUploads = false,
  backgroundColor = 'bg-white',
  showName = false,
  useUrlParams = false,
}: AgentChatProps) => {
  const history = useHistory()
  const divRef = useRef<HTMLDivElement>(null)
  const [firstQuerySubmitted, setFirstQuerySubmitted] = useState(false)
  const { chatState, handleSubmit, isChatReady } = useChat({
    agent,
    initialThreadUuid,
    useUrlParams,
  })

  // 1. If we have an initial query, and we're not already querying, submit it
  useEffect(() => {
    if (!chatState) {
      return
    }

    if (
      initialQuery &&
      !chatState.isQuerying &&
      chatState.messages.length === 0 &&
      !firstQuerySubmitted &&
      isChatReady
    ) {
      setFirstQuerySubmitted(true)
      handleSubmit(initialQuery)
    }
  }, [chatState, isChatReady, initialQuery])

  // 2. If we're using url params, put the threadUuid in the url
  useEffect(() => {
    if (useUrlParams && chatState?.threadUuid) {
      const searchParams = new URLSearchParams(location.search)
      const currentThreadUuid = searchParams.get('thread_uuid')
      if (chatState.threadUuid !== currentThreadUuid) {
        searchParams.set('thread_uuid', chatState.threadUuid)
        history.push(`?${searchParams.toString()}`)
      }
    }
  }, [chatState])

  // 3. if we're using url params, remove the thread_uuid param if there's no thread
  useEffect(() => {
    if (useUrlParams && !chatState?.threadUuid) {
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete('thread_uuid')
      history.push(`?${searchParams.toString()}`)
    }
  }, [chatState?.threadUuid])

  // 3. Scroll to the bottom of the chat
  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [chatState])

  // If loading or not yet initialized, show a spinner
  if (!chatState) {
    return <LinearProgress />
  }

  // Now it's safe to destructure from chatState
  const { isQuerying } = chatState

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow">
        <div role="list" className="space-y-6 p-8">
          <MessageThread chatState={chatState} showName={showName} />
        </div>
        <div ref={divRef}></div>
      </div>

      <div className="flex flex-row p-4 px-10 pb-5 justify-center">
        <div className="flex-grow max-w-3xl">
          <PromptInput
            isQuerying={isQuerying}
            handleSubmit={handleSubmit}
            backgroundColor={backgroundColor}
            allowFileUpload={acceptFileUploads}
          />
        </div>
      </div>
    </div>
  )
}

export default AgentChat

export const daysOfWeek = [
  { name: 'Sunday', value: 0 },
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 },
]

export const hours = Array.from({ length: 24 }, (_, i) => {
  const hour = i % 12 === 0 ? 12 : i % 12
  const period = i < 12 ? 'AM' : 'PM'
  return { label: `${hour.toString().padStart(2, '0')} ${period}`, value: i }
})

export const parseCronExpression = (cron: string) => {
  const [, hour, dayOfMonth, month, dayOfWeek] = cron.split(' ')
  if (dayOfMonth !== '*' && month === '*' && dayOfWeek === '*') {
    return { timeFrame: 'monthly', hour: Number(hour), dayOfMonth: Number(dayOfMonth) }
  } else if (dayOfWeek !== '*' && month === '*' && dayOfMonth === '*') {
    return { timeFrame: 'weekly', hour: Number(hour), dayOfWeek: Number(dayOfWeek) }
  } else if (dayOfMonth !== '*' && month === '*/3') {
    return { timeFrame: 'quarterly', hour: Number(hour), dayOfQuarter: Number(dayOfMonth) }
  } else if (dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
    return { timeFrame: 'daily', hour: Number(hour) }
  }
  return {}
}

import React from 'react'
import DynamicForm, { FieldType } from '../../../components/DynamicForm'
import { UPDATE_AGENT_PROMPTS_MUTATION } from '../../../graphql/queries/agent'
import { useMutation } from '@apollo/client'

type PromptFormProps = {
  agentUuid: string
  prompts: string[]
  onSaved?: () => void
}

const PromptForm = ({ agentUuid, prompts, onSaved }: PromptFormProps) => {
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const [updateAgentPrompts] = useMutation(UPDATE_AGENT_PROMPTS_MUTATION)
  const formData = [
    {
      name: 'prompts',
      label: 'Prompts',
      type: FieldType.StringList,
      required: false,
      defaultValue: [''],
    },
  ]

  const handleSubmit = async (values: { prompts: string[] }) => {
    const response = await updateAgentPrompts({
      variables: { uuid: agentUuid, prompts: values.prompts },
    })
    if (response.data.updateAgentPrompts.errors.length > 0) {
      window.toastr.error('Error updating agent')
    } else {
      window.toastr.success('Agent updated successfully')
      onSaved()
    }
  }

  return (
    <div className="max-w-xl">
      <DynamicForm
        ref={formRef}
        formData={formData}
        initialData={{
          prompts,
        }}
        handleSubmit={handleSubmit}
      />
      <button
        type="submit"
        onClick={() => formRef.current?.submitForm()}
        className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-flowmo-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-flowmo-blue-800"
      >
        Save
      </button>
    </div>
  )
}

export default PromptForm

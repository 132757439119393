import React from 'react'
import Message from '../Chat/Message'
import RunStep from '../../pages/app/OneAgentChat/RunStep'
import {
  AgentFieldsFragment,
  CompanyAgentFieldsFragment,
  MessageStatusEnum,
} from '../../../graphql'
import SideEffect from '../SideEffect'
import { ConversationMessage, ConversationSideEffect, OneChatProps } from '../../slices/chatSlice'
import ThreeDots from '../../../../components/common/ui/ThreeDots'
import { ConversationItem } from '../../slices/chatSlice'

interface BaseMessageThreadProps {
  conversation: ConversationItem[]
  agent: AgentFieldsFragment | CompanyAgentFieldsFragment
  showName: boolean
  showVoting: boolean
}

export const BaseMessageThread = ({
  conversation,
  agent,
  showName,
  showVoting,
}: BaseMessageThreadProps) => {
  return (
    <div className="flex flex-col">
      {conversation.map((item, index) => {
        const previousItem = index > 0 ? conversation[index - 1] : null
        const showNextName = !previousItem || previousItem.role !== item.role

        if (item.type === 'text') {
          return (
            <Message
              agent={agent}
              uuid={item.uuid}
              actor={item.role}
              createdAt={item.createdAt}
              message={item.content as ConversationMessage}
              key={index}
              showName={showName && showNextName}
              showVoting={showVoting}
              inProgress={item.status === MessageStatusEnum.InProgress}
            />
          )
        } else if (item.type === 'step') {
          return (
            <RunStep
              agent={agent}
              conversationItem={item}
              key={index}
              showName={showName && showNextName}
            />
          )
        } else if (item.type === 'side_effect') {
          const sideEffect = item.content as ConversationSideEffect
          return <SideEffect sideEffect={sideEffect} key={index} />
        }
        return null
      })}
    </div>
  )
}

interface MessageThreadProps {
  chatState: OneChatProps
  showName?: boolean
  showVoting?: boolean
}

const MessageThread = ({ chatState, showName = true, showVoting = true }: MessageThreadProps) => {
  if (!chatState) {
    return <></>
  }
  const { isQuerying, agent, chatStatusDescription, conversation } = chatState

  return (
    <div className="flex flex-col space-y-2">
      <BaseMessageThread
        conversation={conversation}
        agent={agent}
        showName={showName}
        showVoting={showVoting}
      />
      {isQuerying && chatStatusDescription && (
        <div className="flex flex-row space-x-2 text-gray-500">
          <ThreeDots />
          <div className="">{chatStatusDescription}</div>
        </div>
      )}
    </div>
  )
}

export default MessageThread

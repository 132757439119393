import React from 'react'
import {
  TextContent as TextContentType,
  ImageContent as ImageContentType,
} from 'app/javascript/components/graphql'
import TextContent from './TextContent'
import ImageContent from './ImageContent'
import LinearProgress from '@mui/material/LinearProgress'

interface MessageBodyProps {
  messages: (TextContentType | ImageContentType)[]
  inProgress?: boolean
}

const MessageBody = ({ messages, inProgress = false }: MessageBodyProps) => {
  return (
    <div className="flex flex-col min-w-32">
      <div className="flex flex-col space-x-2">
        {messages &&
          messages.map((content, index) => {
            return (
              <div key={index}>
                {content.type === 'text' ? (
                  <TextContent content={content as TextContentType} />
                ) : content.type === 'image_file' ? (
                  <ImageContent content={content as ImageContentType} />
                ) : null}
              </div>
            )
          })}
      </div>

      {inProgress && (
        <div className="text-sm min-w-sm max-w-sm text-gray-500">
          <LinearProgress />
        </div>
      )}
    </div>
  )
}

export default MessageBody

import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import * as Sentry from '@sentry/browser'

const uploadLink = createUploadLink({
  uri: `/graphql`,
  credentials: 'include',
  fetch: (uri: RequestInfo, options: RequestInit = {}) => {
    const abortController = new AbortController()
    options.signal = abortController.signal
    return fetch(uri, options).catch((error) => {
      if (error.name === 'AbortError') {
        // Ignore abort errors and return a never-resolving promise
        return new Promise(() => null)
      }
      throw error
    })
  },
})

const httpLink = createHttpLink({ uri: '/open_graphql' })

const errorLink = onError(({ graphQLErrors, operation }) => {
  for (let i = 0; i < graphQLErrors?.length; i++) {
    const { message, path } = graphQLErrors[i]
    Sentry.setContext(`graphql errors[${i}]`, { message, path })
  }

  Sentry.setContext('operation', {
    operationName: operation.operationName,
    variables: operation.variables,
  })
})

const client = new ApolloClient({
  link: from([errorLink, uploadLink]),
  cache: new InMemoryCache(),
})

const openClient = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(),
})

export default client
export { openClient }

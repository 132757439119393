import React from 'react'
import AgentImage from '../../../components/AgentImage'
import { AgentFieldsFragment, CompanyAgentFieldsFragment } from '../../../../graphql'
import StepChip from './StepChip'
import { ConversationItem, ConversationSteps } from '../../../slices/chatSlice'
import ParticipantName from '../../../components/Chat/ParticipantName'

interface RunStepProps {
  agent: AgentFieldsFragment | CompanyAgentFieldsFragment
  conversationItem: ConversationItem
  showName?: boolean
}

const RunStep: React.FC<RunStepProps> = ({ agent, conversationItem, showName }) => {
  if (!agent) {
    return <></>
  }

  let agentImmage = null
  if (agent.__typename != 'CompanyAgent') {
    agentImmage = <AgentImage agent={agent} className="size-6 rounded-full" />
  }

  let agentFirstName = ''
  if (agent.__typename !== 'CompanyAgent') {
    agentFirstName = agent.firstName
  }

  const content = conversationItem.content as ConversationSteps

  return (
    <>
      {showName && (
        <ParticipantName agentFirstName={agentFirstName} isUser={false} agentImage={agentImmage} />
      )}
      <div className="flex flex-col">
        <div className="flex flex-wrap">
          {content.steps.map((oneStep, index) => (
            <StepChip key={index} status={conversationItem.status} step={oneStep} />
          ))}
        </div>
      </div>
    </>
  )
}

export default RunStep

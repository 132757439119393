import React from 'react'
import { BaseMessageThread } from './SidebarChat/MessageThread'
import { AgentFieldsFragment } from '../../graphql'
import { useChat } from '../hooks/useChat'

interface ViewThreadProps {
  agent: AgentFieldsFragment
  threadUuid: string
}

const ViewThread = ({ agent, threadUuid }: ViewThreadProps) => {
  if (!threadUuid) {
    return <></>
  }

  const { chatState } = useChat({
    agent,
    initialThreadUuid: threadUuid,
  })

  if (!chatState) {
    return <></>
  }

  return (
    <BaseMessageThread
      conversation={chatState.conversation}
      agent={agent}
      showName={true}
      showVoting={false}
    />
  )
}

export default ViewThread

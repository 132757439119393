import { clsx } from 'clsx'
import React from 'react'
import { useMutation } from '@apollo/client'
import { MUTATION_REBUILD_AGENT } from '../../../graphql/queries/agent'
import { RefreshCcw } from 'lucide-react'

interface BuildStatusProps {
  uuid: string
  buildStatus: string
  onUpdate: () => void
}

const BuildStatus = ({ uuid, buildStatus, onUpdate }: BuildStatusProps) => {
  const [rebuildAgent] = useMutation(MUTATION_REBUILD_AGENT)

  let color = 'green'
  if (buildStatus == 'queued') {
    color = 'yellow'
  } else if (buildStatus == 'building') {
    color = 'green'
  }

  const isDisabled = buildStatus == 'queued' || buildStatus == 'building'

  const handleClick = () => {
    rebuildAgent({ variables: { uuid } }).then(() => {
      window.toastr.success('Agent rebuild started')
      onUpdate()
    })
  }

  return (
    <div className="flex flex-row justify-center items-center">
      {isDisabled ? (
        <span
          className={clsx(
            'inline-flex items-center rounded-md py-2',
            'bg-' + color + '-100',
            'px-2 py-1 text-xs font-medium',
            'text-' + color + '-800',
            'ring-1 ring-inset',
            'ring-' + color + '-600/20'
          )}
        >
          <span className="font-bold">Build Status</span>: {buildStatus || 'complete'}
        </span>
      ) : (
        <button
          onClick={handleClick}
          className={clsx(
            'flex flex-row space-x-2 py-2.5 items-center px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 focus:z-10 focus:ring-4 focus:ring-gray-200 ',
            !isDisabled && ' hover:bg-gray-100 hover:text-blue-700 cursor-pointer'
          )}
          disabled={isDisabled}
        >
          <span>Rebuild</span> <RefreshCcw className="h-4 w-4 text-slate-600" />
        </button>
      )}
    </div>
  )
}

export default BuildStatus

import React, { useState, useEffect } from 'react'
import { FieldData } from '.'
import clsx from 'clsx'
import { parseCronExpression, daysOfWeek, hours } from '../../utils/cronUtils'

interface TimeFrameSelectorProps {
  field: FieldData
  formik: any
}

const daysOfMonth = Array.from({ length: 30 }, (_, i) => i + 1)
const daysOfQuarter = Array.from({ length: 90 }, (_, i) => i + 1)

const TimeFrameSelector: React.FC<TimeFrameSelectorProps> = ({ field, formik }) => {
  const [timeFrame, setTimeFrame] = useState<string | null>(null)
  const [hour, setHour] = useState<number | null>(null)
  const [dayOfWeek, setDayOfWeek] = useState<number | null>(null)
  const [dayOfMonth, setDayOfMonth] = useState<number | null>(null)
  const [dayOfQuarter, setDayOfQuarter] = useState<number | null>(null)

  useEffect(() => {
    const cronValue = formik.values[field.name]
    if (cronValue) {
      const parsedValues = parseCronExpression(cronValue)
      setTimeFrame(parsedValues.timeFrame || null)
      setHour(parsedValues.hour !== undefined ? parsedValues.hour : null)
      setDayOfWeek(parsedValues.dayOfWeek !== undefined ? parsedValues.dayOfWeek : null)
      setDayOfMonth(parsedValues.dayOfMonth !== undefined ? parsedValues.dayOfMonth : null)
      setDayOfQuarter(parsedValues.dayOfQuarter !== undefined ? parsedValues.dayOfQuarter : null)
    }
  }, [])

  useEffect(() => {
    let cron = ''
    switch (timeFrame) {
      case 'daily':
        if (hour !== null) {
          cron = `0 ${hour} * * *`
        }
        break
      case 'weekly':
        if (hour !== null && dayOfWeek !== null) {
          cron = `0 ${hour} * * ${dayOfWeek}`
        }
        break
      case 'monthly':
        if (hour !== null && dayOfMonth !== null) {
          cron = `0 ${hour} ${dayOfMonth} * *`
        }
        break
      case 'quarterly':
        if (hour !== null && dayOfQuarter !== null) {
          cron = `0 ${hour} ${dayOfQuarter} */3 *`
        }
        break
    }
    if (cron) {
      formik.setFieldValue(field.name, cron)
    } else {
      formik.setFieldValue(field.name, '')
    }
  }, [timeFrame, hour, dayOfWeek, dayOfMonth, dayOfQuarter])

  const clearValue = () => {
    setTimeFrame(null)
    setHour(null)
    setDayOfWeek(null)
    setDayOfMonth(null)
    setDayOfQuarter(null)

    formik.setFieldValue(field.name, '')
  }

  const selectCSS =
    'block appearance-none w-full border bg-gray-50 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline text-sm'

  const labelCSS = 'block text-gray-700 text-xs mb-2'

  return (
    <>
      <input
        type="hidden"
        name={field.name}
        value={formik.values[field.name]}
        onBlur={formik.handleBlur}
      />
      <div className="flex flex-row space-x-4">
        <div className="mb-2">
          <div className={clsx('flex flex-row space-x-2', labelCSS)}>
            <div>Time Frame</div>
            {timeFrame && (
              <button type="button" onClick={clearValue} className="text-red-600 hover:underline">
                (clear)
              </button>
            )}
          </div>
          <select
            value={timeFrame || ''}
            onChange={(e) => setTimeFrame(e.target.value)}
            className={selectCSS}
          >
            <option value="" disabled>
              Select a time frame
            </option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="quarterly">Quarterly</option>
          </select>
        </div>

        {timeFrame && (
          <div className="mb-2">
            {timeFrame === 'daily' && (
              <div className="mb-2">
                <label className={clsx(labelCSS)}>Hour</label>
                <select
                  value={hour || ''}
                  onChange={(e) => setHour(Number(e.target.value))}
                  className={selectCSS}
                >
                  <option value="" disabled>
                    Select an hour
                  </option>
                  {hours.map((hour) => (
                    <option key={hour.value} value={hour.value}>
                      {hour.label}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {timeFrame === 'weekly' && (
              <div className="mb-2">
                <label className={labelCSS}>Day of the Week</label>
                <select
                  value={dayOfWeek || ''}
                  onChange={(e) => setDayOfWeek(Number(e.target.value))}
                  className={selectCSS}
                >
                  <option value="" disabled>
                    Select a day
                  </option>
                  {daysOfWeek.map((day) => (
                    <option key={day.value} value={day.value}>
                      {day.name}
                    </option>
                  ))}
                </select>
                <label className={clsx(labelCSS, 'mt-2')}>Hour</label>
                <select
                  value={hour || ''}
                  onChange={(e) => setHour(Number(e.target.value))}
                  className={selectCSS}
                >
                  <option value="" disabled>
                    Select an hour
                  </option>
                  {hours.map((hour) => (
                    <option key={hour.value} value={hour.value}>
                      {hour.label}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {timeFrame === 'monthly' && (
              <div className="mb-2">
                <label className={labelCSS}>Day of the Month</label>
                <select
                  value={dayOfMonth || ''}
                  onChange={(e) => setDayOfMonth(Number(e.target.value))}
                  className={selectCSS}
                >
                  <option value="" disabled>
                    Select a day
                  </option>
                  {daysOfMonth.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
                <label className={clsx(labelCSS, 'mt-2')}>Hour</label>
                <select
                  value={hour || ''}
                  onChange={(e) => setHour(Number(e.target.value))}
                  className={selectCSS}
                >
                  <option value="" disabled>
                    Select an hour
                  </option>
                  {hours.map((hour) => (
                    <option key={hour.value} value={hour.value}>
                      {hour.label}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {timeFrame === 'quarterly' && (
              <div className="mb-2">
                <label className={labelCSS}>Day of the Quarter</label>
                <select
                  value={dayOfQuarter || ''}
                  onChange={(e) => setDayOfQuarter(Number(e.target.value))}
                  className={selectCSS}
                >
                  <option value="" disabled>
                    Select a day
                  </option>
                  {daysOfQuarter.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
                <label className={clsx(labelCSS, 'mt-2')}>Hour</label>
                <select
                  value={hour || ''}
                  onChange={(e) => setHour(Number(e.target.value))}
                  className={selectCSS}
                >
                  <option value="" disabled>
                    Select an hour
                  </option>
                  {hours.map((hour) => (
                    <option key={hour.value} value={hour.value}>
                      {hour.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        )}
      </div>
      {formik.touched[field.name] && formik.errors[field.name] ? (
        <div className="text-red-500 text-xs mt-1">{formik.errors[field.name]}</div>
      ) : null}
    </>
  )
}

export default TimeFrameSelector

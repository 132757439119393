import React from 'react'
import EmptyContent from '../EditAgent/EmptyContent'
import clsx from 'clsx'
import {
  AnalysisFieldsFragment,
  AnalysisRunFieldsFragment,
} from 'app/javascript/components/graphql'
import { Link } from 'react-router-dom'
import AgentImage from '../../../components/AgentImage'
import { getRelativeTimeString } from '../../../../common/utils/dateDisplay'
import WebhookResponse from './WebhookResponse'
import TextContent from '../../../components/Chat/TextContent'
import MessageBody from '../../../components/Chat/MessageBody'
import ViewThread from '../../../components/ViewThread'
import { VerticalTabs, TabItem } from '../../../../common/ui/VerticalTabs'
import NivoChart, { ChartConfig } from '../../../components/SideEffect/NivoChart'

interface AnalysisResultsProps {
  analysisRun: AnalysisRunFieldsFragment
  analysis: AnalysisFieldsFragment
}

const AnalysisResults = ({ analysis, analysisRun }: AnalysisResultsProps) => {
  const [selectedTask, setSelectedTask] = React.useState<number | null>()

  if (!analysisRun) {
    return <EmptyContent title={"You've never run this analysis"} />
  }
  const agent = analysis.agent

  const ConversationContent = () => (
    <div>
      <div className="bg-gray-200/20 rounded-xl px-4 py-5 sm:px-6">
        <div className="flex space-x-3">
          <div className="flex-shrink-0">
            <Link to={`/agent/${agent.uuid}`} className="cursor-pointer">
              <AgentImage agent={agent} className="h-10 w-10 rounded-full" />
            </Link>
          </div>
          <div className="min-w-0 flex-1">
            <p className="text-sm font-semibold text-gray-900">
              <Link to={`/agent/${agent.uuid}`} className="hover:underline">
                {agent.label}
              </Link>
            </p>
            <p className="text-sm text-gray-500">{getRelativeTimeString(analysisRun.createdAt)}</p>
          </div>
        </div>
      </div>

      {analysisRun.messageThreadUuid ? (
        <div className="my-2 mt-6">
          <ViewThread agent={agent} threadUuid={analysisRun.messageThreadUuid} />
        </div>
      ) : (
        <div className="">
          <TextContent content={{ type: 'text', value: analysisRun.responseMessage }} />
        </div>
      )}
    </div>
  )

  const ResultsContent = () => (
    <div className="p-4">
      {analysisRun.output?.length > 0 ? (
        <div className="flex flex-col gap-4">
          {analysisRun.output.map((oneContentBlock, index) => {
            if (
              oneContentBlock.__typename === 'TextBlock' ||
              oneContentBlock.__typename === 'MarkdownBlock'
            ) {
              return (
                <TextContent
                  key={index}
                  content={{ type: 'text', value: oneContentBlock.content }}
                />
              )
            } else if (oneContentBlock.__typename === 'ChartBlock') {
              const config: ChartConfig = {
                title: oneContentBlock.title,
                chartType: oneContentBlock.chartType as ChartConfig['chartType'],
                xAxis: oneContentBlock.xAxis,
                yAxis: oneContentBlock.yAxis,
                data: oneContentBlock.data,
                options: oneContentBlock.options,
              }
              console.log(config)
              return <NivoChart key={index} config={config} />
            }
            // TODO: Implement TableContent and ChartContent components
            return null
          })}
        </div>
      ) : (
        <>
          <TextContent content={{ type: 'text', value: analysisRun.responseMessage }} />
        </>
      )}
    </div>
  )

  const TasksContent = () => (
    <div className="p-4">
      <div className="">
        {analysisRun.tasks.map((task, index) => (
          <div className="last:border-b" key={index}>
            <h2
              className={clsx(
                selectedTask == index
                  ? 'bg-flowmo-blue-200/50'
                  : 'hover:bg-flowmo-blue-200/50 cursor-pointer'
              )}
              onClick={() => {
                if (selectedTask == index) {
                  setSelectedTask(null)
                } else {
                  setSelectedTask(index)
                }
              }}
            >
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200"
              >
                <span>{task.name}</span>
                <svg
                  data-accordion-icon
                  className={clsx(
                    selectedTask == index ? 'rotate-0' : 'rotate-180',
                    'w-3 h-3 shrink-0'
                  )}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
            <div className={selectedTask == index ? '' : 'hidden'}>
              <div className="p-5 border border-b-0 border-gray-200">
                <MessageBody key={index} messages={task.response} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const WebhookContent = () => (
    <div className="p-4">
      {analysisRun.webhookUrl && (
        <div className="mt-20">
          <hr className="pb-10" />
          <WebhookResponse
            status={analysisRun.webhookResponse['status']}
            message={analysisRun.webhookResponse['message']}
          />
        </div>
      )}
    </div>
  )

  const tabs: TabItem[] = [
    {
      key: 'results',
      label: 'Results',
      content: <ResultsContent />,
    },
    {
      key: 'conversation',
      label: 'Conversation',
      content: <ConversationContent />,
    },
    {
      key: 'tasks',
      label: 'Tasks',
      content: <TasksContent />,
    },
    {
      key: 'webhook',
      label: 'Webhook',
      content: <WebhookContent />,
    },
  ]

  return <VerticalTabs tabs={tabs} className="mt-4" />
}

export default AnalysisResults

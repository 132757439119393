import React from 'react'
import Spin from '../../../../common/ui/Spin'
import { useQuery } from '@apollo/client'
import { GET_AGENT_QUERY } from '../../../graphql/queries/agent'
import {
  AgentQuery,
  AgentQueryVariables,
  AgentWorkflowsQuery,
  AgentWorkflowsQueryVariables,
  AnalysesQuery,
  AnalysesQueryVariables,
  AgentDataSetsQuery,
  AgentDataSetsQueryVariables,
  AgentDataUnionsQuery,
  AgentDataUnionsQueryVariables,
} from 'app/javascript/components/graphql'
import { Link, useHistory } from 'react-router-dom'
import { LinearProgress } from '@mui/joy'
import { VerticalTabs, TabItem } from '../../../../common/ui/VerticalTabs'
import AgentKnowledge from '../AgentKnowledge'
import { GET_AGENT_WORKFLOWS } from '../../../graphql/queries/workflow'
import { GET_ANALYSES_QUERY } from '../../../graphql/queries/analysis'
import AnalysisCard from '../../../components/AnalysisCard'
import AlertCard from '../../../components/AlertCard'
import EmptyState from '../../../../../components/Walkthrough/ui/EmptyState'
import { GET_AGENT_DATA_SETS } from '../../../graphql/queries/data_set'
import DataSetCard from '../../../components/DataSetCard'
import MainHeader, { Action } from '../../../components/ObjectHeader/MainHeader'
import HeaderActionButton from '../../../components/HeaderActionButton'
import { Pencil, Plus, WandSparkles } from 'lucide-react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import AgentImage from '../../../components/AgentImage'
import { GET_AGENT_DATA_UNIONS } from '../../../graphql/queries/data_union'
import DataUnionCard from '../../../components/DataUnionCard'

const OneAgent = ({ uuid }: { uuid: string }) => {
  const history = useHistory()
  const { user } = useSelector((state: RootState) => state.app)
  const { data, loading } = useQuery<AgentQuery, AgentQueryVariables>(GET_AGENT_QUERY, {
    variables: { uuid },
    fetchPolicy: 'network-only',
  })

  const { data: workflowData, loading: workflowLoading } = useQuery<
    AgentWorkflowsQuery,
    AgentWorkflowsQueryVariables
  >(GET_AGENT_WORKFLOWS, {
    variables: {
      agentUuid: uuid,
    },
    fetchPolicy: 'network-only',
  })
  const { data: analysesData, loading: analysesLoading } = useQuery<
    AnalysesQuery,
    AnalysesQueryVariables
  >(GET_ANALYSES_QUERY, {
    variables: {
      agentUuid: uuid,
    },
    fetchPolicy: 'network-only',
  })

  const { data: dataSetData, loading: dataSetLoading } = useQuery<
    AgentDataSetsQuery,
    AgentDataSetsQueryVariables
  >(GET_AGENT_DATA_SETS, {
    variables: {
      uuid,
    },
    fetchPolicy: 'network-only',
  })

  const { data: dataUnionData, loading: dataUnionLoading } = useQuery<
    AgentDataUnionsQuery,
    AgentDataUnionsQueryVariables
  >(GET_AGENT_DATA_UNIONS, {
    variables: {
      uuid,
    },
    fetchPolicy: 'network-only',
  })

  const workflows = workflowData?.agentWorkflows
  const analyses = analysesData?.analyses
  const agent = data?.agent
  const dataSets = dataSetData?.agentDataSets
  const dataUnions = dataUnionData?.agentDataUnions
  if (loading) {
    return <Spin />
  }

  if (!agent) {
    window.toastr.error('Agent not found')
    history.push('/agents')
    return <></>
  }

  if (agent.isCompany) {
    history.push('/agents')
    return <></>
  }

  document.title = agent.label + ' - Agent - FlowMo'

  let actions: Action[] = []
  if (user.isOrganizationAdmin || agent.createdBy.id === user.id.toString()) {
    actions = [
      {
        name: 'Edit with AI',
        icon: WandSparkles,
        href: (uuid: string) => `/edit/agent/${uuid}`,
      },

      {
        name: 'Edit',
        icon: Pencil,
        href: (uuid: string) => `/agent/edit/${uuid}`,
      },
    ]
  }

  const tabs: TabItem[] = [
    {
      key: 'info',
      label: 'Info',
      content: (
        <div className="flex flex-col">
          <div className="flex flex-row items-center relative">
            <div className="flex-grow flex flex-col space-y-4">
              <p className="flex flex-row space-x-2 font-display text-2xl tracking-tight sm:text-4xl">
                <span className="text-slate-700">Hi, I&apos;m an expert</span>
                <span className="block font-semibold text-flowmo-blue-900">{agent.jobTitle} –</span>
              </p>

              <div className="flex flex-col max-w-2xl">
                <div className="my-4 text-base tracking-tight text-slate-800">
                  {agent.description}
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'analyses',
      label: 'Analyses',
      content: (
        <div className="flex flex-col">
          {analysesLoading ? (
            <Spin />
          ) : (
            <div className="flex flex-col space-y-8">
              {analyses?.map((analysis) => (
                <AnalysisCard key={analysis.uuid} analysis={analysis} size="small" />
              ))}
            </div>
          )}
          {analyses?.length === 0 && (
            <EmptyState title="No analyses" description="No analyses found for this agent." />
          )}
        </div>
      ),
    },
    {
      key: 'alerts',
      label: 'Alerts',
      content: (
        <div className="flex flex-col">
          {workflowLoading ? (
            <LinearProgress />
          ) : (
            <div className="flex flex-col space-y-8">
              {workflows?.map((workflow) => (
                <AlertCard key={workflow.uuid} workflow={workflow} size="small" />
              ))}
            </div>
          )}
          {workflows?.length === 0 && (
            <EmptyState title="No alerts" description="No alerts found for this agent." />
          )}
        </div>
      ),
    },
    {
      key: 'data_model',
      label: 'Data Model  ',
      content: (
        <div className="flex flex-col">
          {dataSetLoading || dataUnionLoading ? (
            <LinearProgress />
          ) : dataSets?.length > 0 || dataUnions?.length > 0 ? (
            <div className="flex flex-col space-y-4">
              <div className="flex flex-wrap gap-4">
                {dataUnions?.map((dataUnion) => (
                  <DataUnionCard key={dataUnion.uuid} dataUnion={dataUnion} />
                ))}
              </div>
              <div className="flex flex-wrap gap-4">
                {dataSets?.map((dataSet) => (
                  <DataSetCard key={dataSet.uuid} dataSet={dataSet} />
                ))}
              </div>
            </div>
          ) : (
            <EmptyState title="No data" description="No data found for this agent." />
          )}
        </div>
      ),
    },
    {
      key: 'knowledge',
      label: 'Knowledge',
      content: <AgentKnowledge agent={agent} />,
    },
  ]

  return (
    <div className="h-full flex flex-col">
      <MainHeader
        object={{
          name: agent.firstName,
          description: agent.jobTitle,
          uuid: agent.uuid,
        }}
        type="agent"
        actions={actions}
        image={<AgentImage agent={agent} className="size-12 rounded-full" />}
      >
        <Link to={'/chat?agent_uuid=' + uuid}>
          <HeaderActionButton label={'New Chat'} icon={<Plus className="h-4 w-4" />} />
        </Link>
      </MainHeader>

      <VerticalTabs tabs={tabs} />
    </div>
  )
}

export default OneAgent

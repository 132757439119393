import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { Bell, ChartArea, Bot, Table, Mail, FileText } from 'lucide-react'
import { Tooltip } from '@mui/joy'

const EmptyWorkspace = () => {
  const { user } = useSelector((state: RootState) => state.app)
  const deliverableClassnames = 'text-gray-500'
  const deliverableIconSize = 16
  const deliverables = [
    {
      type: 'alert',
      name: 'Alert',
      description: 'An alert is a tool that can be used to send alerts.',
      icon: <Bell size={deliverableIconSize} className={deliverableClassnames} />,
    },
    {
      type: 'analysis',
      name: 'Analysis',
      description: 'An analysis is a tool that can be used to perform analysis.',
      icon: <ChartArea size={deliverableIconSize} className={deliverableClassnames} />,
    },
    {
      type: 'agent',
      name: 'Agent',
      description: 'An agent is a tool that can be used to perform tasks.',
      icon: <Bot size={deliverableIconSize} className={deliverableClassnames} />,
    },
    {
      type: 'data_set',
      name: 'Dataset',
      description: 'A dataset is a collection of data that can be used for analysis or training.',
      icon: <Table size={deliverableIconSize} className={deliverableClassnames} />,
    },
    {
      type: 'team_email',
      name: 'Team Email',
      description: 'A team email is a tool that can be used to summarize an email.',
      icon: <Mail size={deliverableIconSize} className={deliverableClassnames} />,
    },
    {
      type: 'report',
      name: 'Report',
      description:
        'A report combines multiple visualizations, tables, and analysis to provide a digest.',
      icon: <FileText size={deliverableIconSize} className={deliverableClassnames} />,
    },
  ]

  return (
    <div className="flex flex-col p-5 h-full justify-center">
      <div className="flex flex-col items-center justify-center font-semibold max-w-lg mx-auto">
        <div className="text-4xl flex-col inline-block overflow-hidden break-words bg-clip-text text-transparent bg-gradient-to-r from-[#217bfe] to-[#ac87eb] via-[#078efb]">
          Hello, {user.firstName} -
        </div>
        <div className="text-xl text-gray-600 text-center">
          Let&apos;s have a quick meeting and get some work done.
        </div>
        <div className="flex flex-wrap  gap-2 mt-12 justify-center">
          {deliverables.map((deliverable) => (
            <div
              key={deliverable.type}
              className="flex flex-col w-48 border rounded-md p-2 cursor-pointer hover:bg-gray-100"
            >
              <Tooltip title={deliverable.description} placement="top">
                <div className="flex flex-row space-x-2 justify-center text-sm items-center">
                  {deliverable.icon}
                  <span>{deliverable.name}</span>
                </div>
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default EmptyWorkspace

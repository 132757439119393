import { useMutation } from '@apollo/client'
import {
  AgentQuery,
  UpdateAgentMutation,
  UpdateAgentMutationVariables,
} from '../../../../../components/graphql'
import React from 'react'
import { UPDATE_AGENT_MUTATION } from '../../../graphql/queries/agent'
import DynamicForm, { FieldType } from '../../../components/DynamicForm'
import { useHistory } from 'react-router-dom'

interface DetailsFormProps {
  agent: AgentQuery['agent']
  onUpdate: () => void
}

const DetailsForm = ({ agent, onUpdate }: DetailsFormProps) => {
  const history = useHistory()
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)

  const [updateAgent] = useMutation<UpdateAgentMutation, UpdateAgentMutationVariables>(
    UPDATE_AGENT_MUTATION
  )

  const handleDetailsEdit = async (values: any) => {
    const response = await updateAgent({
      variables: {
        firstName: values.firstName,
        jobTitle: values.jobTitle,
        shortDescription: values.shortDescription,
        description: values.description,
        primer: values.primer,
        uuid: agent.uuid,
      },
    })
    if (response.data.updateAgent.errors.length > 0) {
      window.toastr.error('Error updating agent')
    } else {
      window.toastr.success('Agent updated successfully')
    }
    onUpdate()
    history.push('/agent/' + agent.uuid)
  }

  const agentDetailsFormData = [
    {
      name: 'firstName',
      label: 'First Name',
      type: FieldType.Text,
      required: true,
      defaultValue: agent.firstName,
    },
    {
      name: 'jobTitle',
      label: 'Job Title',
      type: FieldType.Text,
      required: true,
      defaultValue: agent.jobTitle,
    },
    {
      name: 'shortDescription',
      label: 'Short Description',
      type: FieldType.Text,
      required: true,
      defaultValue: agent.shortDescription,
    },
    {
      name: 'description',
      label: 'Long Description',
      type: FieldType.Textarea,
      required: true,
      defaultValue: agent.description,
    },
    {
      name: 'primer',
      label: 'Instructions',
      type: FieldType.Textarea,
      required: agent.type != 'ExpertAgent',
      defaultValue: agent.primer,
    },
  ]

  return (
    <>
      <DynamicForm ref={formRef} formData={agentDetailsFormData} handleSubmit={handleDetailsEdit} />
      <button
        type="submit"
        onClick={() => formRef.current?.submitForm()}
        className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-flowmo-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-flowmo-blue-800"
      >
        Save
      </button>
    </>
  )
}

export default DetailsForm

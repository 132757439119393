import React from 'react'
import SidebarChat from '../../components/SidebarChat'
import Workspace from '../../components/Workspace'

const Home = () => {
  return (
    <div className="flex flex-row h-full">
      <div className="workspace flex-grow mr-[30em]">
        <Workspace />
      </div>
      <div className="conversation fixed w-[30em] right-0 top-0 pt-16 lg:pt-0 flex flex-col h-full border-l shadow ">
        <SidebarChat />
      </div>
    </div>
  )
}

export default Home

import React from 'react'
import BaseHeader from './BaseHeader'
import { BaseObject } from './types'
import HeaderFlyoutMenu from './HeaderFlyoutMenu'

export interface Action {
  name: string
  icon: React.ComponentType
  href: (uuid: string) => string
}

interface MainHeaderProps {
  object: BaseObject
  type: 'analysis' | 'data_set' | 'agent' | 'alert' | 'data_union'
  children?: React.ReactNode
  actions?: Action[]
  image?: React.ReactNode
}

const MainHeader: React.FC<MainHeaderProps> = ({ object, type, children, actions, image }) => {
  return (
    <BaseHeader
      title={object.name}
      description={object.description}
      rightContent={
        <>
          {children}
          {actions && <HeaderFlyoutMenu actions={actions} uuid={object.uuid} />}
        </>
      }
      type={type}
      uuid={object.uuid}
      image={image}
    />
  )
}

export default MainHeader

import React from 'react'
import PageHeader from '../../components/PageHeader'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import HeaderActionButton from '../../components/HeaderActionButton'
import EmptyState from '../../../../components/Walkthrough/ui/EmptyState'
import DataSetCard from '../../components/DataSetCard'
import DataUnionCard from '../../components/DataUnionCard'
import { GET_ORGANIZATION_DATA_SETS } from '../../graphql/queries/data_set'
import { GET_ORGANIZATION_DATA_UNIONS } from '../../graphql/queries/data_union'
import { useQuery } from '@apollo/client'
import {
  OrganizationDataSetsQuery,
  OrganizationDataSetsQueryVariables,
  OrganizationDataUnionsQuery,
  OrganizationDataUnionsQueryVariables,
} from 'app/javascript/components/graphql'
import LinearProgress from '@mui/material/LinearProgress'
import { Plus } from 'lucide-react'
import Typography from '@mui/material/Typography'

interface SectionHeaderProps {
  title: string
  createPath: string
  createTooltip: string
  showAction: boolean
}

const SectionHeader = ({ title, createPath, createTooltip, showAction }: SectionHeaderProps) => (
  <div className="flex items-center gap-2 mb-4">
    <Typography variant="h6">{title}</Typography>
    {showAction && (
      <Link to={createPath}>
        <HeaderActionButton icon={<Plus className="size-4" />} tooltip={createTooltip} />
      </Link>
    )}
  </div>
)

const DataModel = () => {
  const { user } = useSelector((state: RootState) => state.app)
  const { data: dataSetsData, loading: dataSetsLoading } = useQuery<
    OrganizationDataSetsQuery,
    OrganizationDataSetsQueryVariables
  >(GET_ORGANIZATION_DATA_SETS, {
    fetchPolicy: 'cache-and-network',
  })

  const { data: dataUnionsData, loading: dataUnionsLoading } = useQuery<
    OrganizationDataUnionsQuery,
    OrganizationDataUnionsQueryVariables
  >(GET_ORGANIZATION_DATA_UNIONS, {
    fetchPolicy: 'cache-and-network',
  })

  const data_sets = dataSetsData?.organizationDataSets
  const data_unions = dataUnionsData?.organizationDataUnions
  const loading = dataSetsLoading || dataUnionsLoading

  return (
    <React.Fragment>
      <PageHeader title="Data Model" />
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <div className="mb-12">
            <SectionHeader
              title="Data Unions"
              createPath="/data_union/create"
              createTooltip="Create new data union"
              showAction={user?.isOrganizationAdmin ?? false}
            />
            {!data_unions?.length ? (
              <EmptyState
                title="No data unions yet"
                description="Create your first data union to combine multiple datasets"
              />
            ) : (
              <div className="flex flex-wrap">
                {data_unions.map((data_union) => (
                  <div key={data_union.uuid} className="m-2">
                    <DataUnionCard dataUnion={data_union} />
                  </div>
                ))}
              </div>
            )}
          </div>

          <div>
            <SectionHeader
              title="Data Sets"
              createPath="/data_set/create"
              createTooltip="Create new dataset"
              showAction={user?.isOrganizationAdmin ?? false}
            />
            {!data_sets?.length ? (
              <EmptyState
                title="No data sets yet"
                description="Create your first data set and share it with your organization"
              />
            ) : (
              <div className="flex flex-wrap">
                {data_sets.map((data_set) => (
                  <div key={data_set.uuid} className="m-2">
                    <DataSetCard dataSet={data_set} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default DataModel

import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@headlessui/react'
import { StepType } from '../../../utils/planTypes'
import { isDataSet, isAgentStep, isAnalysisStep, isDataUnionStep } from '../CreateList'

interface ViewStepProps {
  step: StepType
}

const getStepPath = (step: StepType): string => {
  if (isDataSet(step)) {
    return 'data_set'
  }
  if (isAgentStep(step)) {
    return 'agent'
  }
  if (isAnalysisStep(step)) {
    return 'analysis'
  }
  if (isDataUnionStep(step)) {
    return 'data_union'
  }
  return 'alert'
}

const ViewStep = ({ step }: ViewStepProps): JSX.Element => {
  const path = getStepPath(step)

  if (step.uuid.type === 'plan') {
    return null
  }
  return (
    <div className="flex flex-row gap-2">
      <Link to={`/${path}/${step.uuid.value}`}>
        <Button className="text-xs border border-gray-300 hover:bg-blue-500 hover:text-white px-4 py-1 rounded-md">
          View
        </Button>
      </Link>
    </div>
  )
}

export default ViewStep

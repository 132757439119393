import React, { useState } from 'react'
import {
  DELETE_ANALYSIS_MUTATION,
  GET_ANALYSIS_QUERY,
  UPDATE_ANALYSIS_MUTATION,
} from '../../graphql/queries/analysis'
import {
  DeleteAnalysisMutation,
  DeleteAnalysisMutationVariables,
  OrganizationAgentsQuery,
  OrganizationAgentsQueryVariables,
  UpdateAnalysisMutation,
  UpdateAnalysisMutationVariables,
  AnalysisQuery,
  AnalysisQueryVariables,
} from '../../../../components/graphql'
import Spin from '../../../../components/common/ui/Spin'
import { useMutation, useQuery } from '@apollo/client'
import DynamicForm from '../../components/DynamicForm'
import { GET_ORGANIZATION_AGENTS_QUERY } from '../../graphql/queries/agent'
import { useHistory } from 'react-router-dom'
import HeaderActionButton from '../../components/HeaderActionButton'
import DeleteModal from '../../components/DeleteModal'
import { analysisFormData } from './CreateAnalysis'
import SubHeader from '../../components/ObjectHeader/SubHeader'
import { Trash } from 'lucide-react'

const EditAnalysis = ({ uuid }: { uuid: string }) => {
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const [isSaving, setIsSaving] = React.useState(false)
  const history = useHistory()
  const { data, loading } = useQuery<AnalysisQuery, AnalysisQueryVariables>(GET_ANALYSIS_QUERY, {
    variables: { uuid },
  })
  const [open, setOpen] = useState(false)

  const { data: agentsData, loading: agentsLoading } = useQuery<
    OrganizationAgentsQuery,
    OrganizationAgentsQueryVariables
  >(GET_ORGANIZATION_AGENTS_QUERY)

  const [updateAnalysis] = useMutation<UpdateAnalysisMutation, UpdateAnalysisMutationVariables>(
    UPDATE_ANALYSIS_MUTATION
  )

  const [deleteAnalysis] = useMutation<DeleteAnalysisMutation, DeleteAnalysisMutationVariables>(
    DELETE_ANALYSIS_MUTATION
  )

  if (loading || agentsLoading) {
    return <Spin />
  }

  const analysis = data?.analysis
  const agents = agentsData?.organizationAgents

  const handleDelete = async () => {
    const response = await deleteAnalysis({
      variables: {
        uuid,
      },
    })
    if (response.data?.deleteAnalysis.errors.length > 0) {
      window.toastr.error('Error deleting analysis')
    } else {
      window.toastr.success('Analysis deleted successfully')
      history.push('/analyses')
    }
    return
  }

  const handleSubmit = async (values: any) => {
    setIsSaving(true)

    const response = await updateAnalysis({
      variables: {
        uuid,
        name: values.name,
        description: values.description,
        instructions: values.instructions,
        outputFormat: values.outputFormat,
        agentUuid: values.agentUuid,
        webhookUrl: values.webhookUrl,
        cronSchedule: values.cronSchedule,
        knowledge: values.knowledge,
        fileAttachments: values.fileAttachments,
        notes: values.notes,
      },
    })
    if (response.data?.updateAnalysis.errors.length > 0) {
      window.toastr.error('Error updating analysis')
      history.push('/analyses')
    } else {
      window.toastr.success('Analysis updated successfully')
      history.push(`/analysis/${uuid}`)
    }
    setIsSaving(false)
    return
  }

  const initialData = {
    name: analysis?.name,
    description: analysis?.description,
    agentUuid: analysis?.agent.uuid,
    instructions: analysis?.instructions,
    outputFormat: analysis?.outputFormat.map((block) => ({
      type: block.type,
      prompt: block.prompt,
    })),
    webhookUrl: analysis?.webhookUrl,
    cronSchedule: analysis?.cronSchedule,
    knowledge: analysis?.knowledge,
    fileAttachments: analysis?.fileAttachments?.map((fa) => fa.uuid),
    notes: analysis?.notes?.map((note) => ({
      uuid: note.uuid,
      note: note.note,
    })),
  }

  const formData = analysisFormData(agents)
  return (
    <>
      <SubHeader
        object={analysis}
        type="analysis"
        subtitle="Edit Analysis"
        rightContent={
          <HeaderActionButton
            onClick={() => setOpen(true)}
            tooltip="Delete"
            icon={<Trash className="size-4" />}
          />
        }
      />
      <DeleteModal
        open={open}
        setOpen={setOpen}
        onDelete={handleDelete}
        title="Delete Analysis"
        buttonLabel="Delete"
        description="Are you sure you want to delete the analysis? All of your data will be permanently removed from our servers forever. This action cannot be undone."
      />
      <div className="mt-10 max-w-2xl">
        <DynamicForm
          ref={formRef}
          formData={formData}
          initialData={initialData}
          handleSubmit={handleSubmit}
        />
        <div className="flex flex-row space-x-4">
          <button
            type="submit"
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-flowmo-blue-600 hover:bg-flowmo-blue-700"
            onClick={() => formRef.current?.submitForm()}
          >
            {isSaving ? <Spin /> : 'Save'}
          </button>
          <button
            type="button"
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-300 hover:bg-gray-500"
            onClick={() => history.push(`/analysis/${uuid}`)}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  )
}
export default EditAnalysis

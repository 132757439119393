import { gql } from '@apollo/client'
import { DATA_UNION_FIELDS } from './fragments'

export const CREATE_DATA_UNION_MUTATION = gql`
  mutation createDataUnion(
    $name: String!
    $description: String!
    $definition: JSON!
    $isEditable: Boolean!
  ) {
    createDataUnion(
      name: $name
      description: $description
      definition: $definition
      isEditable: $isEditable
    ) {
      dataUnion {
        ...DataUnionFields
      }
      success
      errors
    }
  }
  ${DATA_UNION_FIELDS}
`

export const DELETE_DATA_UNION_MUTATION = gql`
  mutation deleteDataUnion($uuid: String!) {
    deleteDataUnion(uuid: $uuid) {
      success
      errors
    }
  }
`

export const UPDATE_DATA_UNION_MUTATION = gql`
  mutation updateDataUnion(
    $uuid: String!
    $name: String!
    $description: String!
    $definition: JSON!
    $isEditable: Boolean!
  ) {
    updateDataUnion(
      uuid: $uuid
      name: $name
      description: $description
      definition: $definition
      isEditable: $isEditable
    ) {
      success
      errors
    }
  }
`

export const GET_DATA_UNION_QUERY = gql`
  query dataUnion($uuid: String!) {
    dataUnion(uuid: $uuid) {
      ...DataUnionFields
    }
  }
  ${DATA_UNION_FIELDS}
`

export const GET_DATA_UNIONS_QUERY = gql`
  query dataUnions($uuids: [String!]!) {
    dataUnions(uuids: $uuids) {
      ...DataUnionFields
    }
  }
  ${DATA_UNION_FIELDS}
`

export const GET_AGENT_DATA_UNIONS = gql`
  query agentDataUnions($uuid: String!) {
    agentDataUnions(uuid: $uuid) {
      ...DataUnionFields
    }
  }
  ${DATA_UNION_FIELDS}
`

export const GET_ORGANIZATION_DATA_UNIONS = gql`
  query organizationDataUnions {
    organizationDataUnions {
      ...DataUnionFields
    }
  }
  ${DATA_UNION_FIELDS}
`

export const ADD_DATA_UNION_TO_AGENT_MUTATION = gql`
  mutation addDataUnionToAgent($agentUuid: String!, $dataUnionUuid: String!) {
    addDataUnionToAgent(agentUuid: $agentUuid, dataUnionUuid: $dataUnionUuid) {
      success
      errors
    }
  }
`

export const REMOVE_DATA_UNION_FROM_AGENT_MUTATION = gql`
  mutation removeDataUnionFromAgent($agentUuid: String!, $dataUnionUuid: String!) {
    removeDataUnionFromAgent(agentUuid: $agentUuid, dataUnionUuid: $dataUnionUuid) {
      success
      errors
    }
  }
`

import React from 'react'
import { Marked } from 'marked'
import { markedHighlight } from 'marked-highlight'
import hljs from 'highlight.js'
// @ts-ignore
import hlsjLookML from 'highlightjs-lookml'
import { AgentFile, TextContent as TextContentType } from '../../../../components/graphql'

hljs.registerLanguage('lookml', hlsjLookML)

const marked = new Marked(
  markedHighlight({
    langPrefix: 'hljs border rounded shadow syntax-highlighter language-',
    highlight(code, lang) {
      const language = hljs.getLanguage(lang) ? lang : 'plaintext'
      return hljs.highlight(code, { language }).value
    },
  })
)
const processText = (text: string) => {
  if (!text) {
    return text
  }
  const modifiedText = marked.parse(text, {
    gfm: true,
    breaks: true,
  })
  return modifiedText
}

const FileChip = ({ agentFile }: { agentFile: AgentFile }) => {
  if (!agentFile || !agentFile.fileUuid || !agentFile.filePath) {
    return <></>
  }

  const fileUrl = '/app/agent_file/' + agentFile.fileUuid
  const filename = agentFile.filePath.split('/').pop()

  return (
    <div
      className="inline-flex items-center px-3 py-1 border border-gray-300 rounded-full cursor-pointer hover:bg-gray-200"
      onClick={() => window.open(fileUrl, '_blank', 'noopener,noreferrer')}
    >
      <span className="text-sm text-gray-800">{filename}</span>
    </div>
  )
}

const TextContent = ({ content }: { content: TextContentType }) => {
  if (!content || !content.value) {
    return null
  }
  const textValue = content.value
  const files = content.files || []
  return (
    <div className="flex flex-col w-full">
      <div
        className="flex flex-col prose max-w-full text-sm my-5 mx-2 break-words overflow-x-auto"
        dangerouslySetInnerHTML={{ __html: processText(textValue) }}
      ></div>
      <div className="flex flex-row flex-wrap justify-end">
        {files.map((file, index) => (
          <FileChip key={index} agentFile={file} />
        ))}
      </div>
    </div>
  )
}

export default TextContent

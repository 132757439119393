import React from 'react'
import { StepType } from '../../../utils/planTypes'
import {
  isDataSet,
  isAgentStep,
  isAlertStep,
  isAnalysisStep,
  isDataUnionStep,
  GraphQLArtifact,
} from '../CreateList'
import EditStep from './EditStep'
import CreateStep from './CreateStep'
import ViewStep from './ViewStep'

interface StepActionProps {
  step: StepType
  handleArtifactChange: ({ makeConsistent }: { makeConsistent: boolean }) => void
  artifact: GraphQLArtifact
}

const StepAction = ({ handleArtifactChange, step, artifact }: StepActionProps): JSX.Element => {
  if (
    !isDataSet(step) &&
    !isAgentStep(step) &&
    !isAlertStep(step) &&
    !isAnalysisStep(step) &&
    !isDataUnionStep(step)
  ) {
    return null
  }

  return (
    <div className="flex flex-row gap-2 mt-4 justify-center items-center">
      <EditStep step={step} handleArtifactChange={handleArtifactChange} />
      <CreateStep step={step} handleArtifactChange={handleArtifactChange} artifact={artifact} />
      <ViewStep step={step} />
    </div>
  )
}

export default StepAction

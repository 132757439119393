import React from 'react'
import PageHeader from '../../components/PageHeader'
import { OneSection, SectionContainer } from '../../components/SectionContainer'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import DynamicForm, { FieldType } from '../../components/DynamicForm'
import Spin from '../../../../components/common/ui/Spin'
import { useMutation } from '@apollo/client'
import {
  UpdateCurrentUserMutation,
  UpdateCurrentUserMutationVariables,
} from 'app/javascript/components/graphql'
import { UPDATE_CURRENT_USER_MUTATION } from '../../graphql/queries/user'
import { RefetchUserContext } from '../../App'

const Profile = () => {
  const refetchUser = React.useContext(RefetchUserContext)
  const { user } = useSelector((state: RootState) => state.app)
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const [saving, setSaving] = React.useState(false)
  const [updateUserMutation] = useMutation<
    UpdateCurrentUserMutation,
    UpdateCurrentUserMutationVariables
  >(UPDATE_CURRENT_USER_MUTATION)
  const formData = [
    {
      name: 'first_name',
      label: 'First Name',
      type: FieldType.Text,
      required: true,
      defaultValue: user?.firstName,
    },
    {
      name: 'last_name',
      label: 'Last Name',
      type: FieldType.Text,
      required: true,
      defaultValue: user?.lastName,
    },
    {
      name: 'email',
      label: 'Email',
      type: FieldType.Email,
      required: true,
      disabled: true,
      defaultValue: user?.email,
    },
    {
      name: 'password',
      label: 'Password',
      type: FieldType.Password,
      required: false,
      defaultValue: '',
    },
    {
      name: 'password_confirmation',
      label: 'Confirm Password',
      type: FieldType.Password,
      required: false,
      defaultValue: '',
    },
  ]

  const handleSubmit = async (data: any) => {
    if (data.password !== data.password_confirmation) {
      window.toastr.error('Passwords do not match')
      return
    }

    setSaving(true)
    const response = await updateUserMutation({
      variables: {
        firstName: data.first_name,
        lastName: data.last_name,
        password: data.password,
      },
    })
    if (response.data.updateCurrentUser.errors.length > 0) {
      window.toastr.error('There was an error updating your profile')
    } else {
      window.toastr.success('Your profile was updated successfully')
    }
    await refetchUser()
    setSaving(false)
  }

  return (
    <React.Fragment>
      <PageHeader title="Profile" />
      <div className="flex flex-col space-y-5">
        <SectionContainer>
          <OneSection
            title="Personal Information"
            description="You can change your personal information"
          >
            <DynamicForm ref={formRef} formData={formData} handleSubmit={handleSubmit} />
            <button
              type="submit"
              onClick={() => formRef.current?.submitForm()}
              className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-flowmo-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-flowmo-blue-800"
              disabled={saving}
            >
              {saving ? <Spin /> : 'Save'}
            </button>
          </OneSection>
        </SectionContainer>
      </div>
    </React.Fragment>
  )
}

export default Profile

import React from 'react'
import Breadcrumb, { BreadcrumbProps } from './Breadcrumb'
import clsx from 'clsx'

interface PageHeaderProps {
  title?: string | React.ReactNode
  breadCrumbs?: BreadcrumbProps[]
  className?: string
  children?: React.ReactNode
}
const PageHeader = ({ title, breadCrumbs, className, children }: PageHeaderProps) => {
  return (
    <div>
      {breadCrumbs && <Breadcrumb pages={breadCrumbs} />}
      {(title || children) && (
        <div
          className={clsx('flex flex-col md:flex-row items-center justify-center mb-5', className)}
        >
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {title}
            </h2>
          </div>
          <div className="md:ml-4 md:mt-0 flex flex-row justify-center items-center">
            {children}
          </div>
        </div>
      )}
    </div>
  )
}

export default PageHeader

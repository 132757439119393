import { UserIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import React from 'react'

const ParticipantName: React.FC<{
  agentFirstName: string
  isUser: boolean
  agentImage: React.ReactNode
}> = ({ agentFirstName, isUser, agentImage }) => {
  return (
    <div className={clsx('mt-6 flex flex-col', isUser ? 'items-end' : 'items-start')}>
      <div className="flex items-center space-x-2 mb-4">
        {!isUser && agentImage}
        <span className="text-sm font-semibold text-gray-500">
          {isUser ? 'you' : agentFirstName}
        </span>
        {isUser && <UserIcon className="h-5 w-5 text-gray-400" />}
      </div>
    </div>
  )
}

export default ParticipantName

import React from 'react'
import { AgentQuery, AgentQueryVariables } from '../../../graphql'
import { useQuery } from '@apollo/client'
import { GET_AGENT_QUERY } from '../../graphql/queries/agent'

const AgentIntro = ({ agentUuid }: { agentUuid: string }) => {
  const { data, loading } = useQuery<AgentQuery, AgentQueryVariables>(GET_AGENT_QUERY, {
    skip: !agentUuid,
    variables: {
      uuid: agentUuid,
    },
  })
  const agent = data?.agent
  if (loading || !agent) {
    return <></>
  }

  let jobTitle = agent.jobTitle
  let description = agent.description

  if (agent.isCompany) {
    jobTitle = 'Chief of Staff'
    description =
      "I'm here to help you setup FlowMo, and keep an eye on your business. I hope I can save you minutes a day, hours a week and days a month!"
  }

  return (
    <div className="flex flex-row items-center relative ">
      <div className="flex-grow flex flex-col space-y-4">
        <div className="flex flex-wrap justify-center space-x-2 font-display text-2xl w-full max-w-2xl items-center text-center tracking-tight sm:text-4xl">
          <div className="text-slate-700">
            {agent.isCompany ? "Hi, I'm your" : "Hi, I'm an expert"}
          </div>
          <div className="block font-semibold text-flowmo-blue-900">{jobTitle} –</div>
        </div>

        <div className="flex flex-col max-w-2xl items-center text-center">
          <div className="my-4 text-base tracking-tight text-slate-800">{description}</div>
        </div>
      </div>
    </div>
  )
}

export default AgentIntro

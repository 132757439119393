import React from 'react'
import PageHeader from '../../components/PageHeader'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import HeaderActionButton from '../../components/HeaderActionButton'
import EmptyState from '../../../../components/Walkthrough/ui/EmptyState'
import DataUnionCard from '../../components/DataUnionCard'
import { GET_ORGANIZATION_DATA_UNIONS } from '../../graphql/queries/data_union'
import { useQuery } from '@apollo/client'
import {
  OrganizationDataUnionsQuery,
  OrganizationDataUnionsQueryVariables,
} from 'app/javascript/components/graphql'
import LinearProgress from '@mui/material/LinearProgress'
import { Plus } from 'lucide-react'

const DataUnions = () => {
  const { user } = useSelector((state: RootState) => state.app)
  const { data, loading } = useQuery<
    OrganizationDataUnionsQuery,
    OrganizationDataUnionsQueryVariables
  >(GET_ORGANIZATION_DATA_UNIONS, {
    fetchPolicy: 'cache-and-network',
  })
  const data_unions = data?.organizationDataUnions

  return (
    <React.Fragment>
      <PageHeader title="Data Unions">
        {user?.isOrganizationAdmin && (
          <Link to="/data_union/create">
            <HeaderActionButton icon={<Plus className="size-4" />} tooltip="Create new" />
          </Link>
        )}
      </PageHeader>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {data_unions?.length === 0 && (
            <EmptyState
              title="No data unions yet"
              description="Create your first data union to combine multiple datasets"
            />
          )}

          <div className="flex flex-wrap">
            {data_unions?.map((data_union) => (
              <div key={data_union.uuid} className="m-2">
                <DataUnionCard dataUnion={data_union} />
              </div>
            ))}
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default DataUnions

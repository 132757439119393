import React, { useState, useEffect } from 'react'
import { FieldData } from '.'
import clsx from 'clsx'
import { RadioGroupLabel, RadioGroupOption, Tab } from '@headlessui/react'
import { RadioGroup } from '@headlessui/react'

interface DataSetDefinitionFieldProps {
  field: FieldData
  formik: any
}

const BACKEND_TYPES = [
  { value: 'bigquery', label: 'BigQuery' },
  { value: 'snowflake', label: 'Snowflake' },
  { value: 'looker', label: 'Looker' },
]

const TABLE_TYPES = [
  { value: 'existing', label: 'Use Existing Table' },
  { value: 'derived', label: 'Use Derived Table' },
]

const DataSetDefinitionField: React.FC<DataSetDefinitionFieldProps> = ({ field, formik }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [definition, setDefinition] = useState<any>({
    bigquery: {
      dataset: '',
      table: '',
      derived_table: '',
      table_type: 'existing',
    },
    snowflake: {
      schema: '',
      table: '',
      derived_table: '',
      table_type: 'existing',
    },
    looker: {
      exploreUrl: '',
    },
  })

  useEffect(() => {
    try {
      const parsedDefinition = JSON.parse(formik.values[field.name])
      // Set table_type based on whether derived_table is populated
      const updatedDefinition = {
        bigquery: {
          dataset: '',
          table: '',
          derived_table: '',
          table_type: 'existing',
          ...(parsedDefinition.bigquery || {}),
        },
        snowflake: {
          schema: '',
          table: '',
          derived_table: '',
          table_type: 'existing',
          ...(parsedDefinition.snowflake || {}),
        },
        looker: {
          exploreUrl: '',
          ...(parsedDefinition.looker || {}),
        },
      }

      // Update table_type after spreading the parsed definition
      if (parsedDefinition.bigquery?.derived_table) {
        updatedDefinition.bigquery.table_type = 'derived'
      }
      if (parsedDefinition.snowflake?.derived_table) {
        updatedDefinition.snowflake.table_type = 'derived'
      }

      setDefinition(updatedDefinition)

      // Set the selected index based on which backend has data
      if (parsedDefinition.bigquery) {
        setSelectedIndex(0)
      } else if (parsedDefinition.snowflake) {
        setSelectedIndex(1)
      } else if (parsedDefinition.looker) {
        setSelectedIndex(2)
      }
    } catch (e) {
      console.error('Failed to parse definition:', e)
    }
  }, [])

  useEffect(() => {
    // Remove table_type before saving to match the expected schema
    const definitionToSave: any = {}

    // Only include the backend that has actual data (non-empty values)
    const hasBigQueryData = Object.entries(definition.bigquery)
      .filter(([key]) => key !== 'table_type') // Exclude table_type from check
      .some(([, val]) => (val as string) && (val as string).trim() !== '')

    if (hasBigQueryData) {
      definitionToSave.bigquery = {
        dataset: definition.bigquery.dataset,
        table: definition.bigquery.table,
        derived_table: definition.bigquery.derived_table,
      }
    }

    const hasSnowflakeData = Object.entries(definition.snowflake)
      .filter(([key]) => key !== 'table_type') // Exclude table_type from check
      .some(([, val]) => (val as string) && (val as string).trim() !== '')

    if (hasSnowflakeData) {
      definitionToSave.snowflake = {
        schema: definition.snowflake.schema,
        table: definition.snowflake.table,
        derived_table: definition.snowflake.derived_table,
      }
    }

    const hasLookerData = definition.looker.exploreUrl && definition.looker.exploreUrl.trim() !== ''
    if (hasLookerData) {
      definitionToSave.looker = definition.looker
    }

    formik.setFieldValue(field.name, JSON.stringify(definitionToSave))
  }, [definition])

  const handleInputChange = (backend: string, field: string, value: string) => {
    setDefinition((prev: any) => ({
      ...prev,
      [backend]: {
        ...prev[backend],
        [field]: value,
      },
    }))
  }

  const handleTableTypeChange = (backend: string, value: string) => {
    setDefinition((prev: any) => ({
      ...prev,
      [backend]: {
        ...prev[backend],
        table_type: value,
        // Clear the derived_table when switching to existing table
        derived_table: value === 'existing' ? '' : prev[backend].derived_table,
      },
    }))
  }

  const renderTableTypeSelector = (backend: string) => (
    <div className="mb-6">
      <label className="block text-sm font-medium text-gray-700 mb-2">Table Type</label>
      <RadioGroup
        value={definition[backend].table_type}
        onChange={(value) => handleTableTypeChange(backend, value)}
        className="flex space-x-4"
      >
        {TABLE_TYPES.map((type) => (
          <RadioGroupOption
            key={type.value}
            value={type.value}
            className={({ checked }) =>
              clsx(
                'relative flex cursor-pointer rounded-lg px-5 py-2 shadow-md focus:outline-none',
                checked
                  ? 'bg-flowmo-blue-600 text-white'
                  : 'bg-white text-gray-900 hover:bg-gray-100'
              )
            }
          >
            {({ checked }) => (
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="text-sm">
                    <RadioGroupLabel
                      as="p"
                      className={clsx('font-medium', checked ? 'text-white' : 'text-gray-900')}
                    >
                      {type.label}
                    </RadioGroupLabel>
                  </div>
                </div>
              </div>
            )}
          </RadioGroupOption>
        ))}
      </RadioGroup>
    </div>
  )

  const renderBigQueryFields = () => (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Dataset</label>
        <input
          type="text"
          value={definition.bigquery.dataset}
          onChange={(e) => handleInputChange('bigquery', 'dataset', e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Enter dataset name"
        />
      </div>
      {renderTableTypeSelector('bigquery')}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          {definition.bigquery.table_type === 'existing' ? 'Table Name' : 'Derived Table Name'}
        </label>
        <input
          type="text"
          value={definition.bigquery.table}
          onChange={(e) => handleInputChange('bigquery', 'table', e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder={
            definition.bigquery.table_type === 'existing'
              ? 'Enter existing table name'
              : 'Enter name for derived table'
          }
        />
      </div>
      {definition.bigquery.table_type === 'derived' && (
        <div>
          <label className="block text-sm font-medium text-gray-700">SQL Query</label>
          <textarea
            value={definition.bigquery.derived_table}
            onChange={(e) => handleInputChange('bigquery', 'derived_table', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            rows={4}
            placeholder="Enter SQL query for derived table"
          />
        </div>
      )}
    </div>
  )

  const renderSnowflakeFields = () => (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Schema</label>
        <input
          type="text"
          value={definition.snowflake.schema}
          onChange={(e) => handleInputChange('snowflake', 'schema', e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Enter schema name"
        />
      </div>
      {renderTableTypeSelector('snowflake')}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          {definition.snowflake.table_type === 'existing' ? 'Table Name' : 'Derived Table Name'}
        </label>
        <input
          type="text"
          value={definition.snowflake.table}
          onChange={(e) => handleInputChange('snowflake', 'table', e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder={
            definition.snowflake.table_type === 'existing'
              ? 'Enter existing table name'
              : 'Enter name for derived table'
          }
        />
      </div>
      {definition.snowflake.table_type === 'derived' && (
        <div>
          <label className="block text-sm font-medium text-gray-700">SQL Query</label>
          <textarea
            value={definition.snowflake.derived_table}
            onChange={(e) => handleInputChange('snowflake', 'derived_table', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            rows={4}
            placeholder="Enter SQL query for derived table"
          />
        </div>
      )}
    </div>
  )

  const renderLookerFields = () => (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Explore URL</label>
        <input
          type="text"
          value={definition.looker.exploreUrl}
          onChange={(e) => handleInputChange('looker', 'exploreUrl', e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Enter Looker explore URL"
        />
      </div>
    </div>
  )

  return (
    <div>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
          {BACKEND_TYPES.map((backend) => (
            <Tab
              key={backend.value}
              className={({ selected }) =>
                clsx(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow text-blue-700'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              {backend.label}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel>
            <div className="mt-4">{renderBigQueryFields()}</div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="mt-4">{renderSnowflakeFields()}</div>
          </Tab.Panel>
          <Tab.Panel>
            <div className="mt-4">{renderLookerFields()}</div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      {formik.touched[field.name] && formik.errors[field.name] ? (
        <div className="text-red-500 text-xs mt-1">{formik.errors[field.name]}</div>
      ) : null}
    </div>
  )
}

export default DataSetDefinitionField

import React from 'react'
import { AddPluginCard, EditPluginCard } from '../../../components/PluginCard/PluginCard'
import EmptyContent from './EmptyContent'
import WalkthroughCard from '../../../components/WalkthroughCard'
import SkillsetCard from '../../../components/SkillsetCard'
import {
  AgentDataSetsQuery,
  AgentDataSetsQueryVariables,
  AgentQuery,
  PluginTemplatesQuery,
  PluginTemplatesQueryVariables,
} from '../../../../../components/graphql'
import { GET_PLUGIN_TEMPLATES_QUERY } from '../../../graphql/queries/agent'
import { GET_AGENT_DATA_SETS } from '../../../graphql/queries/data_set'
import { useQuery } from '@apollo/client'
import Spin from '../../../../../components/common/ui/Spin'
import DataSetCard from '../../../components/DataSetCard'

const Data = ({ agent, onUpdate }: { agent: AgentQuery['agent']; onUpdate: () => void }) => {
  const {
    data: pluginTemplatesData,
    loading: pluginTemplatesLoading,
    refetch: refetchPluginTemplates,
  } = useQuery<PluginTemplatesQuery, PluginTemplatesQueryVariables>(GET_PLUGIN_TEMPLATES_QUERY, {
    fetchPolicy: 'network-only',
  })

  const {
    data: dataSetsData,
    loading: dataSetsLoading,
    refetch: refetchDataSets,
  } = useQuery<AgentDataSetsQuery, AgentDataSetsQueryVariables>(GET_AGENT_DATA_SETS, {
    variables: { uuid: agent.uuid },
    fetchPolicy: 'network-only',
  })

  const handleEdit = () => {
    refetchPluginTemplates()
    refetchDataSets()
    onUpdate()
  }

  return (
    <div className="flex flex-col space-y-16">
      <div className="flex flex-col space-y-4">
        <h3 className="text-xl font-semibold mb-5 pb-5 border-b">Content</h3>
        <div className="flex flex-col ml-12 space-y-4">
          <h4 className="text-lg font-semibold">Walkthroughs</h4>
          <div className="flex flex-wrap">
            {agent.walkthroughs.length == 0 ? (
              <EmptyContent title="No Walkthroughs added" />
            ) : (
              agent.walkthroughs.map((walkthrough) => {
                return (
                  <div className="m-2" key={walkthrough.id}>
                    <WalkthroughCard walkthrough={walkthrough} />
                  </div>
                )
              })
            )}
          </div>
        </div>
        <div className="flex flex-col ml-12 space-y-4">
          <h4 className="text-lg font-semibold">Skill Sets</h4>
          {agent.skillSets && agent.skillSets.length > 0 ? (
            agent.skillSets.map((skillset) => {
              return (
                <div className="m-2 w-64" key={skillset.id}>
                  <SkillsetCard skillset={skillset} />
                </div>
              )
            })
          ) : (
            <EmptyContent title="No Skill Sets added" />
          )}
        </div>
      </div>

      <div className="flex flex-col space-y-4">
        <h3 className="text-xl font-semibold mb-5 pb-5 border-b">Data Sets</h3>
        <div className="flex flex-wrap">
          {dataSetsLoading && <Spin />}
          {dataSetsData?.agentDataSets && dataSetsData.agentDataSets.length > 0 ? (
            dataSetsData.agentDataSets.map((dataSet) => {
              return (
                <div key={dataSet.uuid} className="m-2">
                  <DataSetCard dataSet={dataSet} />
                </div>
              )
            })
          ) : (
            <EmptyContent title="No Data Sets added" />
          )}
        </div>
      </div>

      <div className="flex flex-col space-y-4">
        <h3 className="text-xl font-semibold mb-5 pb-5 border-b">Data Plugins</h3>
        <div className="flex flex-col ml-12 space-y-4">
          <h4 className="text-lg font-semibold">Installed Plugins</h4>
          <div className="flex flex-row flex-wrap">
            {agent.plugins && agent?.plugins.length > 0 ? (
              agent.plugins.map((plugin) => {
                return (
                  <div className="m-2" key={plugin.id}>
                    <EditPluginCard onAction={handleEdit} plugin={plugin} />
                  </div>
                )
              })
            ) : (
              <EmptyContent title="No plugins installed" />
            )}
          </div>
        </div>

        <div className="flex flex-col ml-12 space-y-4">
          <h4 className="text-lg font-semibold">Plugin Library</h4>
          <div className="flex flex-row flex-wrap">
            {pluginTemplatesLoading && <Spin />}
            {pluginTemplatesData?.pluginTemplates &&
              pluginTemplatesData?.pluginTemplates.map((pluginTemplate) => {
                if (
                  pluginTemplate.onePerAgent &&
                  agent.plugins?.some((plugin) => plugin.pluginType == pluginTemplate.id)
                ) {
                  return null
                }
                return (
                  <div className="m-2" key={pluginTemplate.id}>
                    <AddPluginCard
                      agentUuid={agent.uuid}
                      key={pluginTemplate.id}
                      pluginTemplate={pluginTemplate}
                      onAction={handleEdit}
                    />
                  </div>
                )
              })}
            {!pluginTemplatesData?.pluginTemplates && <EmptyContent title="No plugins available" />}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Data

import React, { useEffect, useState } from 'react'
import { Button } from '@headlessui/react'
import { useMutation, useQuery } from '@apollo/client'
import Spin from '../../../../common/ui/Spin'
import { StepType, Task } from '../../../utils/planTypes'
import { isDataSet, isAgentStep, isAlertStep, isAnalysisStep, isDataUnionStep } from '../CreateList'
import { UPDATE_ANALYSIS_MUTATION, GET_ANALYSIS_QUERY } from '../../../graphql/queries/analysis'
import { UPDATE_WORKFLOW_MUTATION, GET_WORKFLOW_QUERY } from '../../../graphql/queries/workflow'
import { UPDATE_AGENT_MUTATION, GET_AGENT_QUERY } from '../../../graphql/queries/agent'
import { UPDATE_DATA_SET_MUTATION, GET_DATA_SET_QUERY } from '../../../graphql/queries/data_set'
import {
  ADD_DATASET_TO_AGENT_MUTATION,
  REMOVE_DATASET_FROM_AGENT_MUTATION,
} from '../../../graphql/queries/data_set'
import {
  ADD_DATA_UNION_TO_AGENT_MUTATION,
  REMOVE_DATA_UNION_FROM_AGENT_MUTATION,
  GET_DATA_UNION_QUERY,
  UPDATE_DATA_UNION_MUTATION,
} from '../../../graphql/queries/data_union'
import {
  UPDATE_TASK_MUTATION,
  CREATE_TASK_MUTATION,
  DELETE_TASK_MUTATION,
} from '../../../graphql/queries/task'
import {
  AgentQueryVariables,
  AnalysisQuery,
  AnalysisQueryVariables,
  DataSetQuery,
  DataSetQueryVariables,
  DataUnionQuery,
  DataUnionQueryVariables,
  AgentQuery,
  WorkflowQuery,
  WorkflowQueryVariables,
  UpdateTaskMutation,
  UpdateTaskMutationVariables,
  CreateTaskMutation,
  CreateTaskMutationVariables,
  DeleteTaskMutation,
  DeleteTaskMutationVariables,
  TaskFieldsFragment,
} from 'app/javascript/components/graphql'
import {
  convertToAgentPlan,
  convertToAlertPlan,
  convertToAnalysisPlan,
  convertToDataSetPlan,
  convertToDataUnionPlan,
  isEqual,
} from '../../../utils/planConversion'

interface EditStepProps {
  step: StepType
  handleArtifactChange: (args: { makeConsistent: boolean }) => void
}

const EditStep = ({ step, handleArtifactChange }: EditStepProps): JSX.Element | null => {
  const [hasChanges, setHasChanges] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  // Query hooks moved before conditional return
  const { data: analysisData, refetch: refetchAnalysis } = useQuery<
    AnalysisQuery,
    AnalysisQueryVariables
  >(GET_ANALYSIS_QUERY, {
    variables: { uuid: step.uuid.type === 'backend' ? step.uuid.value : '' },
    skip: step.type !== 'analysis' || step.uuid.type !== 'backend',
  })

  const { data: workflowData, refetch: refetchWorkflow } = useQuery<
    WorkflowQuery,
    WorkflowQueryVariables
  >(GET_WORKFLOW_QUERY, {
    variables: { uuid: step.uuid.type === 'backend' ? step.uuid.value : '' },
    skip: step.type !== 'alert' || step.uuid.type !== 'backend',
  })

  const { data: dataSetData, refetch: refetchDataSet } = useQuery<
    DataSetQuery,
    DataSetQueryVariables
  >(GET_DATA_SET_QUERY, {
    variables: { uuid: step.uuid.type === 'backend' ? step.uuid.value : '' },
    skip: step.type !== 'data_set' || step.uuid.type !== 'backend',
  })

  const { data: dataUnionData, refetch: refetchDataUnion } = useQuery<
    DataUnionQuery,
    DataUnionQueryVariables
  >(GET_DATA_UNION_QUERY, {
    variables: { uuid: step.uuid.type === 'backend' ? step.uuid.value : '' },
    skip: step.type !== 'data_union' || step.uuid.type !== 'backend',
  })

  const { data: agentData, refetch: refetchAgent } = useQuery<AgentQuery, AgentQueryVariables>(
    GET_AGENT_QUERY,
    {
      variables: { uuid: step.uuid.type === 'backend' ? step.uuid.value : '' },
      skip: step.type !== 'agent' || step.uuid.type !== 'backend',
    }
  )

  // Effect to handle backend data comparison - moved before conditional return
  useEffect(() => {
    if (step.uuid.type !== 'backend') {
      setHasChanges(false)
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    let convertedPlan = null

    if (step.type === 'analysis' && analysisData?.analysis) {
      convertedPlan = convertToAnalysisPlan(analysisData.analysis)
    } else if (step.type === 'alert' && workflowData?.workflow) {
      convertedPlan = convertToAlertPlan(workflowData.workflow)
    } else if (step.type === 'data_set' && dataSetData?.dataSet) {
      convertedPlan = convertToDataSetPlan(dataSetData.dataSet)
    } else if (step.type === 'data_union' && dataUnionData?.dataUnion) {
      convertedPlan = convertToDataUnionPlan(dataUnionData.dataUnion)
    } else if (step.type === 'agent' && agentData?.agent) {
      convertedPlan = convertToAgentPlan(agentData.agent)
    }

    if (convertedPlan) {
      // Compare the converted plan with the current step
      const hasChanged = !isEqual(convertedPlan, step)
      setHasChanges(hasChanged)
    }
    setIsLoading(false)
  }, [step, analysisData, workflowData, dataSetData, dataUnionData, agentData])

  // Mutation hooks
  const [updateAnalysis] = useMutation(UPDATE_ANALYSIS_MUTATION)
  const [updateWorkflow] = useMutation(UPDATE_WORKFLOW_MUTATION)
  const [updateDataSet] = useMutation(UPDATE_DATA_SET_MUTATION)
  const [updateDataUnion] = useMutation(UPDATE_DATA_UNION_MUTATION)
  const [updateAgent] = useMutation(UPDATE_AGENT_MUTATION)
  const [addDataSetToAgent] = useMutation(ADD_DATASET_TO_AGENT_MUTATION)
  const [removeDataSetFromAgent] = useMutation(REMOVE_DATASET_FROM_AGENT_MUTATION)
  const [addDataUnionToAgent] = useMutation(ADD_DATA_UNION_TO_AGENT_MUTATION)
  const [removeDataUnionFromAgent] = useMutation(REMOVE_DATA_UNION_FROM_AGENT_MUTATION)
  const [updateTask] = useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
    UPDATE_TASK_MUTATION
  )
  const [createTask] = useMutation<CreateTaskMutation, CreateTaskMutationVariables>(
    CREATE_TASK_MUTATION
  )
  const [deleteTask] = useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
    DELETE_TASK_MUTATION
  )

  if (step.uuid.type !== 'backend') {
    return null
  }

  const handleMutationResponse = async (
    data: { success: boolean; errors?: string[] } | null | undefined,
    resourceType: string
  ) => {
    if (data?.success) {
      handleArtifactChange({ makeConsistent: true })
      window.toastr.success(`${resourceType} updated successfully`)

      // Refetch the appropriate query based on step type
      if (step.type === 'analysis') {
        await refetchAnalysis()
      } else if (step.type === 'alert') {
        await refetchWorkflow()
      } else if (step.type === 'data_set') {
        await refetchDataSet()
      } else if (step.type === 'data_union') {
        await refetchDataUnion()
      } else if (step.type === 'agent') {
        await refetchAgent()
      }
    } else {
      window.toastr.error(
        data?.errors?.join(', ') || `Failed to update ${resourceType.toLowerCase()}`
      )
    }
  }

  const handleTasksUpdate = async (
    existingTasks: TaskFieldsFragment[],
    newTasks: Task[],
    taskableType: 'Workflow' | 'Analysis',
    taskableUuid: string
  ) => {
    // Update existing tasks
    const updatePromises = existingTasks.map(async (task) => {
      const matchingStepTask = newTasks.find((t) => t.uuid === task.uuid)
      if (matchingStepTask) {
        const variables: any = {
          uuid: task.uuid,
          name: matchingStepTask.name,
          description: matchingStepTask.description,
          task: matchingStepTask.task_prompt,
          outputWebhookUrl: task.outputWebhookUrl || '',
          runSchedule: task.runSchedule || '',
        }
        // Only include taskType for Workflow tasks
        if (taskableType === 'Workflow') {
          variables.taskType = task.taskType
        }
        await updateTask({ variables })
      }
    })

    // Create new tasks
    const createPromises = newTasks
      .filter((newTask) => !existingTasks.some((t) => t.uuid === newTask.uuid))
      .map(async (newTask) => {
        const variables: any = {
          name: newTask.name,
          description: newTask.description,
          task: newTask.task_prompt,
          outputWebhookUrl: '',
          runSchedule: '',
          taskableType,
          taskableUuid,
        }
        // Only include taskType for Workflow tasks
        if (taskableType === 'Workflow') {
          variables.taskType = newTask.type || 'yes_no'
        }
        await createTask({ variables })
      })

    // Delete removed tasks
    const deletePromises = existingTasks
      .filter((existingTask) => !newTasks.some((t) => t.uuid === existingTask.uuid))
      .map(async (taskToDelete) => {
        await deleteTask({
          variables: {
            uuid: taskToDelete.uuid,
          },
        })
      })

    // Execute all task operations in parallel
    await Promise.all([...updatePromises, ...createPromises, ...deletePromises])
  }

  const handleDataSetAssociations = async (
    agentUuid: string,
    dataSets: { uuid: { value: string } }[]
  ) => {
    if (!agentData?.agent) {
      return
    }

    interface AgentDataSet {
      uuid: string
    }

    const currentDataSetUuids = ((agentData.agent as any).dataSets || []).map(
      (ds: AgentDataSet) => ds.uuid
    )
    const newDataSetUuids = dataSets.map((ds) => ds.uuid.value)

    // Remove data sets that aren't in the plan anymore
    const dataSetUuidsToRemove = currentDataSetUuids.filter(
      (uuid: string) => !newDataSetUuids.includes(uuid)
    )
    const removePromises = dataSetUuidsToRemove.map((uuid: string) =>
      removeDataSetFromAgent({
        variables: {
          agentUuid,
          dataSetUuid: uuid,
        },
      })
    )

    // Add data sets that aren't already associated
    const dataSetUuidsToAdd = newDataSetUuids.filter(
      (uuid: string) => !currentDataSetUuids.includes(uuid)
    )
    const addPromises = dataSetUuidsToAdd.map((uuid: string) =>
      addDataSetToAgent({
        variables: {
          agentUuid,
          dataSetUuid: uuid,
        },
      })
    )

    try {
      await Promise.all([...removePromises, ...addPromises])
      if (dataSetUuidsToAdd.length > 0 || dataSetUuidsToRemove.length > 0) {
        handleArtifactChange({ makeConsistent: true })
      }
    } catch (error) {
      console.error('Error updating data set associations:', error)
      window.toastr.error('Error updating data set associations')
    }
  }

  const handleDataUnionAssociations = async (
    agentUuid: string,
    dataUnions: { uuid: { value: string } }[]
  ) => {
    if (!agentData?.agent) {
      return
    }

    interface AgentDataUnion {
      uuid: string
    }

    const currentDataUnionUuids = ((agentData.agent as any).dataUnions || []).map(
      (du: AgentDataUnion) => du.uuid
    )
    const newDataUnionUuids = dataUnions.map((du) => du.uuid.value)

    // Remove data unions that aren't in the plan anymore
    const dataUnionUuidsToRemove = currentDataUnionUuids.filter(
      (uuid: string) => !newDataUnionUuids.includes(uuid)
    )
    const removePromises = dataUnionUuidsToRemove.map((uuid: string) =>
      removeDataUnionFromAgent({
        variables: {
          agentUuid,
          dataUnionUuid: uuid,
        },
      })
    )

    // Add data unions that aren't already associated
    const dataUnionUuidsToAdd = newDataUnionUuids.filter(
      (uuid: string) => !currentDataUnionUuids.includes(uuid)
    )
    const addPromises = dataUnionUuidsToAdd.map((uuid: string) =>
      addDataUnionToAgent({
        variables: {
          agentUuid,
          dataUnionUuid: uuid,
        },
      })
    )

    try {
      await Promise.all([...removePromises, ...addPromises])
      if (dataUnionUuidsToAdd.length > 0 || dataUnionUuidsToRemove.length > 0) {
        handleArtifactChange({ makeConsistent: true })
      }
    } catch (error) {
      console.error('Error updating data union associations:', error)
      window.toastr.error('Error updating data union associations')
    }
  }

  const handleEdit = async (e: React.MouseEvent) => {
    e.preventDefault()
    setIsEditing(true)
    try {
      if (isAnalysisStep(step)) {
        const { data } = await updateAnalysis({
          variables: {
            uuid: step.uuid.value,
            name: step.name,
            description: step.description,
            instructions: step.instructions,
            outputFormat: step.output_format,
            agentUuid: step.agent_uuid.value,
          },
        })
        await handleMutationResponse(data?.updateAnalysis, 'Analysis')

        // Handle tasks if analysis update was successful
        if (data?.updateAnalysis.success && analysisData?.analysis) {
          await handleTasksUpdate(
            analysisData.analysis.tasks,
            step.tasks || [],
            'Analysis',
            step.uuid.value
          )
          await refetchAnalysis()
        }
      } else if (isAlertStep(step)) {
        const { data } = await updateWorkflow({
          variables: {
            uuid: step.uuid.value,
            name: step.name,
            description: step.description,
            agentUuid: step.agent_uuid.value,
            cronSchedule: step.cron_schedule,
          },
        })
        await handleMutationResponse(data?.updateWorkflow, 'Alert')

        // Handle tasks if workflow update was successful
        if (data?.updateWorkflow.success && workflowData?.workflow) {
          await handleTasksUpdate(
            workflowData.workflow.tasks,
            step.tasks || [],
            'Workflow',
            step.uuid.value
          )
          await refetchWorkflow()
        }
      } else if (isDataSet(step)) {
        let definition = {}
        if (step.backend === 'snowflake') {
          definition = {
            snowflake: {
              schema: step.definition.table_definition.schema || '',
              table: step.definition.table_definition.table_name || '',
              derived_table: step.definition.table_definition.sql || '',
            },
          }
        } else if (step.backend === 'bigquery') {
          definition = {
            bigquery: {
              dataset: step.definition.table_definition.schema || '',
              table: step.definition.table_definition.table_name || '',
              derived_table: step.definition.table_definition.sql || '',
            },
          }
        }

        const { data } = await updateDataSet({
          variables: {
            uuid: step.uuid.value,
            name: step.name,
            description: step.description,
            definition: JSON.stringify(definition),
          },
        })
        await handleMutationResponse(data?.updateDataSet, 'Dataset')
      } else if (isDataUnionStep(step)) {
        const definition = {
          dataSet: step.definition.data_set_uuid.value,
          joins: step.definition.joins.map((join) => ({
            dataSet: join.data_set_uuid.value,
            type: join.type.toUpperCase(),
            relationship: join.relationship.toUpperCase(),
            sqlOn: join.sql_on,
          })),
        }

        const { data } = await updateDataUnion({
          variables: {
            uuid: step.uuid.value,
            name: step.name,
            description: step.description,
            definition: JSON.stringify(definition),
            isEditable: true,
          },
        })
        await handleMutationResponse(data?.updateDataUnion, 'Data Union')
      } else if (isAgentStep(step)) {
        const { data } = await updateAgent({
          variables: {
            uuid: step.uuid.value,
            firstName: step.first_name,
            jobTitle: step.job_title || '',
            shortDescription: step.short_description,
            description: step.long_description,
            primer: step.system_instruction,
          },
        })
        await handleMutationResponse(data?.updateAgent, 'Agent')

        // Handle data set associations if agent update was successful
        if (data?.updateAgent.success) {
          await handleDataSetAssociations(step.uuid.value, step.data_sets || [])
          await handleDataUnionAssociations(step.uuid.value, step.data_unions || [])
          await refetchAgent()
        }
      }
    } catch (error) {
      console.error('Error updating resource:', error)
      window.toastr.error('Error updating resource')
    } finally {
      setIsEditing(false)
    }
  }

  if (!hasChanges || isLoading) {
    return null
  }

  return (
    <Button
      onClick={handleEdit}
      disabled={isEditing}
      className="text-xs border border-gray-300 hover:bg-green-500 hover:text-white px-4 py-1 rounded-md flex items-center space-x-1"
    >
      {isEditing ? <Spin className="h-4 w-4" /> : 'Save Changes'}
    </Button>
  )
}

export default EditStep

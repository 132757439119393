import React from 'react'
import { XMarkIcon, PlusIcon } from '@heroicons/react/24/outline'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import EmptyContentBox from './EmptyContentBox'
import ActionButton from './ActionButton'
import { ContentBlockFormat } from 'app/javascript/components/graphql'
import { FieldData } from './index'

interface ContentBlockFieldProps {
  field: FieldData
  formik: any
}

const BLOCK_TYPES = [
  { label: 'Text', value: 'text' },
  { label: 'Markdown', value: 'markdown' },
  { label: 'Chart', value: 'chart' },
  { label: 'Table', value: 'table' },
]

const SortableContentBlock = ({
  block,
  onRemove,
  children,
  id,
}: {
  block: ContentBlockFormat
  onRemove: () => void
  children: React.ReactNode
  id: string
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="relative p-4 border-2 border-gray-300 rounded-lg bg-white"
    >
      <div
        {...attributes}
        {...listeners}
        className="absolute -top-3 -left-3 p-1 text-gray-500 hover:text-gray-700 cursor-grab active:cursor-grabbing"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-4 h-4"
        >
          <path
            fillRule="evenodd"
            d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <button
        type="button"
        className="absolute -top-3 -right-3 p-1 text-white rounded-full bg-red-500 hover:bg-red-600"
        onClick={onRemove}
      >
        <XMarkIcon className="w-4 h-4" />
      </button>
      <div className="font-medium mb-2 capitalize">{block.type} Block</div>
      {children}
    </div>
  )
}

const ContentBlockField: React.FC<ContentBlockFieldProps> = ({ field, formik }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const value = formik.values[field.name] as ContentBlockFormat[]

  const addEmptyBlock = () => {
    formik.setFieldValue(field.name, [...value, { type: 'text', prompt: '' }])
  }

  const removeBlock = (index: number) => {
    const newBlocks = [...value]
    newBlocks.splice(index, 1)
    formik.setFieldValue(
      field.name,
      newBlocks.map(({ type, prompt }) => ({ type, prompt }))
    )
  }

  const updateBlock = (index: number, updates: Partial<ContentBlockFormat>) => {
    const newBlocks = [...value]
    newBlocks[index] = { ...newBlocks[index], ...updates }
    formik.setFieldValue(
      field.name,
      newBlocks.map(({ type, prompt }) => ({ type, prompt }))
    )
  }

  const handleDragEnd = (event: any) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = value.findIndex((_, i) => `block-${i}` === active.id)
      const newIndex = value.findIndex((_, i) => `block-${i}` === over.id)

      formik.setFieldValue(
        field.name,
        arrayMove(value, oldIndex, newIndex).map(({ type, prompt }) => ({ type, prompt }))
      )
    }
  }

  const renderBlockEditor = (block: ContentBlockFormat, index: number) => {
    return (
      <div className="space-y-4">
        <select
          className="p-2 border rounded w-full"
          value={block.type}
          onChange={(e) =>
            updateBlock(index, {
              type: e.target.value as ContentBlockFormat['type'],
            })
          }
        >
          {BLOCK_TYPES.map((type) => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </select>
        <textarea
          className="w-full p-2 border rounded"
          value={block.prompt}
          onChange={(e) => updateBlock(index, { prompt: e.target.value })}
          placeholder="Enter prompt..."
          rows={3}
        />
      </div>
    )
  }

  return (
    <>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <div className="flex flex-col gap-4">
          {value.length === 0 ? (
            <EmptyContentBox
              title="No content blocks added"
              widthClass="w-full"
              heightClass="h-24"
            />
          ) : (
            <SortableContext
              items={value.map((_, i) => `block-${i}`)}
              strategy={verticalListSortingStrategy}
            >
              {value.map((block, index) => (
                <SortableContentBlock
                  key={`block-${index}`}
                  id={`block-${index}`}
                  block={block}
                  onRemove={() => removeBlock(index)}
                >
                  {renderBlockEditor(block, index)}
                </SortableContentBlock>
              ))}
            </SortableContext>
          )}
        </div>
      </DndContext>

      <div className="flex flex-row gap-2 items-center justify-start mt-4">
        <ActionButton onClick={addEmptyBlock}>
          <PlusIcon className="w-4 h-4" /> Add Block
        </ActionButton>
      </div>
      {formik.touched[field.name] && formik.errors[field.name] && (
        <div className="text-red-500 text-xs mt-1">{formik.errors[field.name]}</div>
      )}
    </>
  )
}

export default ContentBlockField

import {
  BackendEnum,
  AnalysisStepType,
  AlertStepType,
  DataSetStepType,
  AgentStepType,
  DataUnionStepType,
  StepTypeEnum,
} from './planTypes'

export const convertToAnalysisPlan = (analysis: any): AnalysisStepType => ({
  type: StepTypeEnum.Analysis,
  uuid: {
    type: 'backend',
    value: analysis.uuid,
  },
  name: analysis.name,
  description: analysis.description,
  instructions: analysis.instructions,
  output_format: analysis.outputFormat,
  agent_uuid: {
    type: 'backend',
    value: analysis.agent.uuid,
  },
  tasks: analysis.tasks?.map((task: any) => ({
    name: task.name,
    description: task.description,
    task_prompt: task.task,
  })),
})

export const convertToAlertPlan = (workflow: any): AlertStepType => ({
  type: StepTypeEnum.Alert,
  uuid: {
    type: 'backend',
    value: workflow.uuid,
  },
  name: workflow.name,
  description: workflow.description,
  agent_uuid: {
    type: 'backend',
    value: workflow.agent.uuid,
  },
  cron_schedule: workflow.cronSchedule || '',
  tasks: workflow.tasks?.map((task: any) => ({
    name: task.name,
    description: task.description,
    task_prompt: task.task,
    type: 'pass_fail',
  })),
})

export const convertToDataSetPlan = (dataSet: any): DataSetStepType => {
  let backend: BackendEnum | null = null
  let tableDefinition: {
    type: 'view' | 'table'
    schema?: string
    table_name: string
    sql?: string
  } | null = null

  if (dataSet.definition.snowflake) {
    backend = BackendEnum.Snowflake
    if (dataSet.definition.snowflake.derived_table) {
      tableDefinition = {
        type: 'view',
        sql: dataSet.definition.snowflake.derived_table,
        table_name: dataSet.definition.snowflake.table,
      }
    } else {
      tableDefinition = {
        type: 'table',
        schema: dataSet.definition.snowflake.schema,
        table_name: dataSet.definition.snowflake.table,
      }
    }
  } else if (dataSet.definition.bigquery) {
    backend = BackendEnum.BigQuery
    if (dataSet.definition.bigquery.derived_table) {
      tableDefinition = {
        type: 'view',
        sql: dataSet.definition.bigquery.derived_table,
        table_name: dataSet.definition.bigquery.table,
      }
    } else {
      tableDefinition = {
        type: 'table',
        schema: dataSet.definition.bigquery.dataset,
        table_name: dataSet.definition.bigquery.table,
      }
    }
  } else if (dataSet.definition.looker) {
    backend = BackendEnum.Looker
    tableDefinition = {
      type: 'view',
      sql: dataSet.definition.looker.exploreUrl,
      table_name: dataSet.name,
    }
  }

  return {
    type: StepTypeEnum.DataSet,
    uuid: {
      type: 'backend',
      value: dataSet.uuid,
    },
    name: dataSet.name,
    description: dataSet.description,
    backend,
    definition: {
      type: backend as BackendEnum.Snowflake | BackendEnum.BigQuery,
      table_definition: tableDefinition,
    },
  }
}

export const convertToAgentPlan = (agent: any): AgentStepType => ({
  type: StepTypeEnum.Agent,
  uuid: {
    type: 'backend',
    value: agent.uuid,
  },
  first_name: agent.firstName,
  job_title: agent.jobTitle,
  short_description: agent.shortDescription,
  long_description: agent.description,
  system_instruction: agent.primer,
  data_sets: agent.dataSets?.map((ds: any) => ({
    uuid: {
      type: 'backend',
      value: ds.uuid,
    },
  })),
  data_unions: agent.dataUnions?.map((du: any) => ({
    uuid: {
      type: 'backend',
      value: du.uuid,
    },
  })),
})

export const convertToDataUnionPlan = (dataUnion: any): DataUnionStepType => ({
  type: StepTypeEnum.DataUnion,
  uuid: {
    type: 'backend',
    value: dataUnion.uuid,
  },
  name: dataUnion.name,
  description: dataUnion.description,
  definition: {
    data_set_uuid: {
      type: 'backend',
      value: dataUnion.definition.dataSet,
    },
    joins: dataUnion.definition.joins.map((join: any) => ({
      data_set_uuid: {
        type: 'backend',
        value: join.dataSet,
      },
      type: join.type,
      relationship: join.relationship,
      sql_on: join.sqlOn,
    })),
  },
})

export const isEqual = (a: any, b: any): boolean => {
  // Handle null/undefined cases
  if (a === b) {
    return true
  }
  if (!a || !b) {
    return false
  }
  if (typeof a !== 'object' || typeof b !== 'object') {
    return false
  }
  if (Array.isArray(a) !== Array.isArray(b)) {
    return false
  }

  if (Array.isArray(a)) {
    if (a.length !== b.length) {
      return false
    }
    // Empty arrays are equal
    if (a.length === 0) {
      return true
    }
    // For tasks array, we need to compare specific fields
    if (a[0] && 'task_prompt' in a[0]) {
      return a.every((taskA, index) => {
        const taskB = b[index]
        return (
          taskA.name === taskB.name &&
          taskA.description === taskB.description &&
          taskA.task_prompt === (taskB.task || taskB.task_prompt)
        )
      })
    }
    // For output_format array, we need to compare type and prompt
    if (a[0] && 'type' in a[0] && 'prompt' in a[0]) {
      return a.every((formatA, index) => {
        const formatB = b[index]
        return formatA.type === formatB.type && formatA.prompt === formatB.prompt
      })
    }
    // For data sets array, we need to compare UUIDs
    if (a[0] && 'uuid' in a[0]) {
      // Handle case where one array has objects with uuid.value and the other has direct uuid strings
      const getUuidValue = (item: any): string | null => {
        if (!item || !item.uuid) {
          return null
        }
        if (typeof item.uuid === 'string') {
          return item.uuid
        }
        if (typeof item.uuid === 'object' && 'value' in item.uuid) {
          return item.uuid.value
        }
        return null
      }

      const aUuids = new Set(a.map(getUuidValue).filter(Boolean))
      const bUuids = new Set(b.map(getUuidValue).filter(Boolean))
      return aUuids.size === bUuids.size && [...aUuids].every((uuid) => bUuids.has(uuid))
    }
    // Default array comparison
    return a.every((item, index) => isEqual(item, b[index]))
  }

  const keysA = Object.keys(a).filter((k) => a[k] !== undefined && a[k] !== null)
  const keysB = Object.keys(b).filter((k) => b[k] !== undefined && b[k] !== null)

  if (keysA.length !== keysB.length) {
    return false
  }

  return keysA.every((key) => {
    if (!Object.prototype.hasOwnProperty.call(b, key)) {
      return false
    }
    // Special handling for task comparison where backend uses 'task' and plan uses 'task_prompt'
    if (key === 'task_prompt' && b.task) {
      return a.task_prompt === b.task
    }
    return isEqual(a[key], b[key])
  })
}

import React from 'react'
import { Link } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { DataUnionFieldsFragment } from 'app/javascript/components/graphql'
import { formatDistanceToNow } from 'date-fns'

interface Props {
  dataUnion: DataUnionFieldsFragment
}

const DataUnionCard = ({ dataUnion }: Props) => {
  return (
    <Link to={`/data_union/${dataUnion.uuid}`}>
      <Card className="w-72 h-48 hover:shadow-lg transition-shadow">
        <CardContent>
          <div className="text-xl font-semibold truncate">{dataUnion.name}</div>
          <div className="text-sm text-gray-600 line-clamp-2 h-10">{dataUnion.description}</div>
          <div className="mt-4 space-y-0.5">
            <div className="text-xs text-gray-600">
              Created by {dataUnion.createdBy.firstName} {dataUnion.createdBy.lastName}
            </div>
            <div className="text-xs text-gray-600">
              {formatDistanceToNow(new Date(dataUnion.createdAt))} ago
            </div>
            <div className="text-xs text-gray-600">{dataUnion.dataSets.length} datasets</div>
          </div>
        </CardContent>
      </Card>
    </Link>
  )
}

export default DataUnionCard

import React, { useState } from 'react'
import {
  DELETE_AGENT_MUTATION,
  GET_AGENT_QUERY,
  MUTATION_GENERATE_PROFILE_IMAGE,
} from '../../../graphql/queries/agent'
import Spin from '../../../../common/ui/Spin'
import { useMutation, useQuery } from '@apollo/client'
import { AgentQuery, AgentQueryVariables } from '../../../../graphql'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import HeaderActionButton from '../../../components/HeaderActionButton'
import DeleteModal from '../../../components/DeleteModal'
import { VerticalTabs, TabItem } from '../../../../common/ui/VerticalTabs'
import DetailsForm from './DetailsForm'
import Prompts from './Prompts'
import Data from './Data'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import BuildStatus from './BuildStatus'
import queryString from 'query-string'
import AgentImage from '../../../components/AgentImage'
import AnalysesList from './AnalysesList'
import AlertList from './AlertList'
import SubHeader from '../../../components/ObjectHeader/SubHeader'
import { Trash } from 'lucide-react'

type EditAgentProps = {
  uuid: string
}

const EditAgent = ({ uuid }: EditAgentProps) => {
  document.title = 'Edit Agent - FlowMo'

  const history = useHistory()
  const location = useLocation()
  const { user } = useSelector((state: RootState) => state.app)
  const { data, loading, refetch } = useQuery<AgentQuery, AgentQueryVariables>(GET_AGENT_QUERY, {
    variables: { uuid },
    fetchPolicy: 'network-only',
  })

  const [updateProfileImage] = useMutation(MUTATION_GENERATE_PROFILE_IMAGE)
  const [generatingProfileImage, setGeneratingProfileImage] = useState(false)
  const [deleteAgent] = useMutation(DELETE_AGENT_MUTATION)
  const [open, setOpen] = useState(false)

  const agent = data?.agent
  if (loading) {
    return <Spin />
  }

  if (!agent) {
    window.toastr.error('Agent not found')
    history.push('/agents')
    return <></>
  }

  if (user.isOrganizationAdmin == false && user.id.toString() != agent.createdBy.id) {
    window.toastr.error('You are not authorized to edit this agent')
    history.push('/agents')
  }

  const handleTabChange = (tab: TabItem) => {
    const newQuery = queryString.stringify({ ...queryString.parse(location.search), tab: tab.key })
    history.push(`${location.pathname}?${newQuery}`)
  }

  const onUpdate = () => {
    refetch()
  }

  const handleRefreshProfileImage = async () => {
    setGeneratingProfileImage(true)
    await updateProfileImage({
      variables: { uuid: agent.uuid },
    })
    setGeneratingProfileImage(false)
    window.toastr.success('Profile image updated successfully')
    refetch()
  }

  const handleDelete = async () => {
    await deleteAgent({
      variables: { uuid: agent.uuid },
    })
    window.toastr.success('Agent deleted successfully')
    history.push('/agents')
  }

  const tabs: TabItem[] = [
    {
      key: 'details',
      label: 'Details',
      content: (
        <div className="flex flex-col space-y-4">
          <div className="max-w-xl">
            <div className="relative flex w-24 flex-col mb-5">
              <AgentImage agent={agent} className="w-24 h-24 rounded-xl mb-5" />
              {generatingProfileImage ? (
                <Spin />
              ) : (
                <div
                  onClick={handleRefreshProfileImage}
                  className="flex flex-row items-center space-x-2 text-gray-800 hover:text-flowmo-blue-800 cursor-pointer"
                >
                  <ArrowPathIcon className="h-5 w-5" />
                  <div className="text-base">Refresh</div>
                </div>
              )}
            </div>
            <DetailsForm agent={agent} onUpdate={onUpdate} />
          </div>
        </div>
      ),
    },
    {
      key: 'data',
      label: 'Things I know',
      content: <Data agent={agent} onUpdate={onUpdate} />,
    },
    {
      key: 'work',
      label: 'Stuff I can do',
      content: (
        <>
          <AnalysesList agentUuid={agent.uuid} onUpdate={onUpdate} />
          <AlertList agentUuid={agent.uuid} onUpdate={onUpdate} />
        </>
      ),
    },
    {
      key: 'prompts',
      label: 'What to ask',
      content: <Prompts agent={agent} onUpdate={onUpdate} />,
    },
  ]

  return (
    <React.Fragment>
      <SubHeader
        object={{ name: agent.firstName, description: agent.jobTitle, uuid: agent.uuid }}
        type="agent"
        subtitle="Edit Agent"
        rightContent={
          <>
            <BuildStatus uuid={agent.uuid} buildStatus={agent.buildStatus} onUpdate={onUpdate} />
            <HeaderActionButton
              onClick={() => setOpen(true)}
              tooltip="Delete"
              icon={<Trash className="h-4 w-4" />}
            />
          </>
        }
        image={<AgentImage agent={agent} className="size-12 rounded-full" />}
      />
      <DeleteModal
        open={open}
        setOpen={setOpen}
        onDelete={handleDelete}
        title="Delete Agent"
        buttonLabel="Delete"
        description="Are you sure you want to delete the agent? All of your data will be permanently removed from our servers forever. This action cannot be undone."
      />

      <div className="mt-2">
        <VerticalTabs tabs={tabs} tabListWidth="w-64" onChange={handleTabChange} />
      </div>
    </React.Fragment>
  )
}

export default EditAgent

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'
import { createConsumer } from '@rails/actioncable'

import { chatActions, ConversationItem } from '../slices/chatSlice'
import { RootState } from '../store'
import {
  CREATE_MESSAGE_THREAD,
  GET_ARTIFACTS,
  GET_CONVERSATION,
} from '../graphql/queries/message_thread'
import { SEND_AGENT_MESSAGE } from '../graphql/queries/agent'
import {
  ActorEnum,
  AgentFieldsFragment,
  ArtifactsQuery,
  ArtifactsQueryVariables,
  CompanyAgentFieldsFragment,
  ConversationUnion,
  CreateMessageThreadMutation,
  CreateMessageThreadMutationVariables,
  FunctionToolCall,
  GetConversationQuery,
  GetConversationQueryVariables,
  MessageContentUnion,
  MessageStatusEnum,
  SendAgentMessageMutation,
  SendAgentMessageMutationVariables,
  StepUnion,
} from '../../graphql'

const getCableUrl = () => {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
  const host = window.location.hostname
  const port = window.location.port ? `:${window.location.port}` : ''
  return `${protocol}//${host}${port}/cable`
}

interface UseChatProps {
  agent: CompanyAgentFieldsFragment | AgentFieldsFragment
  initialThreadUuid?: string
  useUrlParams?: boolean
}

export function useChat({ agent, initialThreadUuid = null }: UseChatProps) {
  const dispatch = useDispatch()

  // --------------------
  // Local state
  // --------------------
  const [chatId, setChatId] = useState<string | null>(null)
  const [isDataFetched, setIsDataFetched] = useState(false)

  // --------------------
  // Redux state
  // --------------------
  const activeChat = useSelector((state: RootState) => (chatId ? state.chat[chatId] : null))

  // --------------------
  // Apollo
  // --------------------
  const [sendMessage] = useMutation<SendAgentMessageMutation, SendAgentMessageMutationVariables>(
    SEND_AGENT_MESSAGE
  )
  const [createMessageThread] = useMutation<
    CreateMessageThreadMutation,
    CreateMessageThreadMutationVariables
  >(CREATE_MESSAGE_THREAD)

  const { refetch: refetchConversation } = useQuery<
    GetConversationQuery,
    GetConversationQueryVariables
  >(GET_CONVERSATION, {
    skip: true,
    fetchPolicy: 'network-only',
  })

  const { refetch: refetchArtifacts } = useQuery<ArtifactsQuery, ArtifactsQueryVariables>(
    GET_ARTIFACTS,
    {
      skip: true,
      fetchPolicy: 'network-only',
    }
  )

  // --------------------
  // Effects
  // --------------------

  // 1. Create/remove a chat in Redux as soon as this hook is used/unmounted
  useEffect(() => {
    initializeChat()
    return () => {
      teardownChat()
    }
  }, [])

  // 2. If an initial thread UUID is provided, sync it once the chat is ready
  useEffect(() => {
    syncInitialThreadUuid()
  }, [initialThreadUuid, activeChat, dispatch])

  // 3. Fetch thread data once we have a valid thread UUID and haven't already fetched
  useEffect(() => {
    if (activeChat?.threadUuid && !isDataFetched) {
      console.log('_______ fetchThreadData', activeChat.threadUuid)
      fetchThreadData(activeChat.threadUuid)
    }
  }, [activeChat?.threadUuid, isDataFetched, activeChat?.chatId])

  // 4. Sync agent data whenever the agent or the chatId changes
  useEffect(() => {
    syncAgentData()
  }, [agent, chatId])

  // 5. Subscribe to the message stream channel
  useEffect(() => {
    if (activeChat?.chatId && !activeChat?.isQuerying) {
      dispatch(chatActions.setChatStatusDescription({ chatId: activeChat.chatId, status: '' }))
    }

    let subscription: ActionCable.Subscription | null = null

    if (activeChat?.chatId && activeChat?.isQuerying) {
      const cable = createConsumer(getCableUrl())

      subscription = cable.subscriptions.create(
        { channel: 'MessageStreamChannel', chat_id: activeChat.chatId },
        {
          received(data: any): void {
            if (data.message_type === 'chat_status') {
              dispatch(
                chatActions.setChatStatusDescription({
                  chatId: activeChat.chatId,
                  status: data.message_body,
                })
              )
            } else if (data.message_type === 'create_chat_message') {
              dispatch(
                chatActions.addConversationItem({
                  chatId: activeChat.chatId,
                  item: {
                    uuid: data.message_body.uuid,
                    role: data.message_body.actor,
                    type: 'text',
                    content: {
                      type: 'message',
                      message: data.message_body.message,
                      notes: data.message_body.notes,
                      fileAttachments: data.message_body.fileAttachments,
                      knowledge: data.message_body.knowledge,
                    },
                    status:
                      data.message_body.status === 'in_progress'
                        ? MessageStatusEnum.InProgress
                        : MessageStatusEnum.Completed,
                    createdAt: data.message_body.createdAt,
                    createdAtMillis: data.message_body.createdAtMilli,
                    previousItemUuid: null,
                  },
                })
              )
            } else if (data.message_type === 'update_chat_message') {
              dispatch(
                chatActions.updateConversationItem({
                  chatId: activeChat.chatId,
                  item: {
                    uuid: data.message_body.uuid,
                    status:
                      data.message_body.status === 'in_progress'
                        ? MessageStatusEnum.InProgress
                        : MessageStatusEnum.Completed,
                    content: {
                      type: 'message',
                      message: data.message_body.message as MessageContentUnion[],
                      notes: data.message_body.notes,
                      fileAttachments: data.message_body.fileAttachments,
                      knowledge: data.message_body.knowledge,
                    },
                  },
                })
              )
            } else if (data.message_type === 'chat_message_delta') {
              dispatch(
                chatActions.appendConversationMessageDelta({
                  chatId: activeChat.chatId,
                  item: {
                    uuid: data.message_body.uuid,
                    delta: data.message_body.delta,
                  },
                })
              )
            } else if (data.message_type === 'create_step_message') {
              dispatch(
                chatActions.addConversationItem({
                  chatId: activeChat.chatId,
                  item: {
                    uuid: data.message_body.uuid,
                    role: ActorEnum.System,
                    type: 'step',
                    status:
                      data.message_body.status === 'in_progress'
                        ? MessageStatusEnum.InProgress
                        : MessageStatusEnum.Completed,
                    content: {
                      type: 'steps',
                      steps: data.message_body.step,
                    },
                    previousItemUuid: null,
                    createdAt: data.message_body.createdAt,
                    createdAtMillis: data.message_body.createdAtMilli,
                  },
                })
              )
            } else if (data.message_type === 'create_side_effect_message') {
              dispatch(
                chatActions.addConversationItem({
                  chatId: activeChat.chatId,
                  item: {
                    uuid: data.message_body.uuid,
                    role: ActorEnum.System,
                    type: 'side_effect',
                    status:
                      data.message_body.status === 'in_progress'
                        ? MessageStatusEnum.InProgress
                        : MessageStatusEnum.Completed,
                    content: {
                      type: 'side_effect',
                      sideEffect: data.message_body.sideEffect,
                      data: data.message_body.data,
                      output: data.message_body.output,
                    },
                    previousItemUuid: null,
                    createdAt: data.message_body.createdAt,
                    createdAtMillis: data.message_body.createdAtMilli,
                  },
                })
              )
            } else if (data.message_type === 'update_side_effect_message') {
              dispatch(
                chatActions.updateConversationItem({
                  chatId: activeChat.chatId,
                  item: {
                    uuid: data.message_body.uuid,
                    status:
                      data.message_body.status === 'in_progress'
                        ? MessageStatusEnum.InProgress
                        : MessageStatusEnum.Completed,
                    content: {
                      type: 'side_effect',
                      sideEffect: data.message_body.sideEffect,
                      data: data.message_body.data,
                      output: data.message_body.output,
                    },
                  },
                })
              )
            } else if (data.message_type === 'update_step_message') {
              dispatch(
                chatActions.updateConversationItem({
                  chatId: activeChat.chatId,
                  item: {
                    uuid: data.message_body.uuid,
                    status:
                      data.message_body.status === 'in_progress'
                        ? MessageStatusEnum.InProgress
                        : MessageStatusEnum.Completed,
                    content: {
                      type: 'steps',
                      steps: data.message_body.step,
                    },
                  },
                })
              )

              // check if one of the steps was either create_deliverable_plan or edit_deliverable_plan
              console.log('_______ data.message_body.step', data.message_body.step)
              const artifactSteps = data.message_body.step.filter(
                (step: StepUnion) =>
                  step.type === 'function' &&
                  ['create_deliverable_plan', 'edit_deliverable_plan'].includes(
                    (step as FunctionToolCall).function?.name
                  )
              )
              console.log('_______ artifactSteps', artifactSteps)
              if (artifactSteps.length > 0) {
                fetchArtifacts(activeChat.threadUuid)
              }
            }

            console.log(data.message_type, data.message_body)
          },
        }
      )
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe()
        subscription.consumer.disconnect()
      }
    }
  }, [activeChat?.chatId, activeChat?.isQuerying])

  // --------------------
  // Helper Functions
  // --------------------

  /**
   * Create a chat in Redux if none exists yet
   */
  const initializeChat = useCallback(() => {
    if (!chatId) {
      const action = chatActions.createChat()
      dispatch(action)
      setChatId(action.payload.chatId)
    }
  }, [chatId, dispatch])

  /**
   * Remove this chat in Redux when the component unmounts
   */
  const teardownChat = useCallback(() => {
    if (chatId) {
      dispatch(chatActions.removeChat({ chatId }))
    }
    setChatId(null)
    setIsDataFetched(false)
  }, [chatId, dispatch])

  /**
   * Sync the initial thread UUID (if provided) into Redux state
   */
  const syncInitialThreadUuid = () => {
    if (!initialThreadUuid || !activeChat) {
      return
    }
    dispatch(
      chatActions.setThreadUuid({ chatId: activeChat.chatId, threadUuid: initialThreadUuid })
    )
  }

  const addConversationItem = (item: ConversationItem) => {
    dispatch(chatActions.addConversationItem({ chatId: activeChat.chatId, item }))

    // if the step is anything to do with artifacts, then refetchs them
    if (item.type === 'step') {
      if (
        item.content.steps.some((oneStep) => {
          if (oneStep.type != 'function_call') {
            return false
          }
          const functionName = (oneStep as FunctionToolCall).function?.name
          return ['create_deliverable_plan', 'edit_deliverable_plan'].includes(functionName)
        })
      ) {
        fetchArtifacts(activeChat.threadUuid)
      }
    }
  }

  const addConversationItemFromBackend = useCallback(
    (item: ConversationUnion) => {
      if (!activeChat.chatId) {
        return
      }
      const previousItemUuid = activeChat.messages[activeChat.messages.length - 1]?.uuid
      if (item.__typename === 'MessageHistory') {
        dispatch(
          chatActions.addConversationItem({
            chatId: activeChat.chatId,
            item: {
              uuid: item.uuid,
              role: item.actor,
              type: 'text',
              status: item.status,
              content: {
                type: 'message',
                message: item.message,
                notes: item.notes,
                fileAttachments: item.fileAttachments,
                knowledge: item.knowledge,
              },
              previousItemUuid,
              createdAt: item.createdAt,
              createdAtMillis: item.createdAtMilli,
            },
          })
        )
      } else if (item.__typename === 'StepHistory') {
        dispatch(
          chatActions.addConversationItem({
            chatId: activeChat.chatId,
            item: {
              uuid: item.uuid,
              role: item.actor,
              type: 'step',
              status: MessageStatusEnum.Completed,
              content: {
                type: 'steps',
                steps: item.step as StepUnion[],
              },
              previousItemUuid,
              createdAt: item.createdAt,
              createdAtMillis: item.createdAtMilli,
            },
          })
        )
      } else if (item.__typename === 'SideEffect') {
        dispatch(
          chatActions.addConversationItem({
            chatId: activeChat.chatId,
            item: {
              uuid: item.uuid,
              role: ActorEnum.System,
              type: 'side_effect',
              status: MessageStatusEnum.Completed,
              content: {
                type: 'side_effect',
                sideEffect: item.sideEffect,
                data: typeof item.data === 'string' ? JSON.parse(item.data || '{}') : item.data,
                output: item.output,
              },
              previousItemUuid,
              createdAt: item.createdAt,
              createdAtMillis: item.createdAtMilli,
            },
          })
        )
      }
    },
    [activeChat?.chatId, dispatch]
  )

  const fetchArtifacts = async (threadUuid: string, makeConsistent = false) => {
    dispatch(chatActions.setArtifactsLoading({ chatId: activeChat.chatId, loading: true }))
    const { data } = await refetchArtifacts({
      messageThreadUuid: threadUuid,
      makeConsistent,
    })
    if (data?.artifacts) {
      dispatch(chatActions.setArtifacts({ chatId: activeChat.chatId, artifacts: data.artifacts }))
    }
    dispatch(chatActions.setArtifactsLoading({ chatId: activeChat.chatId, loading: false }))
  }

  /**
   * Manually trigger a reload of artifact data for the current thread
   */
  const handleArtifactChange = async ({ makeConsistent = false }: { makeConsistent?: boolean }) => {
    if (!activeChat?.threadUuid) {
      return
    }
    await fetchArtifacts(activeChat.threadUuid, makeConsistent)
  }

  const fetchConversation = async (threadUuid: string) => {
    const { data } = await refetchConversation({ uuid: threadUuid })
    if (data?.getConversation) {
      data.getConversation.forEach((item: ConversationUnion) => {
        addConversationItemFromBackend(item)
      })
    }
  }

  /**
   * Fetch messages, steps, side effects for the current thread UUID
   */
  const fetchThreadData = useCallback(
    async (threadUuid: string) => {
      if (isDataFetched) {
        return
      }
      fetchConversation(threadUuid)
      fetchArtifacts(threadUuid)
      setIsDataFetched(true)
    },
    [refetchConversation, addConversationItem]
  )

  /**
   * Sync the agent and agentUuid to the Redux store
   */
  const syncAgentData = () => {
    if (agent && chatId) {
      dispatch(chatActions.setAgent({ chatId, agent }))
      dispatch(chatActions.setAgentUuid({ chatId, agentUuid: agent.uuid }))
    }
  }

  /**
   * Create a new thread in the backend if one doesn't exist yet
   */
  const getOrCreateThreadUuid = useCallback(async () => {
    if (!agent?.uuid || !activeChat?.chatId) {
      return null
    }
    if (activeChat.threadUuid) {
      return activeChat.threadUuid
    }

    const { data } = await createMessageThread({ variables: { agentUuid: agent.uuid } })
    const newThreadUuid = data?.createMessageThread?.uuid

    if (newThreadUuid) {
      dispatch(chatActions.setThreadUuid({ chatId: activeChat.chatId, threadUuid: newThreadUuid }))
      return newThreadUuid
    }

    return null
  }, [agent, activeChat, createMessageThread, dispatch])

  // --------------------
  // Public API
  // --------------------

  /**
   * Send a message to the agent
   */
  const sendChatMessage = async (message: string) => {
    if (!message.trim() || !activeChat) {
      return
    }

    // Ensure we have a valid thread (create one if necessary)
    const threadUuid = await getOrCreateThreadUuid()
    if (!threadUuid) {
      return
    }

    // Mark our chat as querying
    dispatch(chatActions.setIsQuerying({ chatId: activeChat.chatId, isQuerying: true }))
    dispatch(
      chatActions.setChatStatusDescription({
        chatId: activeChat.chatId,
        status: 'Sending message...',
      })
    )
    try {
      // Send message to the backend
      const { data } = await sendMessage({
        variables: {
          agentUuid: agent.uuid,
          chatId: activeChat.chatId,
          enableStreaming: true,
          threadUuid,
          message,
        },
      })

      // Handle errors from the server, if any
      if (data.sendAgentMessage.errors.length) {
        window.toastr.error('Something went wrong. Please try again later.')
      }
    } catch (error) {
      console.error('Failed to process message:', error)
      // Handle or display error
    } finally {
      // Reset the user input in Redux
      dispatch(
        chatActions.setQuery({
          chatId: activeChat.chatId,
          query: '',
          fileAttachments: [],
          content: [],
        })
      )
      dispatch(chatActions.setIsQuerying({ chatId: activeChat.chatId, isQuerying: false }))
    }
  }

  /**
   * Reset the entire chat (both in Redux and local state)
   */
  const resetChat = useCallback(() => {
    teardownChat()
  }, [chatId, initializeChat])

  // --------------------
  // Return everything needed by consuming components
  // --------------------
  return {
    chatState: activeChat,
    isChatReady: chatId && isDataFetched && activeChat.threadUuid,
    addConversationItem,
    addConversationItemFromBackend,
    initializeChat,
    resetChat,
    handleSubmit: sendChatMessage,
    getOrCreateThreadUuid,
    handleArtifactChange,
  }
}

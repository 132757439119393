import { useMutation, useQuery } from '@apollo/client'
import Spin from '../../../../common/ui/Spin'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  AnalysisQuery,
  AnalysisQueryVariables,
  LastAnalysisRunQuery,
  LastAnalysisRunQueryVariables,
} from 'app/javascript/components/graphql'
import {
  GET_ANALYSIS_QUERY,
  GET_LATEST_ANALYSIS_RUN_QUERY,
  MUTATION_SUBMIT_RUN_ANALYSIS,
} from '../../../graphql/queries/analysis'
import AnalysisResults from './AnalysisResults'
import { WandSparkles, FolderClock, Pencil, Settings } from 'lucide-react'
import { MainHeader } from '../../../components/ObjectHeader'
import { PlayCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material'

const actions = [
  { name: 'Edit with AI', icon: WandSparkles, href: (uuid: string) => `/edit/analysis/${uuid}` },
  { name: 'Edit', icon: Pencil, href: (uuid: string) => `/analysis/edit/${uuid}` },
  { name: 'Setup', icon: Settings, href: (uuid: string) => `/analysis/setup/${uuid}` },
  { name: 'History', icon: FolderClock, href: (uuid: string) => `/analysis/history/${uuid}` },
]

const OneAnalysis = ({ uuid }: { uuid: string }) => {
  const history = useHistory()
  const [isRunning, setIsRunning] = React.useState(false)

  // Set up an interval to refetch workflow runs every 2 seconds when isRunning is true
  const refetchAnalysisInterval = React.useRef<NodeJS.Timeout | null>(null)

  document.title = 'Analysis | FlowMo'

  const [submitRunAnalysis] = useMutation(MUTATION_SUBMIT_RUN_ANALYSIS)

  const {
    data: analysisData,
    loading: analysisLoading,
    refetch: refetchAnalysis,
  } = useQuery<AnalysisQuery, AnalysisQueryVariables>(GET_ANALYSIS_QUERY, {
    variables: { uuid },
    fetchPolicy: 'network-only',
  })

  const { data: lastAnalysisRunData, refetch: refetchLastAnalysisRun } = useQuery<
    LastAnalysisRunQuery,
    LastAnalysisRunQueryVariables
  >(GET_LATEST_ANALYSIS_RUN_QUERY, {
    variables: { uuid },
    fetchPolicy: 'network-only',
  })

  // check if the analysis is running on initial load
  useEffect(() => {
    if (analysisData?.analysis) {
      if (analysisData.analysis.status === 'running' && !isRunning) {
        setIsRunning(true)
      }
      if (analysisData.analysis.status != 'running' && isRunning) {
        refetchLastAnalysisRun().then(() => {
          setIsRunning(false)
        })
      }
    }
  }, [analysisData])

  React.useEffect(() => {
    if (isRunning) {
      refetchAnalysisInterval.current = setInterval(() => {
        refetchAnalysis()
      }, 2000)
    } else {
      if (refetchAnalysisInterval.current) {
        clearInterval(refetchAnalysisInterval.current)
        refetchAnalysisInterval.current = null
      }
    }

    // Clear the interval when the component is unmounted
    return () => {
      if (refetchAnalysisInterval.current) {
        clearInterval(refetchAnalysisInterval.current)
      }
    }
  }, [isRunning, refetchAnalysis])

  const handleRunAnalysis = () => {
    setIsRunning(true)
    submitRunAnalysis({ variables: { uuid: uuid } })
  }

  if (analysisLoading) {
    return <Spin />
  }

  const analysis = analysisData?.analysis

  if (!analysis) {
    history.push('/analyses')
    return <></>
  }

  return (
    <div>
      <MainHeader object={analysis} type="analysis" actions={actions}>
        <Tooltip title="Run Analysis">
          <button onClick={handleRunAnalysis} disabled={isRunning}>
            {isRunning ? (
              <Spin />
            ) : (
              <PlayCircleIcon className="w-8 h-8 hover:text-flowmo-blue-600" />
            )}
          </button>
        </Tooltip>
      </MainHeader>

      {isRunning ? (
        <div className="flex flex-col items-center space-y-2 justify-center">
          <span className="text-md">{analysis.agent.firstName} is hard at work for you...</span>
          <Spin />
        </div>
      ) : (
        <AnalysisResults analysis={analysis} analysisRun={lastAnalysisRunData?.lastAnalysisRun} />
      )}
    </div>
  )
}
export default OneAnalysis

import React from 'react'
import { AnalysisQuery, AnalysisQueryVariables } from 'app/javascript/components/graphql'
import CreateTask from '../../../components/CreateTask'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import TaskCard from '../OneAlert/TaskCard'
import TextContent from '../../../components/Chat/TextContent'
import { useQuery } from '@apollo/client'
import { GET_ANALYSIS_QUERY } from '../../../graphql/queries/analysis'
import LinearProgress from '@mui/material/LinearProgress'
import { SubHeader } from '../../../components/ObjectHeader'

const Step = ({ step, label }: { step: string; label: string }) => {
  return (
    <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0 max-w-md">
      <li className="md:flex-1">
        <div className="group flex flex-col border-l-4 border-flowmo-blue-800 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
          <span className="text-sm font-medium text-flowmo-blue-800 group-hover:text-flowmo-blue-800">
            Step {step}
          </span>
          <span className="text-sm font-medium">{label}</span>
        </div>
      </li>
    </ol>
  )
}

interface AnalysisSetupProps {
  uuid: string
}

const AnalysisSetup = ({ uuid }: AnalysisSetupProps) => {
  const [isCreatingTask, setIsCreatingTask] = React.useState(false)
  const { data, loading, refetch } = useQuery<AnalysisQuery, AnalysisQueryVariables>(
    GET_ANALYSIS_QUERY,
    {
      variables: { uuid },
      fetchPolicy: 'network-only',
    }
  )

  const onTaskCreate = () => {
    setIsCreatingTask(false)
    refetch()
  }

  const onTaskCancel = () => {
    setIsCreatingTask(false)
    refetch()
  }

  const onTaskDelete = () => {
    setIsCreatingTask(false)
    refetch()
  }

  const analysis = data?.analysis
  if (loading) {
    return <LinearProgress />
  }

  return (
    <div>
      <SubHeader object={analysis} type="analysis" subtitle="Setup" />

      <div className="mt-10">
        <Step step="1" label="Run all the tasks" />
        <div className="my-10 flex flex-col space-y-2">
          {analysis.tasks.map((oneTask) => (
            <TaskCard task={oneTask} key={oneTask.uuid} onDelete={onTaskDelete} />
          ))}
        </div>
        <div className="ml-5">
          {isCreatingTask ? (
            <div className="">
              <CreateTask
                taskableType={'Analysis'}
                taskableUuid={analysis.uuid}
                onSuccess={onTaskCreate}
                onCancel={onTaskCancel}
              />
            </div>
          ) : (
            <div className="">
              <button
                onClick={() => setIsCreatingTask(true)}
                className="flex flex-row space-x-2 items-center hover:underline text-flowmo-blue-900 hover:text-flowmo-blue-600"
              >
                <PlusCircleIcon className="h-6 w-6" /> <span>Add Task</span>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="mt-10">
        <Step step="2" label="Analyze the results" />
        <div className="mt-10">
          <TextContent content={{ type: 'text', value: analysis.instructions }} />
        </div>
      </div>
    </div>
  )
}

export default AnalysisSetup

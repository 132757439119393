import React, { useState } from 'react'
import { LoaderCircleIcon } from 'lucide-react'
import { Button } from '@headlessui/react'
import { useMutation } from '@apollo/client'
import { StepType } from '../../../utils/planTypes'
import {
  isDataSet,
  isAgentStep,
  isAlertStep,
  isAnalysisStep,
  isDataUnionStep,
  GraphQLArtifact,
} from '../CreateList'
import { CREATE_ANALYSIS_MUTATION } from '../../../graphql/queries/analysis'
import { CREATE_WORKFLOW_MUTATION } from '../../../graphql/queries/workflow'
import { CREATE_AGENT_MUTATION } from '../../../graphql/queries/agent'
import { CREATE_DATA_SET_MUTATION } from '../../../graphql/queries/data_set'
import { CREATE_DATA_UNION_MUTATION } from '../../../graphql/queries/data_union'
import { CREATE_TASK_MUTATION } from '../../../graphql/queries/task'
import { UPDATE_ARTIFACT_UUID } from '../../../graphql/queries/message_thread'
import clsx from 'clsx'
import {
  CreateAgentMutation,
  CreateAgentMutationVariables,
  CreateAnalysisMutationVariables,
  CreateDataSetMutation,
  CreateDataSetMutationVariables,
  CreateTaskMutation,
  CreateWorkflowMutationVariables,
  CreateWorkflowMutation,
  CreateAnalysisMutation,
  CreateDataUnionMutation,
  CreateDataUnionMutationVariables,
} from 'app/javascript/components/graphql'

interface CreateStepProps {
  step: StepType
  handleArtifactChange: ({ makeConsistent }: { makeConsistent: boolean }) => void
  artifact: GraphQLArtifact
}

// Helper function to check for plan dependencies recursively
const hasPlanDependencies = (step: StepType): boolean => {
  // For DataSet type, check data_sets field
  if (isDataSet(step)) {
    return false // DataSet type doesn't have nested dependencies
  }

  if (isAgentStep(step)) {
    // Check data_sets if they exist
    if (step.data_sets) {
      return step.data_sets.some((ds) => ds.uuid.type === 'plan')
    }
  }

  if (isAlertStep(step)) {
    if (step.agent_uuid.type === 'plan') {
      return true
    }
  }

  if (isDataUnionStep(step)) {
    if (step.definition.data_set_uuid.type === 'plan') {
      return true
    }
    return step.definition.joins.some((join) => join.data_set_uuid.type === 'plan')
  }

  return false
}

type ResourceKey = 'analysis' | 'workflow' | 'dataSet' | 'agent' | 'dataUnion'

type CreateResponseType =
  | CreateAnalysisMutation['createAnalysis']
  | CreateWorkflowMutation['createWorkflow']
  | CreateDataSetMutation['createDataSet']
  | CreateAgentMutation['createAgent']
  | CreateDataUnionMutation['createDataUnion']

const getResourceFromResponse = (
  response: CreateResponseType,
  resourceType: string
): { uuid: string } | undefined => {
  const mapping: Record<string, ResourceKey> = {
    Analysis: 'analysis',
    Alert: 'workflow',
    Dataset: 'dataSet',
    Agent: 'agent',
    DataUnion: 'dataUnion',
  }
  const key = mapping[resourceType]
  if (!key) {
    return undefined
  }

  // Type guard functions
  const isAnalysisResponse = (
    resp: CreateResponseType
  ): resp is CreateAnalysisMutation['createAnalysis'] => {
    return 'analysis' in resp
  }
  const isWorkflowResponse = (
    resp: CreateResponseType
  ): resp is CreateWorkflowMutation['createWorkflow'] => {
    return 'workflow' in resp
  }
  const isDataSetResponse = (
    resp: CreateResponseType
  ): resp is CreateDataSetMutation['createDataSet'] => {
    return 'dataSet' in resp
  }
  const isAgentResponse = (
    resp: CreateResponseType
  ): resp is CreateAgentMutation['createAgent'] => {
    return 'agent' in resp
  }
  const isDataUnionResponse = (
    resp: CreateResponseType
  ): resp is CreateDataUnionMutation['createDataUnion'] => {
    return 'dataUnion' in resp
  }

  // Use type guards to safely access the correct property
  switch (key) {
    case 'analysis':
      return isAnalysisResponse(response) ? response.analysis : undefined
    case 'workflow':
      return isWorkflowResponse(response) ? response.workflow : undefined
    case 'dataSet':
      return isDataSetResponse(response) ? response.dataSet : undefined
    case 'agent':
      return isAgentResponse(response) ? response.agent : undefined
    case 'dataUnion':
      return isDataUnionResponse(response) ? response.dataUnion : undefined
    default:
      return undefined
  }
}

const handleResponse = (response: CreateResponseType | null | undefined, resourceType: string) => {
  if (!response) {
    const error = `No response received for ${resourceType.toLowerCase()}`
    console.error(error)
    window.toastr.error(error)
    return null
  }

  const resource = getResourceFromResponse(response, resourceType)

  if (response.success && resource) {
    window.toastr.success(`${resourceType} created successfully`)
    return resource.uuid
  } else {
    const errors = response.errors || [`Failed to create ${resourceType.toLowerCase()}`]
    console.error(`${resourceType} creation failed:`, errors)
    window.toastr.error(errors.join(', '))
    return null
  }
}

const handleTaskCreation = async (
  createTask: any,
  tasks: any[],
  resourceUuid: string,
  taskableType: string,
  runSchedule = '',
  taskType = ''
) => {
  for (const taskDef of tasks) {
    const taskResponse = await createTask({
      variables: {
        name: taskDef.name,
        description: taskDef.description,
        task: taskDef.task_prompt,
        outputWebhookUrl: '',
        runSchedule,
        taskType,
        taskableType,
        taskableUuid: resourceUuid,
      },
    })

    if (taskResponse.data.createTask.errors.length > 0) {
      console.error('Task creation failed:', taskResponse.data.createTask.errors)
    }
  }
}

const handleDefaultTaskCreation = async (
  createTask: any,
  name: string,
  description: string,
  resourceUuid: string,
  runSchedule = ''
) => {
  const taskResponse = await createTask({
    variables: {
      name: `${name} Task`,
      description,
      task: description,
      outputWebhookUrl: '',
      runSchedule,
      taskType: 'yes_no',
      taskableType: 'Workflow',
      taskableUuid: resourceUuid,
    },
  })

  if (taskResponse.data.createTask.errors.length > 0) {
    console.error('Task creation failed:', taskResponse.data.createTask.errors)
  }
}

const handleArtifactUpdate = async (
  updateArtifactUuid: any,
  artifactUuid: string,
  planUuid: string,
  backendUuid: string
) => {
  await updateArtifactUuid({
    variables: {
      artifactUuid,
      planUuid,
      backendUuid,
    },
  })
}

const CreateStep = ({
  step,
  handleArtifactChange,
  artifact,
}: CreateStepProps): JSX.Element | null => {
  // If the step already has a backend UUID, don't show the create button
  if (step.uuid.type === 'backend') {
    return null
  }

  const [createLoading, setCreateLoading] = useState(false)
  const [createAnalysis] = useMutation<CreateAnalysisMutation, CreateAnalysisMutationVariables>(
    CREATE_ANALYSIS_MUTATION
  )
  const [createWorkflow] = useMutation<CreateWorkflowMutation, CreateWorkflowMutationVariables>(
    CREATE_WORKFLOW_MUTATION
  )
  const [createDataSet] = useMutation<CreateDataSetMutation, CreateDataSetMutationVariables>(
    CREATE_DATA_SET_MUTATION
  )
  const [createAgent] = useMutation<CreateAgentMutation, CreateAgentMutationVariables>(
    CREATE_AGENT_MUTATION
  )
  const [createDataUnion] = useMutation<CreateDataUnionMutation, CreateDataUnionMutationVariables>(
    CREATE_DATA_UNION_MUTATION
  )
  const [createTask] = useMutation<CreateTaskMutation>(CREATE_TASK_MUTATION)
  const [updateArtifactUuid] = useMutation(UPDATE_ARTIFACT_UUID)

  const hasUnresolvedDependencies = hasPlanDependencies(step)

  const handleCreate = async () => {
    if (
      !isDataSet(step) &&
      !isAgentStep(step) &&
      !isAlertStep(step) &&
      !isAnalysisStep(step) &&
      !isDataUnionStep(step)
    ) {
      return
    }

    setCreateLoading(true)
    try {
      let resourceUuid: string | null = null

      if (isAnalysisStep(step)) {
        const { data } = await createAnalysis({
          variables: {
            name: step.name,
            description: step.description,
            instructions: step.instructions,
            outputFormat: step.output_format,
            agentUuid: step.agent_uuid.value,
          },
        })

        resourceUuid = handleResponse(data?.createAnalysis, 'Analysis')
        if (resourceUuid && step.tasks?.length > 0) {
          await handleTaskCreation(createTask, step.tasks, resourceUuid, 'Analysis')
        }
      } else if (isAlertStep(step)) {
        const { data } = await createWorkflow({
          variables: {
            name: step.name,
            description: step.description,
            agentUuid: step.agent_uuid.value,
            cronSchedule: step.cron_schedule,
          },
        })

        resourceUuid = handleResponse(data?.createWorkflow, 'Alert')
        if (resourceUuid) {
          if (step.tasks?.length > 0) {
            await handleTaskCreation(
              createTask,
              step.tasks,
              resourceUuid,
              'Workflow',
              step.cron_schedule || '',
              'yes_no'
            )
          } else {
            await handleDefaultTaskCreation(
              createTask,
              step.name,
              step.description,
              resourceUuid,
              step.cron_schedule || ''
            )
          }
        }
      } else if (isDataSet(step)) {
        const definition =
          step.backend === 'snowflake'
            ? {
                snowflake: {
                  schema: step.definition.table_definition.schema || '',
                  table: step.definition.table_definition.table_name || '',
                  derived_table: step.definition.table_definition.sql || '',
                },
              }
            : {
                bigquery: {
                  dataset: step.definition.table_definition.schema || '',
                  table: step.definition.table_definition.table_name || '',
                  derived_table: step.definition.table_definition.sql || '',
                },
              }

        const { data } = await createDataSet({
          variables: {
            name: step.name,
            description: step.description,
            definition: JSON.stringify(definition),
          },
        })
        resourceUuid = handleResponse(data?.createDataSet, 'Dataset')
      } else if (isAgentStep(step)) {
        const { data } = await createAgent({
          variables: {
            firstName: step.first_name,
            jobTitle: step.job_title || '',
            shortDescription: step.short_description,
            description: step.long_description,
            primer: step.system_instruction,
          },
        })

        resourceUuid = handleResponse(data?.createAgent, 'Agent')
      } else if (isDataUnionStep(step)) {
        const definition = {
          dataSet: step.definition.data_set_uuid.value,
          joins: step.definition.joins.map((join) => ({
            dataSet: join.data_set_uuid.value,
            type: join.type,
            relationship: join.relationship,
            sqlOn: join.sql_on,
          })),
        }

        const { data } = await createDataUnion({
          variables: {
            name: step.name,
            description: step.description,
            definition: JSON.stringify(definition),
            isEditable: true,
          },
        })

        resourceUuid = handleResponse(data?.createDataUnion, 'DataUnion')
      }

      if (resourceUuid) {
        await handleArtifactUpdate(updateArtifactUuid, artifact.uuid, step.uuid.value, resourceUuid)
        handleArtifactChange({ makeConsistent: true })
      }
    } catch (error) {
      console.error('Error creating resource:', error)
      window.toastr.error('Error creating resource')
    } finally {
      setCreateLoading(false)
    }
  }

  return (
    <>
      {!createLoading && (
        <Button
          onClick={handleCreate}
          disabled={hasUnresolvedDependencies}
          className={clsx(
            'text-xs border border-gray-300 px-4 py-1 rounded-md',
            hasUnresolvedDependencies
              ? 'opacity-50 cursor-not-allowed'
              : 'hover:bg-blue-500 hover:text-white'
          )}
        >
          {hasUnresolvedDependencies ? 'Dependencies Pending' : 'Create'}
        </Button>
      )}
      {createLoading && <LoaderCircleIcon className="text-gray-500 animate-spin" />}
    </>
  )
}

export default CreateStep

import { gql } from '@apollo/client'
import {
  AGENT_FIELDS,
  ARTIFACT_FIELDS,
  MESSAGE_HISTORY_FIELDS,
  SIDE_EFFECT_FIELDS,
  STEP_HISTORY_FIELDS,
} from './fragments'

export const GET_MESSAGE_HISTORIES = gql`
  query getMessageHistories($uuid: String!) {
    getMessageHistories(uuid: $uuid) {
      ...MessageHistoryFields
    }
  }

  ${MESSAGE_HISTORY_FIELDS}
`

export const GET_STEP_HISTORIES = gql`
  query getStepHistories($uuid: String!) {
    getStepHistories(uuid: $uuid) {
      ...StepHistoryFields
    }
  }

  ${STEP_HISTORY_FIELDS}
`

export const GET_SIDE_EFFECT_HISTORIES = gql`
  query getSideEffectHistories($uuid: String!) {
    getSideEffectHistories(uuid: $uuid) {
      ...SideEffectFields
    }
  }

  ${SIDE_EFFECT_FIELDS}
`

export const GET_MESSAGE_THREADS = gql`
  query getMessageThreads($agentUuid: String!, $limit: Int, $offset: Int) {
    getMessageThreads(agentUuid: $agentUuid, limit: $limit, offset: $offset) {
      createdAt
      updatedAt
      uuid
    }
  }
`

export const GET_MESSAGE_THREAD = gql`
  query getMessageThread($uuid: String!) {
    getMessageThread(uuid: $uuid) {
      messages {
        ...MessageHistoryFields
      }
      steps {
        ...StepHistoryFields
      }
      agent {
        ...AgentFields
      }
      sideEffects {
        ...SideEffectFields
      }
    }
  }

  ${MESSAGE_HISTORY_FIELDS}
  ${STEP_HISTORY_FIELDS}
  ${SIDE_EFFECT_FIELDS}
  ${AGENT_FIELDS}
`

export const ADD_MESSAGE_TO_THREAD = gql`
  mutation addMessageToThread($threadUuid: String!, $message: MessageHistoryInput!) {
    addMessageToThread(threadUuid: $threadUuid, message: $message) {
      success
      errors
    }
  }
`

export const ADD_STEP_TO_THREAD = gql`
  mutation addStepToThread($threadUuid: String!, $step: StepHistoryInput!) {
    addStepToThread(threadUuid: $threadUuid, step: $step) {
      success
      errors
    }
  }
`

export const ADD_SIDE_EFFECT_TO_THREAD = gql`
  mutation addSideEffectToThread($threadUuid: String!, $sideEffect: SideEffectInput!) {
    addSideEffectToThread(threadUuid: $threadUuid, sideEffect: $sideEffect) {
      success
      errors
    }
  }
`

export const CREATE_MESSAGE_THREAD = gql`
  mutation createMessageThread($agentUuid: String!) {
    createMessageThread(agentUuid: $agentUuid) {
      uuid
    }
  }
`

export const GET_CONVERSATION = gql`
  query getConversation($uuid: String!) {
    getConversation(uuid: $uuid) {
      ... on MessageHistory {
        ...MessageHistoryFields
      }
      ... on StepHistory {
        ...StepHistoryFields
      }
      ... on SideEffect {
        ...SideEffectFields
      }
    }
  }

  ${MESSAGE_HISTORY_FIELDS}
  ${STEP_HISTORY_FIELDS}
  ${SIDE_EFFECT_FIELDS}
`

export const GET_ARTIFACTS = gql`
  query artifacts($messageThreadUuid: String!, $makeConsistent: Boolean) {
    artifacts(messageThreadUuid: $messageThreadUuid, makeConsistent: $makeConsistent) {
      ...ArtifactFields
    }
  }

  ${ARTIFACT_FIELDS}
`

export const CREATE_ARTIFACT = gql`
  mutation createArtifact(
    $messageThreadUuid: String!
    $name: String!
    $description: String!
    $content: JSON!
  ) {
    createArtifact(
      messageThreadUuid: $messageThreadUuid
      name: $name
      description: $description
      content: $content
    ) {
      ...ArtifactFields
    }
  }

  ${ARTIFACT_FIELDS}
`

export const UPDATE_ARTIFACT = gql`
  mutation updateArtifact($uuid: String!, $name: String!, $description: String!, $content: JSON!) {
    updateArtifact(uuid: $uuid, name: $name, description: $description, content: $content) {
      ...ArtifactFields
    }
  }

  ${ARTIFACT_FIELDS}
`

export const DELETE_ARTIFACT = gql`
  mutation deleteArtifact($uuid: String!) {
    deleteArtifact(uuid: $uuid) {
      success
      errors
    }
  }
`

export const UPDATE_ARTIFACT_UUID = gql`
  mutation updateArtifactUuid($artifactUuid: String!, $planUuid: String!, $backendUuid: String!) {
    updateArtifactUuid(
      artifactUuid: $artifactUuid
      planUuid: $planUuid
      backendUuid: $backendUuid
    ) {
      ...ArtifactFields
    }
  }

  ${ARTIFACT_FIELDS}
`

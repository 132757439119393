import React, { useCallback, useEffect, useState } from 'react'
import AgentSidebar from './AgentSidebar'
import ThreadList from './ThreadList'
import PromptInput from '../../components/Chat/PromptInput'
import SamplePrompts from './SamplePrompts'
import AgentIntro from './AgentIntro'
import AgentChat from '../../components/AgentChat'
import { GET_AGENT_QUERY } from '../../graphql/queries/agent'
import {
  AgentQueryVariables,
  CreateMessageThreadMutation,
  CreateMessageThreadMutationVariables,
} from 'app/javascript/components/graphql'
import { AgentQuery } from 'app/javascript/components/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_MESSAGE_THREAD } from '../../graphql/queries/message_thread'

const ChatSurface = () => {
  const [selectedAgentUuid, setSelectedAgentUuid] = useState<string | null>(null)
  const [selectedThreadUuid, setSelectedThreadUuid] = useState<string | null>(null)
  const [query, setQuery] = useState<string | null>(null)
  const [newThreadUuid, setNewThreadUuid] = useState<string | null>(null)

  const { data } = useQuery<AgentQuery, AgentQueryVariables>(GET_AGENT_QUERY, {
    skip: !selectedAgentUuid,
    variables: {
      uuid: selectedAgentUuid,
    },
  })
  const agent = data?.agent

  const [createThread] = useMutation<
    CreateMessageThreadMutation,
    CreateMessageThreadMutationVariables
  >(CREATE_MESSAGE_THREAD)

  const handleNewThreadClick = () => {
    setQuery(null)
    setSelectedThreadUuid(null)

    // remove the thread_uuid from the url
    const url = new URL(window.location.href)
    url.searchParams.delete('thread_uuid')
    window.history.replaceState({}, '', url.toString())
  }

  const handleThreadClick = (threadUuid: string) => {
    setSelectedThreadUuid(threadUuid)
    setQuery(null)
  }

  const handleAgentClick = (agentUuid: string) => {
    setSelectedAgentUuid(agentUuid)
    setSelectedThreadUuid(null)
    setQuery(null)
  }

  const handlePromptSubmit = useCallback(
    (query: string) => {
      if (!selectedAgentUuid) {
        return
      }

      createThread({
        variables: {
          agentUuid: selectedAgentUuid,
        },
      }).then((res) => {
        setQuery(query)
        const oneNewThreadUuid = res.data?.createMessageThread?.uuid
        setSelectedThreadUuid(oneNewThreadUuid)
        setNewThreadUuid(oneNewThreadUuid)
      })
    },
    [selectedAgentUuid, createThread]
  )

  // get the agent_uuid and thread_uuid from the url on start
  useEffect(() => {
    const url = new URL(window.location.href)
    const agentUuid = url.searchParams.get('agent_uuid')
    if (agentUuid) {
      setSelectedAgentUuid(agentUuid)
    }

    const threadUuid = url.searchParams.get('thread_uuid')
    if (threadUuid) {
      setSelectedThreadUuid(threadUuid)
    }
  }, [])

  // add the agent_uuid to the url as it changes
  useEffect(() => {
    const url = new URL(window.location.href)

    if (selectedAgentUuid) {
      // add it as a query param
      url.searchParams.set('agent_uuid', selectedAgentUuid)
      window.history.replaceState({}, '', url.toString())
    } else {
      // remove it from the url
      url.searchParams.delete('agent_uuid')
      url.searchParams.delete('thread_uuid')
      window.history.replaceState({}, '', url.toString())
    }
  }, [selectedAgentUuid])

  return (
    <div className="absolute inset-0 flex flex-row h-full">
      <div className="flex flex-col h-full">
        <AgentSidebar onAgentClick={handleAgentClick} />
      </div>
      <div className="flex flex-col h-full shadow-inner">
        <ThreadList
          key={`${selectedAgentUuid}-${newThreadUuid}`}
          agentUuid={selectedAgentUuid}
          threadUuid={selectedThreadUuid}
          onThreadClick={handleThreadClick}
          onNewThreadClick={handleNewThreadClick}
        />
      </div>
      <div className="flex-grow flex flex-col h-full w-full bg-gray-200 shadow-inner overflow-y-auto">
        {selectedAgentUuid &&
          (selectedThreadUuid ? (
            <AgentChat
              key={`${selectedAgentUuid}-${selectedThreadUuid}`}
              agent={agent}
              initialThreadUuid={selectedThreadUuid}
              initialQuery={query}
              useUrlParams={true}
              showName={false}
            />
          ) : (
            <div className="flex-grow flex flex-col items-center justify-center w-full space-y-4">
              <AgentIntro agentUuid={selectedAgentUuid} />
              <SamplePrompts
                key={selectedAgentUuid}
                handleSubmit={handlePromptSubmit}
                agentUuid={selectedAgentUuid}
              />

              <div className="w-full max-w-xl">
                <PromptInput
                  handleSubmit={handlePromptSubmit}
                  isQuerying={false}
                  allowFileUpload={true}
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default ChatSurface

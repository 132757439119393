import React, { useEffect, useRef } from 'react'
import PromptInput from '../Chat/PromptInput'
import MessageThread from './MessageThread'
import logoMark from '../../../../../assets/images/FlowMo-White-Mark.png'
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline'
import { LinearProgress, Tooltip } from '@mui/material'
import { useQuery } from '@apollo/client'
import { CompanyAgentQuery, CompanyAgentQueryVariables } from 'app/javascript/components/graphql'
import { GET_COMPANY_AGENT } from '../../graphql/queries/agent'
import { useChat } from '../../hooks/useChat'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { companyAgentExamplePrompts } from '../../pages/ChatSurface/SamplePrompts'

const SidebarChat = () => {
  const divRef = useRef<HTMLDivElement>(null)

  // 1. Get the company agent via GraphQL
  const { user } = useSelector((state: RootState) => state.app)
  const { data, loading } = useQuery<CompanyAgentQuery, CompanyAgentQueryVariables>(
    GET_COMPANY_AGENT
  )
  const agent = data?.companyAgent

  // 2. Initialize the chat using our custom hook.
  //    Passing the agent is required so `useChat` can handle sending messages, etc.
  const { chatState, resetChat, handleSubmit } = useChat({ agent })

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [chatState])

  // 3. Display a spinner if agent is not ready or hook has not fully initialized
  if (loading || !agent || !chatState) {
    return <LinearProgress />
  }

  const showIntro = !chatState.threadUuid && !chatState.isQuerying

  return (
    <div className="conversation flex flex-col h-full bg-white">
      <div className="message_thread flex-grow overflow-y-auto p-2">
        <div className="absolute z-20 top-0 left-0 px-2 py-4 bg-white inset-1 w-full h-14 flex flex-row border-b space-x-2 border-gray-300">
          <img src={logoMark} alt="avatar" className="size-6" />
          <div className="flex-grow text-md font-semibold text-gray-800">Work Intelligence</div>
          {!showIntro && (
            <div className="">
              <Tooltip title="New Chat" placement="bottom" arrow={true}>
                <ArrowUturnLeftIcon
                  onClick={resetChat}
                  className="size-6 cursor-pointer hover:rounded-full hover:bg-gray-300 p-1"
                />
              </Tooltip>
            </div>
          )}
        </div>
        <div className="mt-14">
          {showIntro && (
            <>
              <div className="pt-10 flex flex-col items-center justify-start text-xl font-semibold">
                <div className="flex-col inline-block overflow-hidden break-words bg-clip-text text-transparent bg-gradient-to-r from-[#217bfe] to-[#ac87eb] via-[#078efb]">
                  Hello, {user.firstName} -
                </div>
                <div className=" text-gray-600">How can I help you today?</div>
              </div>
              <div className="flex flex-col space-y-1 mt-5 justify-center items-center text-sm mx-4">
                {companyAgentExamplePrompts.map((prompt, index) => (
                  <div
                    key={index}
                    className="hover:underline cursor-pointer border bg-[#f0f4f9] hover:bg-[#DDE3EA] first:rounded-t-xl last:rounded-b-xl w-full flex items-center justify-center py-2"
                    onClick={() => handleSubmit(prompt)}
                  >
                    {prompt}
                  </div>
                ))}
              </div>
            </>
          )}

          <MessageThread chatState={chatState} showName={false} />
          <div ref={divRef} />
        </div>
      </div>
      <div className="prompt_input">
        <div className="max-w-3xl mx-auto px-8 pt-4 pb-2 bg-white bg-opacity-80 rounded-full border-t ">
          <PromptInput isQuerying={chatState.isQuerying} handleSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  )
}

export default SidebarChat

import React from 'react'
import PageHeader from '../../components/PageHeader'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { CREATE_DATA_UNION_MUTATION } from '../../graphql/queries/data_union'
import Spin from '../../../../components/common/ui/Spin'
import DynamicForm, { FieldType } from '../../components/DynamicForm'
import {
  CreateDataUnionMutation,
  CreateDataUnionMutationVariables,
  DataUnionDefinition,
  DataUnionJoin,
} from 'app/javascript/components/graphql'

export const dataUnionFormData = [
  {
    name: 'name',
    label: 'Name',
    type: FieldType.Text,
    required: true,
    defaultValue: '',
  },
  {
    name: 'description',
    label: 'Description',
    type: FieldType.Textarea,
    required: true,
    defaultValue: '',
  },
  {
    name: 'definition',
    label: 'Definition',
    type: FieldType.DataUnionDefinition,
    required: true,
    defaultValue: {
      dataSet: '',
      joins: [] as DataUnionJoin[],
    } satisfies DataUnionDefinition,
  },
]

const CreateDataUnion = () => {
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const history = useHistory()
  const pages = [{ title: 'Data Model', to: '/data_model' }, { title: 'Create new data union' }]

  const [isSaving, setIsSaving] = React.useState(false)
  const [createDataUnion] = useMutation<CreateDataUnionMutation, CreateDataUnionMutationVariables>(
    CREATE_DATA_UNION_MUTATION
  )

  const handleSave = async ({
    name,
    description,
    definition,
  }: {
    name: string
    description: string
    definition: DataUnionDefinition
  }) => {
    setIsSaving(true)

    try {
      const { data } = await createDataUnion({
        variables: {
          name: name.trim(),
          description: description.trim(),
          definition,
          isEditable: true,
        },
      })
      window.toastr.success('Data Union added successfully')
      history.push('/data_union/' + data.createDataUnion.dataUnion.uuid)
    } catch (error) {
      window.toastr.error('Error adding data union')
      history.push('/data_model')
    }
    setIsSaving(false)
  }

  return (
    <React.Fragment>
      <PageHeader title="Create Data Union" breadCrumbs={pages} />
      <div className="max-w-2xl">
        <DynamicForm ref={formRef} formData={dataUnionFormData} handleSubmit={handleSave} />
        <button
          type="submit"
          onClick={() => formRef.current?.submitForm()}
          className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-flowmo-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-flowmo-blue-800"
          disabled={isSaving}
        >
          {isSaving ? <Spin /> : 'Create'}
        </button>
      </div>
    </React.Fragment>
  )
}

export default CreateDataUnion

import React, { useState, useEffect, useMemo, useRef } from 'react'
import { FieldData, FieldType } from '.'
import clsx from 'clsx'
import { Combobox } from '@headlessui/react'
import { useQuery, useApolloClient } from '@apollo/client'
import { GET_ORGANIZATION_DATA_SETS, GET_DATA_SET_FIELDS } from '../../graphql/queries/data_set'
import {
  OrganizationDataSetsQuery,
  OrganizationDataSetsQueryVariables,
  DataSetFieldsQuery,
  DataSetFieldsQueryVariables,
  DataUnionDefinition,
  DataUnionJoin,
  JoinTypeEnum,
  JoinRelationshipEnum,
} from '../../../../components/graphql'
import { PlusIcon, XMarkIcon, ChevronUpDownIcon, CheckIcon } from '@heroicons/react/24/solid'

interface DataUnionDefinitionFieldProps {
  field: FieldData
  formik: any
}

interface Field {
  name: string
  type: FieldType
  category: string
}

const JOIN_TYPES = [
  { value: JoinTypeEnum.Inner, label: 'Inner Join' },
  { value: JoinTypeEnum.LeftOuter, label: 'Left Outer Join' },
  { value: JoinTypeEnum.RightOuter, label: 'Right Outer Join' },
  { value: JoinTypeEnum.FullOuter, label: 'Full Outer Join' },
  { value: JoinTypeEnum.Cross, label: 'Cross Join' },
]

const RELATIONSHIP_TYPES = [
  { value: JoinRelationshipEnum.OneToOne, label: 'One to One' },
  { value: JoinRelationshipEnum.OneToMany, label: 'One to Many' },
  { value: JoinRelationshipEnum.ManyToOne, label: 'Many to One' },
  { value: JoinRelationshipEnum.ManyToMany, label: 'Many to Many' },
]

const DataUnionDefinitionField: React.FC<DataUnionDefinitionFieldProps> = ({ field, formik }) => {
  const [definition, setDefinition] = useState<DataUnionDefinition>(() => {
    try {
      return JSON.parse(formik.values[field.name])
    } catch {
      return {
        dataSet: '',
        joins: [],
      }
    }
  })

  const [queries, setQueries] = useState<{ [key: string]: string }>({})
  const { data: dataSetsData } = useQuery<
    OrganizationDataSetsQuery,
    OrganizationDataSetsQueryVariables
  >(GET_ORGANIZATION_DATA_SETS, {
    fetchPolicy: 'cache-and-network',
  })

  const dataSets = dataSetsData?.organizationDataSets || []
  const getFilteredDataSets = (queryKey: string) => {
    const query = queries[queryKey] || ''
    const baseDataSetBackendType = dataSets.find(
      (ds) => ds.uuid === definition.dataSet
    )?.backendType

    // Filter datasets by matching backend type and query
    const filteredDataSets = dataSets.filter((dataSet) => {
      // For base dataset selection, show all datasets
      if (queryKey === 'base') {
        return true
      }

      // For joins, only show datasets with matching backend type
      if (baseDataSetBackendType && dataSet.backendType !== baseDataSetBackendType) {
        return false
      }

      // Apply search query filter
      return (
        query === '' ||
        dataSet.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )
    })

    return filteredDataSets.slice(0, 10) // Show first 10 results
  }

  const [dataSetFields, setDataSetFields] = useState<{ [key: string]: Field[] }>({})
  const [sqlFieldQuery, setSqlFieldQuery] = useState<{ [key: string]: string }>({})
  const [cursorPosition, setCursorPosition] = useState<{ [key: string]: number }>({})
  const [showFieldSuggestions, setShowFieldSuggestions] = useState<{ [key: string]: boolean }>({})

  // Keep track of which dataset fields we've already fetched
  const [fetchedDataSetIds, setFetchedDataSetIds] = useState<Set<string>>(new Set())

  // Get all dataset IDs we need fields for (base dataset + join datasets)
  const allDataSetIds = useMemo(() => {
    const ids = new Set<string>()
    if (definition.dataSet) {
      ids.add(definition.dataSet)
    }
    definition.joins.forEach((join) => {
      if (join.dataSet) {
        ids.add(join.dataSet)
      }
    })
    return Array.from(ids)
  }, [definition.dataSet, definition.joins])

  // Fetch fields for datasets we haven't fetched yet
  const unfetchedDataSetIds = useMemo(
    () => allDataSetIds.filter((id) => !fetchedDataSetIds.has(id)),
    [allDataSetIds, fetchedDataSetIds]
  )

  // Effect to fetch fields for new datasets
  const apolloClient = useApolloClient()
  useEffect(() => {
    const loadDataSetFields = async () => {
      for (const dataSetId of unfetchedDataSetIds) {
        try {
          const { data } = await apolloClient.query<
            DataSetFieldsQuery,
            DataSetFieldsQueryVariables
          >({
            query: GET_DATA_SET_FIELDS,
            variables: { uuid: dataSetId },
          })

          if (data.dataSet?.functionDefinition?.fields) {
            const convertedFields: Field[] = data.dataSet.functionDefinition.fields.map(
              (field) => ({
                name: field.name,
                type: field.type as FieldType,
                category: field.category,
              })
            )

            setDataSetFields((prev) => ({
              ...prev,
              [dataSetId]: convertedFields,
            }))

            setFetchedDataSetIds((prev) => new Set([...prev, dataSetId]))
          }
        } catch (error) {
          console.error(`Error fetching fields for dataset ${dataSetId}:`, error)
        }
      }
    }

    if (unfetchedDataSetIds.length > 0) {
      loadDataSetFields()
    }
  }, [unfetchedDataSetIds, apolloClient])

  useEffect(() => {
    // Validate and clean the definition before saving
    const cleanDefinition = {
      ...definition,
      joins: definition.joins
        .map((join) => ({
          ...join,
          // Ensure the join has all required fields and they're not empty
          type: join.type || JoinTypeEnum.Inner,
          relationship: join.relationship || JoinRelationshipEnum.OneToOne,
          sqlOn: join.sqlOn?.trim() || '',
          dataSet: join.dataSet || '',
        }))
        .filter(
          (join) =>
            // Filter out invalid joins
            join.dataSet &&
            join.dataSet !== definition.dataSet && // Prevent self-joins
            join.sqlOn
        ),
    }

    // Only save if we have valid data
    if (cleanDefinition.dataSet) {
      formik.setFieldValue(field.name, JSON.stringify(cleanDefinition))
    }
  }, [definition])

  const handleBaseDataSetChange = (uuid: string) => {
    setDefinition((prev: DataUnionDefinition) => ({
      ...prev,
      dataSet: uuid,
    }))
  }

  const handleJoinDataSetChange = (index: number, uuid: string) => {
    // Prevent selecting the same dataset as the base dataset
    if (uuid === definition.dataSet) {
      return
    }

    setDefinition((prev: DataUnionDefinition) => ({
      ...prev,
      joins: prev.joins.map((join: DataUnionJoin, i: number) =>
        i === index ? { ...join, dataSet: uuid } : join
      ),
    }))
  }

  const handleJoinTypeChange = (index: number, type: JoinTypeEnum) => {
    setDefinition((prev: DataUnionDefinition) => ({
      ...prev,
      joins: prev.joins.map((join: DataUnionJoin, i: number) =>
        i === index ? { ...join, type } : join
      ),
    }))
  }

  const handleRelationshipChange = (index: number, relationship: JoinRelationshipEnum) => {
    setDefinition((prev: DataUnionDefinition) => ({
      ...prev,
      joins: prev.joins.map((join: DataUnionJoin, i: number) =>
        i === index ? { ...join, relationship } : join
      ),
    }))
  }

  const handleSqlOnChange = (index: number, value: string, cursorPos: number) => {
    setDefinition((prev: DataUnionDefinition) => ({
      ...prev,
      joins: prev.joins.map((join: DataUnionJoin, i: number) =>
        i === index ? { ...join, sqlOn: value } : join
      ),
    }))

    // Update cursor position
    setCursorPosition((prev) => ({ ...prev, [index]: cursorPos }))

    // Get the word being typed
    const textBeforeCursor = value.slice(0, cursorPos)
    const match = textBeforeCursor.match(/\w+$/)
    const currentWord = match ? match[0].toLowerCase() : ''

    if (currentWord.length >= 3) {
      setSqlFieldQuery((prev) => ({ ...prev, [index]: currentWord }))
      setShowFieldSuggestions((prev) => ({ ...prev, [index]: true }))
    } else {
      setShowFieldSuggestions((prev) => ({ ...prev, [index]: false }))
    }
  }

  const getTableName = (dataSet: any) => {
    if (!dataSet) {
      return ''
    }
    try {
      const definition =
        typeof dataSet.definition === 'string' ? JSON.parse(dataSet.definition) : dataSet.definition
      return definition?.tableName || dataSet.name.toLowerCase().replace(/\s+/g, '_')
    } catch {
      return dataSet.name.toLowerCase().replace(/\s+/g, '_')
    }
  }

  const getFieldSuggestions = (index: number) => {
    const query = sqlFieldQuery[index] || ''
    if (query.length < 3) {
      return []
    }

    const baseDataSet = dataSets.find((ds) => ds.uuid === definition.dataSet)
    const relevantDataSets = [
      {
        uuid: definition.dataSet,
        name: getDataSetName(definition.dataSet),
        tableName: getTableName(baseDataSet),
      },
      ...definition.joins.slice(0, index + 1).map((join) => {
        const joinDataSet = dataSets.find((ds) => ds.uuid === join.dataSet)
        return {
          uuid: join.dataSet,
          name: getDataSetName(join.dataSet),
          tableName: getTableName(joinDataSet),
        }
      }),
    ]

    const queryLower = query.toLowerCase()
    return relevantDataSets.flatMap((ds) => {
      const fields = dataSetFields[ds.uuid] || []
      return fields
        .filter(
          (field) =>
            field.name.toLowerCase().includes(queryLower) ||
            ds.tableName.toLowerCase().includes(queryLower)
        )
        .map((field) => ({
          ...field,
          fullName: `${ds.tableName}.${field.name}`,
        }))
    })
  }

  // Add refs for textareas
  const textareaRefs = useRef<{ [key: number]: HTMLTextAreaElement }>({})

  const insertFieldAtCursor = (index: number, fieldName: string) => {
    const join = definition.joins[index]
    const cursorPos = cursorPosition[index] || 0
    const newValue =
      join.sqlOn.slice(0, cursorPos).replace(/\w+$/, '') + fieldName + join.sqlOn.slice(cursorPos)

    setDefinition((prev: DataUnionDefinition) => ({
      ...prev,
      joins: prev.joins.map((j: DataUnionJoin, i: number) =>
        i === index ? { ...j, sqlOn: newValue } : j
      ),
    }))
    setShowFieldSuggestions((prev) => ({ ...prev, [index]: false }))

    // Focus the textarea and set cursor position after the inserted field
    const textarea = textareaRefs.current[index]
    if (textarea) {
      const newCursorPos =
        cursorPos - (join.sqlOn.slice(0, cursorPos).match(/\w+$/)?.length || 0) + fieldName.length
      // Use setTimeout to ensure the state update has completed
      setTimeout(() => {
        textarea.focus()
        textarea.setSelectionRange(newCursorPos, newCursorPos)
      }, 0)
    }
  }

  const addJoin = () => {
    setDefinition((prev: DataUnionDefinition) => ({
      ...prev,
      joins: [
        ...prev.joins,
        {
          __typename: 'DataUnionJoin',
          type: JoinTypeEnum.Inner,
          relationship: JoinRelationshipEnum.OneToOne,
          sqlOn: '',
          dataSet: '',
        },
      ],
    }))
  }

  const removeJoin = (index: number) => {
    setDefinition((prev: DataUnionDefinition) => ({
      ...prev,
      joins: prev.joins.filter((_: DataUnionJoin, i: number) => i !== index),
    }))
  }

  const getDataSetName = (uuid: string) => {
    return dataSets.find((ds) => ds.uuid === uuid)?.name || ''
  }

  const renderDataSetCombobox = (
    value: string,
    onChange: (value: string) => void,
    queryKey: string
  ) => (
    <Combobox value={value} onChange={onChange}>
      <div className="relative mt-1">
        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-flowmo-blue-300 sm:text-sm">
          <Combobox.Input
            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
            displayValue={(uuid: string) => getDataSetName(uuid)}
            onChange={(event) =>
              setQueries((prev) => ({ ...prev, [queryKey]: event.target.value }))
            }
            placeholder="Select a dataset"
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
        </div>
        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {getFilteredDataSets(queryKey).length === 0 ? (
            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
              {queryKey !== 'base' && definition.dataSet ? (
                <>
                  Only datasets with backend type &apos;
                  {dataSets.find((ds) => ds.uuid === definition.dataSet)?.backendType}
                  &apos; can be joined.
                </>
              ) : (
                <>Nothing found.</>
              )}
            </div>
          ) : (
            getFilteredDataSets(queryKey).map((dataSet) => (
              <Combobox.Option
                key={dataSet.uuid}
                className={({ active }) =>
                  clsx(
                    'relative cursor-default select-none py-2 pl-10 pr-4',
                    active ? 'bg-flowmo-blue-600 text-white' : 'text-gray-900'
                  )
                }
                value={dataSet.uuid}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={clsx('block truncate', selected ? 'font-medium' : 'font-normal')}
                    >
                      {dataSet.name}
                    </span>
                    {selected ? (
                      <span
                        className={clsx(
                          'absolute inset-y-0 left-0 flex items-center pl-3',
                          active ? 'text-white' : 'text-flowmo-blue-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Combobox.Option>
            ))
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  )

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Base Dataset</label>
        {renderDataSetCombobox(definition.dataSet, handleBaseDataSetChange, 'base')}
      </div>

      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <label className="block text-sm font-medium text-gray-700">Joins</label>
          <button
            type="button"
            onClick={addJoin}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-flowmo-blue-700 bg-flowmo-blue-100 hover:bg-flowmo-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-flowmo-blue-500"
          >
            <PlusIcon className="h-4 w-4 mr-1" />
            Add Join
          </button>
        </div>

        {definition.joins.map((join, index) => (
          <div key={index} className="border border-gray-200 rounded-lg p-4 space-y-4">
            <div className="flex justify-between items-start">
              <h4 className="text-sm font-medium text-gray-900">Join {index + 1}</h4>
              <button
                type="button"
                onClick={() => removeJoin(index)}
                className="text-gray-400 hover:text-gray-500"
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Dataset</label>
              {renderDataSetCombobox(
                join.dataSet,
                (value) => handleJoinDataSetChange(index, value),
                `join-${index}`
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Join Type</label>
              <select
                value={join.type}
                onChange={(e) => handleJoinTypeChange(index, e.target.value as JoinTypeEnum)}
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-flowmo-blue-500 focus:outline-none focus:ring-flowmo-blue-500 sm:text-sm"
              >
                {JOIN_TYPES.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Relationship</label>
              <select
                value={join.relationship}
                onChange={(e) =>
                  handleRelationshipChange(index, e.target.value as JoinRelationshipEnum)
                }
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-flowmo-blue-500 focus:outline-none focus:ring-flowmo-blue-500 sm:text-sm"
              >
                {RELATIONSHIP_TYPES.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                SQL Join Condition
              </label>
              <div className="relative">
                <textarea
                  ref={(el) => {
                    if (el) {
                      textareaRefs.current[index] = el
                    }
                  }}
                  value={join.sqlOn}
                  onChange={(e) =>
                    handleSqlOnChange(index, e.target.value, e.target.selectionStart)
                  }
                  onSelect={(e) =>
                    setCursorPosition((prev) => ({
                      ...prev,
                      [index]: (e.target as HTMLTextAreaElement).selectionStart,
                    }))
                  }
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-flowmo-blue-500 focus:ring-flowmo-blue-500 sm:text-sm"
                  placeholder="Enter SQL join condition (e.g. table1.id = table2.id)"
                />
                {showFieldSuggestions[index] && (
                  <div className="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {getFieldSuggestions(index).map((suggestion, i) => (
                      <div
                        key={i}
                        className={clsx(
                          'relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-flowmo-blue-600 hover:text-white'
                        )}
                        onClick={() => insertFieldAtCursor(index, suggestion.fullName)}
                      >
                        <div className="flex items-center justify-between">
                          <span className="font-medium block truncate">{suggestion.fullName}</span>
                          <span
                            className={clsx(
                              'ml-2 text-xs',
                              'hover:bg-flowmo-blue-600 hover:text-white',
                              'text-gray-500'
                            )}
                          >
                            ({suggestion.type})
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {formik.touched[field.name] && formik.errors[field.name] ? (
        <div className="text-red-500 text-xs mt-1">{formik.errors[field.name]}</div>
      ) : null}
    </div>
  )
}

export default DataUnionDefinitionField
